import { combineReducers } from "redux"
import accountReducer from "./account"
import callReducer from "./call"
import notificationsReducer from "./notifications"
import commonDataReducer from "./commonData"

const swipcarAdministrator = combineReducers({
  accountReducer,
  callReducer,
  commonDataReducer,
  notificationsReducer
})

export default swipcarAdministrator
