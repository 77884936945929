import PropTypes from "prop-types"
import { gql, useQuery } from "@apollo/client"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import MenuItem from "@material-ui/core/MenuItem"
import { pick } from "lodash"
import useAbilities from "../../../../hooks/useAbilities"
import Loading from "../../../../components/Loading"
import CarListDialog from "../../../../components/CarListDialog"
import Breadcrumb from "../../../../components/layouts/Breadcrumb"
import CurrentTask from "../../../../components/CurrentTask"
import { CarListDialogContext, LeasingRequestCarContext } from "../../../../contexts"
import EntityLayout from "../../../../components/layouts/EntityLayout"
import PendingScheduledCall from "../../../../components/ScheduledCallChip"
import CommentFormWrapper from "../../../../components/CommentFormWrapper"
import Alerts from "./Alerts"
import PrimaryActions from "./PrimaryActions"
import SecondaryActions from "./SecondaryActions"
import SubStatusChips from "./SubStatusChips"
import TabsScene from "./TabsScene"
import AddComment from "../../../../components/forms/AddComment"
import StatusChip from "../../../../components/StatusChip"
import FormDialog from "../../../../components/FormDialog"
import UploadDocuments from "../../../../components/forms/UploadDocuments"
import ScheduleLeasingRequestCall from "../../../../components/forms/ScheduleLeasingRequestCall"
import CloseLeasingRequest from "../../../../components/forms/CloseLeasingRequest"
import AcceptDocumentation from "../../../../components/forms/AcceptDocumentation"
import RollbackLeasingRequest from "../../../../components/forms/RollbackLeasingRequest"
import SendQuotation from "../../../../components/forms/SendQuotation"
import QuotationSigned from "../../../../components/forms/QuotationSigned"
import StartOfflineScoring from "../../../../components/forms/StartOfflineScoring"
import CompleteScoring from "../../../../components/forms/CompleteScoring"
import ScoringUndecided from "../../../../components/forms/ScoringUndecided"
import UndecidedClient from "../../../../components/forms/UndecidedClient"
import RequestContractToDistributor from "../../../../components/forms/RequestContractToDistributor"
import SendContract from "../../../../components/forms/SendContract"
import ContractSigned from "../../../../components/forms/ContractSigned"
import SendAllDataToDistributor from "../../../../components/forms/SendAllDataToDistributor"
import Win from "../../../../components/forms/Win"
import BillLeasingRequest from "../../../../components/forms/BillLeasingRequest"
import CancelLeasingRequest from "../../../../components/forms/CancelLeasingRequest"
import Lose from "../../../../components/forms/Lose"
import StartOnlineScoring from "../../../../components/forms/StartOnlineScoring"
import ReassignLeasingRequest from "../../../../components/forms/ReassignLeasingRequest"
import SendWhatsappTemplate from "../../../../components/forms/SendWhatsappTemplate"
import UpdatePreDeliveryCar from "../../../../components/forms/UpdatePreDeliveryCar"
import { uploadDocument } from "../../../../services/leasingRequestService"
import UpdateLeasingRequestDistributorContact from "../../../../components/forms/UpdateLeasingRequestDistributorContact"
import ChangeLeasingRequestPlan from "../../../../components/forms/ChangeLeasingRequestPlan"
import SendLeasingRequestEmail from "../../../../components/forms/SendLeasingRequestEmail"
import { Paragraph } from "../../../../components/styled/typography"
import DestroyLeasingRequest from "../../../../components/forms/DestroyLeasingRequest"
import ReassignLeasingRequestCreator from "../../../../components/forms/ReassignLeasingRequestCreator"
import ReassignLeasingRequestScoringExpert from "../../../../components/forms/ReassignLeasingRequestScoringExpert"
import ReconsiderScoring from "../../../../components/forms/ReconsiderScoring"
import UpdateLeasingRequestPromotion from "../../../../components/forms/UpdateLeasingRequestPromotion"
import ReceiveOffer from "../../../../components/forms/ReceiveOffer"
import RequestOffer from "../../../../components/forms/RequestOffer"
import ReturnToContract from "../../../../components/forms/ReturnToContract"
import ReturnToContractDistributor from "../../../../components/forms/ReturnToContractDistributor"
import RestartOffer from "../../../../components/forms/RestartOffer"
import RestartQuotation from "../../../../components/forms/RestartQuotation"
import CreateClientRequest from "../../../../components/forms/CreateClientRequest"
import SignContractExtension from "../../../../components/forms/SignContractExtension"
import WinContractExtension from "../../../../components/forms/WinContractExtension"
import ResolveContractExtension from "../../../../components/forms/ResolveContractExtension"
import NoStockScoringReject from "../../../../components/forms/NoStockScoringReject"
import { leasingRequestStatusFields } from "../../../../helpers/constants"
import Reopen from "../../../../components/forms/Reopen"
import CloseRequest from "./CloseRequest"
import { redirectToSubject } from "../../../../helpers/utils"
import CloseNotSigned from "../../../../components/forms/CloseNotSigned"
import NegotiationStatus from "../../../../components/forms/NegotiationStatus"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      leadId
      globalStatus
      status
      offerStatus
      quotationStatus
      scoringStatus
      contractStatus
      closedStatus
      contractExtensionStatus
      title
      jobType
      countryCode
      locale
      pendingScheduledCallAt
      lastStatusChangedAt
      distributorId
      online
      updatedAt
      returnToContractReason
      closedReason
      closedReasonComment
      won
      createdReason
      basicCar {
        id
        priceId
      }
      globalStatuses {
        status
        createdAt
      }
      closeRequest {
        closedReason
        closedReasonComment
        createdAt
      }
      comments(limit: 2) {
        id
        content
        createdAt
        createdById
        commentableId
        commentableType
        commercialDetails
      }
    }
  }
`

const ADD_LEASING_REQUEST_COMMENT = gql`
  mutation addLeasingRequestComment($commentableId: ID!, $commentArguments: AddLeasingRequestCommentInputObject!) {
    addLeasingRequestComment(input: { leasingRequestId: $commentableId, commentArguments: $commentArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const UPDATE_LEASING_REQUEST_COMMENT = gql`
  mutation updateLeasingRequestComment(
    $commentableId: ID!
    $commentArguments: UpdateLeasingRequestCommentInputObject!
  ) {
    updateLeasingRequestComment(input: { leasingRequestId: $commentableId, commentArguments: $commentArguments }) {
      errors {
        className
        messages
        path
      }
    }
  }
`

// TODO At some point React.forwardRef should become unnecessary
const MenuItems = React.forwardRef((props, ref) => {
  const { setOpenModal } = props
  const { t } = useTranslation("leasingRequest")
  const { allowed: reassign } = useAbilities("reassign_leasing_request")
  const { allowed: reassignCreator } = useAbilities("reassign_leasing_request_creator")
  const { allowed: deleteLeasingRequest } = useAbilities("delete_leasing_request")
  const { allowed: reassignScoringExpert } = useAbilities("reassign_leasing_request_scoring_expert")

  return (
    <>
      <MenuItem ref={ref} onClick={setOpenModal("sendEmail")}>
        {t("common:sendEmail")}
      </MenuItem>
      <MenuItem ref={ref} onClick={setOpenModal("uploadDocument")}>
        {t("common:uploadDocument")}
      </MenuItem>
      {reassign && (
        <MenuItem ref={ref} onClick={setOpenModal("reassign")}>
          {t("common:reassignSalesCloser")}
        </MenuItem>
      )}
      {reassignScoringExpert && (
        <MenuItem ref={ref} onClick={setOpenModal("reassignScoringExpert")}>
          {t("common:reassignScoringExpert")}
        </MenuItem>
      )}
      {reassignCreator && (
        <MenuItem ref={ref} onClick={setOpenModal("reassignCreator")}>
          {t("common:reassignCreator")}
        </MenuItem>
      )}
      <MenuItem ref={ref} onClick={setOpenModal("updateDistributorContact")}>
        {t("updateDistributorContact")}
      </MenuItem>
      <MenuItem ref={ref} onClick={setOpenModal("updatePreDeliveryCar")}>
        {t("updatePreDeliveryCar")}
      </MenuItem>
      <MenuItem ref={ref} onClick={setOpenModal("updatePromotion")}>
        {t("common:updatePromotion")}
      </MenuItem>
      <MenuItem ref={ref} onClick={setOpenModal("negotiationStatusCall")}>
        {t("common:negotiationStatusCall")}
      </MenuItem>
      {deleteLeasingRequest && (
        <MenuItem ref={ref} onClick={setOpenModal("delete")}>
          {t("common:delete")}
        </MenuItem>
      )}
    </>
  )
})

MenuItems.propTypes = {
  setOpenModal: PropTypes.func.isRequired
}

function Modals(props) {
  const { openModal, closeModal, refetch, resource } = props
  const { t } = useTranslation("leasingRequest")
  const history = useHistory()

  const handleSuccess = () => {
    refetch()
    closeModal()
  }

  const handleRedirectClientRequestSuccess = ({ id }) => {
    closeModal()
    redirectToSubject(history, "ClientRequest", id)
  }

  return (
    <>
      <FormDialog title={t("common:sendEmail")} handleClose={closeModal} open={openModal === "sendEmail"}>
        <SendLeasingRequestEmail
          leasingRequestId={resource.id}
          locale={resource.locale}
          handleSuccess={handleSuccess}
        />
      </FormDialog>
      <FormDialog title={t("common:uploadDocument")} handleClose={closeModal} open={openModal === "uploadDocument"}>
        <UploadDocuments resourceId={resource.id} uploadDocument={uploadDocument} />
      </FormDialog>
      <FormDialog title={t("common:reassign")} handleClose={closeModal} open={openModal === "reassign"}>
        <ReassignLeasingRequest leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:reassignCreator")} handleClose={closeModal} open={openModal === "reassignCreator"}>
        <ReassignLeasingRequestCreator leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("common:reassignScoringExpert")}
        handleClose={closeModal}
        open={openModal === "reassignScoringExpert"}
      >
        <ReassignLeasingRequestScoringExpert leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("sendWhatsappTemplate")}
        handleClose={closeModal}
        open={openModal === "sendWhatsappTemplate"}
      >
        <SendWhatsappTemplate leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("common:negotiationStatusCall")}
        handleClose={closeModal}
        open={openModal === "negotiationStatusCall"}
      >
        <NegotiationStatus leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("updateDistributorContact")}
        handleClose={closeModal}
        open={openModal === "updateDistributorContact"}
      >
        <UpdateLeasingRequestDistributorContact
          leasingRequestId={resource.id}
          distributorId={resource.distributorId}
          handleSuccess={handleSuccess}
        />
      </FormDialog>
      <FormDialog title={t("common:updatePromotion")} handleClose={closeModal} open={openModal === "updatePromotion"}>
        <UpdateLeasingRequestPromotion leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("updateLeasingRequestPlan")}
        handleClose={closeModal}
        open={openModal === "duplicate"}
        closeButton
      >
        <ChangeLeasingRequestPlan
          leasingRequestId={resource.id}
          distributorId={resource.distributorId}
          handleSuccess={handleSuccess}
        />
      </FormDialog>
      <FormDialog title={t("common:delete")} handleClose={closeModal} open={openModal === "delete"}>
        <DestroyLeasingRequest leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:scheduleACall")} handleClose={closeModal} open={openModal === "scheduleCall"}>
        <ScheduleLeasingRequestCall leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:close")} handleClose={closeModal} open={openModal === "close"}>
        <CloseLeasingRequest leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:closeNotSigned")} handleClose={closeModal} open={openModal === "closeNotSigned"}>
        <CloseNotSigned leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("acceptDocumentation")} handleClose={closeModal} open={openModal === "acceptDocumentation"}>
        <AcceptDocumentation
          distributorId={resource.distributorId}
          leasingRequestId={resource.id}
          locale={resource.locale}
          handleSuccess={handleSuccess}
        />
      </FormDialog>
      <FormDialog title={t("rollback")} handleClose={closeModal} open={openModal === "rollback"}>
        <RollbackLeasingRequest leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("sendQuotation")} handleClose={closeModal} open={openModal === "sendQuotation"}>
        <SendQuotation leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("quotationSigned")} handleClose={closeModal} open={openModal === "quotationSigned"}>
        <QuotationSigned leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("startScoring")} handleClose={closeModal} open={openModal === "startScoring"}>
        {resource.online ? (
          <StartOnlineScoring leasingRequestId={resource.id} handleSuccess={handleSuccess} />
        ) : (
          <StartOfflineScoring
            distributorId={resource.distributorId}
            leasingRequestId={resource.id}
            locale={resource.locale}
            handleSuccess={handleSuccess}
          />
        )}
      </FormDialog>
      <FormDialog title={t("completeScoring")} handleClose={closeModal} open={openModal === "completeScoring"}>
        <CompleteScoring leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("scoringUndecided")} handleClose={closeModal} open={openModal === "scoringUndecided"}>
        <ScoringUndecided leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("undecidedClient")} handleClose={closeModal} open={openModal === "undecidedClient"}>
        <UndecidedClient leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("sendContract")} handleClose={closeModal} open={openModal === "sendContract"}>
        <SendContract leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("requestContractToDistributor")}
        handleClose={closeModal}
        open={openModal === "requestContractToDistributor"}
      >
        <RequestContractToDistributor
          distributorId={resource.distributorId}
          leasingRequestId={resource.id}
          locale={resource.locale}
          handleSuccess={handleSuccess}
        />
      </FormDialog>
      <FormDialog title={t("contractSigned")} handleClose={closeModal} open={openModal === "contractSigned"}>
        <ContractSigned leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("sendAllDataToDistributor")}
        handleClose={closeModal}
        open={openModal === "sendAllDataToDistributor"}
      >
        <SendAllDataToDistributor leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("win")} handleClose={closeModal} open={openModal === "win"}>
        <Win leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("markAsBillable")} handleClose={closeModal} open={openModal === "markAsBillable"}>
        <BillLeasingRequest leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("cancel")} handleClose={closeModal} open={openModal === "cancel"}>
        <CancelLeasingRequest leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("updatePreDeliveryCar")}
        handleClose={closeModal}
        open={openModal === "updatePreDeliveryCar"}
      >
        <UpdatePreDeliveryCar leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("lose")} handleClose={closeModal} open={openModal === "lose"}>
        <Lose leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("reconsiderScoring")} handleClose={closeModal} open={openModal === "reconsiderScoring"}>
        <ReconsiderScoring leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("requestOffer")} handleClose={closeModal} open={openModal === "requestOffer"}>
        <RequestOffer
          distributorId={resource.distributorId}
          leasingRequestId={resource.id}
          locale={resource.locale}
          handleSuccess={handleSuccess}
        />
      </FormDialog>
      <FormDialog title={t("receiveOffer")} handleClose={closeModal} open={openModal === "receiveOffer"}>
        <ReceiveOffer leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("returnToContract")} handleClose={closeModal} open={openModal === "returnToContract"}>
        <ReturnToContract leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("returnToContractDistributor")}
        handleClose={closeModal}
        open={openModal === "returnToContractDistributor"}
      >
        <ReturnToContractDistributor leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("reopen")} handleClose={closeModal} open={openModal === "reopen"}>
        <Reopen leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("restartOffer")} handleClose={closeModal} open={openModal === "restartOffer"}>
        <RestartOffer leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("restartQuotation")} handleClose={closeModal} open={openModal === "restartQuotation"}>
        <RestartQuotation leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("signContractExtension")}
        handleClose={closeModal}
        open={openModal === "signContractExtension"}
      >
        <SignContractExtension leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("clientRequest:createClientRequest")}
        handleClose={closeModal}
        open={openModal === "createClientRequest"}
      >
        <CreateClientRequest leasingRequestId={resource.id} handleSuccess={handleRedirectClientRequestSuccess} />
      </FormDialog>
      <FormDialog
        title={t("winContractExtension")}
        handleClose={closeModal}
        open={openModal === "winContractExtension"}
      >
        <WinContractExtension leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("resolveContractExtension")}
        handleClose={closeModal}
        open={openModal === "resolveContractExtension"}
      >
        <ResolveContractExtension leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("noStockScoringReject")}
        handleClose={closeModal}
        open={openModal === "noStockScoringReject"}
      >
        <NoStockScoringReject leasingRequestId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
    </>
  )
}

Modals.defaultProps = {
  openModal: null
}

Modals.propTypes = {
  openModal: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired
}

export default function LeasingRequestScene(props) {
  const { leasingRequestId } = props
  const { t } = useTranslation()
  const [leasingRequestCar, setLeasingRequestCar] = useState(null)
  const [openModal, setOpenModal] = useState(null)
  const [selectedCar, setSelectedCar] = useState(null)
  const { loading, error, data, refetch } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (error) return <p>Server Error</p>
  if (data.leasingRequest === null) return <p>Not found</p>

  const {
    leadId,
    title,
    online,
    locale,
    pendingScheduledCallAt,
    globalStatus,
    lastStatusChangedAt,
    comments,
    jobType,
    countryCode,
    distributorId,
    basicCar,
    updatedAt,
    status,
    returnToContractReason,
    closedReason,
    closedReasonComment,
    won,
    createdReason,
    closeRequest
  } = data.leasingRequest

  const breadcrumbItems = [{ label: "Leasing Requests" }, { label: `${title}`, active: true }]
  const statusTitle = `${t(`leasingRequestGlobalStatus.${globalStatus}`)} - ${online ? "Online" : "Offline"}`
  const statuses = pick(data.leasingRequest, leasingRequestStatusFields)

  const onPlanClick = (carId, planId, selectedId, carDistributorId) => {
    if (planId !== selectedId) {
      setLeasingRequestCar({
        carId,
        distributorId: carDistributorId,
        priceId: planId
      })
      setOpenModal("duplicate")
    }
  }

  return (
    <>
      <LeasingRequestCarContext.Provider value={{ leasingRequestCar, setLeasingRequestCar }}>
        <CarListDialogContext.Provider
          value={{
            options: { jobType, countryCode, selectedCars: [basicCar] },
            onPlanClick,
            selectedCar,
            setSelectedCar
          }}
        >
          <Breadcrumb breadcrumbItems={breadcrumbItems} />
          <CarListDialog />
          <CurrentTask />
          <EntityLayout
            title={title}
            MenuItemsJSX={MenuItems}
            Modals={Modals}
            openModal={openModal}
            setOpenModal={setOpenModal}
            refetch={refetch}
            resource={{ id: leasingRequestId, locale, distributorId, online }}
          >
            <Alerts
              won={won}
              status={status}
              returnToContractReason={returnToContractReason}
              closedReason={closedReason}
              closedReasonComment={closedReasonComment}
            />
            <CloseRequest leasingRequestId={leasingRequestId} closeRequest={closeRequest} handleSuccess={refetch} />
            <PrimaryActions
              leasingRequestId={leasingRequestId}
              leasingRequestTitle={title}
              setOpenModal={setOpenModal}
            />
            <PendingScheduledCall pendingScheduledCallAt={pendingScheduledCallAt} />
            <SecondaryActions statuses={statuses} openModal={setOpenModal} />
            <CommentFormWrapper lastComments={comments} gqlUpdateMutation={UPDATE_LEASING_REQUEST_COMMENT}>
              <AddComment
                gqlMutation={ADD_LEASING_REQUEST_COMMENT}
                commentableId={leasingRequestId}
                handleSuccess={() => refetch()}
              />
            </CommentFormWrapper>
            <StatusChip
              status={statusTitle}
              lastStatusChangedAt={lastStatusChangedAt}
              tooltip={
                <>
                  <Paragraph>- {t("leasingRequestGlobalStatus.sales")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.documentation_review")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.scoring_documentation_pending")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.scoring_under_review")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.scoring_undecided")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.approved")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.contract_distributor")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.contract_sent")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.contract_undecided")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.contract_signed")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.won")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.billable")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.cancelled")}</Paragraph>
                  <Paragraph>- {t("leasingRequestGlobalStatus.closed")}</Paragraph>
                </>
              }
            />
            <SubStatusChips statuses={statuses} createdReason={createdReason} />
            <TabsScene
              leasingRequestId={leasingRequestId}
              leadId={leadId}
              jobType={jobType}
              countryCode={countryCode}
              selectedCar={selectedCar}
              setOpenModal={setOpenModal}
              lastRefreshAt={updatedAt}
            />
          </EntityLayout>
        </CarListDialogContext.Provider>
      </LeasingRequestCarContext.Provider>
    </>
  )
}

LeasingRequestScene.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
