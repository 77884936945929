import PropTypes from "prop-types"
import React from "react"
import { Description, Grade } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import SimpleData from "../../../../components/SimpleData"

export default function SalesSummarize(props) {
  const { t } = useTranslation("dashboard")
  const { dashboardData } = props

  if (!dashboardData) return null

  return (
    <>
      <SimpleData
        IconJSX={Grade}
        title={t("lastMonthBillable")}
        value={`${dashboardData.lastMonthBillableLeasingRequests}`}
        color="var(--colorful-5)"
      />
      <SimpleData
        IconJSX={Grade}
        title={t("currentMonthBillable")}
        value={`${dashboardData.billableLeasingRequests}`}
        color="var(--colorful-5)"
      />
      <SimpleData
        IconJSX={Description}
        title={t("currentMonthWon")}
        value={`${dashboardData.wonLeasingRequests}`}
        color="var(--colorful-4)"
      />
      <SimpleData
        IconJSX={Description}
        title={t("currentMonthContractSigned")}
        value={`${dashboardData.contractSignedLeasingRequests}`}
        color="var(--colorful-4)"
      />
    </>
  )
}

SalesSummarize.propTypes = {
  dashboardData: PropTypes.object
}

SalesSummarize.defaultProps = {
  dashboardData: null
}
