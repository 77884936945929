import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { find } from "lodash"
import { Text, useFormApi } from "informed"
import TextSelectInput from "../../TextSelectInput"

export const fcaOccupationOptions = t => [
  { value: 4, label: t("common:occupation.employee") },
  { value: 14, label: t("common:occupation.temporary") },
  { value: 2, label: t("common:occupation.official") },
  { value: 15, label: t("common:occupation.freelance") },
  { value: 1, label: t("common:occupation.pensioner") },
  { value: 3, label: t("common:occupation.military") },
  { value: 7, label: t("common:occupation.unemployedWithoutSubsidy") },
  { value: 16, label: t("common:occupation.businessPartner") },
  { value: 17, label: t("common:occupation.rentier") },
  { value: 18, label: t("common:occupation.widow") },
  { value: 27, label: t("common:occupation.fixedDiscontinuous") },
  { value: 28, label: t("common:occupation.preRetired") },
  { value: 29, label: t("common:occupation.disability") }
]

export default function OccupationSelector(props) {
  const { t } = useTranslation()
  const { initialValue, errors } = props
  const formApi = useFormApi()

  const handleChange = e => {
    const { value } = e.target
    const current = find(fcaOccupationOptions(t), { value }) || {}
    formApi.setValue("occupation", current.label)
  }

  const selected = initialValue && find(fcaOccupationOptions(t), { value: initialValue })

  return (
    <>
      <TextSelectInput
        field="fcaOccupation"
        label={t("common:occupation.title")}
        initialValue={selected?.value}
        errors={errors}
        onChange={handleChange}
        options={fcaOccupationOptions(t)}
        required
      />
      <Text field="occupation" initialValue={selected?.label} hidden />
    </>
  )
}

OccupationSelector.propTypes = {
  errors: PropTypes.array,
  initialValue: PropTypes.number
}

OccupationSelector.defaultProps = {
  errors: null,
  initialValue: null
}
