import Button from "@material-ui/core/Button"
import styled from "styled-components"
import PrimaryButton from "../PrimaryButton"

export const DefaultButton = styled(Button)`
  margin-right: 15px;
  margin-bottom: 5px;
`

export const GreenButton = styled(Button)`
  background-color: #85bd48;
  &:hover {
    background-color: #6c9a3b;
  }
`

export const ModalPrimaryButton = styled(PrimaryButton)`
  margin: 15px 0;
`

export const ButtonGroup = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
