import PropTypes from "prop-types"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { isNil } from "lodash"
import BasicField from "../../../../../components/fields/BasicField"
import Loading from "../../../../../components/Loading"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      signaturitSignatureId
      signaturitSignatureUrl
      signaturitSignatureStatus
      signaturitDocumentId
      signaturitSignatureGuarantorStatus
      signaturitGuarantorDocumentId
      signaturitAuditCompleted
      signaturitContractSignatureId
      signaturitContractSignatureUrl
      signaturitContractSignatureStatus
      signaturitContractDocumentId
      signaturitContractAuditCompleted
    }
  }
`

export default function IntegrationData(props) {
  const { leasingRequestId } = props
  const { t } = useTranslation("leasingRequest")

  const { data, loading } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  const { leasingRequest } = data

  return (
    <>
      <Grid item xs={12} md={6}>
        <BasicField title={t("signaturitSignatureId")} value={leasingRequest.signaturitSignatureId} />
        <BasicField title={t("signaturitSignatureUrl")} value={leasingRequest.signaturitSignatureUrl} />
        <BasicField title={t("signaturitSignatureStatus")} value={leasingRequest.signaturitSignatureStatus} />
        <BasicField title={t("signaturitDocumentId")} value={leasingRequest.signaturitDocumentId} />
        <BasicField
          title={t("signaturitSignatureGuarantorStatus")}
          value={leasingRequest.signaturitSignatureGuarantorStatus}
        />
        <BasicField title={t("signaturitGuarantorDocumentId")} value={leasingRequest.signaturitGuarantorDocumentId} />
        <BasicField
          title="Signaturit contract audit completed"
          type="boolean"
          value={leasingRequest.signaturitAuditCompleted}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicField title="Signaturit contract id" value={leasingRequest.signaturitContractSignatureId} />
        <BasicField title="Signaturit contract url" value={leasingRequest.signaturitContractSignatureUrl} />
        <BasicField title="Signaturit contract status" value={leasingRequest.signaturitContractSignatureStatus} />
        <BasicField title="Signaturit contract document id" value={leasingRequest.signaturitContractDocumentId} />
        <BasicField
          title="Signaturit contract audit completed"
          type="boolean"
          value={leasingRequest.signaturitContractAuditCompleted}
        />
      </Grid>
    </>
  )
}

IntegrationData.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
