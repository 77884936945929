import styled from "styled-components"
import { palette } from "./theme"

export const StyledH1 = styled.h1`
  // DO NOT MODIFY
  text-transform: uppercase;
  line-height: 48px;
  font-size: 30px;
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => props.color || "var(--warm-grey)"};
`

export const StyledH2 = styled.h2`
  // DO NOT MODIFY
  text-transform: uppercase;
  line-height: 48px;
  font-size: 24px;
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => props.color || "var(--stormy-blue)"};
`

export const StyledH3 = styled.h3`
  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => props.color || "var(--stormy-blue)"};
`

export const StyledH4 = styled.h4`
  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => props.color || "var(--black)"};
`

export const StyledH5 = styled.h5`
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: bold;
  color: var(--lightpurple);
`

export const StyledH6 = styled.h6`
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => props.color || "var(--warm-grey)"};
`

export const BlockTitle = styled.p`
  color: var(--stormy-blue);
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
`

export const BlockSubtitle = styled.p`
  color: rgba(0, 0, 0, 0.54);
`

export const Paragraph = styled.p`
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  margin: 0;
  font-size: ${props => (props.small ? "12px" : "14px")};
  ${({ ellipsis }) =>
    ellipsis &&
    `width: 100%; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
`

export const MultiLineParagraph = styled(Paragraph)`
  white-space: pre-line;
  margin-bottom: 5px;
`

export const StyledLink = styled.a`
  color: #007bff;
  cursor: pointer;
  font-size: 12px;
`

export const StyledLabel = styled.span`
  font-size: 13px;
  color: ${palette.stormyBlue};
`

export const StyledError = styled.small`
  font-size: 12px;
  color: ${palette.red};
`
