import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import TextSelectInput from "../inputs/TextSelectInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const RETURN_TO_CONTRACT = gql`
  mutation returnToContract($leasingRequestId: ID!, $leasingRequestArguments: ReturnToContractInputObject!) {
    returnToContract(
      input: { leasingRequestId: $leasingRequestId, leasingRequestArguments: $leasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

const returnToContractReasonOptions = t => [
  { value: "missing_documentation", label: t("returnToContractReasons.missing_documentation") },
  { value: "invalid_signature", label: t("returnToContractReasons.invalid_signature") },
  { value: "missing_deposit", label: t("returnToContractReasons.missing_deposit") },
  { value: "administrative_powers", label: t("returnToContractReasons.administrative_powers") }
]

export default function ReturnToContract(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")

  const [mutation, { data, loading, error }] = useMutation(RETURN_TO_CONTRACT, {
    onCompleted: newData => {
      if (newData.returnToContract?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.returnToContract?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, leasingRequestArguments: values }
    })
  }

  const validationSchema = Yup.object().shape({
    returnToContractReason: Yup.string().required(t("common:required"))
  })

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <GraphQLErrors errors={errors} />
      <TextSelectInput
        field="returnToContractReason"
        label={t("returnToContractReason")}
        options={returnToContractReasonOptions(t)}
        errors={errors}
        required
      />
      <ModalPrimaryButton isFetching={loading}>{t("returnToContract")}</ModalPrimaryButton>
    </Form>
  )
}

ReturnToContract.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired
}
