import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Email as EmailIcon } from "@material-ui/icons"
import moment from "moment"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Paragraph } from "../../styled/typography"
import { printDateTime } from "../../../helpers/formatter"

const Small = styled.small`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--stormy-blue);
  border-bottom: 1px solid #bbb;
`

const Summary = styled.div`
  width: 100%;
  margin-left: 15px;
`
const Subject = styled.p`
  margin: 0;
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const Wrapper = styled(Grid)`
  cursor: pointer;
`

const IconWrapper = styled.div`
  display: block;
  text-align: center;
  align-items: baseline;
`

const StyledEmailIcon = styled(EmailIcon)`
  color: ${props => (props.sent ? "var(--lightblue)" : "var(--kiwi-green)")};
  flex-grow: 1;
`

const Label = styled.small`
  text-transform: uppercase;
  color: var(--white);
  background-color: ${props => (props.sent ? "var(--lightblue)" : "var(--kiwi-green)")};
  margin-right: 5px;
  padding: 0 5px;
  font-size: 12px;
  height: 16px;
`

export default function Email(props) {
  const { email, handleClick } = props
  const { t } = useTranslation()
  const { from, to, subject, createdAt, sent, openedAt, opened } = email

  return (
    <Wrapper item onClick={handleClick(email)}>
      <StyledGrid item xs={12}>
        <IconWrapper>
          <StyledEmailIcon fontSize="large" sent={sent ? 1 : 0} />
        </IconWrapper>
        <Summary>
          <Subject>
            <Label sent={sent ? 1 : 0}>{sent ? t("sent") : t("received")}</Label>
            {subject}
          </Subject>
          <Paragraph>{t("from", { sender: from })}</Paragraph>
          <Paragraph>{t("to", { receiver: to })}</Paragraph>
          {sent && (
            <Paragraph>
              {opened ? t("open", { openedAt: printDateTime(openedAt, "HH:mm DD/MM/YYYY", true) }) : t("notOpen")}
            </Paragraph>
          )}
        </Summary>
      </StyledGrid>
      <Small>{`${printDateTime(createdAt)} ${moment(createdAt).fromNow()}`}</Small>
    </Wrapper>
  )
}

Email.defaultProps = {
  handleClick: () => {}
}

Email.propTypes = {
  email: PropTypes.object.isRequired,
  handleClick: PropTypes.func
}
