import PropTypes from "prop-types"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import moment from "moment"
import CommentIcon from "@material-ui/icons/Comment"
import { useTranslation } from "react-i18next"
import { Paragraph } from "../styled/typography"
import Staff from "../../models/staff"
import { printDateTime, printTime } from "../../helpers/formatter"

const Small = styled.small`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--stormy-blue);
  margin-bottom: 0;
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const StyledAssignmentIcon = styled(CommentIcon)`
  margin-right: 15px;
  color: var(--stormy-blue);
`

const Title = styled.p`
  color: var(--stormy-blue);
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
`

export default function Task(props) {
  const { task } = props
  const { identifier, createdAt, startedAt, completedAt, afterDate, timeSpent, assignedAtId } = task
  const { staff } = useSelector(mapStateToProps, shallowEqual)
  const staffName = Staff.getName(staff, assignedAtId)
  const { t } = useTranslation()

  return (
    <>
      <StyledGrid item xs={12}>
        <StyledAssignmentIcon fontSize="large" />
        <div>
          <Title>{t(`task.${identifier}`)}</Title>
          <Paragraph>{`Time spent ${printTime(timeSpent)}`}</Paragraph>
          <Paragraph>{`Completed at ${printDateTime(completedAt, "HH:mm DD/MM/YYYY", true)}`}</Paragraph>
          <Paragraph>{`Started at ${printDateTime(startedAt, "HH:mm DD/MM/YYYY", true)}`}</Paragraph>
          <Paragraph>{`After date ${printDateTime(afterDate, "HH:mm DD/MM/YYYY", true)}`}</Paragraph>
          <Paragraph>{`Created at ${printDateTime(createdAt)}`}</Paragraph>
        </div>
      </StyledGrid>
      <Small>
        {`${printDateTime(createdAt)} ${moment(createdAt).fromNow()}`}
        &nbsp;
        <b>{staffName || "Anonymous"}</b>
      </Small>
    </>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff } = commonDataReducer

  return { staff: staff.items }
}

Task.propTypes = {
  task: PropTypes.object.isRequired
}
