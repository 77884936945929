import React, { useEffect, useState } from "react"
import * as PropTypes from "prop-types"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { concat, isEmpty, uniqBy } from "lodash"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroller"
import Loading from "../Loading"
import ActionLog from "./ActionLog"

const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
`

const PER_PAGE = 5

export default function ActionLogs(props) {
  const { gqlQuery, gqlQueryName, resourceId } = props
  const { t } = useTranslation()
  const [actionLogs, setActionLogs] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const { data, loading } = useQuery(gqlQuery, {
    variables: { id: resourceId, offset: currentPage * PER_PAGE, limit: PER_PAGE }
  })

  useEffect(() => {
    if (data?.[gqlQueryName]?.actionLogs?.results) {
      setActionLogs(current => uniqBy(concat(data[gqlQueryName].actionLogs.results, current), "id"))
    }
  }, [data, gqlQueryName])

  useEffect(() => {
    if (data?.[gqlQueryName]?.actionLogs?.searchInfo?.total) {
      setHasMore(actionLogs.length < data[gqlQueryName].actionLogs.searchInfo.total)
    }
  }, [actionLogs, data, gqlQueryName])

  const handleLoadMore = () => {
    if (loading) return

    setHasMore(false)
    setCurrentPage(currentPage + 1)
  }

  if (!loading && isEmpty(actionLogs)) return t("noContent")

  return (
    <StyledInfiniteScroll pageStart={0} initialLoad={false} loadMore={handleLoadMore} hasMore={hasMore} useWindow>
      <Grid container spacing={2}>
        {actionLogs.map(actionLog => (
          <Grid item xs={12} key={actionLog.id}>
            <ActionLog actionLog={actionLog} />
          </Grid>
        ))}
      </Grid>
      {loading && <Loading />}
    </StyledInfiniteScroll>
  )
}

ActionLogs.propTypes = {
  gqlQuery: PropTypes.object.isRequired,
  gqlQueryName: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired
}
