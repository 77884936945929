import { createContext } from "react"

export const EmailContext = createContext({
  gqlMutation: null,
  resourceId: null,
  resource: null,
  locale: null,
  disabledDocuments: false
})
EmailContext.displayName = "Email Context"

export const LeasingRequestCarContext = createContext({
  leasingRequestCar: null,
  setLeasingRequestCar: null
})
LeasingRequestCarContext.displayName = "Create Leasing Request"

export const CarListDialogContext = createContext({
  selectedCar: null,
  setSelectedCar: null,
  options: {},
  onPlanClick: null,
  selectedCars: []
})
CarListDialogContext.displayName = "Cars StaffList Dialog"

export const CustomerEntityContext = createContext({
  jobType: null,
  countryCode: null
})
CustomerEntityContext.displayName = "Customer Entity"

export const TwilioContext = createContext({
  device: null,
  connection: null,
  destroyDevice: () => {},
  setupDevice: () => {},
  connect: () => {}
})
TwilioContext.displayName = "Twilio"

export const ModalPlanCarsContext = createContext({
  planId: null,
  setPlanId: () => {},
  setOpenModal: () => {}
})
ModalPlanCarsContext.displayName = "Modal Plan Cars"

export const ActionCableContext = createContext({
  consumer: null,
  subscribeWorkingChannel: null,
  unsubscribeWorkingChannel: null,
  sendWorkingMessage: null,
  subscribeOnlineChannel: null,
  unsubscribeOnlineChannel: null
})
ActionCableContext.displayName = "Action Cable Context"

export const GridSizesContext = createContext({
  xs: null,
  sm: null,
  md: null,
  lg: null,
  xl: null
})

export const ChartsContext = createContext({
  sdk: null,
  authToken: null,
  dashboardRenderStatus: null,
  chartRendering: null,
  chartRendered: null
})

export const FilterContext = createContext({
  filter: null,
  setFilter: null
})

export const ClientRequestContext = createContext({
  id: null
})
