import React, { useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Device } from "twilio-client"
import { useDispatch } from "react-redux"
import { gql, useMutation } from "@apollo/client"
import * as PropTypes from "prop-types"
import { includes } from "lodash"
import useSound from "use-sound"
import {
  acceptIncomingCall,
  acceptOutgoingCall,
  receiveEndCall,
  receiveManualCanReceiveACall,
  startOutgoingCall,
  twilioDeviceOffline,
  twilioDeviceReady,
  connectionWarning,
  connectionWarningCleared
} from "../../actions/call"
import { receiveNotification } from "../../actions"
import { ActionCableContext, TwilioContext } from "../../contexts"
import { twilioErrorCodes, twilioWarnings } from "../../helpers/constants"

const UPDATE_ACCOUNT = gql`
  mutation updateAccount($manualCanReceiveACall: Boolean) {
    updateAccount(input: { accountArguments: { manualCanReceiveACall: $manualCanReceiveACall } }) {
      result {
        me {
          manualCanReceiveACall
        }
      }
      errors {
        messages
        path
      }
    }
  }
`

const PREPARING_CONNECTION_TIMEOUT = 5 * 1000 // 5 seconds in ms
const RINGING_CONNECTION_TIMEOUT = 25 * 1000 // 25 seconds in ms

export default function Twilio(props) {
  const { children } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const device = useRef(null)
  const [connection, setConnection] = useState(null)
  const preparingConnectionTimeout = useRef(null)
  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: mutationData => {
      dispatch(receiveManualCanReceiveACall(mutationData.updateAccount.result.me.manualCanReceiveACall))
    }
  })
  const { subscribeOnlineChannel, unsubscribeOnlineChannel } = useContext(ActionCableContext)
  const [playOutgoingCallSound, { stop: stopOutgoingCallSound }] = useSound("/audio/outgoing-call.ogg", { loop: true })

  const addWarning = (warningName, warningData) => {
    console.warn("Twilio warning", warningName, warningData)
    if (includes(twilioWarnings, warningName)) dispatch(connectionWarning(warningName))
  }

  const clearWarning = warningName => {
    console.warn("Twilio warning cleared", warningName)
    if (includes(twilioWarnings, warningName)) dispatch(connectionWarningCleared(warningName))
  }

  const setConnectionTimeout = (newConnection, timeout) => {
    clearConnectionTimeout()

    preparingConnectionTimeout.current = setTimeout(() => {
      console.warn("Twilio outgoing call: forcing disconnection due to timeout")
      device.current.disconnectAll()
      newConnection.reject()
      newConnection.disconnect()
    }, timeout)
  }

  const clearConnectionTimeout = () => {
    if (preparingConnectionTimeout.current == null) return

    clearTimeout(preparingConnectionTimeout.current)
    preparingConnectionTimeout.current = null
  }

  const setupDevice = (token, options) => {
    destroyDevice()
    const newDevice = new Device()

    newDevice.on("ready", deviceReady => {
      console.info("Twilio device ready", deviceReady)
      subscribeOnlineChannel()
      updateAccount({ variables: { manualCanReceiveACall: true } })
      setConnection(null)
      device.current = deviceReady
      dispatch(twilioDeviceReady())
    })

    newDevice.on("offline", () => {
      console.info("Twilio device offline")

      if (connection) {
        console.info("Twilio device offline: closing active connection")
        dispatch(receiveNotification(t("twilioConnectionLost"), { variant: "error" }))
        dispatch(receiveEndCall())
        stopOutgoingCallSound()
        connection.disconnect()
        setConnection(null)
      }

      unsubscribeOnlineChannel()
      clearConnectionTimeout()
      updateAccount({ variables: { manualCanReceiveACall: false } })
      dispatch(twilioDeviceOffline())
      device.current = null
    })

    newDevice.on("error", error => {
      console.error("Twilio device error", error.message, error)

      const twilioMessages = twilioErrorCodes(t)
      if (twilioMessages[error.code]) dispatch(receiveNotification(twilioMessages[error.code], { variant: "error" }))
    })

    newDevice.on("cancel", () => {
      console.info("Twilio device cancel")
      dispatch(receiveEndCall())
      setConnection(null)
    })

    newDevice.on("incoming", newConnection => {
      console.info("Incoming call")

      newConnection.on("accept", acceptedConnection => {
        console.info("Twilio incoming call: accepted", acceptedConnection)
        dispatch(acceptIncomingCall())
      })

      newConnection.on("cancel", () => {
        console.info("Twilio incoming call: cancelled")
        dispatch(receiveEndCall())
        setConnection(null)
      })

      newConnection.on("reject", () => {
        console.info("Twilio incoming call: rejected")
        dispatch(receiveEndCall())
        setConnection(null)
      })

      newConnection.on("disconnect", () => {
        console.info("Twilio incoming call: disconnected")
        dispatch(receiveEndCall())
        setConnection(null)
      })

      newConnection.on("error", error => {
        console.warn("Twilio incoming call: error", error.message)
      })

      newConnection.on("warning", addWarning)
      newConnection.on("warning-cleared", clearWarning)

      setConnection(newConnection)
      newConnection.accept()
    })

    newDevice.setup(token, options)
  }

  const destroyDevice = () => {
    if (device.current != null) {
      device.current.destroy()
    }
  }

  const connect = (subjectId, subjectTitle, subjectType, accountId, extraParams = {}) => {
    const params = { StaffId: accountId, [`${subjectType}Id`]: subjectId, ...extraParams }
    const newConnection = device.current.connect(params)

    console.info("Twilio outgoing call: connecting")

    setConnectionTimeout(newConnection, PREPARING_CONNECTION_TIMEOUT)

    newConnection.on("accept", () => {
      console.info("Twilio outgoing call: client pick up")
      dispatch(acceptOutgoingCall())
      stopOutgoingCallSound()
      clearConnectionTimeout()
    })

    newConnection.on("disconnect", () => {
      console.info("Twilio outgoing call: hang up")
      dispatch(receiveEndCall())
      setConnection(null)
      clearConnectionTimeout()
      stopOutgoingCallSound()
    })

    newConnection.on("reject", () => {
      console.info("Twilio incoming call: rejected")
      dispatch(receiveEndCall())
      setConnection(null)
      clearConnectionTimeout()
      stopOutgoingCallSound()
    })

    newConnection.on("cancel", () => {
      console.warn("Twilio outgoing call: cancelled")
      dispatch(receiveEndCall())
      setConnection(null)
      clearConnectionTimeout()
      stopOutgoingCallSound()
    })

    newConnection.on("error", error => {
      console.warn("Twilio outgoing call: error", error.message)
    })

    newConnection.on("ringing", hasEarlyMedia => {
      console.info("Twilio outgoing call: ringing", { hasEarlyMedia })
      setConnectionTimeout(newConnection, RINGING_CONNECTION_TIMEOUT)

      if (hasEarlyMedia) stopOutgoingCallSound()
      else playOutgoingCallSound()
    })

    newConnection.on("warning", addWarning)
    newConnection.on("warning-cleared", clearWarning)

    setConnection(newConnection)
    dispatch(startOutgoingCall(subjectId, subjectType, subjectTitle))
  }

  return (
    <TwilioContext.Provider value={{ device, connection, setupDevice, destroyDevice, connect }}>
      {children}
    </TwilioContext.Provider>
  )
}

Twilio.propTypes = {
  children: PropTypes.node.isRequired
}
