import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import * as PropTypes from "prop-types"
import moment from "moment"
import business from "moment-business"
import { useTranslation } from "react-i18next"
import DateTimeInput from "../inputs/DateTimeInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const SCHEDULE_LEAD_CALL = gql`
  mutation scheduleLeadCall($leadId: ID!, $scheduleLeadCall: ScheduleLeadCallInputObject!) {
    scheduleLeadCall(input: { leadId: $leadId, scheduleLeadCall: $scheduleLeadCall }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function ScheduleLeadCall(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation()
  const { accountRole } = useSelector(mapStateToProps, shallowEqual)
  const [mutation, { data, loading, error }] = useMutation(SCHEDULE_LEAD_CALL, {
    onCompleted: newData => {
      if (newData.scheduleLeadCall?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.scheduleLeadCall?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leadId, scheduleLeadCall: values }
    })
  }

  const maxWeekDays = accountRole === "ScoringExpert" ? 11 : 10

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <DateTimeInput
        field="callScheduledAt"
        label={t("scheduleAt")}
        maxDate={business.addWeekDays(moment(), maxWeekDays).toDate()}
        disablePast
        minutesStep={5}
        errors={errors}
        required
      />
      <ModalPrimaryButton isFetching={loading}>{t("scheduleACall")}</ModalPrimaryButton>
    </Form>
  )
}

ScheduleLeadCall.defaultProps = {
  handleSuccess: () => {}
}

ScheduleLeadCall.propTypes = {
  leadId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func
}

const mapStateToProps = state => {
  const { accountReducer } = state
  const { item: account } = accountReducer

  return { accountRole: account.role }
}
