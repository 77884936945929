import * as PropTypes from "prop-types"
import React from "react"
import Toolbar from "@material-ui/core/Toolbar"
import ContentAdd from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton"
import DownloadIcon from "@material-ui/icons/GetApp"
import FilterListIcon from "@material-ui/icons/FilterList"
import RefreshIcon from "@material-ui/icons/Refresh"
import styled from "styled-components"
import { StyledH1 } from "../../../styled/typography"

const StyledToolbar = styled(Toolbar)`
  padding: 0 15px 0 15px;
`

const StyledTitle = styled(StyledH1)`
  margin-bottom: 0;
  flex: 1 1 100%;
`

const StyledIcons = styled.div`
  flex: 0 0 auto;
`

export default function EnhancedTableToolbar(props) {
  const {
    title,
    withFilter,
    toggleFilter,
    withCreation,
    openCreation,
    withRefresh,
    refresh,
    withExport,
    exportAction
  } = props

  return (
    <StyledToolbar>
      <StyledTitle>{title}</StyledTitle>
      <StyledIcons>
        {withExport && (
          <IconButton onClick={exportAction}>
            <DownloadIcon />
          </IconButton>
        )}
        {withCreation && (
          <IconButton onClick={openCreation}>
            <ContentAdd />
          </IconButton>
        )}
        {withFilter && (
          <IconButton onClick={toggleFilter}>
            <FilterListIcon />
          </IconButton>
        )}
        {withRefresh && (
          <IconButton onClick={refresh}>
            <RefreshIcon />
          </IconButton>
        )}
      </StyledIcons>
    </StyledToolbar>
  )
}

EnhancedTableToolbar.defaultProps = {
  openCreation: null,
  toggleFilter: null,
  refresh: null,
  exportAction: null,
  withCreation: false,
  withFilter: false,
  withRefresh: false,
  withExport: false
}

EnhancedTableToolbar.propTypes = {
  openCreation: PropTypes.func,
  title: PropTypes.string.isRequired,
  toggleFilter: PropTypes.func,
  refresh: PropTypes.func,
  withCreation: PropTypes.bool,
  withFilter: PropTypes.bool,
  withRefresh: PropTypes.bool,
  exportAction: PropTypes.func
}
