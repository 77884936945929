import React from "react"
import * as PropTypes from "prop-types"
import { shallowEqual, useSelector } from "react-redux"
import { Grid, Paper } from "@material-ui/core"
import styled from "styled-components"
import { isEmpty } from "lodash"
import moment from "moment"
import Staff from "../../../models/staff"
import { Paragraph, StyledH4, StyledH5 } from "../../styled/typography"
import { printDateTime } from "../../../helpers/formatter"

const StyledPaper = styled(Paper)`
  padding: 15px;
  border-left: 10px solid var(${props => (props.error ? "--warm-red" : "--lightblue")});
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`

const StyledTitle = styled(StyledH4)`
  margin-top: 0;
  text-transform: none;
  margin-bottom: 10px;
`

const StyledError = styled.span`
  color: var(--warm-red);
  margin-left: 5px;
`

const Small = styled.small`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--stormy-blue);
  margin-bottom: 0;
`

const StyledSubtitle = styled(StyledH5)`
  margin-top: 0;
  text-transform: none;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: 10px;
`

const StyledCode = styled.pre`
  margin: 0;
  padding: 15px;
  background-color: var(--light-grey);
`

export default function ActionLog(props) {
  const { actionLog } = props
  const { staff } = useSelector(mapStateToProps, shallowEqual)

  const {
    action,
    callMethod,
    calledAt,
    enqueuedAt,
    error,
    id,
    parameters,
    performerId,
    transactionName,
    transationUser
  } = actionLog
  const performer = Staff.getName(staff, performerId) || "Anonymous"

  return (
    <StyledPaper elevation={2} error={!isEmpty(error)}>
      <Small>
        {`${printDateTime(calledAt)} ${moment(calledAt).fromNow()}`}
        {enqueuedAt && <span>&nbsp;(enqueued at {printDateTime(enqueuedAt)})</span>}
        &nbsp;
        <b>{performer}</b>
      </Small>

      <StyledTitle>
        {action}#{callMethod}
        {!isEmpty(error) && <StyledError>[{error}]</StyledError>}
      </StyledTitle>

      <Grid container>
        <Grid item xs={12} md={3}>
          <StyledSubtitle>ID</StyledSubtitle>
          <StyledParagraph>{id}</StyledParagraph>

          <StyledSubtitle>Transaction name</StyledSubtitle>
          <StyledParagraph>{transactionName || "-"}</StyledParagraph>

          <StyledSubtitle>Transaction user</StyledSubtitle>
          <StyledParagraph>{transationUser || "-"}</StyledParagraph>
        </Grid>
        <Grid item xs={12} md={9}>
          <StyledSubtitle>Attributes</StyledSubtitle>
          <StyledCode>{JSON.stringify(JSON.parse(parameters), null, 2)}</StyledCode>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff } = commonDataReducer

  return { staff: staff.items }
}

ActionLog.propTypes = {
  actionLog: PropTypes.object.isRequired
}
