import PropTypes from "prop-types"
import LinearProgress from "@material-ui/core/LinearProgress"
import React, { useContext, useEffect } from "react"
import { gql, useQuery } from "@apollo/client"
import { shallowEqual, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { capitalize } from "lodash"
import Distributor from "../../../../../models/distributor"
import BasicField from "../../../../../components/fields/BasicField"
import CarTagList from "../../../../../components/CarTagList"
import CarTag from "../../../../../components/CarTagList/CarTag"
import { CarListDialogContext } from "../../../../../contexts"

const CAR = gql`
  query car($id: ID!) {
    car(id: $id) {
      id
      isActive
      sold
      new
      onlineScoring
      transmission
      lastItems
      fastDelivering
      sellingStatus
      fuel
      deliveryTime
      activeForSales
    }
  }
`

export default function BasicCarData(props) {
  const { title, duration, kmYear, price, deliveryTime, distributorId, carId, outdated, onCarLoaded, color } = props
  const { t } = useTranslation()
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const distributorName = Distributor.getName(distributors, distributorId)
  const { setSelectedCar } = useContext(CarListDialogContext)
  const { loading, data } = useQuery(CAR, {
    variables: {
      id: carId
    }
  })

  useEffect(() => {
    if (data?.car) {
      onCarLoaded()
    }
  }, [data])

  const handleSelectCar = id => {
    if (setSelectedCar && id) {
      return setSelectedCar(id)
    }
    return null
  }

  return (
    <>
      <BasicField title={t("car")} value={title} handleClick={() => handleSelectCar(data?.car?.id)} />
      {loading && <LinearProgress />}
      <div>
        <CarTagList car={data?.car} loading={loading} />
      </div>
      <BasicField
        title={t("plan")}
        value={t("concretePlan", {
          duration,
          kmYear,
          price
        })}
      />
      {outdated && <CarTag tagName="outdated" small />}
      {data?.car && (
        <>
          <BasicField title={t("distributor")} value={distributorName} />
          <BasicField title={t("transmission.title")} value={t(`transmission.${data?.car.transmission}`)} />
          <BasicField title={t("fuel.title")} value={t(`fuel.${data?.car.fuel}`)} />
          {deliveryTime && (
            <BasicField
              title={t("deliveryTimeOnCreate")}
              value={t("concreteDeliveryTime", {
                deliveryTimeFrom: deliveryTime - 1,
                deliveryTimeUntil: deliveryTime + 1
              })}
            />
          )}
          <BasicField
            title={t("currentCarDeliveryTime")}
            value={t("concreteDeliveryTime", {
              deliveryTimeFrom: data?.car.deliveryTime - 1,
              deliveryTimeUntil: data?.car.deliveryTime + 1
            })}
          />
        </>
      )}
      <BasicField title={t("color")} value={capitalize(t(`colors.${color}`))} />
    </>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

BasicCarData.propTypes = {
  carId: PropTypes.string.isRequired,
  distributorId: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  kmYear: PropTypes.number.isRequired,
  onCarLoaded: PropTypes.func,
  outdated: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  deliveryTime: PropTypes.number
}

BasicCarData.defaultProps = {
  onCarLoaded: () => {},
  color: null,
  deliveryTime: null
}
