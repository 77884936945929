import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { asField } from "informed"
import { concat, includes, isEmpty, map, trim, without } from "lodash"
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Loading from "../../../Loading"
import { getFieldError } from "../../../../helpers/errorParser"
import { EmailContext } from "../../../../contexts"

const PERSONAL_DOCUMENTS = `
  personalDocuments {
    id
    tag
    documentFileName
  }
`

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      ${PERSONAL_DOCUMENTS}
    }
  }
`

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      ${PERSONAL_DOCUMENTS}
    }
  }
`

const PersonalDocumentsChecklist = asField(({ fieldState, fieldApi, ...props }) => {
  const { startExpanded, errors, errorsPlace, field, selectAll } = props
  const { t } = useTranslation()
  const { resource, resourceId } = useContext(EmailContext)

  const { setValue, setTouched } = fieldApi
  const { value, error: fieldError } = fieldState

  const gqlQuery = resource === "Lead" ? LEAD : LEASING_REQUEST
  const operationName = gqlQuery.definitions[0].name.value
  const { data, loading } = useQuery(gqlQuery, {
    variables: { id: resourceId }
  })

  const personalDocuments = data?.[operationName]?.personalDocuments

  const formErrorsText = getFieldError(errors, field, errorsPlace)
  const errorText = trim(formErrorsText) || trim(fieldError)

  const onCheckboxChange = (id, checked) => {
    if (checked) setValue(concat(value || [], [id]))
    else setValue(without(value || [], id))
  }

  useEffect(() => {
    if (selectAll) setValue(map(personalDocuments, "id"))
  }, [data])

  if (loading) return <Loading />

  return (
    <ExpansionPanel defaultExpanded={startExpanded}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>{t("personalDocuments")}</ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <FormControl fullWidth error={!isEmpty(errorText)}>
          <FormLabel component="legend">{t("personalDocumentsChecklistLabel")}</FormLabel>
          <FormGroup>
            {isEmpty(personalDocuments) && t("noDocumentation")}
            {!isEmpty(personalDocuments) &&
              map(personalDocuments, personalDocument => {
                return (
                  <FormControlLabel
                    label={personalDocument.documentFileName}
                    key={personalDocument.id}
                    control={
                      <Checkbox
                        onChange={e => onCheckboxChange(personalDocument.id, e.target.checked)}
                        onBlur={() => setTouched()}
                        checked={includes(value, personalDocument.id)}
                      />
                    }
                  />
                )
              })}
          </FormGroup>
          {!isEmpty(errorText) && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
})

PersonalDocumentsChecklist.propTypes = {
  errors: PropTypes.array,
  initialValue: PropTypes.array,
  startExpanded: PropTypes.bool
}

PersonalDocumentsChecklist.defaultProps = {
  errors: [],
  initialValue: [],
  startExpanded: false
}

export default PersonalDocumentsChecklist
