import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { shallowEqual, useSelector } from "react-redux"
import { Grid } from "@material-ui/core"
import BasicField from "../../fields/BasicField"
import Distributor from "../../../models/distributor"
import { CarListDialogContext } from "../../../contexts"

export default function CarInformation(props) {
  const { t } = useTranslation("lead")
  const { setSelectedCar } = useContext(CarListDialogContext)
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const { car } = props

  const handleSelectCar = () => {
    if (setSelectedCar) setSelectedCar(car.id)
  }

  if (!car) return null

  const distributor = Distributor.getDistributor(distributors, car.distributorId)
  const { plan: priceRange } = car

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <BasicField title={t("common:car")} value={car.title} handleClick={handleSelectCar} />
        <BasicField title={t("common:distributor")} value={distributor.name} />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicField title={t("common:plan")} value={priceRange && t("common:concretePlan", priceRange)} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

CarInformation.propTypes = {
  car: PropTypes.object
}

CarInformation.defaultProps = {
  car: null
}
