import PropTypes from "prop-types"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { isNil } from "lodash"
import Comment from "../../../../../components/Comment"
import Loading from "../../../../../components/Loading"

const StyledGrid = styled(Grid)`
  padding: 1px;
`

// TODO Think about using fragments https://www.apollographql.com/docs/react/v3.0-beta/data/fragments/
const LEAD_COMMENTS = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      comments {
        id
        content
        createdById
        createdAt
        commentableId
        commentableType
        commercialDetails
      }
    }
  }
`

export default function Feed(props) {
  const { leadId } = props
  const { loading, data } = useQuery(LEAD_COMMENTS, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.lead)) return <p>Not found</p>

  return (
    <StyledGrid container>
      {data.lead.comments.map(comment => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </StyledGrid>
  )
}

Feed.propTypes = {
  leadId: PropTypes.string.isRequired
}
