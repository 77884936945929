import * as Yup from "yup"

// eslint-disable-next-line import/prefer-default-export
export const createLeasingRequestValidationSchema = t => {
  return Yup.object().shape({
    firstName: Yup.string().trim().required(t("common:required")),
    lastName: Yup.string().trim().required(t("common:required")),
    secondLastName: Yup.string().trim().required(t("common:required")),
    phone: Yup.string().trim().required(t("common:required")),
    closedReason: Yup.string().required(t("common:required")),
    documentType: Yup.string().required(t("common:required")),
    actualMonthlyIncome: Yup.number().integer(t("common:integer")),
    invoiced: Yup.number().integer(t("common:integer")),
    netReturns: Yup.number().integer(t("common:integer")),
    quantity: Yup.number().positive(t("common:positive")).integer(t("common:integer")),
    fcaBirthProvince: Yup.string().required(t("common:required")),
    fcaCivilStatus: Yup.string().required(t("common:required")),
    fcaOccupation: Yup.string().required(t("common:required")),
    fcaProvince: Yup.string().required(t("common:required")),
    birthday: Yup.date().required(t("common:required")),
    idNumber: Yup.string().trim().required(t("common:required")),
    employedSince: Yup.date().required(t("common:required")),
    driveLicenseExpeditionDate: Yup.date().required(t("common:required")),
    province: Yup.string().trim().required(t("common:required")),
    postalCode: Yup.string().trim().required(t("common:required")),
    town: Yup.string().trim().required(t("common:required")),
    street: Yup.string().trim().required(t("common:required")),
    streetNumber: Yup.string().trim().required(t("common:required")),
    countryCode: Yup.string().required(t("common:required")),
    deliveryAddress: Yup.string().trim().required(t("common:required")),
    deliveryTown: Yup.string().trim().required(t("common:required")),
    deliveryPostalCode: Yup.string().trim().required(t("common:required")),
    deliveryProvince: Yup.string().trim().required(t("common:required")),
    jobType: Yup.string().required(t("common:required")),
    clientAcceptance: Yup.boolean().required(t("common:required")).oneOf([true], t("common:required")),
    color: Yup.string().required(t("common:required")),
    createdReason: Yup.string().required(t("common:required")),
    guarantorName: Yup.string().trim().required(t("common:required")),
    guarantorEmail: Yup.string().trim().required(t("common:required")),
    guarantorIdNumber: Yup.string().trim().required(t("common:required")),
    guarantorPhone: Yup.string().trim().required(t("common:required")),
    guarantorStreet: Yup.string().trim().required(t("common:required")),
    guarantorStreetNumber: Yup.string().trim().required(t("common:required")),
    guarantorPostalCode: Yup.string().trim().required(t("common:required")),
    guarantorTown: Yup.string().trim().required(t("common:required")),
    guarantorProvince: Yup.string().trim().required(t("common:required")),
    distributorOfferId: Yup.string().trim().required(t("common:required")),
    downPayment: Yup.number().required(t("common:required")),
    renovationLeasingRequestId: Yup.string().trim().required(t("common:required")),
    renewal: Yup.string().trim().required(t("common:required"))
  })
}
