import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const SmallPrice = styled.small`
  color: var(--black);
  display: block;
`

const PriceText = styled.div`
  color: ${({ minimum }) => (minimum ? "var(--medium-pink)" : "inherit")};
  font-weight: ${({ minimum }) => (minimum ? "bold" : "inherit")};
`

const PriceWithoutTaxesText = styled.div`
  font-size: 0.8rem;
  color: var(--warm-grey);
  text-align: end;
  margin-right: 5px;
`

const KmsWrapper = styled.div`
  display: block;
  margin: 0 10px;
`

export default function Price(props) {
  const { priceRange, minimumPrice, sellingStatus } = props
  const { t } = useTranslation()

  return (
    <>
      {priceRange.priceWithoutTaxes && (
        <PriceWithoutTaxesText minimum={priceRange.price === minimumPrice}>
          {t("concretePrice", { price: priceRange.priceWithoutTaxes })}
        </PriceWithoutTaxesText>
      )}
      <PriceText minimum={priceRange.price === minimumPrice}>
        {t("concretePrice", { price: priceRange.price })}
      </PriceText>
      <KmsWrapper>
        <SmallPrice>{`${t("belowKmRefunds")} ${t("concretePrice", { price: priceRange.belowKmRefund })}`}</SmallPrice>
        <SmallPrice>{`${t("extraKmCost")} ${t("concretePrice", { price: priceRange.extraKmCost })}`}</SmallPrice>
        {sellingStatus === "flexible" && (
          <SmallPrice>{`${t("concretePrice", { price: priceRange.flexibleEndPrice || "-" })}`}</SmallPrice>
        )}
      </KmsWrapper>
    </>
  )
}

Price.propTypes = {
  sellingStatus: PropTypes.string.isRequired,
  minimumPrice: PropTypes.number.isRequired,
  priceRange: PropTypes.object.isRequired
}
