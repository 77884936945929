import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { Alert } from "@material-ui/lab"
import { Grid } from "@material-ui/core"
import { isEmpty, omit, reject } from "lodash"
import * as Yup from "yup"
import styled from "styled-components"
import TextSelectInput from "../inputs/TextSelectInput"
import BasicInput from "../inputs/BasicInput"
import CheckboxInput from "../inputs/CheckboxInput"
import DepositInput from "../inputs/DepositInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import Loading from "../../Loading"
import { leasingRequestUndecidedReasonOptions } from "../../../helpers/constants"

const StyledAlert = styled(Alert)`
  margin-top: 8px;
`

const COMPLETE_SCORING = gql`
  mutation completeScoring($leasingRequestId: ID!, $completeScoringArguments: CompleteScoringInputObject!) {
    completeScoring(
      input: { leasingRequestId: $leasingRequestId, completeScoringArguments: $completeScoringArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      online
      basicCar {
        duration
        price
        priceWithoutTaxes
      }
      otherLeasingRequests {
        id
        status
      }
    }
  }
`

const nextStatusOption = t => [
  { value: "approved", label: t("common:scoringStatus.approved") },
  { value: "undecided", label: t("common:scoringStatus.undecided") },
  { value: "rejected", label: t("common:scoringStatus.rejected") }
]

const rejectedReasonOptions = t => {
  return ["incidence", "payment_capacity", "age", "cross_with_broker", "no_stock"].map(s => {
    return { value: s, label: t(`common:closeReasonOptions.${s}`) }
  })
}

export default function CompleteScoring(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { data: queryData, loading: queryLoading } = useQuery(LEASING_REQUEST, {
    variables: { id: leasingRequestId }
  })
  const [mutation, { data, loading, error }] = useMutation(COMPLETE_SCORING, {
    onCompleted: newData => {
      if (newData.completeScoring?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.completeScoring?.errors
  if (error) return <p>Server Error</p>

  if (queryLoading) return <Loading />
  if (queryData.leasingRequest === null) return <p>Not found</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, completeScoringArguments: omit(values, ["planTotal", "planTotalWithoutTaxes"]) }
    })
  }

  const {
    online,
    otherLeasingRequests,
    basicCar: { duration, price, priceWithoutTaxes }
  } = queryData.leasingRequest
  const planTotal = duration * price
  const planTotalWithoutTaxes = duration * priceWithoutTaxes
  const ongoingLeasingRequests = reject(reject(otherLeasingRequests, { status: "closed" }), { id: leasingRequestId })

  const validationSchema = Yup.object().shape({
    closedReason: Yup.string().required(t("common:required"))
  })

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} />
          {formState.values.closedReason === "bad_gdpr_sign" && (
            <Alert severity="warning">{t("scoringRejectedBadGdprSignWarning")}</Alert>
          )}
          <TextSelectInput
            field="scoringStatus"
            label={t("common:scoringStatus.title")}
            options={nextStatusOption(t)}
            errors={errors}
            required
          />
          {!online &&
            formState.values.scoringStatus === "rejected" &&
            !isEmpty(reject(ongoingLeasingRequests, { id: leasingRequestId })) && (
              <>
                <StyledAlert severity="warning">{t("ongoingLeasingRequestsWarning")}</StyledAlert>
                <CheckboxInput
                  field="ongoingRejected"
                  label={t("ongoingRejected")}
                  initialValue={false}
                  errors={errors}
                />
              </>
            )}
          {formState.values.scoringStatus === "approved" && (
            <>
              <CheckboxInput field="guarantor" label={t("common:guarantor")} initialValue={false} errors={errors} />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <BasicInput
                    field="planTotal"
                    label={t("common:planTotal")}
                    initialValue={`${planTotal} €`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BasicInput
                    field="planTotalWithoutTaxes"
                    label={t("common:planTotalWithoutTaxes")}
                    initialValue={`${planTotalWithoutTaxes} €`}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <DepositInput
                    field="deposit"
                    label={t("common:depositWithPercentageTitle")}
                    initialValue={0}
                    errors={errors}
                    total={planTotal}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BasicInput
                    type="number"
                    field="downPayment"
                    label={t("common:downPayment")}
                    initialValue={0}
                    errors={errors}
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}
          {(formState.values.scoringStatus === "rejected" || formState.values.scoringStatus === "undecided") && (
            <TextSelectInput
              field="rejectedReason"
              label={t("scoringRejectedReason")}
              options={
                formState.values.scoringStatus === "rejected"
                  ? rejectedReasonOptions(t)
                  : leasingRequestUndecidedReasonOptions(t)
              }
              errors={errors}
              required
            />
          )}
          <BasicInput
            field="scoringComments"
            label={t("common:comments")}
            errors={errors}
            multiline
            required={formState.values.scoringStatus === "rejected" || formState.values.scoringStatus === "undecided"}
          />
          <ModalPrimaryButton isFetching={loading}>{t("completeScoring")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

CompleteScoring.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
