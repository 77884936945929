import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { gql, useLazyQuery } from "@apollo/client"
import { Dialog, DialogContent } from "@material-ui/core"
import Slide from "@material-ui/core/Slide"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { assign } from "lodash"
import CarItem from "./CarItem"
import Filter from "./Filter"
import { CarListDialogContext, CustomerEntityContext } from "../../../contexts"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const StyledAppBar = styled(AppBar)`
  position: relative;
`

const StyledToolbar = styled(Toolbar)`
  flex-direction: row-reverse;
`

const SEARCH_CARS = gql`
  query searchCars(
    $q: String
    $availableFor: String
    $kind: String
    $minPrice: Int
    $maxPrice: Int
    $duration: Int
    $kmYear: Int
    $fuel: String
    $transmission: String
    $color: String
    $deliveryTime: Int
    $distributorId: ID
    $sellingStatus: String
    $minCV: Int
    $sold: Boolean
    $activeForSales: Boolean
    $countryCode: CountryCode
    $deliveryProvinces: Int
    $limit: Int
    $offset: Int
    $sort: Sort
    $orderBy: CarsSearchOrderBy
  ) {
    searchCars(
      q: $q
      availableFor: $availableFor
      kind: $kind
      minPrice: $minPrice
      maxPrice: $maxPrice
      duration: $duration
      kmYear: $kmYear
      fuel: $fuel
      transmission: $transmission
      color: $color
      deliveryTime: $deliveryTime
      distributorId: $distributorId
      sellingStatus: $sellingStatus
      minCv: $minCV
      sold: $sold
      activeForSales: $activeForSales
      countryCode: $countryCode
      deliveryProvinces: $deliveryProvinces
      limit: $limit
      offset: $offset
      sort: $sort
      orderBy: $orderBy
    ) {
      searchInfo {
        total
      }
      results {
        id
        isActive
        sold
        new
        onlineScoring
        transmission
        lastItems
        fastDelivering
        sellingStatus
        fuel
        deliveryTime
        brand
        model
        version
        minimumPrice
        defaultImageUrl
        distributorId
        activeForSales
        availableForCompany
        availableForEmployee
        availableForFreelance
      }
    }
  }
`

const PER_PAGE = 10

export default function Cars(props) {
  const { open, setOpen } = props
  const { selectedCar, setSelectedCar, options } = useContext(CarListDialogContext)
  const customerEntityContext = useContext(CustomerEntityContext)
  const [filters, setFilters] = useState({})
  const [queryAttributes, setQueryAttributes] = useState({
    sold: false,
    activeForSales: true,
    orderBy: "price",
    sort: "asc",
    limit: PER_PAGE
  })
  const [currentPage, setCurrentPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [cars, setCars] = useState([])
  const [favCars, setFavCars] = useState([])
  let scrollParentRef = null
  const [searchCars, { data, loading }] = useLazyQuery(SEARCH_CARS)
  // TODO: Passing jobType on CarListDialogContext is deprecated. Use CustomerEntityContext instead.
  const jobType = customerEntityContext.jobType || options.jobType
  const countryCode = customerEntityContext.countryCode || options.countryCode

  const currentlyOpen = open && !selectedCar

  const resetCars = () => {
    setCars([])
    setCurrentPage(0)
    setHasMore(false)
  }

  useEffect(() => {
    if (data?.searchCars?.results) {
      const newCars = [...cars, ...data.searchCars.results]
      setCars(newCars)
      setHasMore(newCars.length < data.searchCars.searchInfo.total)
    }
  }, [data])

  useEffect(() => {
    if (currentlyOpen) searchCars({ variables: { ...queryAttributes, ...filters } })
    else resetCars()
  }, [currentlyOpen, filters, queryAttributes, searchCars])

  useEffect(() => {
    if (jobType) setFilters(newFilters => assign({}, newFilters, { availableFor: jobType }))
  }, [jobType])

  useEffect(() => {
    resetCars()
  }, [filters])

  useEffect(() => {
    setQueryAttributes(query => assign({}, query, { countryCode, offset: currentPage * PER_PAGE }))
  }, [countryCode, currentPage])

  const handleLoadMore = () => {
    if (loading) return

    setHasMore(false)
    setCurrentPage(currentPage + 1)
  }

  const setFavCar = car => {
    const newArray = [...favCars]
    const index = newArray.indexOf(car)
    if (index === -1) {
      newArray.push(car)
      setFavCars(newArray)
    } else {
      newArray.splice(index, 1)
      setFavCars(newArray)
    }
  }

  return (
    <Dialog fullScreen open={currentlyOpen} onClose={() => setOpen(false)} TransitionComponent={Transition}>
      <StyledAppBar>
        <StyledToolbar>
          <IconButton edge="end" color="inherit" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </StyledToolbar>
      </StyledAppBar>
      <DialogContent
        ref={newRef => {
          scrollParentRef = newRef
        }}
      >
        <Filter countryCode={countryCode} filters={filters} setFilters={setFilters} />
        <Grid container>
          {favCars.map(car => (
            <Grid key={car.id} item xs={12} sm={6} md={4}>
              <CarItem car={car} setFavCar={setFavCar} fav setSelectedCar={setSelectedCar} />
            </Grid>
          ))}
        </Grid>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={handleLoadMore}
          hasMore={hasMore}
          useWindow={false}
          getScrollParent={() => scrollParentRef}
        >
          <Grid container>
            {cars.map(car => (
              <Grid key={car.id} item xs={12} sm={6} md={4}>
                <CarItem car={car} setFavCar={setFavCar} fav={false} setSelectedCar={setSelectedCar} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </DialogContent>
    </Dialog>
  )
}

Cars.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}
