import { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { getManifest } from "../../services/manifestService"
import { receiveNotification } from "../../actions"

// NOTE: Periodically check if there are new updates available, and show a push notification in that case.
export default function ManifestUpdateNotifier(props) {
  const { children } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const version = process.env.REACT_APP_GIT_INFO
    const intervalTime = 1 * 60 * 1000 // 1 minute in ms

    const interval = setInterval(() => {
      if (process.env.REACT_APP_ENVIRONMENT !== "production") return

      getManifest().then(response => {
        const newVersion = response?.data?.version
        if (!newVersion || newVersion === version) return
        console.info(`New version available:`, response)

        dispatch(receiveNotification(t("newUpdateAvailable"), { variant: "info" }))
      })
    }, intervalTime)

    return () => clearInterval(interval)
  }, [dispatch, t])

  return children
}

ManifestUpdateNotifier.propTypes = {
  children: PropTypes.node.isRequired
}
