import { concat, uniq, without } from "lodash"
import {
  ACCEPT_INCOMING_CALL,
  ACCEPT_OUTGOING_CALL,
  RECEIVE_END_CALL,
  RECEIVE_MANUAL_CAN_RECEIVE_A_CALL,
  START_OUTGOING_CALL,
  TWILIO_DEVICE_OFFLINE,
  TWILIO_DEVICE_READY,
  UPDATE_CURRENT_CALL,
  CONNECTION_WARNING,
  CONNECTION_WARNING_CLEARED
} from "../actions/call"

const defaultState = {
  twilioDeviceReady: false,
  manualCanReceiveACall: false,
  everythingReady: false,
  inCall: false,
  allPartiesInCall: false,
  incomingCall: false,
  subjectId: null,
  subjectType: null,
  subjectName: null,
  warnings: []
}

export default function callReducer(state = defaultState, action) {
  switch (action.type) {
    case TWILIO_DEVICE_READY:
      return {
        ...state,
        twilioDeviceReady: true,
        everythingReady: state.manualCanReceiveACall
      }
    case TWILIO_DEVICE_OFFLINE:
      return {
        ...state,
        twilioDeviceReady: false,
        everythingReady: false,
        inCall: false,
        warnings: []
      }
    case RECEIVE_MANUAL_CAN_RECEIVE_A_CALL:
      return {
        ...state,
        manualCanReceiveACall: action.value,
        everythingReady: action.value && state.twilioDeviceReady
      }
    case START_OUTGOING_CALL:
      return {
        ...state,
        subjectId: action.subjectId,
        subjectType: action.subjectType,
        subjectName: action.subjectName,
        inCall: true
      }
    case RECEIVE_END_CALL:
      return {
        ...state,
        subjectId: null,
        subjectType: null,
        subjectName: null,
        incomingCall: false,
        inCall: false,
        allPartiesInCall: false,
        warnings: []
      }
    case ACCEPT_INCOMING_CALL:
      return {
        ...state,
        incomingCall: false,
        inCall: true,
        allPartiesInCall: true
      }
    case ACCEPT_OUTGOING_CALL:
      return {
        ...state,
        allPartiesInCall: true
      }
    case UPDATE_CURRENT_CALL:
      return {
        ...state,
        subjectId: action.subjectId,
        subjectType: action.subjectType,
        subjectName: action.subjectName
      }
    case CONNECTION_WARNING:
      return {
        ...state,
        warnings: uniq(concat(state.warnings, action.warning))
      }
    case CONNECTION_WARNING_CLEARED:
      return {
        ...state,
        warnings: without(state.warnings, action.warning)
      }
    default:
      return state
  }
}
