import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import BasicInput from "../inputs/BasicInput"

const NOT_DELIVERED_CLIENT = gql`
  mutation notDeliveredClient($clientId: ID!, $clientArguments: NotDeliveredClientInputObject) {
    notDeliveredClient(input: { clientId: $clientId, clientArguments: $clientArguments }) {
      result
      errors {
        messages
        path
      }
    }
  }
`

export default function NotDeliveredClient(props) {
  const { clientId, leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("client")
  const [mutation, { data, loading, error }] = useMutation(NOT_DELIVERED_CLIENT, {
    onCompleted: newData => {
      if (newData.notDeliveredClient?.errors == null) {
        handleSuccess(newData.notDeliveredClient.result)
      }
    }
  })
  const errors = data?.notDeliveredClient?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { clientId, clientArguments: { leasingRequestId, ...values } }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <BasicInput field="comment" label={t("common:comments")} required />
      <ModalPrimaryButton isFetching={loading}>{t("notDelivered")}</ModalPrimaryButton>
    </Form>
  )
}

NotDeliveredClient.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
