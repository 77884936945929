import PropTypes from "prop-types"
import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import MenuItem from "@material-ui/core/MenuItem"
import { useMutation } from "@apollo/client"
import { StyledGreyPaper } from "../../styled/papers"
import { PLACEHOLDER_MUTATION } from "../../../helpers/utils"

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
`

const Title = styled.a`
  display: block;
  text-transform: uppercase;
`

export default function Header(props) {
  const {
    resourceId,
    personalDocumentId,
    src,
    caption,
    tag,
    documentationList,
    showTag,
    gqlUpdateMutation,
    gqlDestroyMutation,
    afterDestroy
  } = props
  const { t } = useTranslation()
  const [currentTag, setCurrentTag] = useState(tag || "")
  const [destroyDocument] = useMutation(gqlDestroyMutation, { onCompleted: afterDestroy })
  const [updateDocument] = useMutation(gqlUpdateMutation || PLACEHOLDER_MUTATION)

  const handleOnChange = event => {
    const { value } = event.target
    setCurrentTag(value)
    updateDocument({
      variables: {
        id: resourceId,
        personalDocumentId,
        personalDocumentArguments: {
          tag: value
        }
      }
    })
  }

  const handleDestroy = () => {
    // eslint-disable-next-line no-alert
    const r = window.confirm(t("areYouSure"))
    if (r) {
      destroyDocument({
        variables: {
          id: resourceId,
          personalDocumentId
        }
      })
    }
  }

  return (
    <StyledGreyPaper>
      <InlineContainer>
        <Title rel="noreferrer" target="_blank" href={src}>
          {caption}
        </Title>
        <IconButton color="secondary" onClick={handleDestroy}>
          <DeleteIcon />
        </IconButton>
      </InlineContainer>
      {showTag && (
        <TextField
          fullWidth
          select
          variant="outlined"
          onChange={handleOnChange}
          label={t("documentTag")}
          value={currentTag || ""}>
          {documentationList.map(({ key, translation }) => (
            <MenuItem key={key} value={key}>
              {translation}
            </MenuItem>
          ))}
        </TextField>
      )}
    </StyledGreyPaper>
  )
}

Header.propTypes = {
  afterDestroy: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired,
  documentationList: PropTypes.array,
  gqlUpdateMutation: PropTypes.object,
  gqlDestroyMutation: PropTypes.object.isRequired,
  personalDocumentId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  showTag: PropTypes.bool,
  src: PropTypes.string.isRequired,
  tag: PropTypes.string
}

Header.defaultProps = {
  documentationList: [],
  gqlUpdateMutation: null,
  showTag: false,
  tag: null
}
