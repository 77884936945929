import * as PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import Paper from "@material-ui/core/Paper"
import TablePagination from "@material-ui/core/TablePagination"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { useHistory } from "react-router"
import queryString from "query-string"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import Grid from "@material-ui/core/Grid"
import { useLocation } from "react-router-dom"
import EnhancedTableHead from "./EnhancedTableHead"
import EnhancedTableToolbar from "./EnhancedTableToolbar"
import Loading from "../../Loading"
import FormDialog from "../../FormDialog"

const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 0;
`

const TableWrapper = styled.div`
  overflow-x: auto;
`

const FilterWrapper = styled.div`
  margin: 15px 0;
  padding: 15px 15px 15px 15px;
  background-color: var(--filter-background);
  box-shadow: 0px 2px 4px -1px var(--box-shadow-0), 0px 4px 5px 0px var(--box-shadow-1),
    0px 1px 10px 0px var(--box-shadow-2);
`

export default function TableList(props) {
  const {
    title,
    gqlQuery,
    defaultSort,
    defaultOrderBy,
    CustomTableRow,
    Filter,
    CreationForm,
    attributes,
    withRefresh,
    withExport,
    actionExport
  } = props
  const location = useLocation()
  const history = useHistory()
  const refreshFilter = useRef(null)
  const locationSearch = queryString.parse(location.search)
  const operationName = gqlQuery.definitions[0].name.value

  const [currentPage, setCurrentPage] = useState(parseInt(locationSearch.page, 10) || 0)
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(locationSearch.rowsPerPage || process.env.REACT_APP_PER_PAGE, 10)
  )
  const [sort, setSort] = useState(defaultSort)
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [showFilter, setShowFilter] = useState(true)
  const [openCreationModal, setOpenCreationModal] = useState(false)
  const [filterAttributes, setFilterAttributes] = useState({})
  const { loading, error, data, refetch } = useQuery(gqlQuery, {
    notifyOnNetworkStatusChange: true, // https://www.apollographql.com/docs/react/data/queries/#inspecting-loading-states
    variables: {
      ...filterAttributes,
      offset: rowsPerPage * currentPage,
      limit: rowsPerPage,
      sort,
      orderBy
    }
  })

  const totalRows = data?.[operationName]?.searchInfo?.total || 0
  const filters = data?.[operationName]?.searchInfo?.filters || {}
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalRows - currentPage * rowsPerPage)
  const [currentFilter, setCurrentFilter] = useState({})
  const handleChangePage = (event, newCurrentPage) => {
    const newSearch = { ...locationSearch, page: newCurrentPage }
    history.push({ search: queryString.stringify(newSearch) })
    setCurrentPage(newCurrentPage)
  }

  const handleChangeRowsPerPage = event => {
    const newParams = {
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }
    const newSearch = { ...locationSearch, ...newParams }
    history.push({ search: queryString.stringify(newSearch) })
    setCurrentPage(0)
    setRowsPerPage(newParams.rowsPerPage)
  }

  const handleRequestSort = (event, attribute) => {
    const newSort = orderBy === attribute && sort === "desc" ? "asc" : "desc"

    setSort(newSort)
    setOrderBy(attribute)
  }

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleFilterAttributes = filterValues => {
    if (refreshFilter.current) clearTimeout(refreshFilter.current)
    setCurrentFilter(filterValues)
    refreshFilter.current = setTimeout(() => setFilterAttributes(JSON.parse(JSON.stringify(filterValues))), 2000)
  }

  return (
    <>
      <Grid container>
        <StyledPaper square>
          <EnhancedTableToolbar
            title={title}
            withFilter={Boolean(Filter)}
            toggleFilter={toggleFilter}
            withCreation={Boolean(CreationForm)}
            openCreation={() => setOpenCreationModal(true)}
            withRefresh={withRefresh}
            withExport={withExport}
            exportAction={() => actionExport(currentFilter)}
            refresh={() => refetch()}
          />
          {Filter && showFilter && (
            <FilterWrapper>
              <Filter filters={filters} handleFilterAttributes={handleFilterAttributes} filterAttributes={filterAttributes} />
            </FilterWrapper>
          )}
          <TableWrapper>
            {loading && (
              <Grid container justify="center" alignItems="center">
                <Loading />
              </Grid>
            )}
            {error && <p>Server Error</p>}
            {!error && !loading && (
              <Table>
                <EnhancedTableHead
                  onRequestSort={handleRequestSort}
                  sort={sort}
                  orderBy={orderBy}
                  attributes={attributes}
                />
                <TableBody>
                  {data[operationName].results.map(entity => {
                    return <CustomTableRow key={entity.id} entity={entity} />
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 49 * emptyRows,
                        backgroundColor: "white"
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableWrapper>
          {!loading && (
            <TablePagination
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
              rowsPerPageOptions={[10, 25, 50]}
            />
          )}
        </StyledPaper>
      </Grid>
      {CreationForm && (
        <FormDialog
          title="Create"
          handleClose={() => {
            setOpenCreationModal(false)
          }}
          open={openCreationModal}
        >
          <CreationForm
            handleSuccess={result => {
              setOpenCreationModal(false)
              history.push(`${history.location.pathname}/${result.id}`)
            }}
          />
        </FormDialog>
      )}
    </>
  )
}

TableList.defaultProps = {
  CreationForm: null,
  Filter: null,
  withRefresh: false,
  withExport: false
}

TableList.propTypes = {
  CreationForm: PropTypes.func,
  CustomTableRow: PropTypes.func.isRequired,
  Filter: PropTypes.func,
  attributes: PropTypes.array.isRequired,
  defaultOrderBy: PropTypes.string.isRequired,
  defaultSort: PropTypes.string.isRequired,
  gqlQuery: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  withRefresh: PropTypes.bool,
  withExport: PropTypes.bool
}
