import { join } from "lodash"
import { receiveNotification } from "../actions"

export function getFieldError(errors, field, place) {
  let errorsValue = place || (errors && errors[field])
  if (Array.isArray(errors) && errors != null && errors.find) {
    errorsValue = errors?.find(e => e.path === field)?.messages
  }

  return Array.isArray(errorsValue) ? join(errorsValue, ", ") : errorsValue
}

export function getBaseError(errors, hasError = false) {
  const error = getFieldError(errors, "base")
  if (hasError && !error) return "Something went wrong"
  return error
}

export function errorCallbackNotification(errors, dispatch) {
  const errorText = errors && errors.base
  dispatch(receiveNotification(errorText, { variant: "error" }))
}
