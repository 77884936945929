import PropTypes from "prop-types"
import { gql, useMutation } from "@apollo/client"
import React, { useContext } from "react"
import moment from "moment"
import styled from "styled-components"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import { Ballot } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import BasicCarData from "../../scenes/LeadScene/ShowScene/LeadScene/BasicCarData"
import { LeasingRequestCarContext } from "../../contexts"
import { printDateTime } from "../../helpers/formatter"

const REMOVE_INTERESTED_CAR = gql`
  mutation removeInterestedCar($id: ID!, $interestedCar: RemoveInterestedCarInputObject!) {
    removeInterestedCar(input: { leadId: $id, interestedCar: $interestedCar }) {
      errors {
        messages
        path
      }
    }
  }
`

const REMOVE_CLIENT_INTERESTED_CAR = gql`
  mutation removeInterestedCar($id: ID!, $interestedCar: RemoveClientInterestedCarInputObject!) {
    removeClientInterestedCar(input: { clientId: $id, interestedCar: $interestedCar }) {
      errors {
        messages
        path
      }
    }
  }
`

const Wrapper = styled.div`
  padding: 15px 15px 15px 0;
  margin: 10px 15px 10px 0;
  box-shadow: 15px 15px 10px -7px rgba(222, 222, 222, 1);
`

const StyledSmall = styled.small`
  display: block;
  margin: 0;
`

export default function InterestedCar(props) {
  const { subjectId, subjectType, interestedCar, onCarLoaded, refetch, setOpenModal } = props
  const { title, duration, kmYear, price, deliveryTime, distributorId, carId, outdated, createdAt } = interestedCar
  const { setLeasingRequestCar } = useContext(LeasingRequestCarContext)
  const { t } = useTranslation("lead")
  const [leadMutation] = useMutation(REMOVE_INTERESTED_CAR, { onCompleted: () => refetch() })
  const [clientMutation] = useMutation(REMOVE_CLIENT_INTERESTED_CAR, { onCompleted: () => refetch() })

  const removeInterestedCar = () => {
    const variables = { id: subjectId, interestedCar: { id: interestedCar.id } }

    if (subjectType === "Client") clientMutation({ variables })
    if (subjectType === "Lead") leadMutation({ variables })
  }

  const createLeasingRequest = () => {
    setLeasingRequestCar({
      carId: interestedCar.carId,
      priceId: interestedCar.priceId,
      distributorId: interestedCar.distributorId
    })
    setOpenModal("createLeasingRequest")
  }

  return (
    <Wrapper>
      {createdAt && <StyledSmall>{`${printDateTime(createdAt)} ${moment(createdAt).fromNow()} `}</StyledSmall>}
      <Button onClick={createLeasingRequest} color="secondary" startIcon={<Ballot />}>
        {t("createLeasingRequest")}
      </Button>
      <IconButton color="secondary" onClick={removeInterestedCar}>
        <DeleteForeverIcon />
      </IconButton>
      <BasicCarData
        title={title}
        duration={duration}
        kmYear={kmYear}
        price={price}
        deliveryTime={deliveryTime}
        distributorId={distributorId}
        carId={carId}
        outdated={outdated}
        onCarLoaded={onCarLoaded}
      />
    </Wrapper>
  )
}

InterestedCar.propTypes = {
  interestedCar: PropTypes.object.isRequired,
  subjectId: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
  onCarLoaded: PropTypes.any,
  refetch: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired
}

InterestedCar.defaultProps = {
  onCarLoaded: () => {}
}
