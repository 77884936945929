import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import { leadStatusesOptions } from "../../../helpers/constants"
import TextSelectInput from "../inputs/TextSelectInput"
import GraphQLErrors from "../../GraphQLErrors"

const UPDATE_LEAD = gql`
  mutation updateLead($id: ID!, $leadArguments: UpdateLeadInputObject!) {
    updateLead(input: { leadId: $id, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function UpdateLeadStatus(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation("lead")
  const [mutation, { data, loading, error }] = useMutation(UPDATE_LEAD, {
    onCompleted: newData => {
      if (newData.updateLead?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updateLead?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leadId, leadArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <TextSelectInput field="status" label="Status" options={leadStatusesOptions(t)} required />
      <ModalPrimaryButton isFetching={loading}>{t("common:updateStatus")}</ModalPrimaryButton>
    </Form>
  )
}

UpdateLeadStatus.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
