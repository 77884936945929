import React, { useRef, useState } from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import CheckboxInput from "../inputs/CheckboxInput"
import DistributorEmail from "../inputs/DistributorEmail"

const START_OFFLINE_SCORING = gql`
  mutation startOfflineScoring($leasingRequestId: ID!, $startOfflineScoringArguments: StartOfflineScoringInputObject!) {
    startOfflineScoring(
      input: { leasingRequestId: $leasingRequestId, startOfflineScoringArguments: $startOfflineScoringArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function StartOfflineScoring(props) {
  const { leasingRequestId, distributorId, locale, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const formApi = useRef(null)
  const [sendDistributorEmail, setSendDistributorEmail] = useState(false)

  const [mutation, { data, loading, error }] = useMutation(START_OFFLINE_SCORING, {
    onCompleted: newData => {
      if (newData.startOfflineScoring?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.startOfflineScoring?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, startOfflineScoringArguments: values }
    })
  }

  return (
    <Form
      onSubmit={handleSubmit}
      getApi={api => {
        formApi.current = api
      }}>
      <GraphQLErrors errors={errors} />
      <CheckboxInput
        field="sendDistributorEmail"
        label={t("sendDistributorEmail")}
        initialValue={false}
        onChange={event => setSendDistributorEmail(event.target.checked)}
      />
      {sendDistributorEmail && (
        <DistributorEmail
          formApi={formApi}
          leasingRequestId={leasingRequestId}
          distributorId={distributorId}
          locale={locale}
          errors={errors}
          personalDocumentationChecklist
          sortContactsBy="mainScoringContact"
        />
      )}
      <ModalPrimaryButton isFetching={loading}>{t("startScoring")}</ModalPrimaryButton>
    </Form>
  )
}

StartOfflineScoring.defaultProps = {
  handleSuccess: () => {}
}

StartOfflineScoring.propTypes = {
  distributorId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func,
  leasingRequestId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}
