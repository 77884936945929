import PropTypes from "prop-types"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import React from "react"
import styled from "styled-components"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import { useTranslation } from "react-i18next"
import Plan from "./Plan"

const StyledTable = styled(Table)`
  margin-bottom: 30px;
  background-color: var(--light-grey);
`

const StyledTableHead = styled(TableHead)`
  background-color: var(--stormy-blue);
  th {
    color: var(--white);
  }
`

export default function PlansTable(props) {
  const { id, durationsIncluded, kmsYearIncluded, car, minimumPrice, ActionJSX } = props

  const { t } = useTranslation()
  return (
    <StyledTable>
      <StyledTableHead>
        <TableRow>
          <TableCell>{t("plan_plural")}</TableCell>
          {durationsIncluded.map(duration => (
            <TableCell key={duration}>{t("concreteDuration", { duration })}</TableCell>
          ))}
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {kmsYearIncluded.map(kmYear => (
          <TableRow key={kmYear}>
            <TableCell>{t("concreteKmYear", { kmYear })}</TableCell>
            {durationsIncluded.map(duration => {
              return (
                <Plan
                  key={`${id}_${kmYear}_${duration}`}
                  id={id}
                  car={car}
                  duration={duration}
                  kmYear={kmYear}
                  minimumPrice={minimumPrice}
                  ActionJSX={ActionJSX}
                />
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  )
}

PlansTable.propTypes = {
  ActionJSX: PropTypes.func,
  car: PropTypes.object.isRequired,
  durationsIncluded: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  kmsYearIncluded: PropTypes.array.isRequired,
  minimumPrice: PropTypes.number
}

PlansTable.defaultProps = {
  ActionJSX: null,
  minimumPrice: null
}
