export const TWILIO_DEVICE_READY = "TWILIO_DEVICE_READY"
export const TWILIO_DEVICE_OFFLINE = "TWILIO_DEVICE_OFFLINE"
export const RECEIVE_MANUAL_CAN_RECEIVE_A_CALL = "RECEIVE_MANUAL_CAN_RECEIVE_A_CALL"
export const ACCEPT_INCOMING_CALL = "ACCEPT_INCOMING_CALL"
export const START_OUTGOING_CALL = "START_OUTGOING_CALL"
export const ACCEPT_OUTGOING_CALL = "ACCEPT_OUTGOING_CALL"
export const UPDATE_CURRENT_CALL = "UPDATE_CURRENT_CALL"
export const RECEIVE_END_CALL = "RECEIVE_END_CALL"
export const CONNECTION_WARNING = "TWILIO_CONNECTION_WARNING"
export const CONNECTION_WARNING_CLEARED = "TWILIO_CONNECTION_WARNING_CLEARED"

export const twilioDeviceReady = () => {
  return {
    type: TWILIO_DEVICE_READY
  }
}

export const twilioDeviceOffline = () => {
  return {
    type: TWILIO_DEVICE_OFFLINE
  }
}

export const receiveManualCanReceiveACall = value => {
  return {
    value,
    type: RECEIVE_MANUAL_CAN_RECEIVE_A_CALL
  }
}

export const receiveEndCall = () => {
  return {
    type: RECEIVE_END_CALL
  }
}

export const acceptIncomingCall = () => {
  return {
    type: ACCEPT_INCOMING_CALL
  }
}

export const acceptOutgoingCall = () => {
  return {
    type: ACCEPT_OUTGOING_CALL
  }
}

export const startOutgoingCall = (subjectId, subjectType, subjectName) => {
  return {
    subjectId,
    subjectType,
    subjectName,
    type: START_OUTGOING_CALL
  }
}

export const updateCurrentCall = (subjectId, subjectType, subjectName) => {
  return {
    subjectId,
    subjectType,
    subjectName,
    type: UPDATE_CURRENT_CALL
  }
}

export const connectionWarning = warning => ({
  warning,
  type: CONNECTION_WARNING
})

export const connectionWarningCleared = warning => ({
  warning,
  type: CONNECTION_WARNING_CLEARED
})
