import React, { useContext, useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Grid from "@material-ui/core/Grid"
import { Alert } from "@material-ui/lab"
import { gql, useLazyQuery } from "@apollo/client"
import styled from "styled-components"
import { Toolbar } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import { useTranslation } from "react-i18next"
import Tooltip from "@material-ui/core/Tooltip"
import Slide from "@material-ui/core/Slide"
import { isEmpty } from "lodash"
import { Description } from "@material-ui/icons"
import Distributor from "../../../models/distributor"
import { Paragraph, StyledLabel } from "../../styled/typography"
import BasicField from "../../fields/BasicField"
import CarTagList from "../../CarTagList"
import Services from "./Services"
import ExtrasList from "./ExtrasList"
import Plans from "./Plans"
import { CarListDialogContext } from "../../../contexts"
import { DefaultButton } from "../../styled/buttons"

const CAR = gql`
  query car($id: ID!) {
    car(id: $id) {
      id
      activeForSales
      title
      fuel
      transmission
      cv
      cylinder
      doors
      co2Emission
      color
      warning
      comment
      defaultImageUrl
      environmentalLabel
      environmentalLabelImage
      distributorId
      isActive
      sold
      new
      onlineScoring
      transmission
      lastItems
      fastDelivering
      sellingStatus
      fuel
      deliveryTime
      allRiskExcess
      hasRoadTax
      hasAllRiskInsurance
      hasCarChecking
      hasCarRepair
      hasRoadAssistance
      deductible
      hasReplacementCar
      hasReplacementTyres
      preDeliveryCar
      refurbishInsurance
      homeDelivery
      extrasList
      featuredExtras
      technology
      comfort
      security
      outside
      promotion
      promotionDescription
      durationsIncluded
      kmsYearIncluded
      minimumPrice
      downPayment
      deliveryTime
      registrationYear
      currentKm
      plateNumber
      averageFuelConsumption
      loadspaceCapacity
      width
      height
      length
      range
      cubicMeters
      isothermal
      parcel
      slug
      countryCode
      humanizedDeliveryProvinces
      priceRanges {
        id
        price
        priceWithoutTaxes
        belowKmRefund
        extraKmCost
        kmYear
        duration
      }
    }
  }
`

const StyledDialog = styled(Dialog)`
  background-color: var(--light-gre);
  position: static;
`

const CarImage = styled.img`
  width: 100%;
`

const CarTitle = styled(Paragraph)`
  text-transform: uppercase;
  font-weight: bold;
`

const DistributorName = styled(Paragraph)`
  text-transform: uppercase;
`

const EnvironmentalImage = styled.img`
  position: absolute;
  left: 0px;
  width: 75px;
`

const ImageGrid = styled(Grid)`
  position: relative;
`

const StyledAppBar = styled(AppBar)`
  position: relative;
`

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  align-items: center;
`

const ColorCircle = styled.span(props => ({
  backgroundColor: props.color,
  width: "30px",
  height: "30px",
  boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.16)",
  border: `3px solid ${props.color}`,
  borderRadius: "60px",
  position: "relative",
  display: "inline-block",
  margin: "0 5px"
}))

const ColorTitle = styled(StyledLabel)`
  display: block;
`

const MoreExtras = styled.p`
  margin: 5px 0 15px 0;
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const colorToHtml = color => {
  if (color === "bronze") return "#cd7f32"

  return color
}

export default function CarDialog() {
  const { selectedCar, setSelectedCar } = useContext(CarListDialogContext)
  const { t } = useTranslation()
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const [fetchCar, { data, loading }] = useLazyQuery(CAR)

  useEffect(() => {
    if (selectedCar) {
      fetchCar({
        variables: {
          id: selectedCar
        }
      })
    }
  }, [selectedCar])

  const car = data?.car
  const distributor = Distributor.getDistributor(distributors, car?.distributorId)
  const open = Boolean(selectedCar && car)
  const singleExtrasList = isEmpty(car?.featuredExtras)

  return (
    <StyledDialog fullScreen open={open} TransitionComponent={Transition}>
      {car && (
        <>
          <StyledAppBar>
            <StyledToolbar>
              <div>
                <CarTitle>{car.title}</CarTitle>
                <DistributorName>{distributor.name}</DistributorName>
              </div>
              <IconButton edge="end" color="inherit" onClick={() => setSelectedCar(null)}>
                <CloseIcon />
              </IconButton>
            </StyledToolbar>
          </StyledAppBar>
          <DialogContent>
            {car.promotion && <Alert severity="success">{car.promotionDescription}</Alert>}
            {car.warning && <Alert severity="warning">{car.warning}</Alert>}
            <CarTagList car={car} loading={loading} />
            <Grid container>
              <ImageGrid item xs={12} md={3}>
                <CarImage src={car.defaultImageUrl} />
                {car.environmentalLabel && <EnvironmentalImage src={car.environmentalLabelImage} />}
                <DefaultButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.open(
                      `https://lambda.swipcar.com/pdf/sc-car-pdf?url=https://car-pdf.vercel.app/${car.countryCode}/${car.slug}`
                    )
                  }
                  startIcon={<Description />}
                >
                  PDF
                </DefaultButton>
              </ImageGrid>
              <Grid item xs={12} md={3}>
                <BasicField title={t("fuel.title")} value={t(`fuel.${car.fuel}`)} />
                <BasicField title={t("transmission.title")} value={t(`transmission.${car.transmission}`)} />
                <BasicField title={t("doors")} value={car.doors} />
                <BasicField title={t("cv")} value={car.cv} />
                <BasicField title={t("cylinder")} value={car.cylinder} />
                <BasicField title={t("cubicMeters")} value={car.cubicMeters} />
                <BasicField title={t("co2Emission")} value={car.co2Emission} />
                <BasicField title={t("currentKm")} value={car.currentKm} />
                <BasicField title={t("registrationYear")} value={car.registrationYear} />
                <BasicField title={t("leasingRequest:plateNumber")} value={car.plateNumber} />
              </Grid>
              <Grid item xs={12} md={3}>
                <BasicField title={t("averageFuelConsumption")} value={car.averageFuelConsumption} />
                <BasicField title={t("loadspaceCapacity")} value={car.loadspaceCapacity} />
                <BasicField title={t("width")} value={car.width} />
                <BasicField title={t("height")} value={car.height} />
                <BasicField title={t("length")} value={car.length} />
                <BasicField title={t("range")} value={car.range} />
                <BasicField title={t("isothermal")} type="boolean" value={car.isothermal} />
                <BasicField title={t("parcel")} type="boolean" value={car.parcel} />
              </Grid>
              <Grid item xs={12} md={3}>
                <BasicField title={t("sellingStatus.title")} value={t(`sellingStatus.${car.sellingStatus}`)} />
                <BasicField title={t("downPayment")} value={car.downPayment} />
                <BasicField
                  title={t("deliveryTime")}
                  value={t("concreteDeliveryTime", {
                    deliveryTimeFrom: car.deliveryTime - 1,
                    deliveryTimeUntil: car.deliveryTime + 1
                  })}
                />
                <BasicField title={t("deliveryProvinces")} value={car.humanizedDeliveryProvinces?.join(", ")} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <ColorTitle>{t("color")}</ColorTitle>
                {car.color.map(c => (
                  <Tooltip key={c} title={t(`colors.${c}`)} placement="top">
                    <ColorCircle color={colorToHtml(c)} />
                  </Tooltip>
                ))}
              </Grid>
              <Grid item xs={12}>
                <ExtrasList extras={singleExtrasList ? car.extrasList : car.featuredExtras} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {!singleExtrasList && (
                  <>
                    <Paragraph>{t("technology")}</Paragraph>
                    <MoreExtras>{car.technology.join(", ")}</MoreExtras>
                    <Paragraph>{t("comfort")}</Paragraph>
                    <MoreExtras>{car.comfort.join(", ")}</MoreExtras>
                    <Paragraph>{t("security")}</Paragraph>
                    <MoreExtras>{car.security.join(", ")}</MoreExtras>
                    <Paragraph>{t("outside")}</Paragraph>
                    <MoreExtras>{car.outside.join(", ")}</MoreExtras>
                  </>
                )}
                {singleExtrasList && <MoreExtras>{[...car.extrasList].splice(5).join(", ")}</MoreExtras>}
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12} md={3}>
                <Services car={car} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Plans car={car} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {car.comment && <Alert severity="info">{car.comment}</Alert>}
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </StyledDialog>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}
