import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import SignedIn from "../../components/layouts/SignedIn"
import readmeUrl from "./readme.md"
import "../../markdown.scss"

export default function HelpScene() {
  const [readme, setReadme] = useState(null)

  useEffect(() => {
    fetch(readmeUrl)
      .then(response => response.text())
      .then(text => {
        setReadme(text)
      })
  }, [])

  return (
    <SignedIn>
      <ReactMarkdown plugins={[gfm]} source={readme} escapeHtml={false} className="markdown" />
    </SignedIn>
  )
}
