import React from "react"
import ReactDOM from "react-dom"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./index.scss"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { applyMiddleware, createStore } from "redux"
import thunkMiddleware from "redux-thunk"
import axios from "axios"
import { create } from "jss"
import { isEmpty } from "lodash"
import { I18nextProvider } from "react-i18next"
import { Provider } from "react-redux"
import { createGenerateClassName, jssPreset, StylesProvider, ThemeProvider } from "@material-ui/styles"
import LogRocket from "logrocket"
import * as Sentry from "@sentry/react"
import i18n from "./i18n"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import MuiTheme from "./MuiTheme"
import swipcarAdministrator from "./reducers/index"
import App from "./App"

const generateClassName = createGenerateClassName()
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: document.getElementById("jss-insertion-point")
})

const middlewares = [thunkMiddleware, LogRocket.reduxMiddleware()]

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  const { logger } = require(`redux-logger`)

  middlewares.push(logger)
}

const store = createStore(
  swipcarAdministrator,
  process.env.REACT_APP_ENVIRONMENT !== "production" &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true, trace: true }),
  applyMiddleware(...middlewares)
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    let jsonResponse = { httpStatus: error.request.status }
    const data = {
      request: error.config.data,
      response: error.request.response
    }
    if (error.request.status >= 500) {
      if (window.Rollbar) {
        window.Rollbar.critical("Server down", error, data)
      }
      jsonResponse = { ...jsonResponse, base: "Server Error! Please contact administrator." }
    }

    try {
      if (!isEmpty(error.request.response)) {
        const response = JSON.parse(error.request.response)
        jsonResponse = { ...jsonResponse, ...response.errors }
      }
    } catch (e) {
      if (window.Rollbar) {
        window.Rollbar.critical("Error is not JSON", error, data)
      }
    }
    return Promise.reject(jsonResponse)
  }
)

ReactDOM.render(
  <Sentry.ErrorBoundary onError={Sentry.captureException}>
    <ThemeProvider theme={MuiTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StylesProvider jss={jss} generateClassName={generateClassName}>
          <I18nextProvider i18n={i18n}>
            <Provider store={store}>
              <App />
            </Provider>
          </I18nextProvider>
        </StylesProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
)
