import React from "react"
import SignedIn from "../../components/layouts/SignedIn"
import NextTask from "./SalesDashboard/NextTask"
import SalesDashboard from "./SalesDashboard"
import SalesCloserDashboard from "./SalesCloserDashboard"
import useAbilities from "../../hooks/useAbilities"
import NothingDashboard from "./NothingDashboard"

export default function DashboardScene() {
  const { allowed: salesAllowed } = useAbilities("view_sales_dashboard", true)
  const { allowed: salesCloserAllowed } = useAbilities("view_sales_closer_dashboard", true)
  const nothingAllowed = !salesAllowed && !salesCloserAllowed

  return (
    <SignedIn>
      <NextTask />
      {salesAllowed && <SalesDashboard />}
      {salesCloserAllowed && <SalesCloserDashboard />}
      {nothingAllowed && <NothingDashboard />}
    </SignedIn>
  )
}
