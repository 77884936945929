import PropTypes from "prop-types"
import React from "react"
import Button from "@material-ui/core/Button"
import styled from "styled-components"

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export default function PrimaryButton(props) {
  const { children, disabled, isFetching, ...rest } = props

  return (
    <ButtonWrapper>
      <Button disabled={disabled || isFetching} type="submit" {...rest}>
        {isFetching ? "Loading" : children}
      </Button>
    </ButtonWrapper>
  )
}

PrimaryButton.defaultProps = {
  color: "primary",
  disabled: false,
  variant: "contained"
}

PrimaryButton.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  variant: PropTypes.string
}
