import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Comment from "../Comment"
import { StyledGreyPaper } from "../styled/papers"

const CommentsWrapper = styled.div`
  margin: 10px 0;
`

export default function CommentFormWrapper(props) {
  const { children, lastComments, gqlUpdateMutation } = props

  return (
    <StyledGreyPaper>
      {children}
      <CommentsWrapper>
        {lastComments.map(comment => (
          <Comment editionEnabled key={comment.id} comment={comment} gqlUpdateMutation={gqlUpdateMutation} />
        ))}
      </CommentsWrapper>
    </StyledGreyPaper>
  )
}

CommentFormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  lastComments: PropTypes.array,
  gqlUpdateMutation: PropTypes.object
}

CommentFormWrapper.defaultProps = {
  lastComments: [],
  gqlUpdateMutation: null
}
