import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const PDFWrapper = styled.div`
  padding: 5px;
  height: 100%;
`

const StyledEmbed = styled.embed`
  width: 100%;
  height: 100%;
`

export default function PdfViewer(props) {
  const { preferredUrl } = props
  const file = { url: preferredUrl }

  return (
    <PDFWrapper>
      <StyledEmbed
        className="pdfobject"
        src={`${file.url}#search=wordList&toolbar=1&statusbar=1&messages=1&navpanes=1`}
        type="application/pdf"
      />
    </PDFWrapper>
  )
}

PdfViewer.propTypes = {
  preferredUrl: PropTypes.string.isRequired
}
