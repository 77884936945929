import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import TextSelectInput from "../inputs/TextSelectInput"
import BasicInput from "../inputs/BasicInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import { leasingRequestUndecidedReasonOptions } from "../../../helpers/constants"

const COMPLETE_SCORING = gql`
  mutation completeScoring($leasingRequestId: ID!, $completeScoringArguments: CompleteScoringInputObject!) {
    completeScoring(
      input: { leasingRequestId: $leasingRequestId, completeScoringArguments: $completeScoringArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function ScoringUndecided(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(COMPLETE_SCORING, {
    onCompleted: newData => {
      if (newData.completeScoring?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.completeScoring?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, completeScoringArguments: { ...values, scoringStatus: "undecided" } }
    })
  }

  const validationSchema = Yup.object().shape({
    closedReason: Yup.string().required(t("common:required")),
    scoringComments: Yup.string().trim().required(t("common:required"))
  })

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <TextSelectInput
        field="rejectedReason"
        label={t("scoringRejectedReason")}
        options={leasingRequestUndecidedReasonOptions(t)}
        errors={errors}
        required
      />
      <BasicInput field="scoringComments" label={t("common:comments")} errors={errors} multiline required />
      <ModalPrimaryButton isFetching={loading}>{t("scoringUndecided")}</ModalPrimaryButton>
    </Form>
  )
}

ScoringUndecided.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
