import PropTypes from "prop-types"
import { Grid } from "@material-ui/core"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import styled from "styled-components"
import { isNil } from "lodash"
import Loading from "../../../../../components/Loading"
import EmailList from "../../../../../components/EmailList"

const StyledGrid = styled(Grid)`
  padding: 1px;
  min-height: 720px;
`

// TODO Think about using fragments https://www.apollographql.com/docs/react/v3.0-beta/data/fragments/
const LEASING_REQUEST_EMAILS = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      locale
      emails {
        id
        from
        to
        subject
        content
        opened
        clickedAt
        createdAt
        openedAt
        sent
      }
    }
  }
`

const SEND_EMAIL = gql`
  mutation sendEmailToLeasingRequest($resourceId: ID!, $emailArguments: SendEmailToLeasingRequestInputObject!) {
    sendEmailToLeasingRequest(input: { leasingRequestId: $resourceId, emailArguments: $emailArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function Emails(props) {
  const { leasingRequestId } = props
  const { loading, data } = useQuery(LEASING_REQUEST_EMAILS, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  return (
    <StyledGrid container direction="column" spacing={2}>
      <EmailList
        emails={data.leasingRequest.emails}
        gqlMutation={SEND_EMAIL}
        resourceId={leasingRequestId}
        resource="LeasingRequest"
        locale={data.leasingRequest.locale}
      />
    </StyledGrid>
  )
}

Emails.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
