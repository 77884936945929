import PropTypes from "prop-types"
import React from "react"
import CarTag from "../CarTagList/CarTag"

export default function LeasingRequestTagList(props) {
  const { leasingRequest } = props

  return (
    <>
      {leasingRequest.basicCar.outdated && <CarTag tagName="outdated" small />}
      {leasingRequest.basicCar.sold && <CarTag tagName="sold" small />}
      {leasingRequest.online && <CarTag tagName="onlineScoring" small />}
    </>
  )
}

LeasingRequestTagList.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
