import React, { useState } from "react"
import * as PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Grid, Tab, Tabs } from "@material-ui/core"
import SwipeableViews from "react-swipeable-views"
import { useTranslation } from "react-i18next"
import Steps from "./Steps"
import Comments from "./Comments"

const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(2)}px`
  },
  tabContent: {
    height: "100%",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "margin-bottom": theme.spacing(3)
  },
  tab: {
    ".Mui-selected": {
      "background-color": "var(--light-grey)"
    }
  },
  fillHeight: {
    flex: "1"
  },
  swipeableView: {
    "&, .react-swipeable-view-container": {
      height: "100%"
    }
  }
}))

export default function RightPane(props) {
  const { leasingRequest } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab)
  }

  return (
    <Grid item container xs={12} md={7} lg={8} xl={9} className={classes.container} direction="column">
      <Grid item>
        <Tabs variant="scrollable" value={selectedTab} onChange={handleTabChange}>
          <Tab label={t("statuses")} className={classes.tab} />
          <Tab label={t("comments")} className={classes.tab} />
        </Tabs>
      </Grid>
      <Grid item className={classes.fillHeight}>
        <SwipeableViews
          index={selectedTab}
          onChangeIndex={index => handleTabChange(null, index)}
          slideStyle={{ overflow: "auto hidden" }}
          className={classes.swipeableView}>
          <Grid container value={selectedTab} index={0} className={classes.tabContent}>
            {selectedTab === 0 && <Steps leasingRequest={leasingRequest} />}
          </Grid>
          <Grid container value={selectedTab} index={1} className={classes.tabContent}>
            {selectedTab === 1 && <Comments leasingRequest={leasingRequest} />}
          </Grid>
        </SwipeableViews>
      </Grid>
    </Grid>
  )
}

RightPane.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
