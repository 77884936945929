import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import * as PropTypes from "prop-types"
import moment from "moment"
import business from "moment-business"
import { useTranslation } from "react-i18next"
import DateTimeInput from "../inputs/DateTimeInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const SCHEDULE_LEASING_REQUEST_CALL = gql`
  mutation scheduleLeasingRequestCall(
    $leasingRequestId: ID!
    $scheduleLeasingRequestCall: ScheduleLeasingRequestCallInputObject!
  ) {
    scheduleLeasingRequestCall(
      input: { leasingRequestId: $leasingRequestId, scheduleLeasingRequestCall: $scheduleLeasingRequestCall }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function ScheduleLeasingRequestCall(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation()
  const [mutation, { data, loading, error }] = useMutation(SCHEDULE_LEASING_REQUEST_CALL, {
    onCompleted: newData => {
      if (newData.scheduleLeasingRequestCall?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.scheduleLeasingRequestCall?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, scheduleLeasingRequestCall: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <DateTimeInput
        field="callScheduledAt"
        label={t("scheduleAt")}
        maxDate={business.addWeekDays(moment(), 10).toDate()}
        disablePast
        minutesStep={5}
        errors={errors}
        required
      />
      <ModalPrimaryButton isFetching={loading}>{t("scheduleACall")}</ModalPrimaryButton>
    </Form>
  )
}

ScheduleLeasingRequestCall.defaultProps = {
  handleSuccess: () => {}
}

ScheduleLeasingRequestCall.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func
}
