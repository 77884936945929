import React from "react"
import * as PropTypes from "prop-types"
import styled from "styled-components"
import StarIcon from "@material-ui/icons/Star"

const FeaturedExtraTitle = styled.p`
  display: inline-block;
  margin: 0;
  line-height: 20px;
`

const FeaturedExtra = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
`

export default function ExtrasList(props) {
  const { extras } = props

  return (
    <>
      {extras.slice(0, 5).map(e => (
        <FeaturedExtra key={e}>
          <StarIcon color="primary" />
          <FeaturedExtraTitle>{e}</FeaturedExtraTitle>
        </FeaturedExtra>
      ))}
    </>
  )
}

ExtrasList.propTypes = {
  extras: PropTypes.array.isRequired
}
