import React from "react"
import PropTypes from "prop-types"
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import styled from "styled-components"

const StyledIcon = styled(ListItemIcon)`
  color: white;
`

const StyledListItemText = styled(ListItemText)`
  span {
    font-size: 14px;
    color: white;
    line-height: 1;
  }
`

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  primary: {
    color: "white",
    lineHeight: 1
  }
}))

export default function SingleListItemBasic(props) {
  const { access, title, icon, action, isNested } = props
  const classes = useStyles()

  if (!access) return null

  return (
    <ListItem button onClick={action} className={isNested ? classes.nested : ""}>
      <StyledIcon>{icon}</StyledIcon>
      <StyledListItemText primary={title} />
    </ListItem>
  )
}

SingleListItemBasic.defaultProps = {
  access: true,
  isNested: false,
  action: null,
  icon: null,
  title: null
}

SingleListItemBasic.propTypes = {
  access: PropTypes.bool,
  action: PropTypes.any,
  icon: PropTypes.any,
  isNested: PropTypes.bool,
  title: PropTypes.any
}
