import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import BasicInput from "../inputs/BasicInput"
import GraphQLErrors from "../../GraphQLErrors"
import { ModalPrimaryButton } from "../../styled/buttons"
import Distributor from "../../../models/distributor"
import Loading from "../../Loading"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      online
      distributor {
        type
      }
    }
  }
`

const SEND_CONTRACT = gql`
  mutation sendContract($leasingRequestId: ID!, $sendContractArguments: SendContractInputObject!) {
    sendContract(input: { leasingRequestId: $leasingRequestId, sendContractArguments: $sendContractArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function SendContract(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { data: queryData, loading: queryLoading, error: queryError } = useQuery(LEASING_REQUEST, {
    variables: { id: leasingRequestId }
  })
  const [mutation, { data, loading, error }] = useMutation(SEND_CONTRACT, {
    onCompleted: newData => {
      if (newData.sendContract?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.sendContract?.errors
  if (error || queryError) return <p>Server Error</p>

  if (queryLoading) return <Loading />
  if (queryData.leasingRequest === null) return <p>Not found</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, sendContractArguments: values }
    })
  }

  const distributor = new Distributor(queryData.leasingRequest.distributor)

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      {distributor.isFCAES() && queryData.leasingRequest.online && (
        <>
          <BasicInput field="paymentIban" label={t("paymentIban")} errors={errors} required />
          <BasicInput field="deliveryAddress" label={t("deliveryAddress")} errors={errors} required />
        </>
      )}
      <ModalPrimaryButton isFetching={loading}>{t("sendContract")}</ModalPrimaryButton>
    </Form>
  )
}

SendContract.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
