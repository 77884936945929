import * as PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Form } from "informed"
import { useTranslation } from "react-i18next"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import * as Yup from "yup"
import BasicInput from "../inputs/BasicInput"
import GraphQLErrors from "../../GraphQLErrors"
import Loading from "../../Loading"
import { ModalPrimaryButton } from "../../styled/buttons"
import TextSelectInput from "../inputs/TextSelectInput"
import { clientRequestCategories, clientRequestSubcategories } from "../../../helpers/constants"

const CREATE_CLIENT_REQUEST = gql`
  mutation createClientRequest($clientRequestArguments: CreateClientRequestInputObject!) {
    createClientRequest(input: { clientRequestArguments: $clientRequestArguments }) {
      result {
        id
      }
      errors {
        messages
        path
      }
    }
  }
`

const CLIENT_LEASING_REQUESTS = gql`
  query client($clientId: ID!) {
    client(id: $clientId) {
      leasingRequests(offset: 0, limit: 100) {
        searchInfo {
          total
        }
        results {
          id
          distributor {
            id
            name
          }
          basicCar {
            kmYear
            duration
            price
            car {
              title
            }
          }
        }
      }
    }
  }
`

export default function CreateClientRequest(props) {
  const { leasingRequestId, clientId, handleSuccess } = props
  const { t } = useTranslation("clientRequest")
  const [mutation, { data, loading, error }] = useMutation(CREATE_CLIENT_REQUEST, {
    onCompleted: newData => {
      if (newData.createClientRequest?.errors == null) {
        handleSuccess(newData.createClientRequest.result)
      }
    }
  })
  const [fetchLeasingRequests, { data: queryData, loading: queryLoading, error: queryError }] =
    useLazyQuery(CLIENT_LEASING_REQUESTS)

  useEffect(() => {
    if (clientId) {
      fetchLeasingRequests({
        variables: { clientId }
      })
    }
  }, [clientId, fetchLeasingRequests])

  const errors = data?.createClientRequest?.errors
  if (error || queryError) return <p>Server Error</p>

  if (queryLoading) return <Loading />

  const handleSubmit = values => {
    mutation({
      variables: { clientRequestArguments: { clientId, leasingRequestId, ...values } }
    })
  }

  const validationSchema = Yup.object().shape({
    leasingRequestId: Yup.string().required(t("common:required"))
  })

  const leasingRequestOptions = queryData?.client?.leasingRequests?.results?.map(leasingRequest => {
    const plan = t("common:concretePlan", {
      duration: leasingRequest.basicCar.duration,
      kmYear: leasingRequest.basicCar.kmYear,
      price: leasingRequest.basicCar.price
    })

    return {
      label: `${leasingRequest.distributor.name} ${leasingRequest.basicCar.car.title} ${plan}`,
      value: leasingRequest.id
    }
  })

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} />
          {clientId && (
            <TextSelectInput
              field="leasingRequestId"
              label={t("common:leasingRequest")}
              options={leasingRequestOptions || []}
              errors={errors}
            />
          )}
          <BasicInput field="description" label={t("fields.description")} errors={errors} multiline />
          <TextSelectInput
            field="category"
            label={t("fields.category")}
            options={clientRequestCategories(t)}
            errors={errors}
          />
          {formState.values.category && (
            <TextSelectInput
              field="subcategory"
              label={t("fields.subcategory")}
              options={clientRequestSubcategories(t)[formState.values.category] || []}
              errors={errors}
            />
          )}
          <ModalPrimaryButton isFetching={loading}>{t("createClientRequest")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

CreateClientRequest.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string,
  clientId: PropTypes.string
}

CreateClientRequest.defaultProps = {
  leasingRequestId: null,
  clientId: null
}
