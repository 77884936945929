import React from "react"
import { Form } from "informed"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import BasicInput from "../inputs/BasicInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import CheckboxInput from "../inputs/CheckboxInput"
import TextSelectInput from "../inputs/TextSelectInput"
import GraphQLErrors from "../../GraphQLErrors"
import { leasingRequestCloseReasonsOptions } from "../../../helpers/constants"

const ROLLBACK = gql`
  mutation rollback($leasingRequestId: ID!, $rollbackLeasingRequestArguments: RollbackLeasingRequestInputObject!) {
    rollbackLeasingRequest(
      input: { leasingRequestId: $leasingRequestId, rollbackLeasingRequestArguments: $rollbackLeasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function RollbackLeasingRequest(props) {
  const { leasingRequestId } = props
  const { t } = useTranslation("leasingRequest")
  const history = useHistory()

  const [mutation, { data, loading, error }] = useMutation(ROLLBACK, {
    onCompleted: newData => {
      if (newData.rollbackLeasingRequest?.errors == null) {
        history.push("/leasing-requests")
      }
    }
  })
  const errors = data?.rollbackLeasingRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, rollbackLeasingRequestArguments: values }
    })
  }

  const validationSchema = Yup.object().shape({
    closedReason: Yup.string().required(t("common:required"))
  })

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} />
          <BasicInput field="comment" label={t("common:comments")} required multiline />
          <CheckboxInput field="close" label={t("closeRollback")} errors={errors} />
          {formState.values.close && (
            <TextSelectInput
              field="closedReason"
              label={t("common:closeReason")}
              options={leasingRequestCloseReasonsOptions(t)}
              errors={errors}
              required
            />
          )}
          <ModalPrimaryButton isFetching={loading}>{t("rollback")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

RollbackLeasingRequest.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
