import { includes, isEmpty } from "lodash"

export default class Account {
  constructor(data) {
    Object.assign(this, Account.defaultProperties, data)
  }

  static defaultProperties = {
    abilities: []
  }

  hasAbility(ability, strictMode = false) {
    if (!strictMode && includes(this.abilities, "manage_all")) return true

    return includes(this.abilities, ability)
  }

  hasAbilities(abilities, strictMode = false) {
    if (!strictMode && includes(this.abilities, "manage_all")) return true

    return !isEmpty(this.abilities.filter(a => abilities.includes(a)))
  }
}
