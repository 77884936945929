import * as PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Checkbox, TextField } from "@material-ui/core"
import { includes, isEmpty, isFunction, isNaN } from "lodash"
import { gql, useMutation } from "@apollo/client"
import styled from "styled-components"
import LinearProgress from "@material-ui/core/LinearProgress"
import MDEditor from "@uiw/react-md-editor"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import SelectField from "../SelectField"
import { Paragraph, StyledError, StyledLabel } from "../../styled/typography"
import BasicField from "../BasicField"

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 0;
`

const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 5px;
`

// NOTE: Since useMutation cannot be invoked conditionally, and redefining two subcomponents to
//   render them conditionally is overkill, this mutation is defined to be used in case the field is
//   not editable, and a mutation is not provided, to avoid `useMutation` raising errors. The
//   content of this mutation does not matter as long as it does not change anything. More info:
//   https://spectrum.chat/apollo/react-apollo/88c6e45b-5697-472b-ad73-afd99893d016
const PLACEHOLDER_MUTATION = gql`
  mutation {
    placeholder
  }
`

const InputJSX = (
  field,
  type,
  value,
  setValue,
  handleUpdate,
  handleBlur,
  humanizeFunc,
  errors,
  options,
  multiline,
  t,
  rest
) => {
  const defaultEvents = {
    autoFocus: true,
    onBlur: handleBlur,
    onChange: event => {
      switch (type) {
        case "number": {
          const parsedValue = parseFloat(event.target.value)
          setValue(isNaN(parsedValue) ? null : parsedValue)
          break
        }
        case "date": {
          const newValue = event.target.value
          setValue(isEmpty(newValue) ? null : newValue)
          break
        }
        default:
          setValue(event.target.value)
      }
    }
  }

  const errorMessages = errors
    ?.map(e => e?.messages?.map(m => (e.path === "base" || e.path === field ? m : `${t(e.path)} ${m}`)))
    ?.join(", ")

  switch (type) {
    case "boolean":
      return (
        <>
          <Paragraph>
            <StyledCheckbox
              checked={value}
              color="primary"
              onChange={(_, checked) => {
                setValue(checked)
                handleUpdate(checked)
              }}
            />
          </Paragraph>
          {!isEmpty(errors) && <StyledError>{errorMessages}</StyledError>}
        </>
      )
    case "select":
      return (
        <>
          <SelectField humanizeFunc={humanizeFunc} value={value} options={options} {...defaultEvents} {...rest} />
          {!isEmpty(errors) && <StyledError>{errorMessages}</StyledError>}
        </>
      )
    case "markdown":
      return (
        <>
          <MDEditor value={value} onChange={setValue} onBlur={handleBlur} autoFocus={false} />
          {!isEmpty(errors) && <StyledError>{errorMessages}</StyledError>}
        </>
      )
    default:
      return (
        <TextField
          fullWidth
          multiline={multiline}
          value={value}
          error={!isEmpty(errors)}
          helperText={errorMessages}
          type={type}
          {...defaultEvents}
          {...rest}
        />
      )
  }
}

export default function InlineEditableField(props) {
  const {
    id,
    title,
    field,
    value: initialValue,
    type,
    isEditable,
    gqlMutation,
    mutationArguments,
    handleUpdate: propsHandleUpdate,
    humanizeFunc,
    options,
    multiline,
    validationSchema,
    ...rest
  } = props
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState(initialValue != null ? initialValue : "")
  const oldValue = useRef(initialValue)
  const [mutation, { data, loading, error }] = useMutation(gqlMutation || PLACEHOLDER_MUTATION)
  const fieldValidation = validationSchema ? Yup.reach(validationSchema, field) : Yup.mixed()
  const [validationErrors, setValidationErrors] = useState(null)
  const operationName = gqlMutation && gqlMutation.definitions[0].name.value
  const errors = validationErrors || data?.[operationName]?.errors || error

  useEffect(() => {
    setValue(initialValue != null ? initialValue : "")
  }, [initialValue])

  useEffect(() => {
    if (!loading && isEmpty(errors)) {
      setIsFocused(false)
      oldValue.current = value
    }
  }, [loading, errors])

  useEffect(() => {
    if (!isEmpty(errors) && type === "boolean") {
      setValue(oldValue.current)
    }
  }, [errors, type])

  const handleClick = () => {
    if (isEditable) setIsFocused(true)
  }

  const handleBlur = () => {
    handleUpdate(value)
  }

  const handleUpdate = (newValue = null) => {
    fieldValidation
      .validate(newValue)
      .then(() => {
        setValidationErrors(null)

        if ((oldValue.current == null && !newValue) || oldValue.current === newValue) {
          setIsFocused(false)
        } else if (propsHandleUpdate) {
          propsHandleUpdate(mutation, newValue)
        } else {
          const pair = { [field]: newValue }
          const args = isFunction(mutationArguments) ? mutationArguments(pair) : { [mutationArguments]: pair }
          mutation({ variables: { id, ...args } })
        }
      })
      .catch(err => {
        setValidationErrors([{ path: field, messages: err.errors }])
      })
  }

  // Boolean and Markdown are directly shown as editable version if isEditable is true
  if (!isEditable || (!isFocused && !includes(["boolean", "markdown"], type)))
    return (
      <BasicField
        type={type}
        value={value}
        title={title}
        humanizeFunc={humanizeFunc}
        handleClick={isEditable ? handleClick : undefined}
        {...rest}
      />
    )

  return (
    <FieldWrapper>
      {title && <StyledLabel>{title}</StyledLabel>}
      {InputJSX(
        field,
        type,
        value,
        setValue,
        handleUpdate,
        handleBlur,
        humanizeFunc,
        errors,
        options,
        multiline,
        t,
        rest
      )}
      {loading && <LinearProgress />}
    </FieldWrapper>
  )
}

InlineEditableField.defaultProps = {
  field: null,
  gqlMutation: null,
  handleUpdate: null,
  humanizeFunc: null,
  id: null,
  isEditable: true,
  mutationArguments: null,
  options: null,
  title: null,
  type: null,
  value: null,
  multiline: false,
  validationSchema: null
}

InlineEditableField.propTypes = {
  field: PropTypes.string,
  gqlMutation: PropTypes.object,
  handleUpdate: PropTypes.func,
  humanizeFunc: PropTypes.func,
  id: PropTypes.string,
  isEditable: PropTypes.bool,
  mutationArguments: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  options: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  multiline: PropTypes.bool,
  validationSchema: PropTypes.object
}
