import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { Alert } from "@material-ui/lab"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const EARLY_CANCEL_LEASING_REQUEST = gql`
  mutation earlyCancelLeasingRequest($id: ID!) {
    earlyCancelLeasingRequest(input: { leasingRequestId: $id }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function EarlyCancelLeasingRequest(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(EARLY_CANCEL_LEASING_REQUEST, {
    onCompleted: newData => {
      if (newData.earlyCancelLeasingRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.earlyCancelLeasingRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = () => {
    mutation({ variables: { id: leasingRequestId } })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Alert severity="info">{t("earlyCancelLeasingRequestExplanation")}</Alert>
      <GraphQLErrors errors={errors} />
      <ModalPrimaryButton isFetching={loading}>{t("earlyCancelLeasingRequest")}</ModalPrimaryButton>
    </Form>
  )
}

EarlyCancelLeasingRequest.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
