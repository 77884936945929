import PropTypes from "prop-types"
import React, { useContext, useRef } from "react"
import { Form, Text } from "informed"
import { useMutation } from "@apollo/client"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import PrimaryButton from "../../PrimaryButton"
import MDInput from "../inputs/MDInput"
import { receiveNotification } from "../../../actions"
import { Paragraph, StyledError } from "../../styled/typography"
import EmailTemplateSelector from "../inputs/EmailTemplateSelector"
import PersonalDocumentsChecklist from "../inputs/PersonalDocumentsChecklist"
import { EmailContext } from "../../../contexts"
import GraphQLErrors from "../../GraphQLErrors"

const StyledMDInput = styled(MDInput)`
  margin-top: 5px;
`

export default function ReplyEmail(props) {
  const { emailSubject } = props
  const dispatch = useDispatch()
  const { gqlMutation, resourceId, disabledDocuments } = useContext(EmailContext)
  const operationName = gqlMutation.definitions[0].name.value
  const { t } = useTranslation()
  const formApi = useRef(null)

  const [mutation, { data, loading, error }] = useMutation(gqlMutation, {
    onCompleted: newData => {
      if (newData[operationName]?.errors == null) {
        dispatch(receiveNotification("Email enqueued successfully"))
        formApi.current.reset()
      }
    }
  })
  const errors = data?.[operationName]?.errors || error

  const handleTemplateChange = emailTemplate => {
    formApi.current.setValue("body", emailTemplate.body)
  }

  const handleSubmit = values => {
    mutation({
      variables: {
        resourceId,
        emailArguments: values
      }
    })
  }

  const validationSchema = Yup.object().shape({
    body: Yup.string().required(t("required"))
  })

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      getApi={api => {
        formApi.current = api
      }}>
      <GraphQLErrors errors={errors} />
      <EmailTemplateSelector onChange={handleTemplateChange} />
      <StyledMDInput field="body" errors={errors} />
      <Text type="hidden" field="emailSubject" initialValue={emailSubject} />
      <Paragraph>{t("doNotAddSignature")}</Paragraph>
      {!disabledDocuments && <PersonalDocumentsChecklist field="personalDocumentIds" errors={errors} />}
      <PrimaryButton isFetching={loading}>{t("reply")}</PrimaryButton>
      {errors && <StyledError>{t("somethingWrong")}</StyledError>}
    </Form>
  )
}

ReplyEmail.defaultProps = {}

ReplyEmail.propTypes = {
  emailSubject: PropTypes.string.isRequired
}
