import React from "react"
import * as PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import Button from "@material-ui/core/Button"
import { gql, useMutation } from "@apollo/client"
import { printDateTime } from "../../../../../helpers/formatter"
import { receiveNotification } from "../../../../../actions"

const APPROVE_CLOSE_REQUEST = gql`
  mutation approveCloseRequest($leasingRequestId: ID!) {
    approveCloseRequest(input: { leasingRequestId: $leasingRequestId }) {
      errors {
        messages
        path
      }
    }
  }
`

const DENY_CLOSE_REQUEST = gql`
  mutation denyCloseRequest($leasingRequestId: ID!) {
    denyCloseRequest(input: { leasingRequestId: $leasingRequestId }) {
      errors {
        messages
        path
      }
    }
  }
`

const buildErrorMessage = (t, errors) => {
  return errors.map(e => e.messages.map(m => (e.path === "base" ? m : `${t(`common:${e.path}`)} ${m}`))).join(", ")
}

export default function CloseRequest(props) {
  const { closeRequest, leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const dispatch = useDispatch()
  const [approveCloseRequest, { loading: approveLoading }] = useMutation(APPROVE_CLOSE_REQUEST, {
    variables: {
      leasingRequestId
    },
    onCompleted: newData => {
      if (newData.approveCloseRequest?.errors == null) {
        handleSuccess()
      } else {
        dispatch(receiveNotification(buildErrorMessage(t, newData.approveCloseRequest.errors), { variant: "error" }))
      }
    }
  })
  const [denyCloseRequest, { loading: denyLoading }] = useMutation(DENY_CLOSE_REQUEST, {
    variables: {
      leasingRequestId
    },
    onCompleted: newData => {
      if (newData.denyCloseRequest?.errors == null) {
        handleSuccess()
      } else {
        dispatch(receiveNotification(buildErrorMessage(t, newData.denyCloseRequest.errors), { variant: "error" }))
      }
    }
  })

  if (!closeRequest) return null

  const { closedReason, closedReasonComment, createdAt } = closeRequest
  const loading = approveLoading || denyLoading

  return (
    <Alert
      severity="warning"
      action={
        <>
          <Button color="inherit" size="small" onClick={denyCloseRequest} disabled={loading}>
            {t("common:deny")}
          </Button>
          <Button color="inherit" size="small" onClick={approveCloseRequest} disabled={loading}>
            {t("common:approve")}
          </Button>
        </>
      }>
      <AlertTitle>{t("closeRequest")}</AlertTitle>
      <strong>{t(`common:closeReasons.${closedReason}`)}</strong>
      <p>{closedReasonComment}</p>
      <strong>{printDateTime(createdAt)}</strong>
    </Alert>
  )
}

CloseRequest.defaultProps = {
  closeRequest: null,
  handleSuccess: () => {}
}

CloseRequest.propTypes = {
  closeRequest: PropTypes.object,
  handleSuccess: PropTypes.func,
  leasingRequestId: PropTypes.string.isRequired
}
