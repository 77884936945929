import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Clear, Email, HourglassFull, SettingsBackupRestore } from "@material-ui/icons"
import { includes } from "lodash"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { shallowEqual, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useTimer } from "use-timer"
import { DefaultButton } from "../../../../../components/styled/buttons"
import usePrevious from "../../../../../hooks/usePrevious"

const items = {
  sendDocumentationRequestEmail: {
    allowedStatus: ["pre_opportunity", "new_opportunity"],
    icon: Email
  },
  sendMissingDocumentationEmail: {
    allowedStatus: ["documentation_requested", "rollback", "scoring_rejected"],
    icon: Email
  },
  wrongNumber: {
    allowedStatus: ["pre_opportunity", "new_opportunity"],
    icon: Email
  },
  close: {
    allowedStatus: ["pre_opportunity", "new_opportunity", "documentation_requested", "rollback", "scoring_rejected"],
    icon: Clear
  },
  relist: {
    allowedStatus: ["converted", "abandoned"],
    icon: SettingsBackupRestore
  }
}

const Wrapper = styled(Grid)`
  margin: 15px 0;
`

const getIcon = Icon => {
  return <Icon />
}

export default function SecondaryActions(props) {
  const { leadStatus, openModal } = props
  const { inCall } = useSelector(mapStateToProps, shallowEqual)
  const previousInCall = usePrevious(inCall)
  const afterCallTimer = useTimer({ initialTime: 3, endTime: 0, timerType: "DECREMENTAL" })
  const { t } = useTranslation("lead")

  useEffect(() => {
    if (previousInCall && !inCall) {
      afterCallTimer.reset()
      afterCallTimer.start()
    }
  }, [afterCallTimer, previousInCall, inCall])

  const handleClick = key => {
    openModal(key)
  }

  return (
    <Wrapper item xs={12}>
      {Object.keys(items).map(
        k =>
          includes(items[k].allowedStatus, leadStatus) && (
            <DefaultButton
              key={k}
              onClick={() => handleClick(k)}
              disabled={afterCallTimer.status === "RUNNING"}
              variant="contained"
              size="large"
              color="secondary"
              startIcon={getIcon(items[k].icon)}>
              {t(k)}
            </DefaultButton>
          )
      )}
      {afterCallTimer.status === "RUNNING" && (
        <DefaultButton disabled variant="contained" size="large" color="secondary" startIcon={<HourglassFull />}>
          {afterCallTimer.time}
        </DefaultButton>
      )}
    </Wrapper>
  )
}

SecondaryActions.propTypes = {
  leadStatus: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const { callReducer } = state
  const { inCall } = callReducer

  return { inCall }
}
