import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Grid } from "@material-ui/core"
import { Text } from "informed"
import BasicInput from "../../BasicInput"
import TextSelectInput from "../../TextSelectInput"
import DateInput from "../../DateInput"
import {
  countryCodeOptions,
  documentTypeOptions,
  jobTypeOptions,
  renewalTypeOptions
} from "../../../../../helpers/constants"

export default function LeaseplanFields(props) {
  const { getPropsForField } = props
  const { t } = useTranslation()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Text type="hidden" field="status" initialValue="scoring" />
          <BasicInput {...getPropsForField("phone")} required />
          <TextSelectInput {...getPropsForField("documentType")} options={documentTypeOptions} required />
          <BasicInput {...getPropsForField("idNumber")} required />
          <DateInput {...getPropsForField("birthday")} required openTo="year" views={["year", "month", "date"]} />
          <BasicInput {...getPropsForField("province")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("street")} required />
          <BasicInput {...getPropsForField("streetNumber")} required />
          <BasicInput {...getPropsForField("floor")} />
          <BasicInput {...getPropsForField("postalCode")} required />
          <BasicInput {...getPropsForField("town")} required />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextSelectInput
            {...getPropsForField("countryCode")}
            label={t("common:country.title")}
            options={countryCodeOptions(t)}
            humanizeFunc={value => t(`country.${value}`)}
          />
          <TextSelectInput {...getPropsForField("jobType")} options={jobTypeOptions} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            {...getPropsForField("driveLicenseExpeditionDate")}
            required
            openTo="year"
            views={["year", "month", "date"]}
          />
          <BasicInput {...getPropsForField("distributorOfferId")} required />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextSelectInput {...getPropsForField("renewal")} label="Renovación" options={renewalTypeOptions} />
        </Grid>
      </Grid>
    </>
  )
}

LeaseplanFields.propTypes = {
  getPropsForField: PropTypes.func.isRequired
}
