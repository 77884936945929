import { shallowEqual, useSelector } from "react-redux"

export default function useAbilities(abilities, strictMode = false) {
  const { account } = useSelector(mapStateToProps, shallowEqual)

  if (Array.isArray(abilities)) return { allowed: account?.hasAbilities(abilities, strictMode) }
  return { allowed: account?.hasAbility(abilities, strictMode) }
}

const mapStateToProps = state => {
  const { accountReducer } = state
  const { item: account } = accountReducer

  return {
    account
  }
}
