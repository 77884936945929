import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import IconButton from "@material-ui/core/IconButton"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import MUIMenu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import FormDialog from "../../../../FormDialog"
import Reopen from "../../../../forms/Reopen"
import SendWebProcessLinkEmail from "../../../../forms/SendWebProcessLinkEmail"
import StartOnlineScoring from "../../../../forms/StartOnlineScoring"
import CreateCloseRequest from "../../../../forms/CreateCloseRequest"
import QuotationRequest from "../../../../forms/QuotationRequest"
import UpdateLeasingRequestPlan from "../../../../forms/UpdateLeasingRequestPlan"
import EarlyCancelLeasingRequest from "../../../../forms/EarlyCancelLeasingRequest"
import CancelLeasingRequest from "../../../../forms/CancelLeasingRequest"
import NotDeliveredClient from "../../../../forms/NotDeliveredClient"
import AssignLeasingRequestToClientRequest from "../../../../forms/AssignLeasingRequestToClientRequest"
import StartContractExtension from "../../../../forms/StartContractExtension"
import WinOfflineContractExtension from "../../../../forms/WinOfflineContractExtension"
import useAbilities from "../../../../../hooks/useAbilities"
import { ClientRequestContext } from "../../../../../contexts"

export default function Menu(props) {
  const { leasingRequest, refetch } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(null)
  const history = useHistory()
  const { t } = useTranslation()
  const { allowed: updateLeasingRequestPlan } = useAbilities("update_leasing_request_plans")
  const { allowed: manageClients } = useAbilities(["manage_clients", "manage_country_clients"])
  const { id: clientRequestId } = useContext(ClientRequestContext)

  const {
    status,
    webProcessEnabled,
    contractExtensionStatus,
    closedStatus,
    earlyCancelled,
    distributor: { onlineUrlEnabled }
  } = leasingRequest

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSuccess = () => {
    setOpenModal(null)
    refetch()
  }

  const handleSuccessWithRemoval = isRemoved => {
    setOpenModal(null)
    if (isRemoved) {
      history.push("/dashboard")
    } else {
      refetch()
    }
  }

  const handleModalClose = () => {
    setOpenModal(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <MUIMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {onlineUrlEnabled && ( // Menu doesn't accept fragments as children
          <MenuItem onClick={() => setOpenModal("sendWebProcessLinkEmail")}>{t("sendWebProcessLinkEmail")}</MenuItem>
        )}
        {webProcessEnabled && (
          <MenuItem onClick={() => setOpenModal("startOnlineScoring")}>{t("startOnlineScoring")}</MenuItem>
        )}
        <MenuItem onClick={() => setOpenModal("createQuotationRequest")}>{t("lead:createQuotationRequest")}</MenuItem>
        {status === "closed" && (
          <MenuItem onClick={() => setOpenModal("reopen")}>{t("leasingRequest:reopen")}</MenuItem>
        )}
        {status !== "closed" && (
          <MenuItem onClick={() => setOpenModal("createCloseRequest")}>{t("lead:createCloseRequest")}</MenuItem>
        )}
        {updateLeasingRequestPlan && (
          <MenuItem onClick={() => setOpenModal("updatePlan")}>{t("leasingRequest:updatePlan")}</MenuItem>
        )}
        {updateLeasingRequestPlan && closedStatus === "billable" && !earlyCancelled && (
          <MenuItem onClick={() => setOpenModal("earlyCancelLeasingRequest")}>
            {t("leasingRequest:earlyCancelLeasingRequest")}
          </MenuItem>
        )}
        {clientRequestId && (
          <MenuItem onClick={() => setOpenModal("assignLeasingRequestToClientRequest")}>
            {t("clientRequest:assignLeasingRequestToClientRequest")}
          </MenuItem>
        )}
        {(closedStatus === "billable" || closedStatus === "won") && (
          <MenuItem onClick={() => setOpenModal("cancel")}>{t("leasingRequest:cancelLeasingRequest")}</MenuItem>
        )}
        {manageClients && leasingRequest.clientId && (
          <MenuItem onClick={() => setOpenModal("notDelivered")}>{t("client:notDelivered")}</MenuItem>
        )}
        {closedStatus === "billable" &&
          (contractExtensionStatus === "not_started" || contractExtensionStatus === "won") && (
            <MenuItem onClick={() => setOpenModal("startContractExtension")}>
              {`${t("leasingRequest:startContractExtension")} ${Number(leasingRequest.contractExtensionAttempts) + 1}`}
            </MenuItem>
          )}
        {closedStatus === "billable" && contractExtensionStatus === "not_started" && (
          <MenuItem onClick={() => setOpenModal("winOfflineContractExtension")}>
            {t("leasingRequest:winOfflineContractExtension")}
          </MenuItem>
        )}
      </MUIMenu>
      <FormDialog
        title={t("sendWebProcessLinkEmail")}
        handleClose={handleModalClose}
        open={openModal === "sendWebProcessLinkEmail"}
      >
        <SendWebProcessLinkEmail leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("startOnlineScoring")}
        handleClose={handleModalClose}
        open={openModal === "startOnlineScoring"}
      >
        <StartOnlineScoring leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("leasingRequest:reopen")} handleClose={handleModalClose} open={openModal === "reopen"}>
        <Reopen leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("lead:createCloseRequest")}
        handleClose={handleModalClose}
        open={openModal === "createCloseRequest"}
      >
        <CreateCloseRequest leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("lead:createQuotationRequest")}
        handleClose={handleModalClose}
        open={openModal === "createQuotationRequest"}
      >
        <QuotationRequest leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("leasingRequest:updatePlan")}
        handleClose={handleModalClose}
        open={openModal === "updatePlan"}
      >
        <UpdateLeasingRequestPlan leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("leasingRequest:earlyCancelLeasingRequest")}
        handleClose={handleModalClose}
        open={openModal === "earlyCancelLeasingRequest"}
      >
        <EarlyCancelLeasingRequest leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("leasingRequest:cancelLeasingRequest")}
        handleClose={handleModalClose}
        open={openModal === "cancel"}
      >
        <CancelLeasingRequest leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
      {leasingRequest.clientId && (
        <FormDialog title={t("client:notDelivered")} handleClose={handleModalClose} open={openModal === "notDelivered"}>
          <NotDeliveredClient
            clientId={leasingRequest.clientId}
            leasingRequestId={leasingRequest.id}
            handleSuccess={handleSuccessWithRemoval}
          />
        </FormDialog>
      )}
      {clientRequestId && (
        <FormDialog
          title={t("clientRequest:assignLeasingRequestToClientRequest")}
          handleClose={handleModalClose}
          open={openModal === "assignLeasingRequestToClientRequest"}
        >
          <AssignLeasingRequestToClientRequest
            clientRequestId={clientRequestId}
            leasingRequestId={leasingRequest.id}
            handleSuccess={handleSuccess}
          />
        </FormDialog>
      )}
      <FormDialog
        title={t("leasingRequest:startContractExtension")}
        handleClose={handleModalClose}
        open={openModal === "startContractExtension"}
      >
        <StartContractExtension
          leasingRequestId={leasingRequest.id}
          setOpenModal={setOpenModal}
          handleSuccess={refetch}
        />
      </FormDialog>
      <FormDialog
        title={t("leasingRequest:winOfflineContractExtension")}
        handleClose={handleModalClose}
        open={openModal === "winOfflineContractExtension"}
      >
        <WinOfflineContractExtension leasingRequestId={leasingRequest.id} handleSuccess={handleSuccess} />
      </FormDialog>
    </>
  )
}

Menu.propTypes = {
  leasingRequest: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
}
