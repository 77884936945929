import React from "react"
import TextField from "@material-ui/core/TextField"
import { asField } from "informed"
import { isEmpty } from "lodash"
import { getFieldError } from "../../../../helpers/errorParser"

const BasicInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { field, initialValue, helperText, errors, onChange, onBlur, forwardedRef, errorsPlace, ...rest } = props

  const { setValue, setTouched } = fieldApi

  const { value } = fieldState
  const { error: fieldError } = fieldState

  const errorsText = getFieldError(errors, field, errorsPlace)

  return (
    <>
      <TextField
        {...rest}
        defaultValue={initialValue}
        field={field}
        fullWidth
        error={!isEmpty(errorsText) || !isEmpty(fieldError)}
        helperText={errorsText || fieldError || helperText}
        ref={forwardedRef}
        value={value === undefined ? "" : value}
        onChange={e => {
          setValue(e.target.value)
          if (onChange) {
            onChange(e)
          }
        }}
        onBlur={e => {
          setTouched()
          if (onBlur) {
            onBlur(e)
          }
        }}
      />
    </>
  )
})

export default BasicInput
