import PropTypes from "prop-types"
import React from "react"
import moment from "moment"
import { Form, useFormState } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import TextSelectInput from "../inputs/TextSelectInput"
import CheckboxInput from "../inputs/CheckboxInput"
import DateInput from "../inputs/DateInput"
import GraphQLErrors from "../../GraphQLErrors"
import Loading from "../../Loading"
import { promotionOptions } from "../../../helpers/constants"

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      promotion
      promotionStartedAt
      promotionOption
    }
  }
`

const UPDATE_LEAD_PROMOTION = gql`
  mutation updateLeadPromotion($id: ID!, $leadArguments: UpdateLeadPromotionInputObject!) {
    updateLeadPromotion(input: { leadId: $id, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

function PromotionOptions(props) {
  const { lead, errors } = props
  const { t } = useTranslation()
  const formState = useFormState()

  const getPropsForField = field => {
    return {
      field,
      label: t(`common:${field}`),
      initialValue: lead[field],
      errors
    }
  }

  return (
    <>
      <CheckboxInput {...getPropsForField("promotion")} />
      {formState.values.promotion && (
        <>
          <TextSelectInput required options={promotionOptions(t)} {...getPropsForField("promotionOption")} />
          <DateInput
            required
            disableFuture
            minDate={moment().subtract(2, "months")}
            openTo="month"
            views={["year", "month", "date"]}
            {...getPropsForField("promotionStartedAt")}
            initialValue={lead.promotionStartedAt || new Date()}
          />
        </>
      )}
    </>
  )
}

PromotionOptions.propTypes = {
  lead: PropTypes.object.isRequired,
  errors: PropTypes.array
}

PromotionOptions.defaultProps = {
  errors: null
}

export default function UpdateLeadPromotion(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation()
  const { data: queryData, loading: queryLoading } = useQuery(LEAD, { variables: { id: leadId } })
  const [mutation, { data, loading, error }] = useMutation(UPDATE_LEAD_PROMOTION, {
    onCompleted: newData => {
      if (newData.updateLeadPromotion?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updateLeadPromotion?.errors
  if (error) return <p>Server Error</p>

  if (queryLoading) return <Loading />
  if (queryData.lead === null) return <p>Not found</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leadId, leadArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <PromotionOptions lead={queryData.lead} errors={errors} />
      <ModalPrimaryButton isFetching={loading}>{t("updatePromotion")}</ModalPrimaryButton>
    </Form>
  )
}

UpdateLeadPromotion.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
