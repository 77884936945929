import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { asField } from "informed"
import { FormControl } from "@material-ui/core"
import FormHelperText from "@material-ui/core/FormHelperText"
import { isEmpty } from "lodash"
import { getFieldError } from "../../../../helpers/errorParser"

const CheckboxInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { field, errors, errorsPlace, label, onChange, onBlur, forwardedRef, helperText, disabled } = props
  const { setValue, setTouched } = fieldApi

  const { value } = fieldState
  const { error: fieldError } = fieldState
  const errorsText = getFieldError(errors, field, errorsPlace)

  return (
    <FormControl fullWidth error={!isEmpty(errorsText) || !isEmpty(fieldError)}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            id={field}
            field={field}
            ref={forwardedRef}
            onChange={e => {
              setValue(e.target.checked)
              if (onChange) {
                onChange(e)
              }
            }}
            onBlur={e => {
              setTouched()
              if (onBlur) {
                onBlur(e)
              }
            }}
            checked={value || false}
            disabled={disabled}
          />
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {(!isEmpty(errorsText) || !isEmpty(fieldError)) && <FormHelperText>{errorsText || fieldError}</FormHelperText>}
    </FormControl>
  )
})

export default CheckboxInput
