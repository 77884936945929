import PropTypes from "prop-types"
import React from "react"
import DropzoneInput from "../inputs/DropzoneInput"

export default function UploadDocuments(props) {
  const { uploadDocument, resourceId } = props

  const handleUpload = accepted => {
    const formData = new FormData()
    formData.append("document", accepted)
    uploadDocument(resourceId, formData)
  }

  return <DropzoneInput handleUpload={handleUpload} />
}

UploadDocuments.propTypes = {
  resourceId: PropTypes.string.isRequired,
  uploadDocument: PropTypes.func.isRequired
}
