import { shallowEqual, useSelector } from "react-redux"
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { orderBy } from "lodash"
import { EmailContext } from "../../../../contexts"
import { createOption } from "../../../../helpers/forms"
import EmailTemplateSelector from "../EmailTemplateSelector"
import TextSelectInput from "../TextSelectInput"
import BasicInput from "../BasicInput"
import { Paragraph } from "../../../styled/typography"
import Distributor from "../../../../models/distributor"
import MDInput from "../MDInput"
import PersonalDocumentsChecklist from "../PersonalDocumentsChecklist"

const StyledMDInput = styled(MDInput)`
  margin-top: 5px;
`

export default function DistributorEmail(props) {
  const {
    formApi,
    leasingRequestId,
    distributorId,
    locale,
    errors,
    personalDocumentationChecklist,
    sortContactsBy
  } = props
  const { t } = useTranslation("leasingRequest")
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const distributor = Distributor.getDistributor(distributors, distributorId)
  const contacts = sortContactsBy ? orderBy(distributor.contacts, [sortContactsBy], ["desc"]) : distributor.contacts
  const distributorEmails = contacts.map(c => createOption(c.email, `${c.fullName} <${c.email}>`))

  const handleTemplateChange = emailTemplate => {
    formApi.current.setValue("emailSubject", emailTemplate.subject)
    formApi.current.setValue("body", emailTemplate.body)
  }

  return (
    <EmailContext.Provider
      value={{
        resourceId: leasingRequestId,
        resource: "LeasingRequest",
        locale
      }}>
      <EmailTemplateSelector onChange={handleTemplateChange} />
      <TextSelectInput
        field="to"
        label={t("common:sendTo")}
        errors={errors}
        options={distributorEmails}
        required
        initialValue={distributorEmails[0]?.value}
      />
      <TextSelectInput field="cc" label="CC" errors={errors} options={distributorEmails} multiple />
      <BasicInput field="emailSubject" label={t("common:emailSubject")} required />
      <StyledMDInput field="body" errors={errors} />
      <Paragraph>{t("common:doNotAddSignature")}</Paragraph>
      {personalDocumentationChecklist && (
        <PersonalDocumentsChecklist field="personalDocumentIds" errors={errors} selectAll startExpanded />
      )}
    </EmailContext.Provider>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

DistributorEmail.propTypes = {
  distributorId: PropTypes.string.isRequired,
  errors: PropTypes.array,
  formApi: PropTypes.object.isRequired,
  leasingRequestId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  personalDocumentationChecklist: PropTypes.bool,
  sortContactsBy: PropTypes.string
}

DistributorEmail.defaultProps = {
  errors: null,
  personalDocumentationChecklist: false,
  sortContactsBy: null
}
