import { filter, orderBy } from "lodash"
import { RECEIVE_COMMON_DATA } from "../actions/actions"
import Distributor from "../models/distributor"
import Staff from "../models/staff"

const defaultState = {
  distributors: {
    items: []
  },
  staff: {
    items: [],
    actives: []
  }
}

function commonDataReducer(state = defaultState, action) {
  const allStaff = orderBy(
    action.staff?.map(u => new Staff(u)),
    "name",
    "asc"
  )

  switch (action.type) {
    case RECEIVE_COMMON_DATA:
      return {
        distributors: {
          items: action.distributors.map(d => new Distributor(d))
        },
        staff: {
          items: allStaff,
          actives: filter(allStaff, { isActive: true })
        }
      }
    default:
      return state
  }
}

export default commonDataReducer
