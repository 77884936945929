import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { Alert } from "@material-ui/lab"
import { isNil } from "lodash"
import AllDocumentation from "../../../../../components/AllDocumentation"
import Loading from "../../../../../components/Loading"
import Checkboxes from "./Checkboxes"

const LEAD_DOCUMENTATION_DATA = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      jobType
      countryCode
      idPhotoReady
      driveLicenseReady
      bankStatementReady
      irpfReady
      laboralLifeReady
      payrollReady
      model390Ready
      model303Ready
      model036Ready
      model200Ready
      companyConstitutionReady
      financialSheetsReady
      cifReady
      bankStatement2Ready
      financialSheets2Ready
      proofOfResidenceReady
      personalDocuments {
        id
        tag
        preferredUrl
        documentFileName
        documentContentType
      }
    }
  }
`

const DOCUMENTATION_LIST = gql`
  query documentationList($countryCode: CountryCode!, $jobType: JobType!) {
    documentationList(countryCode: $countryCode, jobType: $jobType) {
      key
      translation
    }
  }
`

const DESTROY_PERSONAL_DOCUMENT = gql`
  mutation destroyLeadPersonalDocument($id: ID!, $personalDocumentId: ID!) {
    destroyLeadPersonalDocument(input: { leadId: $id, personalDocumentId: $personalDocumentId }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function Documentation(props) {
  const { leadId, getPropsForField } = props
  const { t } = useTranslation("lead")
  const { loading, data } = useQuery(LEAD_DOCUMENTATION_DATA, {
    variables: { id: leadId }
  })
  const [
    getDocumentationList,
    { called: documentationCalled, loading: loadingDocumentation, data: documentationListData }
  ] = useLazyQuery(DOCUMENTATION_LIST, {})

  useEffect(() => {
    if (data?.lead?.jobType && data?.lead?.countryCode) {
      getDocumentationList({
        variables: {
          jobType: data.lead.jobType,
          countryCode: data.lead.countryCode
        }
      })
    }
  }, [data, getDocumentationList])

  if (!loading && isNil(data?.lead)) return <p>Not found</p>
  if (!loading && !data?.lead?.jobType) return <Alert severity="warning">{t("requiredJobType")}</Alert>

  if (loading || loadingDocumentation || !documentationCalled) return <Loading />

  return (
    <>
      <Checkboxes
        documentationList={documentationListData.documentationList}
        getPropsForField={getPropsForField}
        data={data}
      />
      <AllDocumentation
        resourceId={leadId}
        personalDocuments={data.lead.personalDocuments}
        documentationList={documentationListData.documentationList}
        gqlDestroyMutation={DESTROY_PERSONAL_DOCUMENT}
      />
    </>
  )
}

Documentation.propTypes = {
  getPropsForField: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
