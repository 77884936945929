import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Grid } from "@material-ui/core"
import { Form, Text } from "informed"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import * as Yup from "yup"
import BasicInput from "../../../../../components/forms/inputs/BasicInput"
import PrimaryButton from "../../../../../components/PrimaryButton"
import TextSelectInput from "../../../../../components/forms/inputs/TextSelectInput"
import { createOption } from "../../../../../helpers/forms"
import Staff from "../../../../../models/staff"
import { ButtonGroup } from "../../../../../components/styled/buttons"
import { receiveCurrentTask } from "../../../../../actions"

const UPDATE_LEAD_EMAIL = gql`
  mutation updateLeadEmail($leadId: ID!, $leadArguments: UpdateLeadEmailInputObject!) {
    updateLeadEmail(input: { leadId: $leadId, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
      result {
        existingLead {
          id
        }
        differentPhones
        shouldBeMerged
        phones
      }
    }
  }
`

const MERGE_LEAD = gql`
  mutation mergeLead($leadId: ID!, $leadArguments: MergeLeadInputObject!) {
    mergeLead(input: { leadId: $leadId, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
      result {
        belongsToOtherStaff
        nextTask {
          id
          identifier
          comment
          commentType
        }
        lead {
          id
          assignedAtId
        }
      }
    }
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 15px;
`

export default function NoEmailScene(props) {
  const { leadId, refetch, adminAllowed, setSkipNoEmail } = props
  const { staff, currentTask } = useSelector(mapStateToProps, shallowEqual)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation("lead")
  const [step, setStep] = useState(0)
  const newLead = useRef(null)
  const phoneOptions = useRef(null)
  const [updateLeadEmail, { data: updateLeadEmailData, loading: updateLeadEmailLoading }] = useMutation(
    UPDATE_LEAD_EMAIL,
    {
      onCompleted: newData => {
        if (newData.updateLeadEmail.result) {
          const { shouldBeMerged, differentPhones, phones, existingLead } = newData.updateLeadEmail.result
          if (!shouldBeMerged) {
            refetch()
          } else if (differentPhones) {
            newLead.current = existingLead
            phoneOptions.current = phones.map(phone => createOption(phone, phone))
            setStep(1)
          } else {
            mergeLead({
              variables: {
                leadId: existingLead.id,
                leadArguments: {
                  leadId
                }
              }
            })
          }
        }
      }
    }
  )

  const [mergeLead, { loading: mergeLoading }] = useMutation(MERGE_LEAD, {
    onCompleted: newData => {
      const { belongsToOtherStaff, nextTask, lead } = newData.mergeLead.result
      if (currentTask && belongsToOtherStaff) {
        newLead.current = lead
        setStep(2)
      } else if (currentTask) {
        dispatch(receiveCurrentTask(nextTask))
        history.push(`/leads/${lead.id}`)
      } else {
        history.push(`/leads/${lead.id}`)
      }
    }
  })

  const handleSubmit = values => {
    updateLeadEmail({
      variables: {
        leadId,
        leadArguments: values
      }
    })
  }

  const handleMergeSubmit = values => {
    mergeLead({
      variables: {
        leadId: newLead.current.id,
        leadArguments: values
      }
    })
  }

  const staffName = Staff.getName(staff, newLead.current?.assignedAtId)

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required(t("common:required"))
  })

  return (
    <Grid container>
      <Grid item xs={12}>
        {step === 0 && (
          <Form onSubmit={handleSubmit}>
            <Alert severity="info">
              <AlertTitle>{t("noEmailTitle")}</AlertTitle>
              {t("noEmailExplanation")}
            </Alert>
            <BasicInput
              field="email"
              label={t("common:email")}
              errors={updateLeadEmailData?.updateLeadEmail?.errors}
              required
            />
            <ButtonGroup>
              {adminAllowed && (
                <Button variant="contained" color="secondary" onClick={() => setSkipNoEmail(true)}>
                  Skip
                </Button>
              )}
              <Button variant="contained" color="primary" disabled={updateLeadEmailLoading} type="submit">
                {t("noEmailUpdateLead")}
              </Button>
            </ButtonGroup>
          </Form>
        )}
        {step === 1 && (
          <Form onSubmit={handleMergeSubmit} validationSchema={validationSchema}>
            <Alert severity="info">
              <AlertTitle>{t("differentPhonesTitle")}</AlertTitle>
              {t("differentPhonesExplanation")}
            </Alert>
            <TextSelectInput field="phone" label={t("common:phone")} options={phoneOptions.current} required />
            <Text type="hidden" field="leadId" initialValue={leadId} />
            <StyledPrimaryButton isFetching={mergeLoading}>{t("mergeLead")}</StyledPrimaryButton>
          </Form>
        )}
        {step === 2 && (
          <Alert severity="warning">
            <AlertTitle>{t("belongsToOtherStaffTitle", { staffName })}</AlertTitle>
            {t("belongsToOtherStaffExplanation", { staffName })}
          </Alert>
        )}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer, accountReducer } = state
  const { staff } = commonDataReducer
  const { currentTask } = accountReducer

  return {
    staff: staff.items,
    currentTask
  }
}

NoEmailScene.propTypes = {
  adminAllowed: PropTypes.bool.isRequired,
  leadId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  setSkipNoEmail: PropTypes.func.isRequired
}
