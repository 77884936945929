import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const ImageWrapper = styled.div`
  padding: 5px;
`

const Image = styled.img`
  vertical-align: middle;
  width: 100%;
  height: auto;
`

export default function ImageViewer(props) {
  const { preferredUrl } = props

  return (
    <ImageWrapper>
      <Image src={preferredUrl} />
    </ImageWrapper>
  )
}

ImageViewer.propTypes = {
  preferredUrl: PropTypes.string.isRequired
}
