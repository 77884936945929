import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Alert } from "@material-ui/lab"
import { useFormApi } from "informed"

const ErrorsWrapper = styled.div`
  margin-bottom: 15px;
`

const Title = styled.p`
  color: var(--warm-red);
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 12px;
`

const Error = styled.p`
  color: var(--warm-red);
  margin-bottom: 0;
`

export default function GraphQLErrors(props) {
  const { errors, translations, showAll } = props
  const { t } = useTranslation(translations)
  const { fieldExists } = useFormApi()

  if (!errors) return null

  return (
    <ErrorsWrapper>
      <Alert severity="error">
        <Title>{t("common:errors")}</Title>
        {errors.map(e => {
          if (e.path === "base") {
            return e.messages.map(m => <Error key={m}>{m}</Error>)
          }
          if ((showAll && e.path !== "base") || !fieldExists(e.path)) {
            return e.messages.map(m => <Error key={m}>{`${t(e.path)} ${m}`}</Error>)
          }
          return null
        })}
      </Alert>
    </ErrorsWrapper>
  )
}

GraphQLErrors.propTypes = {
  errors: PropTypes.array,
  showAll: PropTypes.bool,
  translations: PropTypes.string
}

GraphQLErrors.defaultProps = {
  errors: null,
  showAll: false,
  translations: null
}
