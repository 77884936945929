import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { Alert } from "@material-ui/lab"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import Loading from "../../Loading"
import DateInput from "../inputs/DateInput"

const WIN = gql`
  mutation win($leasingRequestId: ID!, $winArguments: WinInputObject!) {
    win(input: { leasingRequestId: $leasingRequestId, winArguments: $winArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      quantity
      markAsBillableAt
    }
  }
`

export default function Win(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(LEASING_REQUEST, {
    variables: { id: leasingRequestId }
  })
  const [mutation, { data, loading, error }] = useMutation(WIN, {
    onCompleted: newData => {
      if (newData.win?.errors == null) {
        handleSuccess()
      }
    }
  })

  if (queryLoading) return <Loading />

  const errors = data?.win?.errors
  if (error || queryError || !queryData?.leasingRequest) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, winArguments: values }
    })
  }

  const { quantity, markAsBillableAt } = queryData.leasingRequest

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      {quantity > 1 && <Alert severity="warning">{t("winDuplicationWarning", { quantity })}</Alert>}
      <DateInput
        required
        field="markAsBillableAt"
        label={t("leasingRequest:markAsBillableAt")}
        errors={errors}
        openTo="date"
        views={["year", "month", "date"]}
        initialValue={markAsBillableAt || moment().add(5, "d")}
        minDate={new Date()}
      />
      <ModalPrimaryButton isFetching={loading}>{t("win")}</ModalPrimaryButton>
    </Form>
  )
}

Win.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
