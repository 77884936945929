import React, { useEffect } from "react"
import { Grid } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { gql, useMutation } from "@apollo/client"
import { Alert, AlertTitle } from "@material-ui/lab"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useTimer } from "use-timer"
import { finishCurrentTask, receiveNotification } from "../../actions"
import { handleNotificationErrors } from "../../helpers/utils"
import usePrevious from "../../hooks/usePrevious"

const StyledAlert = styled(Alert)`
  margin-bottom: 15px;
`

const FINISH_TASK = gql`
  mutation finishTask($id: ID!) {
    finishTask(input: { taskId: $id }) {
      errors {
        messages
        path
      }
      result {
        id
      }
    }
  }
`

function Comment(props) {
  const { comment, commentType } = props
  const { t } = useTranslation()

  if (!comment) return null
  if (commentType === "i18n") return t(comment)

  return comment
}

export default function CurrentTask() {
  const { task, inCall } = useSelector(mapStateToProps, shallowEqual)
  const dispatch = useDispatch()
  const history = useHistory()
  const [finishTask, { data }] = useMutation(FINISH_TASK)
  const previousInCall = usePrevious(inCall)
  const afterCallTimer = useTimer({ initialTime: 3, endTime: 0, timerType: "DECREMENTAL" })
  const { t } = useTranslation()

  useEffect(() => {
    if (data?.finishTask?.errors) {
      const { errors } = data.finishTask
      dispatch(receiveNotification(handleNotificationErrors(errors), { variant: "error" }))
    } else if (data?.finishTask?.result) {
      dispatch(finishCurrentTask())
      history.push("/dashboard")
    }
  }, [data, dispatch, history])

  useEffect(() => {
    if (previousInCall && !inCall) {
      afterCallTimer.reset()
      afterCallTimer.start()
    }
  }, [afterCallTimer, previousInCall, inCall])

  if (!task) return null

  const handleFinishTask = () => {
    finishTask({
      variables: {
        id: task.id
      }
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledAlert
          severity="info"
          action={
            <Button
              disabled={inCall || afterCallTimer.status === "RUNNING"}
              onClick={handleFinishTask}
              color="inherit"
              size="small">
              {afterCallTimer.status === "RUNNING" && `${afterCallTimer.time} - `}
              {t("finishTask")}
            </Button>
          }>
          <AlertTitle>{t(`task.${task.identifier}`)}</AlertTitle>
          <Comment comment={task.comment} commentType={task.commentType} />
        </StyledAlert>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  const { accountReducer, callReducer } = state
  const { currentTask } = accountReducer
  const { inCall } = callReducer

  return { task: currentTask, inCall }
}
