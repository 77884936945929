import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import CommentIcon from "@material-ui/icons/Comment"
import { useSelector } from "react-redux"
import shallowEqual from "react-redux/es/utils/shallowEqual"
import { Grid } from "@material-ui/core"
import moment from "moment"
import { Link } from "react-router-dom"
import Staff from "../../models/staff"
import InlineEditableField from "../fields/InlineEditableField"
import { printDateTime } from "../../helpers/formatter"

const Small = styled.small`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--stormy-blue);
  margin-bottom: 0;
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const StyledCommentIcon = styled(CommentIcon)`
  margin-right: 15px;
  color: var(--stormy-blue);
`

export default function Comment(props) {
  const { comment, editionEnabled, gqlUpdateMutation } = props
  const { id, commentableId, commentableType, createdAt, createdById, content, commercialDetails } = comment
  const { staff, accountId } = useSelector(mapStateToProps, shallowEqual)
  const staffName = Staff.getName(staff, createdById)

  const editable = editionEnabled && createdById === accountId

  const mutationArguments = values => ({
    commentableId,
    commentArguments: { id, ...values }
  })

  const camelToKebabCase = str => str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()

  return (
    <>
      <StyledGrid item xs={12}>
        <StyledCommentIcon fontSize="large" />
        <InlineEditableField
          field="content"
          multiline
          value={content}
          isEditable={editable}
          gqlMutation={gqlUpdateMutation}
          mutationArguments={mutationArguments}
        />
      </StyledGrid>

      {commercialDetails && <Small>{commercialDetails}</Small>}
      <Small>
        {`${printDateTime(createdAt)} ${moment(createdAt).fromNow()}`}
        &nbsp;
        <b>{staffName || "Anonymous"}</b>
        &nbsp; - &nbsp;
        {commentableType && (
          <Link to={`/${camelToKebabCase(commentableType)}s/${commentableId}`} target="_blank">
            {commentableType}
          </Link>
        )}
      </Small>
    </>
  )
}

const mapStateToProps = state => {
  const { accountReducer, commonDataReducer } = state
  const { staff } = commonDataReducer
  const { item: account } = accountReducer

  return { staff: staff.items, accountId: account?.id }
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  editionEnabled: PropTypes.bool,
  gqlUpdateMutation: PropTypes.object
}

Comment.defaultProps = {
  editionEnabled: false,
  gqlUpdateMutation: null
}
