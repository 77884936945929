import React, { useEffect, useState } from "react"
import * as PropTypes from "prop-types"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { concat, isEmpty, map, orderBy, partition, uniqBy } from "lodash"
import { Divider, Grid } from "@material-ui/core"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroller"
import Loading from "../Loading"
import LeasingRequest from "./LeasingRequest"
import gqlFields from "./gqlFields"
import { StyledH3 } from "../styled/typography"
import { ClientRequestContext } from "../../contexts"

const COMMON_LEASING_REQUESTS = `
  leasingRequests(offset: $offset, limit: $limit) {
    searchInfo {
      total
    }
    results {
      ${gqlFields}
    }
  }
`

const CLIENT_LEASING_REQUESTS = gql`
  query client($id: ID!, $offset: Int, $limit: Int) {
    client(id: $id) {
      ${COMMON_LEASING_REQUESTS}
    }
  }
`

const CLIENT_REQUEST_LEASING_REQUESTS = gql`
  query clientRequest($id: ID!, $offset: Int, $limit: Int) {
    clientRequest(id: $id) {
      ${COMMON_LEASING_REQUESTS}
    }
  }
`

const LEAD_LEASING_REQUESTS = gql`
  query lead($id: ID!, $offset: Int, $limit: Int) {
    lead(id: $id) {
      ${COMMON_LEASING_REQUESTS}
    }
  }
`

const StyledDivider = styled(Divider)`
  width: 100%;
  margin-bottom: 32px;
  margin-top: 16px;
  height: 2px;
`

const StyledTitle = styled(StyledH3)`
  margin-bottom: 15px;
`

const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
`

const LeasingRequestList = props => {
  const { leasingRequests, divider, title } = props

  if (isEmpty(leasingRequests)) return null

  return (
    <>
      <StyledTitle>{title}</StyledTitle>
      <Grid container>
        {map(leasingRequests, leasingRequest => (
          <Grid item xs={12} key={leasingRequest.id}>
            <LeasingRequest leasingRequest={leasingRequest} />
          </Grid>
        ))}
      </Grid>
      {divider && <StyledDivider />}
    </>
  )
}

LeasingRequestList.propTypes = {
  leasingRequests: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  divider: PropTypes.bool
}

LeasingRequestList.defaultProps = {
  divider: false
}

const PER_PAGE = 5

const getQueryType = ({ clientId, clientRequestId }) => {
  if (clientId) return "client"
  if (clientRequestId) return "clientRequest"

  return "lead"
}

const getQuery = ({ queryType, clientId, clientRequestId, leadId }) => {
  if (queryType === "client") return [CLIENT_LEASING_REQUESTS, clientId]
  if (queryType === "clientRequest") return [CLIENT_REQUEST_LEASING_REQUESTS, clientRequestId]

  return [LEAD_LEASING_REQUESTS, leadId]
}

const LeasingRequestsGrid = props => {
  const { leadId, clientId, clientRequestId } = props
  const { t } = useTranslation()
  const [allLeasingRequests, setAllLeasingRequests] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const queryType = getQueryType({ clientId, clientRequestId, leadId })
  const [query, queryId] = getQuery({ queryType, clientId, clientRequestId, leadId })

  const { data, loading } = useQuery(query, {
    variables: { id: queryId, offset: currentPage * PER_PAGE, limit: PER_PAGE }
  })

  useEffect(() => {
    if (data?.[queryType]?.leasingRequests?.results) {
      setAllLeasingRequests(current => uniqBy(concat(data[queryType].leasingRequests.results, current), "id"))
    }
  }, [data, queryType])

  useEffect(() => {
    if (data?.[queryType]?.leasingRequests?.searchInfo?.total) {
      setHasMore(allLeasingRequests.length < data[queryType].leasingRequests.searchInfo.total)
    }
  }, [allLeasingRequests, data, queryType])

  const handleLoadMore = () => {
    if (loading) return

    setHasMore(false)
    setCurrentPage(currentPage + 1)
  }

  // NOTE: Remember to change the backend order when updating these sorting options
  const [completedLeasingRequests, ongoingLeasingRequests] = partition(allLeasingRequests, { status: "closed" })
  const [wonLeasingRequests, closedLeasingRequests] = partition(completedLeasingRequests, { won: true })

  if (!loading && isEmpty(allLeasingRequests)) return t("noLeasingRequests")

  const sortedOngoingLeasingRequests = orderBy(
    ongoingLeasingRequests,
    ["prioritization", "lastStatusChangedAt"],
    ["desc", "desc"]
  )
  const sortedWonLeasingRequests = orderBy(wonLeasingRequests, ["lastStatusChangedAt"], ["desc"])
  const sortedClosedLeasingRequests = orderBy(closedLeasingRequests, ["lastStatusChangedAt"], ["desc"])

  return (
    <ClientRequestContext.Provider value={{ id: clientRequestId }}>
      <StyledInfiniteScroll pageStart={0} initialLoad={false} loadMore={handleLoadMore} hasMore={hasMore} useWindow>
        <LeasingRequestList
          title={t("ongoingLeasingRequests")}
          leasingRequests={sortedOngoingLeasingRequests}
          divider
        />
        <LeasingRequestList title={t("wonLeasingRequests")} leasingRequests={sortedWonLeasingRequests} divider />
        <LeasingRequestList title={t("closedLeasingRequests")} leasingRequests={sortedClosedLeasingRequests} />
        {loading && <Loading />}
      </StyledInfiniteScroll>
    </ClientRequestContext.Provider>
  )
}

LeasingRequestsGrid.propTypes = {
  leadId: PropTypes.string,
  clientId: PropTypes.string,
  clientRequestId: PropTypes.string
}

LeasingRequestsGrid.defaultProps = {
  leadId: null,
  clientId: null,
  clientRequestId: null
}

export default function LeasingRequests(props) {
  const { leadId, clientId, clientRequestId } = props
  const { t } = useTranslation()

  if (leadId || clientId || clientRequestId) return <LeasingRequestsGrid {...props} />

  return t("noLeasingRequests")
}

LeasingRequests.propTypes = {
  leadId: PropTypes.string,
  clientId: PropTypes.string,
  clientRequestId: PropTypes.string
}

LeasingRequests.defaultProps = {
  leadId: null,
  clientId: null,
  clientRequestId: null
}
