import PropTypes from "prop-types"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import moment from "moment"
import CallIcon from "@material-ui/icons/Call"
import { startCase } from "lodash"
import { printDateTime, printPhone, printTime } from "../../helpers/formatter"
import Staff from "../../models/staff"
import { Paragraph } from "../styled/typography"
import useAbilities from "../../hooks/useAbilities"

const Small = styled.small`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--stormy-blue);
  margin-bottom: 0;
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const StyledCallLogIcon = styled(CallIcon)`
  margin-right: 15px;
  color: var(--stormy-blue);
`

const Title = styled.p`
  color: var(--stormy-blue);
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
`

export default function CallLog(props) {
  const { callLog } = props
  const {
    responseStatus,
    incomingCall,
    callDuration,
    createdAt,
    assignedAtId,
    from,
    to,
    recordingUrl,
    recordingDuration
  } = callLog
  const { staff } = useSelector(mapStateToProps, shallowEqual)
  const staffName = Staff.getName(staff, assignedAtId)
  const { allowed: viewQuality } = useAbilities("view_quality_analytics")

  return (
    <>
      <StyledGrid item xs={12}>
        <StyledCallLogIcon fontSize="large" />
        <div>
          <Title>{`${startCase(responseStatus)} ${incomingCall ? "incoming" : "outgoing"} call`}</Title>
          <Paragraph>{`Call duration ${printTime(callDuration)}`}</Paragraph>
          <Paragraph>{`Created at ${printDateTime(createdAt)}`}</Paragraph>
          <Paragraph>{`Call from ${printPhone(from)} to ${printPhone(to)}`}</Paragraph>
          {viewQuality && !!recordingUrl && (
            <Paragraph>
              Call recorded:
              <a target="_blank" href={recordingUrl} rel="noreferrer">
                Escuchar grabación
              </a>
            </Paragraph>
          )}
          {viewQuality && !!recordingDuration && (
            <Paragraph>{`Call recorded duration: ${recordingDuration}s`}</Paragraph>
          )}
        </div>
      </StyledGrid>
      <Small>
        {`${printDateTime(createdAt)} ${moment(createdAt).fromNow()}`}
        &nbsp;
        <b>{staffName || "Anonymous"}</b>
      </Small>
    </>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff } = commonDataReducer

  return { staff: staff.items }
}

CallLog.propTypes = {
  callLog: PropTypes.object.isRequired
}
