import React, { useState } from "react"
import { Form } from "informed"
import { useHistory } from "react-router-dom"
import BasicInput from "../inputs/BasicInput"
import { login, storeAuth } from "../../../services/accountService"
import { ModalPrimaryButton } from "../../styled/buttons"

export default function SignInForm() {
  const history = useHistory()
  const [isFetching, setIsFetching] = useState(false)
  const [errors, setErrors] = useState({})

  const handleSubmit = values => {
    setIsFetching(true)
    login(values)
      .then(response => {
        storeAuth(response.data.authToken)
        history.push("/dashboard")
      })
      .catch(data => {
        setErrors(data)
        setIsFetching(false)
      })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <BasicInput autoComplete="email" field="email" name="email" label="Email" errors={errors} required />
      <BasicInput
        autoComplete="current-password"
        field="password"
        name="password"
        label="Password"
        type="password"
        errors={errors}
        required
      />
      <ModalPrimaryButton isFetching={isFetching} type="submit" fullWidth>
        Sign In
      </ModalPrimaryButton>
    </Form>
  )
}
