import React from "react"
import { asField } from "informed"
import MaterialRadioGroup from "@material-ui/core/RadioGroup"

const RadioGroup = asField(({ fieldState, fieldApi, ...props }) => {
  const { children, onChange, row } = props

  const { setValue } = fieldApi
  const { value } = fieldState

  const onValueChange = event => {
    setValue(event.target.value)
    if (onChange) onChange(event.target.value)
  }

  return (
    <MaterialRadioGroup row={row} value={value} onChange={onValueChange}>
      {children}
    </MaterialRadioGroup>
  )
})

export default RadioGroup
