import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import { Paragraph } from "../../styled/typography"
import BasicInput from "../inputs/BasicInput"

const REOPEN = gql`
  mutation reopen($leasingRequestId: ID!, $leasingRequestArguments: ReopenInputObject!) {
    reopen(input: { leasingRequestId: $leasingRequestId, leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function Reopen(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(REOPEN, {
    onCompleted: newData => {
      if (newData.reopen?.errors == null) {
        handleSuccess()
      }
    }
  })

  const errors = data?.reopen?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, leasingRequestArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Paragraph>{t("common:areYouSure")}</Paragraph>
      <BasicInput field="comment" label={t("common:comment")} required errors={errors} />
      <ModalPrimaryButton isFetching={loading}>{t("reopen")}</ModalPrimaryButton>
    </Form>
  )
}

Reopen.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func
}

Reopen.defaultProps = {
  handleSuccess: () => {}
}
