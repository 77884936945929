import PropTypes from "prop-types"
import { gql, useQuery } from "@apollo/client"
import React from "react"
import { isNil, orderBy } from "lodash"
import { Grid } from "@material-ui/core"
import Loading from "../../../../../components/Loading"
import Task from "../../../../../components/Task"
import CallLog from "../../../../../components/CallLog"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      tasks {
        id
        identifier
        startedAt
        createdAt
        afterDate
        comment
        completedAt
        reassignable
        assignedAtId
        timeSpent
        className
      }
      callLogs {
        id
        from
        to
        responseStatus
        incomingCall
        callDuration
        createdAt
        assignedAtId
        className
        recordingUrl
        recordingDuration
      }
    }
  }
`
function Element(props) {
  const { className, item } = props

  if (className === "Task") return <Task task={item} />
  return <CallLog callLog={item} />
}

export default function CallsAndTasks(props) {
  const { leasingRequestId } = props
  const { loading, data } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  const items = data.leasingRequest.tasks.concat(data.leasingRequest.callLogs)

  return (
    <Grid item xs={12}>
      {orderBy(items, "createdAt", "desc").map(item => (
        <Grid item xs={12} key={item.id}>
          <Element item={item} className={item.className} />
        </Grid>
      ))}
    </Grid>
  )
}

Element.propTypes = {
  className: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
}

CallsAndTasks.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
