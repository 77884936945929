import React, { useState } from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import { printSnakeCase } from "../../../helpers/formatter"
import {
  leasingRequestNegotiationLostReasonOptions,
  leasingRequestNegotiationStatusOptions
} from "../../../helpers/constants"
import TextSelectInput from "../inputs/TextSelectInput"

const NEGOTIATION_STATUS_UPDATE = gql`
  mutation negotiationStatusUpdate(
    $leasingRequestId: ID!
    $negotiationStatusUpdate: NegotiationStatusUpdateInputObject!
  ) {
    negotiationStatusUpdate(
      input: { leasingRequestId: $leasingRequestId, negotiationStatusUpdate: $negotiationStatusUpdate }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function NegotiationStatus(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation()
  const [negotiationStatus, setNegotiationStatus] = useState(null)
  const [mutation, { data, loading, error }] = useMutation(NEGOTIATION_STATUS_UPDATE, {
    onCompleted: newData => {
      if (newData.negotiationStatusUpdate?.errors == null) {
        handleSuccess()
      }
    }
  })

  const errors = data?.negotiationStatusUpdate?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    setNegotiationStatus(values.negotiationStatus)
    mutation({
      variables: { leasingRequestId, negotiationStatusUpdate: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <TextSelectInput
        field="negotiationStatus"
        label="Estado de negociación"
        options={leasingRequestNegotiationStatusOptions(t)}
        errors={errors}
        displayClear
        onChange={({ target }) => {
          // Esto establecerá el estado cuando cambie el negotiationStatus
          setNegotiationStatus(target.value)
        }}
      />
      {negotiationStatus === "lost" && (
        <TextSelectInput
          field="negotiationLostReason"
          label="Razón perdido"
          humanizeFunc={printSnakeCase}
          options={leasingRequestNegotiationLostReasonOptions(t)}
          errors={errors}
          required
          displayClear
        />
      )}
      <ModalPrimaryButton isFetching={loading}>{t("Guardar")}</ModalPrimaryButton>
    </Form>
  )
}

NegotiationStatus.defaultProps = {
  handleSuccess: () => {}
}

NegotiationStatus.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func
}
