import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import DriveEtaIcon from "@material-ui/icons/DriveEta"
import { Fab } from "@material-ui/core"
import { useLazyQuery } from "@apollo/client"
import Cars from "./Cars"
import CarDialog from "./CarDialog"
import { CarListDialogContext, CustomerEntityContext } from "../../contexts"
import { receiveNotification } from "../../actions"

const StyledFab = styled(Fab)`
  background-color: var(--lightblue);
  color: var(--white);
  position: fixed;
  bottom: 15px;
  z-index: 10;
  &:hover {
    background-color: var(--stormy-blue);
  }
`

export default function CarListDialog(props) {
  const { customerEntityQuery, customerEntityQueryVariables, customerEntityRetriever } = props
  // TODO: Passing jobType on CarListDialogContext is deprecated. Use CustomerEntityContext instead.
  const { options } = useContext(CarListDialogContext)
  const [open, setOpen] = useState(false)
  const [jobType, setJobType] = useState(options.jobType)
  const [countryCode, setCountryCode] = useState(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const tryOpenModal = entityJobType => {
    if (entityJobType) setOpen(true)
    else dispatch(receiveNotification(t("jobTypes.notDefined"), { variant: "warning" }))
  }

  const onGetCustomerEntity = () => {
    const { jobType: entityJobType, countryCode: entityCountryCode } = customerEntityRetriever(data)
    setJobType(entityJobType)
    setCountryCode(entityCountryCode)

    tryOpenModal(entityJobType)
  }

  const [getCustomerEntity, { loading, data }] = useLazyQuery(customerEntityQuery, { onCompleted: onGetCustomerEntity })

  const handleClick = () => {
    if (customerEntityQuery) getCustomerEntity({ variables: customerEntityQueryVariables })
    else tryOpenModal(jobType)
  }

  return (
    <>
      <StyledFab disabled={loading} onClick={handleClick}>
        <DriveEtaIcon fontSize="large" />
      </StyledFab>

      <CustomerEntityContext.Provider value={{ jobType, countryCode }}>
        <Cars open={open} setOpen={setOpen} />
        <CarDialog />
      </CustomerEntityContext.Provider>
    </>
  )
}

CarListDialog.propTypes = {
  customerEntityQuery: PropTypes.object,
  customerEntityQueryVariables: PropTypes.object,
  customerEntityRetriever: PropTypes.func
}

CarListDialog.defaultProps = {
  customerEntityQuery: null,
  customerEntityQueryVariables: null,
  customerEntityRetriever: null
}
