import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import React from "react"
import styled from "styled-components"
import Tooltip from "@material-ui/core/Tooltip"
import { printDateTime } from "../../helpers/formatter"

const Chip = styled.p`
  color: var(--stormy-blue);
  font-size: 20px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 15px 0;
  text-align: center;
  position: relative;
  small {
    color: black;
    margin-top: 5px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
  }
  &:before {
    top: 20%;
    left: -35px;
    background-color: var(--stormy-blue);
  }
  &:after {
    top: 20%;
    right: -35px;
    background-color: var(--stormy-blue);
  }
  &:before,
  &:after {
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    position: absolute;
    content: "";
  }
`

export default function StatusChip(props) {
  const { status, lastStatusChangedAt, tooltip } = props

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Tooltip title={tooltip || ""} placement="bottom-start" arrow>
        <Chip>
          {status}
          {lastStatusChangedAt && <small>{printDateTime(lastStatusChangedAt, "DD/MM/YYYY", true)}</small>}
        </Chip>
      </Tooltip>
    </Grid>
  )
}

StatusChip.propTypes = {
  lastStatusChangedAt: PropTypes.string,
  status: PropTypes.string.isRequired,
  tooltip: PropTypes.object
}

StatusChip.defaultProps = {
  lastStatusChangedAt: null,
  tooltip: null
}
