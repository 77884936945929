import PropTypes from "prop-types"
import React, { useContext } from "react"
import { shallowEqual, useSelector } from "react-redux"
import CallIcon from "@material-ui/icons/Call"
import AssignmentIcon from "@material-ui/icons/Assignment"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { DefaultButton } from "../../../../../components/styled/buttons"
import { TwilioContext } from "../../../../../contexts"

const Wrapper = styled(Grid)`
  margin: 15px 0;
`

export default function PrimaryActions(props) {
  const { leadId, leadTitle, openScheduleCallModal } = props
  const { t } = useTranslation()
  const { connect } = useContext(TwilioContext)
  const { everythingReady, inCall, accountId } = useSelector(mapStateToProps, shallowEqual)

  const handleCallLead = () => {
    connect(leadId, leadTitle, "Lead", accountId)
  }

  return (
    <Wrapper item xs={12}>
      <DefaultButton
        onClick={handleCallLead}
        disabled={!everythingReady || inCall}
        variant="contained"
        size="large"
        color="primary"
        startIcon={<CallIcon />}>
        {t("callLead")}
      </DefaultButton>
      <DefaultButton
        onClick={openScheduleCallModal}
        variant="contained"
        size="large"
        color="primary"
        startIcon={<AssignmentIcon />}>
        {t("scheduleACall")}
      </DefaultButton>
    </Wrapper>
  )
}

const mapStateToProps = state => {
  const { accountReducer, callReducer } = state
  const { inCall, everythingReady } = callReducer
  const { item } = accountReducer

  return {
    accountId: item && item.id,
    everythingReady,
    inCall
  }
}

PrimaryActions.propTypes = {
  leadId: PropTypes.string.isRequired,
  leadTitle: PropTypes.string.isRequired,
  openScheduleCallModal: PropTypes.func.isRequired
}
