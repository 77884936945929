import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Chip, Grid } from "@material-ui/core"
import { Announcement, CheckCircle, Email, EmojiObjects, Warning } from "@material-ui/icons"
import makeStyles from "@material-ui/styles/makeStyles"
import styled from "styled-components"

const StyledGrid = styled(Grid)`
  margin-bottom: 15px;
`

const StyledChip = styled(Chip)`
  border-radius: 0;
  padding: 0 5px;
`

const useStyles = makeStyles(theme => ({
  common: {
    color: theme.palette.common.white,
    "& .MuiChip-icon": {
      color: theme.palette.common.white
    }
  },
  success: {
    backgroundColor: theme.palette.primary.main
  },
  error: {
    backgroundColor: "var(--warm-red)"
  }
}))

const variants = {
  offer: {
    classes: {
      not_started: "error",
      requested: "error",
      received: "success"
    },
    icons: {
      not_started: Warning,
      requested: Announcement,
      received: CheckCircle
    }
  },
  quotation: {
    classes: {
      not_started: "error",
      requested: "error",
      sent: "error",
      signed: "success"
    },
    icons: {
      not_started: Warning,
      requested: Announcement,
      sent: Email,
      signed: CheckCircle
    }
  }
}

const getIcon = Icon => {
  return <Icon />
}

export default function SubStatusChips(props) {
  const {
    createdReason,
    statuses: { offerStatus, quotationStatus }
  } = props
  const { t } = useTranslation("leasingRequest")
  const classes = useStyles()

  return (
    <StyledGrid container justify="center">
      {createdReason && (
        <Grid container item justify="center" xs={12}>
          <StyledChip
            className={classNames(classes.common, classes.success)}
            label={`${t("common:createdReason")}: ${t(`common:createdReasonOptions.${createdReason}`)}`}
            icon={<EmojiObjects />}
          />
        </Grid>
      )}
      <StyledChip
        className={classNames(classes.common, classes[variants.offer.classes[offerStatus]])}
        label={t(`offerStatuses.${offerStatus}`)}
        icon={getIcon(variants.offer.icons[offerStatus])}
      />
      <StyledChip
        className={classNames(classes.common, classes[variants.quotation.classes[quotationStatus]])}
        label={t(`quotationStatuses.${quotationStatus}`)}
        icon={getIcon(variants.quotation.icons[quotationStatus])}
      />
    </StyledGrid>
  )
}

SubStatusChips.propTypes = {
  statuses: PropTypes.object.isRequired,
  createdReason: PropTypes.string
}

SubStatusChips.defaultProps = {
  createdReason: null
}
