import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useFormState } from "informed"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { shallowEqual, useSelector } from "react-redux"
import { capitalize } from "@material-ui/core/utils"
import { Checkbox, FormControl, FormControlLabel, Grid, Radio } from "@material-ui/core"
import { isNil } from "lodash"
import CheckboxInput from "../CheckboxInput"
import TextSelectInput from "../TextSelectInput"
import { createOption } from "../../../../helpers/forms"
import BasicInput from "../BasicInput"
import RadioGroup from "../RadioGroup"
import GuarantorInput from "../GuarantorInput"
import Distributor from "../../../../models/distributor"
import { StyledLabel } from "../../../styled/typography"
import CarInformationFields from "../../CarInformationFields"
import FCAFields from "./FCAFields"
import LeaseplanFields from "./LeaseplanFields"
import { LeasingRequestCarContext } from "../../../../contexts"
import { preDeliveryCarOptions, leasingRequestCreatedReasonOptions } from "../../../../helpers/constants"
import DateInput from "../DateInput"

const CAR = gql`
  query car($id: ID!, $planId: ID!) {
    car(id: $id) {
      id
      color
      title
      distributorId
      plan(planId: $planId) {
        kmYear
        duration
        price
      }
    }
  }
`

const LEAD = gql`
  query lead($leadId: ID!, $distributorId: ID) {
    lead(id: $leadId) {
      leasingRequests(limit: 100, closedStatus: billable, distributorId: $distributorId) {
        results {
          id
          basicCar {
            duration
            kmYear
            price
            car {
              title
            }
          }
        }
      }
    }
  }
`

function PreDeliveryCarFields(props) {
  const { getPropsForField } = props
  const { t } = useTranslation()
  const formState = useFormState()

  return (
    <>
      <CheckboxInput {...getPropsForField("preDeliveryCar", false)} />
      {formState.values.preDeliveryCar && (
        <TextSelectInput
          required
          multiple
          options={preDeliveryCarOptions(t)}
          {...getPropsForField("preDeliveryCarOptions")}
        />
      )}
    </>
  )
}

PreDeliveryCarFields.propTypes = {
  getPropsForField: PropTypes.func.isRequired
}

export default function CreateLeasingRequestFields(props) {
  const { leadId, getPropsForField, errors } = props
  const { t } = useTranslation("lead")
  const { leasingRequestCar } = useContext(LeasingRequestCarContext)
  const { carId, distributorId, priceId } = leasingRequestCar
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const distributor = Distributor.getDistributor(distributors, distributorId)
  const [mode, setMode] = useState(distributor.onlineScoring ? "online" : "offline")
  const [downPaymentChecked, setDownPaymentChecked] = useState(!isNil(getPropsForField("downPayment").initialValue))
  const formState = useFormState()
  const provinces = [
    "Álava",
    "Albacete",
    "Alicante",
    "Almería",
    "Asturias",
    "Ávila",
    "Badajoz",
    "Barcelona",
    "Burgos",
    "Cáceres",
    "Cádiz",
    "Cantabria",
    "Castellón",
    "Ciudad Real",
    "Córdoba",
    "La Coruña",
    "Cuenca",
    "Gerona",
    "Granada",
    "Guadalajara",
    "Guipúzcoa",
    "Huelva",
    "Huesca",
    "Islas Baleares",
    "Jaén",
    "León",
    "Lérida",
    "Lugo",
    "Madrid",
    "Málaga",
    "Murcia",
    "Navarra",
    "Orense",
    "Palencia",
    "Las Palmas",
    "Pontevedra",
    "La Rioja",
    "Salamanca",
    "Santa Cruz de Tenerife",
    "Segovia",
    "Sevilla",
    "Soria",
    "Tarragona",
    "Teruel",
    "Toledo",
    "Valencia",
    "Valladolid",
    "Vizcaya",
    "Zamora",
    "Zaragoza"
  ]

  const provincesArray = provinces.map(prov => ({
    value: prov,
    label: prov
  }))

  const { data: carData } = useQuery(CAR, {
    variables: { id: carId, planId: priceId }
  })
  const { data: leadData } = useQuery(LEAD, {
    variables: { leadId, distributorId }
  })

  const jobType = formState.values.jobType || getPropsForField("jobType").initialValue

  const onModeChange = newMode => {
    setMode(newMode)
  }

  const onDownPaymentCheckChange = e => {
    setDownPaymentChecked(e.target.checked)
  }

  const billableLeasingRequestOptions = leadData?.lead?.leasingRequests?.results?.map(leasingRequest => {
    const concretePlan = t("common:concretePlan", {
      duration: leasingRequest.basicCar.duration,
      kmYear: leasingRequest.basicCar.kmYear,
      price: leasingRequest.basicCar.price
    })

    return {
      label: `${leasingRequest.basicCar?.car?.title} ${concretePlan}`,
      value: leasingRequest.id
    }
  })

  return (
    <>
      {/* Online/offline radio */}
      {distributor.onlineScoring && (
        <FormControl component="fieldset">
          <StyledLabel>{t("lead:scoringMethod")}</StyledLabel>
          <RadioGroup field="mode" row initialValue={mode} onChange={onModeChange}>
            {["online", "offline"].map(m => (
              <FormControlLabel key={m} control={<Radio color="primary" />} label={capitalize(m)} value={m} />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {/* Car readonly properties */}
      <CarInformationFields car={carData?.car} />
      {/* Color and quantity */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextSelectInput
            {...getPropsForField("color")}
            required
            options={carData?.car?.color.map(c => createOption(c, c)) || []}
            humanizeFunc={value => t(`common:colors.${value}`)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput type="number" {...getPropsForField("quantity", 1)} required />
        </Grid>
      </Grid>
      {/* Created reason */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextSelectInput
            {...getPropsForField("createdReason", "new")}
            required
            options={leasingRequestCreatedReasonOptions(t)}
          />
        </Grid>
        {formState.values.createdReason === "renovation" && (
          <Grid item xs={12} md={6}>
            <CheckboxInput field="sameDistributor" label={t("leasingRequest:sameDistributor")} />
          </Grid>
        )}
        {formState.values.sameDistributor && (
          <Grid item xs={12}>
            <TextSelectInput
              {...getPropsForField("renovationLeasingRequestId")}
              required
              options={billableLeasingRequestOptions || []}
            />
          </Grid>
        )}
      </Grid>
      {/* Guarantor and down payment */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GuarantorInput
            getPropsForField={getPropsForField}
            distributorId={distributorId}
            initialGuarantorValue={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              label={t("common:hasDownPayment")}
              control={<Checkbox onChange={onDownPaymentCheckChange} checked={downPaymentChecked} />}
            />
          </FormControl>
          {downPaymentChecked && (
            <>
              <BasicInput type="number" {...getPropsForField("downPayment", 0)} required />
            </>
          )}
        </Grid>
      </Grid>
      {/* Pre delivery car options */}
      <PreDeliveryCarFields getPropsForField={getPropsForField} />
      {/* Comment */}
      <h3>Comentarios</h3>
      <BasicInput field="comment" label={t("common:comments")} />
      {/* Names */}
      <h3>Datos personales</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("firstName")} required />
          <BasicInput {...getPropsForField("lastName")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          {(distributor.isFCAES() || distributor.isLeaseplanES()) && mode === "online" && (
            <BasicInput {...getPropsForField("secondLastName")} required />
          )}
          {jobType === "company" && <BasicInput {...getPropsForField("companyName")} required />}
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("idNumber")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput {...getPropsForField("birthday")} required openTo="year" views={["year", "month", "date"]} />
        </Grid>
      </Grid>
      {/* Address */}
      <h3>Dirección</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("street")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("streetNumber")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("floor")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("postalCode")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("town")} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("province")} required />
        </Grid>
      </Grid>
      {/* Delivery Address */}
      <h3>Dirección de entrega</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BasicInput field="deliveryAddress" label="Calle" type="text" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput field="deliveryTown" label="Ciudad" type="text" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput field="deliveryPostalCode" label="Codigo postal" type="text" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextSelectInput
            field="deliveryProvince"
            label={t("leasingRequest:deliveryProvince")}
            options={provincesArray}
          />
        </Grid>
      </Grid>
      <h3>Otros datos</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BasicInput field="signatoryEmail" label="Email Firmante" type="text" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput field="plateNumber" label="Matricula" type="text" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput field="oldPlateNumber" label="Matricula anterior" type="text" />
        </Grid>
      </Grid>
      {/* Online and offline specific fields */}
      {distributor.integrationEnabled && <BasicInput {...getPropsForField("externalReferenceId")} initialValue="" />}
      {distributor.isFCAES() && mode === "online" && (
        <>
          <h3>FCA</h3>
          <FCAFields getPropsForField={getPropsForField} />
        </>
      )}
      {distributor.isLeaseplanES() && mode === "online" && (
        <>
          <h3>LeasePlan</h3>
          <LeaseplanFields getPropsForField={getPropsForField} />
        </>
      )}
      {mode === "offline" && (
        <CheckboxInput
          field="clientAcceptance"
          label={t("common:clientAcceptance")}
          initialValue={false}
          required
          errors={errors}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

CreateLeasingRequestFields.propTypes = {
  leadId: PropTypes.string.isRequired,
  getPropsForField: PropTypes.func.isRequired,
  errors: PropTypes.array
}

CreateLeasingRequestFields.defaultProps = {
  errors: null
}
