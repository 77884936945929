import React from "react"
import { gql, useLazyQuery } from "@apollo/client"
import * as PropTypes from "prop-types"
import { CircularProgress, Grid, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import classNames from "classnames"
import LeftPane from "./LeftPane"
import RightPane from "./RightPane"
import gqlFields from "../gqlFields"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      ${gqlFields}
    }
  }
`

const PROGRESS_SIZE = 200

const useStyles = makeStyles(theme => ({
  wrapper: {
    "margin-bottom": theme.spacing(2),
    position: "relative"
  },
  closed: {
    opacity: 0.65
  },
  loading: {
    opacity: 0.65,
    filter: "blur(10px)",
    "pointer-events": "none",
    transition: "filter 0.2s, opacity 0.2s"
  },
  progress: {
    position: "absolute",
    left: `calc(50% - ${PROGRESS_SIZE}px / 2)`,
    top: `calc(50% - ${PROGRESS_SIZE}px / 2)`
  }
}))

export default function LeasingRequest(props) {
  const { leasingRequest } = props
  const classes = useStyles()
  const [fetchLeasingRequest, { data, loading }] = useLazyQuery(LEASING_REQUEST)

  const isClosed = leasingRequest.globalStatus === "closed"

  const refetch = () => {
    fetchLeasingRequest({ variables: { id: leasingRequest.id } })
  }

  const currentLeasingRequest = data?.leasingRequest || leasingRequest

  return (
    <Paper className={classes.wrapper} elevation={2}>
      <Grid container className={classNames({ [classes.closed]: isClosed, [classes.loading]: loading })}>
        <LeftPane leasingRequest={currentLeasingRequest} refetch={refetch} />
        <RightPane leasingRequest={currentLeasingRequest} />
      </Grid>
      {loading && (
        <CircularProgress className={classes.progress} size={PROGRESS_SIZE} color="primary" variant="indeterminate" />
      )}
    </Paper>
  )
}

LeasingRequest.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
