import { gql } from "@apollo/client"
import { find, includes, isArray, reverse } from "lodash"

export const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

export const PLACEHOLDER_MUTATION = gql`
  mutation {
    placeholder
  }
`

export const pathForSubject = (subjectType, subjectId) => {
  if (subjectType === "Lead") return `/leads/${subjectId}`
  if (subjectType === "LeasingRequest") return `/leasing-requests/${subjectId}`
  if (subjectType === "Client") return `/clients/${subjectId}`
  if (subjectType === "Car") return `/cars/${subjectId}`
  if (subjectType === "ClientRequest") return `/client-requests/${subjectId}`

  return null
}

export const redirectToSubject = (history, subjectType, subjectId) => {
  const path = pathForSubject(subjectType, subjectId)

  if (path !== null) return history.push(path)

  return null
}

export const openWindowForSubject = (subjectType, subjectId) => {
  const path = pathForSubject(subjectType, subjectId)

  if (path !== null) return window.open(`#${path}`)

  return null
}

export const handleNotificationErrors = errors => {
  return errors
    .filter(e => e.path === "base")
    .map(e => e.messages)
    .join(", ")
}

export const handleAllNotificationErrors = (t, errors) => {
  return errors.map(e => e.messages.map(m => (e.path === "base" ? m : `${t(e.path)} ${m}`))).join(", ")
}

export const lastStatusDate = (statuses, status, property = "createdAt") => {
  const statusList = isArray(status) ? status : [status]

  const lastStatus = find(reverse(statuses), item => includes(statusList, item.status))
  if (!lastStatus) return null

  return lastStatus[property]
}

export const dateFilter = (field, startDate, endDate) => {
  if (!startDate || !endDate) return {}

  return {
    [field]: {
      $gte: startDate.toDate(),
      $lt: endDate.toDate()
    }
  }
}

export const renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => ({
  [newProp]: old,
  ...others
})
