import moment from "moment"
import { isArray, startCase, toLower } from "lodash"
import humanizeDuration from "humanize-duration"
import { capitalize } from "@material-ui/core/utils"

export function printNumber(value, ops = {}) {
  const number = new Intl.NumberFormat("en-ES", {
    style: "decimal",
    minimumFractionDigits: 0,
    ...ops
  })
  return number.format(value)
}

export function printCurrency(value) {
  if (!value) return null

  const currency = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0
  })

  return currency.format(value)
}

export function printDateTime(date, format = "HH:mm DD/MM/YYYY", withFromNow = false) {
  if (date == null) return "-"
  const momentDate = moment(date)
  if (withFromNow) return `${momentDate.format(format)} ${momentDate.fromNow()}`
  return momentDate.format(format)
}

export function printDate(date, format = "DD/MM/YYYY") {
  if (!date) return "-"
  return moment(date).format(format)
}

export function pastDate(date) {
  return date && moment(date).isBefore()
}

export function printSnakeCase(value) {
  if (!value) return "-"
  if (isArray(value)) return printSnakeCaseArray(value)
  const words = value.match(/[A-Za-z0-9][a-z]*/g) || []

  return capitalize(words.join(" "))
}

export function printSnakeCaseArray(values) {
  return values.map(value => printSnakeCase(value)).join(", ")
}

export function printName(name) {
  return startCase(toLower(name))
}

export function printTime(time) {
  return humanizeDuration(time * 1000)
}

export function printPhone(phone) {
  if (!phone) return "-"

  return phone
}
