import React from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Step from "../Step"

const calculateVariant = scoringStatus => {
  if (scoringStatus === "not_started") return "default"
  if (scoringStatus === "started") return "current"

  return "completed"
}

export default function Scoring(props) {
  const { leasingRequest } = props
  const { online, createdAt, scoringStatus, scoringStartedAt, scoringCompletedAt } = leasingRequest
  const { t } = useTranslation("leasingRequest")

  const variant = calculateVariant(scoringStatus)

  const startedAt = online ? createdAt : scoringStartedAt

  return (
    <Step
      title={t("flowSteps.scoring")}
      variant={variant}
      startedAt={startedAt}
      endedAt={scoringCompletedAt}
      leftArrow
      rightArrow
    />
  )
}

Scoring.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
