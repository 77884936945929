import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import { asField } from "informed"
import { isEmpty, isNaN, round } from "lodash"
import { useTranslation } from "react-i18next"
import { getFieldError } from "../../../../helpers/errorParser"

const isNumericString = value => {
  return value.match(/^-?\d+$/) || value.match(/^\d+\.\d+$/)
}

const DepositInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { total, field, initialValue, errors, onChange, onBlur, forwardedRef, errorsPlace, ...rest } = props
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(initialValue)
  const [helperText, setHelperText] = useState(initialValue ? t("withDeposit", { price: initialValue }) : "")
  const { setValue, setTouched, setError: setFieldError } = fieldApi
  const { error: fieldError } = fieldState

  const errorsText = getFieldError(errors, field, errorsPlace)

  const handleInputEmpty = () => {
    return null
  }

  const handleInputPercentage = newInputValue => {
    const numberWithoutSymbols = newInputValue.replace("%", "").replace(",", ".").replace(" ", "")
    const percentage = parseFloat(numberWithoutSymbols)

    const newValue = round((total * percentage) / 100)

    if (!isNumericString(numberWithoutSymbols) || isNaN(percentage) || percentage < 0 || percentage > 100) {
      setFieldError(t("invalidPercentage"))
    }

    return newValue
  }

  const handleInputNumber = newInputValue => {
    const numberWithoutSymbols = newInputValue.replace("€", "").replace(",", ".").replace(" ", "")
    const newValue = parseInt(numberWithoutSymbols, 10)

    if (!isNumericString(numberWithoutSymbols) || isNaN(newValue) || newValue < 0) {
      setFieldError(t("invalidNumber"))
    }

    return newValue
  }

  const onInputChange = e => {
    setInputValue(e.target.value)
    setFieldError(undefined)
    setHelperText("")

    const newInputValue = e.target.value
    let newValue

    if (isEmpty(newInputValue)) {
      newValue = handleInputEmpty(newInputValue)
    } else if (/%$/.test(newInputValue)) {
      newValue = handleInputPercentage(newInputValue)
    } else {
      newValue = handleInputNumber(newInputValue)
    }

    if (newValue !== null) {
      setHelperText(t("withDeposit", { price: newValue }))
    }

    setValue(newValue)
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <>
      <TextField
        {...rest}
        defaultValue={initialValue}
        field={field}
        fullWidth
        error={!isEmpty(errorsText) || !isEmpty(fieldError)}
        helperText={errorsText || fieldError || helperText}
        ref={forwardedRef}
        value={inputValue === undefined ? 0 : inputValue}
        onChange={onInputChange}
        onBlur={e => {
          setTouched()
          if (onBlur) {
            onBlur(e)
          }
        }}
      />
    </>
  )
})

export default DepositInput
