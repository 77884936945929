import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import * as actionCreators from "../../actions/index"

export default function withRedux(ComposedComponent, mapStateToProps) {
  // eslint-disable-next-line no-shadow
  class withRedux extends PureComponent {
    constructor(props) {
      super(props)
      const { dispatch } = props
      this.boundActionCreators = bindActionCreators(actionCreators, dispatch)
    }

    render() {
      return <ComposedComponent {...this.props} {...this.boundActionCreators} />
    }
  }

  withRedux.propTypes = {
    dispatch: PropTypes.func
  }
  withRedux.defaultProps = {
    dispatch: () => {}
  }
  return connect(mapStateToProps)(withRedux)
}
