import PropTypes from "prop-types"
import React from "react"
import moment from "moment"
import { Form, useFormState } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import TextSelectInput from "../inputs/TextSelectInput"
import CheckboxInput from "../inputs/CheckboxInput"
import DateInput from "../inputs/DateInput"
import GraphQLErrors from "../../GraphQLErrors"
import Loading from "../../Loading"
import { promotionOptions } from "../../../helpers/constants"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      promotion
      promotionStartedAt
      promotionOption
    }
  }
`

const UPDATE_LEASING_REQUEST_PROMOTION = gql`
  mutation updateLeasingRequestPromotion(
    $id: ID!
    $leasingRequestArguments: UpdateLeasingRequestPromotionInputObject!
  ) {
    updateLeasingRequestPromotion(input: { leasingRequestId: $id, leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

function PromotionOptions(props) {
  const { leasingRequest, errors } = props
  const { t } = useTranslation()
  const formState = useFormState()

  const getPropsForField = field => {
    return {
      field,
      label: t(`common:${field}`),
      initialValue: leasingRequest[field],
      errors
    }
  }

  return (
    <>
      <CheckboxInput {...getPropsForField("promotion")} />
      {formState.values.promotion && (
        <>
          <TextSelectInput required options={promotionOptions(t)} {...getPropsForField("promotionOption")} />
          <DateInput
            required
            disableFuture
            minDate={moment().subtract(2, "months")}
            openTo="month"
            views={["year", "month", "date"]}
            {...getPropsForField("promotionStartedAt")}
            initialValue={leasingRequest.promotionStartedAt || new Date()}
          />
        </>
      )}
    </>
  )
}

PromotionOptions.propTypes = {
  leasingRequest: PropTypes.object.isRequired,
  errors: PropTypes.array
}

PromotionOptions.defaultProps = {
  errors: null
}

export default function UpdateLeasingRequestPromotion(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation()
  const { data: queryData, loading: queryLoading } = useQuery(LEASING_REQUEST, { variables: { id: leasingRequestId } })
  const [mutation, { data, loading, error }] = useMutation(UPDATE_LEASING_REQUEST_PROMOTION, {
    onCompleted: newData => {
      if (newData.updateLeasingRequestPromotion?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updateLeasingRequestPromotion?.errors
  if (error) return <p>Server Error</p>

  if (queryLoading) return <Loading />
  if (queryData.leasingRequest === null) return <p>Not found</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leasingRequestId, leasingRequestArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <PromotionOptions leasingRequest={queryData.leasingRequest} errors={errors} />
      <ModalPrimaryButton isFetching={loading}>{t("updatePromotion")}</ModalPrimaryButton>
    </Form>
  )
}

UpdateLeasingRequestPromotion.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
