import * as PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import AppBar from "@material-ui/core/AppBar/AppBar"
import makeStyles from "@material-ui/styles/makeStyles"
import styled from "styled-components"
import isEmpty from "lodash/isEmpty"
import { Redirect } from "react-router"
import { getAuth } from "../../../services/accountService"

const drawerWidth = 300

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "#fff",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px) !important`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    height: 60,
    width: 60,
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: "none !important"
  }
}))

const Logo = styled.img`
  height: 40px;
  width: auto;
  margin: 10px 1rem;
`

const Container = styled.div`
  padding-top: 70px;
  height: 100vh;
  width: 100%;
  background-image: url(${process.env.REACT_APP_CLOUDINARY_AUTO}/v1568477367/static/troc-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
`

export default function SignedOut(props) {
  const { children } = props
  const classes = useStyles() // TODO remove useStyles whenever is possible

  if (!isEmpty(getAuth())) {
    return <Redirect push to="/dashboard" />
  }

  return (
    <>
      <AppBar position="fixed" className={classNames(classes.appBar)}>
        <div>
          <Logo src={`${process.env.REACT_APP_CLOUDINARY_AUTO}/v1543925691/static/logos/swipcar_white.png`} />
        </div>
      </AppBar>
      <Container>{children}</Container>
    </>
  )
}

SignedOut.propTypes = {
  children: PropTypes.node.isRequired
}
