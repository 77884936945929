import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@material-ui/core"
import { Form } from "informed"
import { useTranslation } from "react-i18next"
import BasicInput from "../../../../components/forms/inputs/BasicInput"
import {
  activeOptions,
  binaryOptions,
  leasingRequestClosedStatusesOptions,
  leasingRequestCloseReasonsOptions,
  leasingRequestCreatedReasonOptions,
  leasingRequestGlobalStatusesOptions,
  leasingRequestNegotiationStatusOptions,
  leasingRequestOfferStatusesOptions,
  leasingRequestQuotationStatusesOptions,
  leasingRequestScoringStatusesOptions,
  winProbabilityOptions
} from "../../../../helpers/constants"
import TextSelectInput from "../../../../components/forms/inputs/TextSelectInput"
import StaffSelector from "../../../../components/forms/inputs/StaffSelector"
import DistributorSelector from "../../../../components/forms/inputs/DistributorSelector"
import CountryCodeSelector from "../../../../components/forms/inputs/CountryCodeSelector"

export default function Filter(props) {
  const { handleFilterAttributes } = props
  const { t } = useTranslation("leasingRequest")

  return (
    <Form onValueChange={handleFilterAttributes}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BasicInput field="q" label={t("common:search")} variant="outlined" />
        </Grid>
        <Grid item xs={12} md={3}>
          <BasicInput field="basicCar" label={t("common:searchCar")} variant="outlined" />
        </Grid>
        <Grid item xs={12} md={3}>
          <DistributorSelector variant="outlined" displayClear />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="globalStatus"
            label={t("common:leasingRequestGlobalStatus.title")}
            options={leasingRequestGlobalStatusesOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="quotationStatus"
            label={t("leasingRequest:quotationStatus")}
            options={leasingRequestQuotationStatusesOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="offerStatus"
            label={t("leasingRequest:offerStatus")}
            options={leasingRequestOfferStatusesOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="scoringStatus"
            label={t("leasingRequest:scoringStatusFilter")}
            options={leasingRequestScoringStatusesOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="closedStatus"
            label={t("common:leasingRequestGlobalStatus.won")}
            options={leasingRequestClosedStatusesOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="closedReason"
            label={t("common:closeReason")}
            options={leasingRequestCloseReasonsOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <StaffSelector
            field="assignedAtId"
            label={t("common:assignedAt")}
            options={activeOptions}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <StaffSelector
            field="createdById"
            label={t("common:createdBy")}
            options={activeOptions}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput field="online" label="Online" options={binaryOptions} variant="outlined" displayClear />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="winProbability"
            label={t("leasingRequest:winProbability")}
            options={winProbabilityOptions.slice(0, 3)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="incidence"
            label="Incidence"
            options={binaryOptions}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="undecidedClient"
            label="Undecided client"
            options={binaryOptions}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <CountryCodeSelector variant="outlined" displayClear />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="createdReason"
            label="Razón"
            options={leasingRequestCreatedReasonOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BasicInput field="plateNumber" label={t("common:searchPlate")} variant="outlined" />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextSelectInput
            field="negotiationStatus"
            label="Estado negociación"
            options={leasingRequestNegotiationStatusOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
      </Grid>
    </Form>
  )
}

Filter.propTypes = {
  handleFilterAttributes: PropTypes.func.isRequired
}
