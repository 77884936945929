export const palette = {
  stormyBlue: "#547aa6",
  mediumPink: "#ec4e8c",
  hardPink: "#b03a68",
  yellow: "#FFBC00",
  kiwiGreen: "#00B328",
  lightBlue: "#72adf1",
  purple: "#c162f9",
  red: "#dc3545",
  softError: "rgba(255, 0, 0, .1)"
}

export const statusColors = {
  new: "#0028B3",
  scoring: "#194DFF",
  contract: "#0039FF",
  closed: "#B38400",
  not_started: "#0028B3",
  signed: "#FFBB00",
  sent: "#194DFF",
  requested: "#194DFF",
  started: "#0039FF",
  undecided: "#B38400",
  rejected: "#B38400",
  approved: "#FFBB00"
}
