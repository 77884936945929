import React, { useContext } from "react"
import * as PropTypes from "prop-types"
import { Avatar, Grid, IconButton, Tooltip } from "@material-ui/core"
import { Refresh, Tab } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { isEmpty, trim, upperCase } from "lodash"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import LeasingRequestTagList from "../../../LeasingRequestTagList"
import BasicField from "../../../fields/BasicField"
import { BlockSubtitle, BlockTitle } from "../../../styled/typography"
import { CarListDialogContext } from "../../../../contexts"
import { openWindowForSubject } from "../../../../helpers/utils"
import { printDateTime } from "../../../../helpers/formatter"
import useAbilities from "../../../../hooks/useAbilities"
import Menu from "./Menu"

const useStyles = makeStyles(theme => ({
  leftPane: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`
  },
  withSpacing: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  // NOTE: Car image container must be used to fix browser reflows
  //   https://www.codecaptain.io/blog/web-development/responsive-images-and-preventing-page-reflow/474
  carImageContainer: {
    width: "100%",
    position: "relative",
    height: "120px"
  },
  carImage: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "block",
    width: "100%",
    height: "120px",
    "object-fit": "cover"
  },
  interactable: {
    cursor: "pointer"
  },
  alignRight: {
    "margin-left": "auto"
  },
  stamp: {
    color: theme.palette.error.main,
    top: "50%",
    left: "50%",
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -70%) rotate(5deg)",
    "line-height": "1em",
    "margin-bottom": "0px",
    "font-size": "350%",
    "font-weight": "bold",
    "text-align": "center",
    "text-shadow": `1px 1px 2px ${theme.palette.common.black}`,
    "user-select": "none",
    width: "100%"
  },
  stampSubtitle: {
    "font-size": "40%",
    "line-height": "1em"
  }
}))

export default function LeftPane(props) {
  const { leasingRequest, refetch } = props
  const { assignedAt, basicCar, distributor, estimatedDeliveryAt, contractExpiresAt, closedStatus } = leasingRequest
  const { car } = basicCar
  const { t } = useTranslation()
  const { setSelectedCar } = useContext(CarListDialogContext)
  const classes = useStyles()
  const { allowed: readLeasingRequestAllowed } = useAbilities([
    "read_leasing_requests",
    "manage_own_leasing_requests",
    "manage_country_leasing_requests",
    "manage_leasing_requests"
  ])

  const handleSelectCar = () => {
    if (setSelectedCar) setSelectedCar(car.id)
  }

  return (
    <Grid item container direction="column" xs={12} md={5} lg={4} xl={3} className={classes.leftPane}>
      {/* Header */}
      <Grid item container spacing={2} className={classes.withSpacing}>
        <Grid item>
          <Avatar title={assignedAt?.name}>
            {assignedAt && upperCase(trim(assignedAt.name)[0])}
            {!assignedAt && "?"}
          </Avatar>
        </Grid>
        <Grid item className={classes.interactable} onClick={handleSelectCar}>
          <BlockTitle>{`${car.brand} ${car.model}`}</BlockTitle>
          <BlockSubtitle>{car.version}</BlockSubtitle>
        </Grid>
        <Grid item className={classes.alignRight}>
          {readLeasingRequestAllowed && (
            <IconButton onClick={() => openWindowForSubject("LeasingRequest", leasingRequest.id)}>
              <Tab />
            </IconButton>
          )}
          <IconButton onClick={refetch}>
            <Refresh />
          </IconButton>
          <Menu leasingRequest={leasingRequest} refetch={refetch} />
        </Grid>
      </Grid>
      {/* Image */}
      <Grid item className={classNames(classes.interactable, classes.carImageContainer)} onClick={handleSelectCar}>
        <img className={classes.carImage} src={car.defaultImageUrl} />
        {leasingRequest.globalStatus === "closed" && (
          <Tooltip title={leasingRequest.closedReasonComment || "N/A"} placement="bottom">
            <div className={classes.stamp}>
              <>
                {t("leasingRequestGlobalStatus.closed")}
                {!isEmpty(leasingRequest.closedReason) && (
                  <div className={classes.stampSubtitle}>{t(`common:closeReasons.${leasingRequest.closedReason}`)}</div>
                )}
              </>
            </div>
          </Tooltip>
        )}
      </Grid>
      {/* Labels */}
      <Grid item className={classes.withSpacing}>
        <LeasingRequestTagList leasingRequest={leasingRequest} />
      </Grid>
      {/* Fields */}
      <Grid item container className={classes.withSpacing} spacing={1}>
        <Grid item xs={12} md={6}>
          <BasicField title={t("distributor")} value={distributor.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicField
            title={t("plan")}
            value={t("concretePlan", {
              duration: basicCar.duration,
              kmYear: basicCar.kmYear,
              price: basicCar.price
            })}
          />
        </Grid>
        {basicCar.deliveryTime && (
          <Grid item xs={12} md={6}>
            <BasicField
              title={t("deliveryTimeOnCreate")}
              value={t("concreteDeliveryTime", {
                deliveryTimeFrom: basicCar.deliveryTime - 1,
                deliveryTimeUntil: basicCar.deliveryTime + 1
              })}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <BasicField
            title={t("currentCarDeliveryTime")}
            value={t("concreteDeliveryTime", {
              deliveryTimeFrom: basicCar.carDeliveryTime - 1,
              deliveryTimeUntil: basicCar.carDeliveryTime + 1
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicField title={t("color")} value={t(`colors.${basicCar.color}`)} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicField title={t("quantity")} value={leasingRequest.quantity} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicField title={t("assignedAt")} value={assignedAt?.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicField title={t("externalReferenceId")} value={leasingRequest.externalReferenceId} />
        </Grid>
        {closedStatus === "billable" && estimatedDeliveryAt && (
          <Grid item xs={12} md={6}>
            <BasicField
              title={t("leasingRequest:estimatedDeliveryAt")}
              value={printDateTime(estimatedDeliveryAt, "DD/MM/YYYY", true)}
            />
          </Grid>
        )}
        {closedStatus === "billable" && contractExpiresAt && (
          <Grid item xs={12} md={6}>
            <BasicField
              title={t("leasingRequest:contractExpiresAt")}
              value={printDateTime(contractExpiresAt, "DD/MM/YYYY", true)}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <BasicField title={t("createdAt")} value={printDateTime(leasingRequest.createdAt, "DD/MM/YYYY", true)} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicField title={t("updatedAt")} value={printDateTime(leasingRequest.updatedAt, "DD/MM/YYYY", true)} />
        </Grid>
      </Grid>
    </Grid>
  )
}

LeftPane.propTypes = {
  leasingRequest: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
}
