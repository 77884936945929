import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Form } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { camelCase, isNil, upperFirst } from "lodash"
import { Alert } from "@material-ui/lab"
import { ModalPrimaryButton } from "../../styled/buttons"
import CreateLeasingRequestFields from "../inputs/CreateLeasingRequestFields"
import { LeasingRequestCarContext } from "../../../contexts"
import { createLeasingRequestValidationSchema } from "../../../helpers/validators"
import Loading from "../../Loading"
import GraphQLErrors from "../../GraphQLErrors"

const CREATE_LEASING_REQUEST = gql`
  mutation createLeasingRequest($leasingRequestArguments: CreateLeasingRequestInputObject!) {
    createLeasingRequest(input: { leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      firstName
      lastName
      secondLastName
      companyName
      email
      phone
      street
      streetNumber
      floor
      postalCode
      town
      province
      birthday
      documentType
      idNumber
      jobType
      driveLicenseExpeditionDate
      countryCode
      fcaProvince
      fcaBirthProvince
      fcaOccupation
      fcaCivilStatus
      employedSince
      actualMonthlyIncome
      invoiced
      netReturns
      guarantor
      guarantorName
      guarantorEmail
      guarantorIdNumber
      guarantorPhone
      guarantorStreet
      guarantorStreetNumber
      guarantorPostalCode
      guarantorTown
      guarantorProvince
      renewal
    }
  }
`

const CAR = gql`
  query car($id: ID!) {
    car(id: $id) {
      availableForCompany
      availableForEmployee
      availableForFreelance
    }
  }
`

export default function CreateLeasingRequest(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation("lead")
  const { leasingRequestCar } = useContext(LeasingRequestCarContext)
  const { carId, priceId } = leasingRequestCar
  const { data: leadData, loading: leadLoading } = useQuery(LEAD, { variables: { id: leadId } })
  const { data: carData, loading: carLoading } = useQuery(CAR, { variables: { id: carId } })

  const [mutation, { data, loading, error }] = useMutation(CREATE_LEASING_REQUEST, {
    onCompleted: newData => {
      if (newData?.createLeasingRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.createLeasingRequest?.errors

  if (leadLoading || carLoading) return <Loading />
  if (leadData.lead === null || carData.car === null) return <p>Not found</p>

  if (error) return <p>Server Error</p>

  if (isNil(priceId)) return <Alert severity="error">{t("blankPriceId")}</Alert>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestArguments: { ...values, leadId, carId, priceId, mode: values.mode || "offline" } }
    })
  }

  const getPropsForField = (field, defaultValue) => {
    const initialValue = leadData.lead[field] === undefined ? defaultValue : leadData.lead[field]

    return {
      errors,
      field,
      initialValue,
      label: t(`common:${field}`)
    }
  }

  const {
    lead: { jobType }
  } = leadData
  const carAvailable = jobType && carData.car[`availableFor${upperFirst(camelCase(jobType))}`]

  return (
    <Form validationSchema={createLeasingRequestValidationSchema(t)} onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      {carAvailable && (
        <CreateLeasingRequestFields leadId={leadId} getPropsForField={getPropsForField} errors={errors} />
      )}
      {!carAvailable && (
        <Alert severity="error">
          {t("carNotAvailable", { jobType: t(`common:jobTypes.${leadData.lead.jobType || "notDefined"}`) })}
        </Alert>
      )}
      <ModalPrimaryButton isFetching={loading} disabled={!carAvailable}>
        {t("createLeasingRequest")}
      </ModalPrimaryButton>
    </Form>
  )
}

CreateLeasingRequest.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
