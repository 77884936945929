import PropTypes from "prop-types"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import moment from "moment"
import { startCase } from "lodash"
import LabelIcon from "@material-ui/icons/Label"
import { printDateTime } from "../../helpers/formatter"
import Staff from "../../models/staff"
import { Paragraph } from "../styled/typography"

const Small = styled.small`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--stormy-blue);
  margin-bottom: 0;
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const StyledVignetteIcon = styled(LabelIcon)`
  margin-right: 15px;
  color: var(--stormy-blue);
`

const Title = styled.p`
  color: var(--stormy-blue);
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
`

export default function StatusBlock(props) {
  const { item } = props
  const { status, createdAt, endedAt, assignedAtId } = item
  const { staff } = useSelector(mapStateToProps, shallowEqual)
  const staffName = Staff.getName(staff, assignedAtId)

  return (
    <>
      <StyledGrid item xs={12}>
        <StyledVignetteIcon fontSize="large" />
        <div>
          <Title>{startCase(status)}</Title>
          <Paragraph>{`Ended at ${printDateTime(endedAt)}`}</Paragraph>
          <Paragraph>{`Created at ${printDateTime(createdAt)}`}</Paragraph>
        </div>
      </StyledGrid>
      <Small>
        {`${printDateTime(createdAt)} ${moment(createdAt).fromNow()}`}
        &nbsp;
        <b>{staffName || "Anonymous"}</b>
      </Small>
    </>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff } = commonDataReducer

  return { staff: staff.items }
}

StatusBlock.propTypes = {
  item: PropTypes.object.isRequired
}
