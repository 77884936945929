import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import IconButton from "@material-ui/core/IconButton"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import Grid from "@material-ui/core/Grid"
import { Paragraph } from "../../../../components/styled/typography"
import { printDateTime } from "../../../../helpers/formatter"
import { openWindowForSubject } from "../../../../helpers/utils"
import { DashboardStyledTableCell, DashboardStyledTableContainer } from "../../../../components/styled/tables"

const COMPLETED_TASKS_QUERY = gql`
  query account {
    account {
      completedTasks {
        id
        identifier
        subjectId
        subjectType
        subjectTitle
        completedAt
      }
    }
  }
`

export default function CompletedTasks() {
  const { t } = useTranslation("dashboard")
  const { data } = useQuery(COMPLETED_TASKS_QUERY)

  const completedTasks = data?.account?.completedTasks || []

  return (
    <Grid item lg={8} xl={6}>
      <DashboardStyledTableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <DashboardStyledTableCell colSpan={4}>{t("completedTasks")}</DashboardStyledTableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell>{t("common:subject")}</TableCell>
              <TableCell>{t("common:completedAt")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {completedTasks.map(task => (
              <TableRow key={task.id} hover>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={event => {
                      event.stopPropagation()
                      openWindowForSubject(task.subjectType, task.subjectId)
                    }}>
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{t(`common:task.${task.identifier}`)}</TableCell>
                <TableCell>{task.subjectTitle}</TableCell>
                <TableCell align="left">
                  <Paragraph>{printDateTime(task.completedAt)}</Paragraph>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DashboardStyledTableContainer>
    </Grid>
  )
}
