import PropTypes from "prop-types"
import React, { useContext } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Assignment, Call, WhatsApp } from "@material-ui/icons"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import { DefaultButton } from "../../../../../components/styled/buttons"
import { TwilioContext } from "../../../../../contexts"
import useAbilities from "../../../../../hooks/useAbilities"

const CALL_FROM_PHONE = gql`
  mutation callFromPhone($callFromPhoneArguments: CallFromPhoneInputObject!) {
    callFromPhone(input: { callFromPhoneArguments: $callFromPhoneArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const Wrapper = styled(Grid)`
  margin: 15px 0;
`

export default function PrimaryActions(props) {
  const { leasingRequestId, leasingRequestTitle, setOpenModal } = props
  const { t } = useTranslation()
  const { connect } = useContext(TwilioContext)
  const { allowed } = useAbilities("call_from_phone")
  const { everythingReady, inCall, accountId } = useSelector(mapStateToProps, shallowEqual)
  const [callFromPhone] = useMutation(CALL_FROM_PHONE)

  const handleCallLeasingRequest = () => {
    connect(leasingRequestId, leasingRequestTitle, "LeasingRequest", accountId)
  }

  const handleCallFromPhone = () => {
    callFromPhone({
      variables: { callFromPhoneArguments: { calleeEntityId: leasingRequestId, calleeEntityType: "LeasingRequest" } }
    })
  }

  return (
    <Wrapper item xs={12}>
      <DefaultButton
        onClick={handleCallLeasingRequest}
        disabled={!everythingReady || inCall}
        variant="contained"
        size="large"
        color="primary"
        startIcon={<Call />}
      >
        {t("callLead")}
      </DefaultButton>
      {allowed && (
        <DefaultButton
          onClick={handleCallFromPhone}
          variant="contained"
          size="large"
          color="primary"
          startIcon={<Call />}
        >
          {t("callLeadFromPhone")}
        </DefaultButton>
      )}
      <DefaultButton
        onClick={() => setOpenModal("scheduleCall")}
        variant="contained"
        size="large"
        color="primary"
        startIcon={<Assignment />}
      >
        {t("scheduleACall")}
      </DefaultButton>
      <DefaultButton
        onClick={() => setOpenModal("sendWhatsappTemplate")}
        variant="contained"
        size="large"
        color="primary"
        startIcon={<WhatsApp />}
      >
        {t("leasingRequest:sendWhatsappTemplate")}
      </DefaultButton>
    </Wrapper>
  )
}

const mapStateToProps = state => {
  const { accountReducer, callReducer } = state
  const { inCall, everythingReady } = callReducer
  const { item } = accountReducer

  return {
    accountId: item && item.id,
    everythingReady,
    inCall
  }
}

PrimaryActions.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  leasingRequestTitle: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired
}
