import PropTypes from "prop-types"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { isNil } from "lodash"
import Comment from "../../../../../components/Comment"
import Loading from "../../../../../components/Loading"

const StyledGrid = styled(Grid)`
  padding: 1px;
`

// TODO Think about using fragments https://www.apollographql.com/docs/react/v3.0-beta/data/fragments/
const LEASING_REQUEST_COMMENTS = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      comments {
        id
        content
        commentableId
        commentableType
        commercialDetails
        createdById
        createdAt
      }
    }
  }
`

export default function Feed(props) {
  const { leasingRequestId } = props
  const { loading, data } = useQuery(LEASING_REQUEST_COMMENTS, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  return (
    <StyledGrid container>
      {data.leasingRequest.comments.map(comment => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </StyledGrid>
  )
}

Feed.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
