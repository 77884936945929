import PropTypes from "prop-types"
import React from "react"
import { find } from "lodash"
import { Text, useFormApi } from "informed"
import TextSelectInput from "../../TextSelectInput"

export const FCA_PROVINCE_OPTIONS = [
  { value: "EE", label: "Paises Extranjeros" },
  { value: "VI", label: "Álava" },
  { value: "AB", label: "Albacete" },
  { value: "A", label: "Alicante" },
  { value: "AL", label: "Almería" },
  { value: "AV", label: "Ávila" },
  { value: "BA", label: "Badajoz" },
  { value: "IB", label: "Baleares" },
  { value: "B", label: "Barcelona" },
  { value: "BU", label: "Burgos" },
  { value: "CC", label: "Cáceres" },
  { value: "CA", label: "Cadiz" },
  { value: "CS", label: "Castellón" },
  { value: "CE", label: "Ceuta" },
  { value: "CR", label: "Ciudad Real" },
  { value: "CO", label: "Córdoba" },
  { value: "CU", label: "Cuenca" },
  { value: "GI", label: "Gerona" },
  { value: "GR", label: "Granada" },
  { value: "GU", label: "Guadalajara" },
  { value: "SS", label: "Guipúzcoa" },
  { value: "H", label: "Huelva" },
  { value: "HU", label: "Huesca" },
  { value: "J", label: "Jaén" },
  { value: "C", label: "La Coruña" },
  { value: "LO", label: "La Rioja" },
  { value: "GC", label: "Las Palmas" },
  { value: "LE", label: "León" },
  { value: "L", label: "Lérida" },
  { value: "LU", label: "Lugo" },
  { value: "M", label: "Madrid" },
  { value: "MA", label: "Málaga" },
  { value: "ML", label: "Melilla" },
  { value: "MU", label: "Murcia" },
  { value: "NA", label: "Navarra" },
  { value: "OR", label: "Orense" },
  { value: "O", label: "Oviedo" },
  { value: "P", label: "Palencia" },
  { value: "PO", label: "Pontevedra" },
  { value: "SH", label: "Sáhara Occidental" },
  { value: "SA", label: "Salamanca" },
  { value: "S", label: "Santander" },
  { value: "SG", label: "Segovia" },
  { value: "SE", label: "Sevilla" },
  { value: "SO", label: "Soria" },
  { value: "TF", label: "Sta.Cruz de Tenerife" },
  { value: "T", label: "Tarragona" },
  { value: "TE", label: "Teruel" },
  { value: "TO", label: "Toledo" },
  { value: "V", label: "Valencia" },
  { value: "VA", label: "Valladolid" },
  { value: "BI", label: "Vizcaya" },
  { value: "ZA", label: "Zamora" },
  { value: "Z", label: "Zaragoza" }
]

export default function ProvincesInput(props) {
  const { initialValue, field, secondaryField, label, errors, variant } = props
  const formApi = useFormApi()

  const selected = find(FCA_PROVINCE_OPTIONS, { value: initialValue }) || {}

  const handleChange = e => {
    const { value } = e.target
    const current = find(FCA_PROVINCE_OPTIONS, { value }) || {}
    formApi.setValue(secondaryField, current.label)
  }

  return (
    <>
      <TextSelectInput
        field={field}
        label={label}
        initialValue={selected.value}
        errors={errors}
        options={FCA_PROVINCE_OPTIONS}
        variant={variant}
        onChange={handleChange}
        required
      />
      <Text field={secondaryField} initialValue={selected.label} hidden />
    </>
  )
}

ProvincesInput.propTypes = {
  errors: PropTypes.array,
  field: PropTypes.string.isRequired,
  secondaryField: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string
}

ProvincesInput.defaultProps = {
  errors: null,
  initialValue: null,
  variant: "standard"
}
