import PropTypes from "prop-types"
import React from "react"
import { Form, Text } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import { Paragraph } from "../../styled/typography"

const START_ONLINE_SCORING = gql`
  mutation startOnlineScoring($leasingRequestId: ID!, $leasingRequestArguments: StartOnlineScoringInputObject!) {
    startOnlineScoring(
      input: { leasingRequestId: $leasingRequestId, leasingRequestArguments: $leasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function StartOnlineScoring(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation()
  const [mutation, { data, loading, error }] = useMutation(START_ONLINE_SCORING, {
    onCompleted: newData => {
      if (newData.startOnlineScoring?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.startOnlineScoring?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, leasingRequestArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <Paragraph>{t("startOnlineScoringExplanation")}</Paragraph>
      <Text type="hidden" field="instantorTransactionId" initialValue="0" />
      <ModalPrimaryButton isFetching={loading}>{t("startOnlineScoring")}</ModalPrimaryButton>
    </Form>
  )
}

StartOnlineScoring.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
