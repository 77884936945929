import { find } from "lodash"

export default class Staff {
  constructor(user) {
    Object.assign(this, user)
  }

  static getName(users, id) {
    const user = find(users, { id })

    return user && user.name
  }
}
