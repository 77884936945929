import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { StyledLabel } from "../../styled/typography"
import Staff from "../../../models/staff"
import { Text } from "../BasicField"

export default function StaffField(props) {
  const { value, title } = props
  const { staff } = useSelector(mapStateToProps, shallowEqual)

  const text = Staff.getName(staff, value)
  const valueHtml = <Text>{text || "Anonymous"}</Text>

  if (title)
    return (
      <div>
        <StyledLabel>{title}</StyledLabel>
        {valueHtml}
      </div>
    )

  return valueHtml
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff } = commonDataReducer

  return { staff: staff.items }
}
