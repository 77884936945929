import React from "react"

export default function NothingDashboard() {
  return (
    <p>
      No tienes un dashboard asignado, solo Sales, Backoffice, Customer Care y Sales closers tienen dashboards
      actualmente, si crees que es un error comentalo en #runtime-issues
    </p>
  )
}
