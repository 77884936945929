import React, { useContext, useRef, useState } from "react"
import PropTypes from "prop-types"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import CallIcon from "@material-ui/icons/Call"
import PauseIcon from "@material-ui/icons/Pause"
import IconButton from "@material-ui/core/IconButton"
import styled from "styled-components"
import { gql, useLazyQuery } from "@apollo/client"
import { shallowEqual, useSelector } from "react-redux"
import { useBeforeunload } from "react-beforeunload"
import { useTranslation } from "react-i18next"
import LogRocket from "logrocket"
import { ActionCableContext, TwilioContext } from "../../contexts"
import FormDialog from "../FormDialog"
import { Paragraph } from "../styled/typography"
import { ModalPrimaryButton } from "../styled/buttons"
import useUnload from "../../hooks/useUnload"

const PlayIconStyled = styled(PlayArrowIcon)`
  border-radius: 100%;
  backface-visibility: hidden;
  background-color: var(--warm-red);
  color: var(--white);
  margin: 0;
`

const PauseIconStyled = styled(PauseIcon)`
  color: var(--kiwi-green);
`

const IconButtonStyled = styled(IconButton)`
  color: var(--white);
`

const CallIconStyled = styled(CallIcon)`
  color: var(--kiwi-green);
`

const TWILIO_TOKEN = gql`
  query account {
    account {
      twilioToken
    }
  }
`

const Modal = props => {
  const { show, closeModal, acceptModal, ready } = props
  const { t } = useTranslation()

  const onSubmit = () => {
    acceptModal()
    closeModal()
  }

  return (
    <FormDialog title={t("areYouSure")} handleClose={closeModal} open={show}>
      {show && <Paragraph>{ready ? t("disconnectTwilioConfirmation") : t("connectTwilioConfirmation")}</Paragraph>}
      <ModalPrimaryButton isFetching={!show} onClick={onSubmit}>
        {t("yes")}
      </ModalPrimaryButton>
    </FormDialog>
  )
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  acceptModal: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired
}

export default function ActiveTwilio() {
  const { manualCanReceiveACall, inCall, twilioDeviceReady } = useSelector(mapStateToProps, shallowEqual)
  const { subscribeWorkingChannel, sendWorkingMessage, unsubscribeWorkingChannel } = useContext(ActionCableContext)
  const closedDuringCall = useRef(false)
  const { setupDevice, destroyDevice } = useContext(TwilioContext)

  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const [getTwilioToken] = useLazyQuery(TWILIO_TOKEN, {
    onCompleted: twilioTokenData =>
      setupDevice(twilioTokenData.account.twilioToken, {
        enableRingingState: true,
        debug: process.env.REACT_APP_ENVIRONMENT === "development" || window.twilioDebug || false,
        warnings: process.env.REACT_APP_ENVIRONMENT === "development" || window.twilioDebug || false,
        edge: "dublin"
      })
  })

  useBeforeunload(event => {
    closedDuringCall.current = twilioDeviceReady && inCall

    if (twilioDeviceReady) {
      event.preventDefault()
      event.returnValue = "" // eslint-disable-line no-param-reassign

      return t("disconnectTwilioConfirmation")
    }

    return false
  })

  useUnload(() => {
    tryPunish()
  })

  const tryPunish = () => {
    if (closedDuringCall.current) {
      LogRocket.track("punish")
      sendWorkingMessage("punish")
    }
  }

  const everyThingReady = twilioDeviceReady && manualCanReceiveACall

  const toggleActive = () => {
    closedDuringCall.current = twilioDeviceReady && inCall

    if (!twilioDeviceReady) {
      subscribeWorkingChannel()
      getTwilioToken()
    } else {
      tryPunish()
      destroyDevice()
      unsubscribeWorkingChannel()
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        closeModal={() => setShowModal(false)}
        acceptModal={toggleActive}
        ready={twilioDeviceReady}
      />
      <IconButtonStyled onClick={() => setShowModal(true)} disabled={inCall}>
        {inCall && <CallIconStyled />}
        {!inCall && everyThingReady && <PauseIconStyled />}
        {!inCall && !everyThingReady && <PlayIconStyled />}
      </IconButtonStyled>
    </>
  )
}

const mapStateToProps = state => {
  const { callReducer } = state
  const { twilioDeviceReady, manualCanReceiveACall, inCall, everyThingReady } = callReducer

  return { twilioDeviceReady, manualCanReceiveACall, inCall, everyThingReady }
}
