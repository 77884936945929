import * as PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import * as Yup from "yup"
import BasicInput from "../inputs/BasicInput"
import GraphQLErrors from "../../GraphQLErrors"
import { ModalPrimaryButton } from "../../styled/buttons"
import TextSelectInput from "../inputs/TextSelectInput"
import { leasingRequestCloseReasonsOptions, leasingRequestPostponedDaysOptions } from "../../../helpers/constants"

const CLOSE_LEASING_REQUEST = gql`
  mutation closeLeasingRequest($leasingRequestId: ID!, $leasingRequestArguments: CloseLeasingRequestInputObject!) {
    closeLeasingRequest(
      input: { leasingRequestId: $leasingRequestId, leasingRequestArguments: $leasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function CloseLeasingRequest(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(CLOSE_LEASING_REQUEST, {
    onCompleted: newData => {
      if (newData.closeLeasingRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.closeLeasingRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, leasingRequestArguments: values }
    })
  }

  const validationSchema = Yup.object().shape({
    closedReason: Yup.string().required(t("common:required")),
    closedReasonComment: Yup.string().trim().required(t("common:required")),
    postponedDays: Yup.string().required(t("common:required"))
  })

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} />
          <TextSelectInput
            field="closedReason"
            label={t("common:closeReason")}
            options={leasingRequestCloseReasonsOptions(t)}
            errors={errors}
          />
          {formState.values.closedReason === "postponed" && (
            <TextSelectInput
              field="postponedDays"
              label={t("postponedDays")}
              options={leasingRequestPostponedDaysOptions(t)}
              required
              errors={errors}
            />
          )}
          <BasicInput field="closedReasonComment" label={t("common:closeReasonComment")} required errors={errors} />
          <ModalPrimaryButton isFetching={loading}>{t("close")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

CloseLeasingRequest.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
