import React from "react"
import PropTypes from "prop-types"
import { gql, useQuery } from "@apollo/client"
import { Alert } from "@material-ui/lab"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { EmailContext } from "../../../contexts"
import Loading from "../../Loading"
import SendEmail from "../SendEmail"

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      locale
    }
  }
`

const SEND_EMAIL = gql`
  mutation sendEmailToLead($resourceId: ID!, $emailArguments: SendEmailToLeadInputObject!) {
    sendEmailToLead(input: { leadId: $resourceId, emailArguments: $emailArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function SendLeadEmail(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation("lead")
  const { loading, data } = useQuery(LEAD, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (data.lead === null) return <p>Not found</p>

  return (
    <EmailContext.Provider
      value={{
        gqlMutation: SEND_EMAIL,
        resourceId: leadId,
        resource: "Lead",
        locale: data.lead.locale
      }}>
      <Alert severity="warning">{t("favorReplyOverSend")}</Alert>
      <Grid item xs={12}>
        <SendEmail handleSuccess={handleSuccess} />
      </Grid>
    </EmailContext.Provider>
  )
}

SendLeadEmail.propTypes = {
  leadId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired
}
