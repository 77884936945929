import React from "react"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import { Link } from "react-router-dom"
import styled from "styled-components"
import * as PropTypes from "prop-types"

const StyledBreadcrumbs = styled(Breadcrumbs)`
  background-color: transparent;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  margin-bottom: 25px;
  margin-left: -15px;
  margin-right: -15px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  & span,
  a {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
`

export default function Breadcrumb(props) {
  const { breadcrumbItems } = props
  const items = breadcrumbItems.length

  return (
    <StyledBreadcrumbs>
      {items > 1 &&
        breadcrumbItems.map(bt => {
          const content =
            !bt.active && bt.link ? (
              <Link to={bt.link} onClick={bt.handleOnClick}>
                {bt.label}
              </Link>
            ) : (
              bt.label
            )
          return <span key={bt.label}>{content}</span>
        })}
    </StyledBreadcrumbs>
  )
}

Breadcrumb.defaultProps = {
  breadcrumbItems: []
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array
}
