import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import * as PropTypes from "prop-types"
import classNames from "classnames"
import { Divider, Grid, Tooltip } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { ExpandMore, HelpOutline } from "@material-ui/icons"
import { green, red } from "@material-ui/core/colors"
import styled from "styled-components"
import moment from "moment"
import StepIcon from "../StepIcon"
import { Paragraph } from "../../../../../styled/typography"
import { printDateTime } from "../../../../../../helpers/formatter"
import { GridSizesContext } from "../../../../../../contexts"

const arrowSize = {
  width: "20px",
  height: "50px"
}

// NOTE: For more information on how triangles are rendered, check this article:
//   https://css-tricks.com/triangle-breadcrumbs/
const useStyles = makeStyles(theme => ({
  box: {
    position: "relative",
    height: "100%",
    "min-height": "240px",
    "box-shadow": theme.shadows[1],
    "font-size": "90%",
    "text-align": "center"
  },
  content: {
    margin: `${theme.spacing(2)}px 0`
  },
  boxContent: {
    height: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
  },
  fullWidth: {
    width: "100%"
  },
  leftArrow: {
    "&:before": {
      position: "absolute",
      display: "block",
      top: `calc(50% - ${arrowSize.height})`,
      left: "-2px",
      width: 0,
      height: 0,
      content: "' '",
      "border-left": `${arrowSize.width} solid ${theme.palette.background.paper}`,
      "border-top": `${arrowSize.height} solid transparent`,
      "border-bottom": `${arrowSize.height} solid transparent`
    }
  },
  rightArrow: {
    "&:after": {
      position: "absolute",
      display: "block",
      top: `calc(50% - ${arrowSize.height})`,
      right: `-${arrowSize.width}`,
      width: 0,
      height: 0,
      content: "' '",
      "z-index": 1,
      "border-left": `${arrowSize.width} solid red`,
      "border-top": `${arrowSize.height} solid transparent`,
      "border-bottom": `${arrowSize.height} solid transparent`
    }
  },
  // Color variants
  default: {
    "background-color": theme.palette.grey[200],
    "&:after": {
      "border-left-color": theme.palette.grey[200]
    }
  },
  current: {
    "background-color": "var(--lighterblue)",
    "&:after": {
      "border-left-color": "var(--lighterblue)"
    }
  },
  completed: {
    "background-color": green[50],
    "&:after": {
      "border-left-color": green[50]
    }
  },
  cancelled: {
    "background-color": red[100],
    "&:after": {
      "border-left-color": red[100]
    }
  }
}))

const StyledTitle = styled(Paragraph)`
  font-size: 120%;
  font-weight: 600;
`

const StyledArrow = styled(ExpandMore)`
  margin: -5px auto;
`

const StyledDivider = styled(Divider)`
  margin: 5px auto;
`

const DateRange = props => {
  const { from, to } = props
  const { t } = useTranslation()

  const fromDate = from && moment(from)
  const toDate = to && moment(to)
  const fromToDifference = from && to && moment.duration(toDate.diff(fromDate)).humanize()
  const fromIsToday = from && fromDate >= moment().startOf("day")
  const toIsToday = to && toDate >= moment().startOf("day")

  if (!fromDate && !toDate) return null

  return (
    <>
      <StyledDivider />
      {from && (
        <Tooltip title={fromDate.fromNow()}>
          <Paragraph>
            {printDateTime(from, "HH:mm DD/MM/YYYY")}
            {fromIsToday && <small> ({t("today")})</small>}
          </Paragraph>
        </Tooltip>
      )}
      {to && <StyledArrow />}
      {to && (
        <Tooltip title={toDate.fromNow()}>
          <Paragraph>
            {printDateTime(to, "HH:mm DD/MM/YYYY")}
            {toIsToday && <small> ({t("today")})</small>}
          </Paragraph>
        </Tooltip>
      )}
      {fromToDifference && (
        <Paragraph>
          <small>({fromToDifference})</small>
        </Paragraph>
      )}
    </>
  )
}

DateRange.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string
}

DateRange.defaultProps = {
  from: null,
  to: null
}

export default function Step(props) {
  const { children, variant, leftArrow, rightArrow, startedAt, endedAt, title } = props
  const classes = useStyles()
  const gridSizes = useContext(GridSizesContext)

  const hideContent = variant === "default"

  return (
    <Grid item {...gridSizes}>
      <div
        className={classNames(classes.box, classes[variant], {
          [classes.leftArrow]: leftArrow,
          [classes.rightArrow]: rightArrow
        })}>
        <Grid container direction="column" justify="space-between" alignItems="stretch" className={classes.boxContent}>
          <Grid item className={classes.fullWidth}>
            <StyledTitle>{title}</StyledTitle>
          </Grid>
          <Grid item className={classNames(classes.fullWidth, classes.content)}>
            {!hideContent && children}
            {hideContent && <StepIcon icon={HelpOutline} />}
          </Grid>
          <Grid item className={classes.fullWidth}>
            {!hideContent && <DateRange from={startedAt} to={endedAt} />}
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

Step.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  leftArrow: PropTypes.bool,
  rightArrow: PropTypes.bool,
  startedAt: PropTypes.string,
  endedAt: PropTypes.string
}

Step.defaultProps = {
  children: null,
  variant: "default",
  leftArrow: false,
  rightArrow: false,
  startedAt: null,
  endedAt: null
}
