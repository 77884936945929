import { getAuth } from "../services/accountService"

export function exportLeads(filters, success, error) {
  fetch(`${process.env.REACT_APP_API_URL}leads?filters=${JSON.stringify(filters)}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${getAuth("client")}`
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(responseJson => {
          success()
        })
      } else if (response.status !== 401) {
        error()
      }
    })
    .catch(error => {
      console.error(error)
    })
}
