import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import StaffSelector from "../inputs/StaffSelector"

const REASSIGN_LEASING_REQUEST_CREATOR = gql`
  mutation reassignLeasingRequestCreator(
    $id: ID!
    $leasingRequestArguments: ReassignLeasingRequestCreatorInputObject!
  ) {
    reassignLeasingRequestCreator(input: { leasingRequestId: $id, leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function ReassignLeasingRequestCreator(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(REASSIGN_LEASING_REQUEST_CREATOR, {
    onCompleted: newData => {
      if (newData.reassignLeasingRequestCreator?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.reassignLeasingRequestCreator?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leasingRequestId, leasingRequestArguments: values }
    })
  }

  const validationSchema = Yup.object().shape({
    createdById: Yup.string().required(t("common:required"))
  })

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <GraphQLErrors errors={errors} />
      <StaffSelector field="createdById" label={t("common:createdBy")} />
      <ModalPrimaryButton isFetching={loading}>{t("common:reassignCreator")}</ModalPrimaryButton>
    </Form>
  )
}

ReassignLeasingRequestCreator.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
