import * as PropTypes from "prop-types"
import React, { useState } from "react"
import { Form } from "informed"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import * as Yup from "yup"
import { omit } from "lodash"
import TextSelectInput from "../inputs/TextSelectInput"
import GraphQLErrors from "../../GraphQLErrors"
import { ModalPrimaryButton } from "../../styled/buttons"

const CLOSE_LEAD = gql`
  mutation closeLead($leadId: ID!, $leadArguments: CloseLeadInputObject!) {
    closeLead(input: { leadId: $leadId, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const DELAY_LEAD = gql`
  mutation delayLead($leadId: ID!, $leadArguments: DelayLeadNewOpportunityInputObject!) {
    delayLeadNewOpportunity(input: { leadId: $leadId, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const closedReasonOptions = t => [
  { value: "information_only", label: t("closeReasonOptions.information_only") },
  { value: "personal_issues", label: t("closeReasonOptions.personal_issues") },
  { value: "do_not_have_guarantee", label: t("closeReasonOptions.do_not_have_guarantee") },
  { value: "do_not_have_extra_documentation", label: t("closeReasonOptions.do_not_have_extra_documentation") },
  { value: "payment_capacity", label: t("closeReasonOptions.payment_capacity") },
  { value: "incidence", label: t("closeReasonOptions.incidence") },
  { value: "competitor", label: t("closeReasonOptions.competitor") },
  { value: "no_stock", label: t("closeReasonOptions.no_stock") },
  { value: "unsuitable_offer", label: t("closeReasonOptions.unsuitable_offer") },
  { value: "duplicated", label: t("closeReasonOptions.duplicated") },
  { value: "distrust", label: t("closeReasonOptions.distrust") },
  { value: "do_not_answer", label: t("closeReasonOptions.do_not_answer") },
  { value: "delayed", label: t("closeReasonOptions.delayed") }
]

const closedReasonSubcategoryOptions = {
  competitor: t => [
    { disabled: true, value: "renting", label: t("closedReasonSubcategoryOptions.renting") },
    { value: "arval", label: t("closedReasonSubcategoryOptions.arval") },
    { value: "renting_finders", label: t("closedReasonSubcategoryOptions.renting_finders") },
    { value: "idoneo", label: t("closedReasonSubcategoryOptions.idoneo") },
    { value: "amovens", label: t("closedReasonSubcategoryOptions.amovens") },
    { value: "vamos", label: t("closedReasonSubcategoryOptions.vamos") },
    { value: "destiners", label: t("closedReasonSubcategoryOptions.destiners") },
    { value: "other_broker", label: t("closedReasonSubcategoryOptions.other_broker") },
    { value: "renting_car_dealership", label: t("closedReasonSubcategoryOptions.renting_car_dealership") },
    { value: "renting_bank", label: t("closedReasonSubcategoryOptions.renting_bank") },
    { value: "renting_finance", label: t("closedReasonSubcategoryOptions.renting_finance") },
    { disabled: true, value: "purchase", label: t("closedReasonSubcategoryOptions.purchase") },
    { value: "purchase_car_dealership", label: t("closedReasonSubcategoryOptions.purchase_car_dealership") },
    { value: "purchase_car_dealership_used", label: t("closedReasonSubcategoryOptions.purchase_car_dealership_used") },
    { value: "purchase_web", label: t("closedReasonSubcategoryOptions.purchase_web") },
    { value: "purchase_web_used", label: t("closedReasonSubcategoryOptions.purchase_web_used") }
  ],
  unsuitable_offer: t => [
    { value: "price", label: t("closedReasonSubcategoryOptions.price") },
    { value: "delivery_time", label: t("closedReasonSubcategoryOptions.delivery_time") },
    { value: "delivery_address", label: t("closedReasonSubcategoryOptions.delivery_address") },
    { value: "finance", label: t("closedReasonSubcategoryOptions.finance") },
    { value: "duration_to_short", label: t("closedReasonSubcategoryOptions.duration_to_short") }
  ]
}

const delayTimeOptions = t => [
  { value: 15, label: t("day", { count: 15 }) },
  { value: 30, label: t("month", { count: 1 }) },
  { value: 90, label: t("month", { count: 3 }) },
  { value: 150, label: t("month", { count: 5 }) }
]

const filterLeadMutationArguments = (currentAction, values) => {
  if (currentAction === "closeLead") return values

  return omit(values, "closedReason")
}

export default function CloseLead(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation()
  const [currentAction, setCurrentAction] = useState("closeLead")

  const commonMutationOptions = {
    onCompleted: newData => {
      if (newData[currentAction]?.errors == null) {
        handleSuccess()
      }
    }
  }

  const mutations = {
    closeLead: useMutation(CLOSE_LEAD, commonMutationOptions),
    delayLeadNewOpportunity: useMutation(DELAY_LEAD, commonMutationOptions)
  }

  const [mutation, { loading, data, error }] = mutations[currentAction]

  const updateAction = event => {
    switch (event.target.value) {
      case "delayed":
        setCurrentAction("delayLeadNewOpportunity")
        break
      default:
        setCurrentAction("closeLead")
    }
  }

  const errors = data?.[currentAction]?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leadId, leadArguments: filterLeadMutationArguments(currentAction, values) }
    })
  }

  const validationSchema = Yup.object().shape({
    delayTime: Yup.number().required(t("required")),
    closedReason: Yup.string().required(t("required")),
    closedReasonSubcategory: Yup.string().required(t("required")),
    leadId: Yup.string().required(t("required"))
  })

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} />
          <TextSelectInput
            field="closedReason"
            label={t("closeReason")}
            options={closedReasonOptions(t)}
            errors={errors}
            onChange={updateAction}
            disabled={loading}
          />
          {currentAction === "closeLead" && closedReasonSubcategoryOptions[formState.values.closedReason] && (
            <TextSelectInput
              field="closedReasonSubcategory"
              label={t("closedReasonSubcategory")}
              options={closedReasonSubcategoryOptions[formState.values.closedReason](t)}
              errors={errors}
              disabled={loading}
            />
          )}
          {currentAction === "delayLeadNewOpportunity" && (
            <TextSelectInput field="delayTime" label={t("delayTime")} options={delayTimeOptions(t)} required />
          )}
          <ModalPrimaryButton isFetching={loading}>{t("lead:close")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

CloseLead.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
