import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import TabIcon from "@material-ui/icons/Tab"
import IconButton from "@material-ui/core/IconButton"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const Wrapper = styled(Grid)`
  height: 40px;
`

export const Text = styled.p`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};
  font-size: 16px;
  vertical-align: center;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 0;
  margin-right: 5px;
`

export default function LinkField(props) {
  const { url, title, target } = props
  const externalUrl = /^https?:\/\//.test(url)

  return (
    <Wrapper container alignItems="center">
      <Grid item>
        <Text>
          {externalUrl && (
            <a href={url} target="_blank" rel="noreferrer">
              {title}
            </a>
          )}
          {!externalUrl && (
            <Link to={url} target={target}>
              {title}
            </Link>
          )}
        </Text>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={event => {
            event.stopPropagation()
            return externalUrl ? window.open(url) : window.open(`#${url}`)
          }}
        >
          <TabIcon />
        </IconButton>
      </Grid>
    </Wrapper>
  )
}

LinkField.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string
}

LinkField.defaultProps = {
  target: "_self"
}
