import React from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { compact, includes, join } from "lodash"
import { Check, Close, Comment, HelpOutline } from "@material-ui/icons"
import { Tooltip } from "@material-ui/core"
import styled from "styled-components"
import Step from "../Step"
import StepIcon from "../StepIcon"
import { Paragraph } from "../../../../../styled/typography"
import { lastStatusDate } from "../../../../../../helpers/utils"

const StatusTitle = styled(Paragraph)`
  font-weight: bold;
  font-size: 130%;
`

const ScoringComment = styled(Comment)`
  font-size: 300%;
  color: var(--stormy-blue);
`

const calculateVariant = scoringStatus => {
  if (includes(["not_started", "started"], scoringStatus)) return "default"
  if (scoringStatus === "approved") return "completed"
  if (scoringStatus === "rejected") return "cancelled"

  return "current"
}

export default function ScoringResult(props) {
  const { leasingRequest } = props
  const {
    statuses,
    scoringStatus,
    scoringCompletedAt,
    scoringComments,
    deposit,
    downPayment,
    guarantor,
    guarantorName,
    guarantorEmail,
    guarantorIdNumber,
    conditions
  } = leasingRequest
  const { t } = useTranslation("leasingRequest")

  const variant = calculateVariant(scoringStatus)
  const guarantorTitle = join(compact([guarantorName, guarantorEmail, guarantorIdNumber]), ", ")
  const endedAt = lastStatusDate(statuses, "contract")

  return (
    <Step
      title={t("flowSteps.scoringResult")}
      variant={variant}
      startedAt={scoringCompletedAt}
      endedAt={endedAt}
      leftArrow
      rightArrow>
      <StatusTitle>{t(`common:scoringStatus.${scoringStatus}`)}</StatusTitle>
      {scoringStatus === "approved" && <StepIcon icon={Check} variant={variant} />}
      {scoringStatus === "rejected" && <StepIcon icon={Close} variant={variant} />}
      {scoringStatus === "undecided" && <StepIcon icon={HelpOutline} variant={variant} />}
      {scoringComments && (
        <Tooltip title={scoringComments} placement="bottom-start" arrow>
          <ScoringComment />
        </Tooltip>
      )}
      {scoringStatus === "approved" && (
        <>
          {conditions && (
            <>
              {guarantor && (
                <Tooltip title={guarantorTitle} placement="bottom-start" arrow>
                  <Paragraph>{t("common:withGuarantor")}</Paragraph>
                </Tooltip>
              )}
              {!guarantor && <Paragraph>{t("common:withoutGuarantor")}</Paragraph>}
              {downPayment > 0 && <Paragraph>{t("common:withDownPayment", { price: downPayment })}</Paragraph>}
              {!(downPayment > 0) && <Paragraph>{t("common:withoutDownPayment")}</Paragraph>}
              {deposit > 0 && <Paragraph>{t("common:withDeposit", { price: deposit })}</Paragraph>}
              {!(deposit > 0) && <Paragraph>{t("common:withoutDeposit")}</Paragraph>}
            </>
          )}
          {!conditions && <Paragraph>{t("common:withoutConditions")}</Paragraph>}
        </>
      )}
    </Step>
  )
}

ScoringResult.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
