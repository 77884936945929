import React from "react"
import classNames from "classnames"
import { shallowEqual, useSelector } from "react-redux"
import makeStyles from "@material-ui/styles/makeStyles/makeStyles"
import * as PropTypes from "prop-types"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import NavBar from "../NavBar"
import LeftMenu from "../LeftMenu"
import Notifications from "../../Notifications"
import ActiveCall from "../../ActiveCall"
import Account from "./Account"

const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  signedInMainContentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  }
}))

const StyledMain = styled.main`
  margin-top: 64px;
  flex-grow: 1;
  padding: 0 15px 0px 15px;
`

export default function SignedIn(props) {
  const { children, leftMenu, activeCall } = props
  const classes = useStyles()
  const { leftMenuOpen } = useSelector(mapStateToProps, shallowEqual)

  return (
    <Account>
      <NavBar leftMenu={leftMenu} />
      {leftMenu && <LeftMenu />}
      <StyledMain
        className={classNames({
          [classes.signedInMainContentShift]: leftMenu && leftMenuOpen
        })}
      >
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </StyledMain>
      <Notifications />
      {activeCall && <ActiveCall />}
    </Account>
  )
}

const mapStateToProps = state => {
  const { accountReducer } = state
  const {
    visualConfiguration: { leftMenuOpen }
  } = accountReducer

  return {
    leftMenuOpen
  }
}

SignedIn.defaultProps = {
  leftMenu: true,
  activeCall: true
}

SignedIn.propTypes = {
  children: PropTypes.node.isRequired,
  leftMenu: PropTypes.bool,
  activeCall: PropTypes.bool
}
