import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { find, omit } from "lodash"
import BasicField from "../../fields/BasicField"
import TextSelectInput from "../inputs/TextSelectInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import Loading from "../../Loading"

const SIRENA = gql`
  query sirenaWhatsappTemplates {
    sirenaWhatsappTemplates {
      id
      name
      preview
    }
  }
`

const SEND_WHATSAPP_TEMPLATE = gql`
  mutation sendWhatsappTemplate($leasingRequestId: ID!, $leasingRequestArguments: SendWhatsappTemplateInputObject!) {
    sendWhatsappTemplate(
      input: { leasingRequestId: $leasingRequestId, leasingRequestArguments: $leasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function SendWhatsappTemplate(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { data: queryData, loading: queryLoading } = useQuery(SIRENA)
  const [mutation, { data, loading, error }] = useMutation(SEND_WHATSAPP_TEMPLATE, {
    onCompleted: newData => {
      if (newData.sendWhatsappTemplate?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.sendWhatsappTemplate?.errors
  if (error) return <p>Server Error</p>

  if (queryLoading) return <Loading />

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, leasingRequestArguments: omit(values, "preview") }
    })
  }

  const whatsappTemplateOptions = queryData.sirenaWhatsappTemplates.map(({ id, name }) => ({ value: id, label: name }))

  const validationSchema = Yup.object().shape({
    templateId: Yup.string().required(t("common:required"))
  })

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} />
          <TextSelectInput
            field="templateId"
            label={t("whatsappTemplate")}
            options={whatsappTemplateOptions}
            errors={errors}
          />
          {formState.values.templateId && (
            <BasicField
              title={t("common:preview")}
              type="markdown"
              value={find(queryData.sirenaWhatsappTemplates, { id: formState.values.templateId }).preview}
            />
          )}
          <ModalPrimaryButton isFetching={loading}>{t("sendWhatsappTemplate")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

SendWhatsappTemplate.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
