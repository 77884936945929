import PropTypes from "prop-types"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Grid } from "@material-ui/core"
import { isNil } from "lodash"
import BasicField from "../../../../../components/fields/BasicField"
import Loading from "../../../../../components/Loading"

const LEAD_MARKETING_DATA = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      origin
      gclid
      utmAdgroup
      utmCampaign
      utmContent
      utmMedium
      utmSource
      utmTerm
      utmMultistep
      utmConversionPage
      adposition
      device
      locPhysicalMs
      matchtype
      adid
      acceptsConditions
      acceptsOffers
    }
  }
`

export default function Marketing(props) {
  const { leadId } = props

  const { data, loading } = useQuery(LEAD_MARKETING_DATA, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.lead)) return <p>Not found</p>

  const { lead } = data

  return (
    <>
      <Grid item xs={12} md={6}>
        <BasicField title="Origin" value={lead.origin} />
        <BasicField title="UTM Source" value={lead.utmSource} />
        <BasicField title="UTM Medium" value={lead.utmMedium} />
        <BasicField title="UTM Campaign" value={lead.utmCampaign} />
        <BasicField title="UTM Adgroup" value={lead.utmAdgroup} />
        <BasicField title="UTM Content" value={lead.utmContent} />
        <BasicField title="UTM Term" value={lead.utmTerm} />
        <BasicField title="UTM Multistep" value={lead.utmMultistep} />
        <BasicField title="UTM Conversion Page" value={lead.utmConversionPage} />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicField title="Gclid" value={lead.gclid} />
        <BasicField title="Ad Position" value={lead.adposition} />
        <BasicField title="Device" value={lead.device} />
        <BasicField title="Loc Physical MS" value={lead.locPhysicalMs} />
        <BasicField title="Matchtype" value={lead.matchtype} />
        <BasicField title="Adid" value={lead.adid} />
      </Grid>
      <Grid item xs={12}>
        <BasicField title="Accepts conditions" type="boolean" value={lead.acceptsConditions} />
        <BasicField title="Accepts Offers" type="boolean" value={lead.acceptsOffers} />
      </Grid>
    </>
  )
}

Marketing.propTypes = {
  leadId: PropTypes.string.isRequired
}
