import PropTypes from "prop-types"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SwipeableViews from "react-swipeable-views"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { gql } from "@apollo/client"
import MainData from "../MainData"
import useAbilities from "../../../../../hooks/useAbilities"
import LeasingRequests from "../../../../../components/LeasingRequests"
import ActionLogs from "../../../../../components/ActionLogs"
import actionLogQqlFields from "../../../../../components/ActionLogs/gqlFields"
import Documentation from "../Documentation"
import Emails from "../Emails"
import Feed from "../Feed"
import MoreData from "../MoreData"
import StatusList from "../StatusList"
import CallsAndTasks from "../CallsAndTasks"
import IntegrationData from "../IntegrationData"
import Admin from "../Admin"

const UPDATE_LEASING_REQUEST = gql`
  mutation updateLeasingRequest($id: ID!, $leasingRequestArguments: UpdateLeasingRequestInputObject!) {
    updateLeasingRequest(input: { leasingRequestId: $id, leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
      result {
        id
        title
        status
        lastStatusChangedAt
        firstName
        lastName
        secondLastName
        email
        phone
        jobType
        countryCode
        locale
        scoringStartedAt
        scoringCompletedAt
        wonAt
        closedAt
        createdAt
        billableAt
      }
    }
  }
`

const ACTION_LOGS_QUERY = gql`
  query leasingRequest($id: ID!, $offset: Int, $limit: Int) {
    leasingRequest(id: $id) {
      id
      ${actionLogQqlFields}
    }
  }
`

const GridTab = styled(Grid)`
  margin-bottom: 15px;
  padding: 5px 10px;
`

const StyledTabs = styled(Tabs)`
  .Mui-selected {
    background-color: var(--light-grey);
  }
`

export default function TabsScene(props) {
  const { leasingRequestId, leadId, selectedCar, setOpenModal, lastRefreshAt } = props
  const { t } = useTranslation("leasingRequest")
  const [selectedTab, setSelectedTab] = useState(0)
  const { allowed: adminAllowed } = useAbilities("read_admin_leasing_request_data")
  const { allowed: manageLeasingRequests } = useAbilities("manage_leasing_requests")

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab)
  }

  const getPropsForField = (field, newData) => {
    return {
      field,
      id: leasingRequestId,
      value: newData.leasingRequest[field],
      mutationArguments: "leasingRequestArguments",
      gqlMutation: UPDATE_LEASING_REQUEST,
      isEditable: true
    }
  }

  return (
    <>
      <StyledTabs variant="scrollable" value={selectedTab} onChange={handleTabChange}>
        <Tab label={t("common:mainData")} />
        <Tab label={t("common:leasingRequest_plural")} />
        <Tab label={t("common:documentation.title")} />
        <Tab label={t("common:comments")} />
        <Tab label={t("common:emails")} />
        <Tab label={t("common:moreData")} />
        <Tab label="Status" />
        {adminAllowed && <Tab label="Integration Data" value={7} />}
        {adminAllowed || (manageLeasingRequests && <Tab label="Calls & Tasks" value={8} />)}
        {(adminAllowed || manageLeasingRequests) && <Tab label="Admin Data" value={9} />}
        {adminAllowed && <Tab label="Action logs" value={10} />}
      </StyledTabs>
      <SwipeableViews key={lastRefreshAt} index={selectedTab} onChangeIndex={index => handleTabChange(null, index)}>
        <GridTab container value={selectedTab} index={0}>
          {!selectedCar &&
            selectedTab === 0 && ( // Negated selected car is for forcing reconstruction when car is added to interested cars
              <MainData
                leasingRequestId={leasingRequestId}
                getPropsForField={getPropsForField}
                setOpenModal={setOpenModal}
              />
            )}
        </GridTab>
        <GridTab container value={selectedTab} index={1}>
          {selectedTab === 1 && <LeasingRequests leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={2}>
          {selectedTab === 2 && (
            <Documentation leasingRequestId={leasingRequestId} getPropsForField={getPropsForField} />
          )}
        </GridTab>
        <GridTab container value={selectedTab} index={3}>
          {selectedTab === 3 && <Feed leasingRequestId={leasingRequestId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={4}>
          {selectedTab === 4 && <Emails leasingRequestId={leasingRequestId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={5}>
          {selectedTab === 5 && <MoreData leasingRequestId={leasingRequestId} getPropsForField={getPropsForField} />}
        </GridTab>
        <GridTab container value={selectedTab} index={6}>
          {selectedTab === 6 && <StatusList leasingRequestId={leasingRequestId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={7}>
          {selectedTab === 7 && <IntegrationData leasingRequestId={leasingRequestId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={8}>
          {selectedTab === 8 && <CallsAndTasks leasingRequestId={leasingRequestId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={9}>
          {selectedTab === 9 && <Admin leasingRequestId={leasingRequestId} getPropsForField={getPropsForField} />}
        </GridTab>
        <GridTab container value={selectedTab} index={10}>
          {selectedTab === 10 && (
            <ActionLogs gqlQueryName="leasingRequest" gqlQuery={ACTION_LOGS_QUERY} resourceId={leasingRequestId} />
          )}
        </GridTab>
      </SwipeableViews>
    </>
  )
}

TabsScene.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  leadId: PropTypes.string.isRequired,
  selectedCar: PropTypes.string,
  setOpenModal: PropTypes.func.isRequired,
  lastRefreshAt: PropTypes.string
}

TabsScene.defaultProps = {
  selectedCar: null,
  lastRefreshAt: null
}
