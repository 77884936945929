import PropTypes from "prop-types"
import React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import { Select } from "@material-ui/core"

export default function SelectField(props) {
  const { options, humanizeFunc, ...rest } = props

  return (
    <Select fullWidth {...rest}>
      {options.map(option => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {humanizeFunc ? humanizeFunc(option.value) : option.label}
          </MenuItem>
        )
      })}
    </Select>
  )
}

SelectField.propTypes = {
  humanizeFunc: PropTypes.func,
  options: PropTypes.array.isRequired
}

SelectField.defaultProps = {
  humanizeFunc: null
}
