import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@material-ui/core"
import { camelCase } from "lodash"
import { StyledGreyPaper } from "../../../../../../components/styled/papers"
import InlineEditableField from "../../../../../../components/fields/InlineEditableField"

export default function Checkboxes(props) {
  const { documentationList, getPropsForField, data } = props

  return (
    <Grid item xs={12}>
      <StyledGreyPaper>
        {documentationList.map(({ key, translation }) => (
          <InlineEditableField
            key={key}
            title={translation}
            type="boolean"
            {...getPropsForField(`${camelCase(key)}Ready`, data)}
          />
        ))}
      </StyledGreyPaper>
    </Grid>
  )
}

Checkboxes.propTypes = {
  data: PropTypes.object.isRequired,
  documentationList: PropTypes.array.isRequired,
  getPropsForField: PropTypes.func.isRequired
}
