import actionCable from "actioncable"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { ActionCableContext } from "../../contexts"
import { getAuth } from "../../services/accountService"

export default function ActionCable(props) {
  const { children } = props
  const { accountId } = useSelector(mapStateToProps, shallowEqual)
  const workingChannelSubscription = useRef()
  const onlineChannelSubscription = useRef()
  const qualityChannelSubscription = useRef()

  const consumer = accountId
    ? actionCable.createConsumer(`${process.env.REACT_APP_API_WS_ROOT}?auth_token=${getAuth()}`)
    : null

  const subscribeWorkingChannel = (onConnect, onReceive) => {
    workingChannelSubscription.current = consumer.subscriptions.create(
      { channel: "WorkingChannel" },
      {
        connected: onConnect,
        received: onReceive
      }
    )
  }

  const subscribeOnlineChannel = (onConnect, onReceive) => {
    onlineChannelSubscription.current = consumer.subscriptions.create(
      { channel: "OnlineChannel" },
      {
        connected: onConnect,
        received: onReceive
      }
    )
  }

  const subscribeQualityChannel = (onConnect, onReceive) => {
    qualityChannelSubscription.current = consumer.subscriptions.create(
      { channel: "QualityChannel" },
      {
        connected: onConnect,
        received: onReceive
      }
    )
  }

  const sendWorkingMessage = (action, data) => {
    if (workingChannelSubscription.current != null) {
      workingChannelSubscription.current.perform(action, data)
    }
  }

  const sendQualityMessage = (action, data) => {
    if (qualityChannelSubscription.current != null) {
      qualityChannelSubscription.current.perform(action, data)
    }
  }

  const unsubscribeWorkingChannel = () => {
    consumer.subscriptions.remove(workingChannelSubscription.current)
    workingChannelSubscription.current = null
  }

  const unsubscribeOnlineChannel = () => {
    consumer.subscriptions.remove(onlineChannelSubscription.current)
    onlineChannelSubscription.current = null
  }

  const unsubscribeQualityChannel = () => {
    if (qualityChannelSubscription.current !== null) {
      consumer.subscriptions.remove(qualityChannelSubscription.current)
      qualityChannelSubscription.current = null
    }
  }

  return (
    <ActionCableContext.Provider
      value={{
        consumer,
        subscribeWorkingChannel,
        unsubscribeWorkingChannel,
        sendWorkingMessage,
        subscribeOnlineChannel,
        unsubscribeOnlineChannel,
        subscribeQualityChannel,
        sendQualityMessage,
        unsubscribeQualityChannel
      }}
    >
      {children}
    </ActionCableContext.Provider>
  )
}

const mapStateToProps = state => {
  const { accountReducer } = state
  const { item: account } = accountReducer

  return {
    accountId: account?.id
  }
}

ActionCable.propTypes = {
  children: PropTypes.node.isRequired
}
