import React from "react"
import { gql } from "@apollo/client"
import SignedIn from "../../../components/layouts/SignedIn"
import IndexElement from "./IndexElement"
import TableList from "../../../components/layouts/TableList"
import Allowed from "../../../components/layouts/Allowed"
import Breadcrumb from "../../../components/layouts/Breadcrumb"
import Filter from "./Filter"

const SEARCH_LEASING_REQUESTS = gql`
  query searchLeasingRequests(
    $q: String
    $globalStatus: LeasingRequestGlobalStatus
    $createdReason: String
    $quotationStatus: LeasingRequestQuotationStatus
    $offerStatus: LeasingRequestOfferStatus
    $scoringStatus: LeasingRequestScoringStatus
    $closedStatus: LeasingRequestClosedStatus
    $closedReason: LeasingRequestClosedReason
    $negotiationStatus: LeasingRequestNegotiationStatus
    $countryCode: CountryCode
    $assignedAtId: String
    $createdById: String
    $distributorId: ID
    $online: Boolean
    $incidence: Boolean
    $basicCar: String
    $plateNumber: String
    $undecidedClient: Boolean
    $winProbability: Int
    $limit: Int
    $offset: Int
    $sort: Sort
    $orderBy: LeasingRequestsSearchOrderBy
  ) {
    searchLeasingRequests(
      q: $q
      countryCode: $countryCode
      globalStatus: $globalStatus
      offerStatus: $offerStatus
      createdReason: $createdReason
      quotationStatus: $quotationStatus
      scoringStatus: $scoringStatus
      closedStatus: $closedStatus
      negotiationStatus: $negotiationStatus
      closedReason: $closedReason
      assignedAtId: $assignedAtId
      createdById: $createdById
      distributorId: $distributorId
      online: $online
      incidence: $incidence
      basicCar: $basicCar
      plateNumber: $plateNumber
      undecidedClient: $undecidedClient
      winProbability: $winProbability
      limit: $limit
      offset: $offset
      sort: $sort
      orderBy: $orderBy
    ) {
      searchInfo {
        total
      }
      results {
        id
        title
        assignedAtId
        createdReason
        contractExtensionAssignedAtId
        globalStatus
        quotationStatus
        offerStatus
        negotiationStatus
        basicCar {
          brand
          model
        }
        plateNumber
        online
        distributorId
        lastStatusChangedAt
        lastComment {
          content
          createdAt
          createdById
        }
        countryCode
      }
    }
  }
`

const attributes = [
  { name: "title", label: "full name", sortable: false },
  { name: "assignedAtId", label: "assignee", sortable: false },
  { name: "createdReason", label: "created reason", sortable: false },
  { name: "globalStatus", label: "global status", sortable: false },
  { name: "quotationStatus", label: "quotation status", sortable: false },
  { name: "offerStatus", label: "offer status", sortable: false },
  { name: "negotiationStatus", label: "negotiation status", sortable: false },
  { name: "car", sortable: false },
  { name: "plateNumber", sortable: false },
  { name: "online", sortable: false },
  { name: "distributorId", label: "distributor", sortable: false },
  { name: "lastStatusChangedAt", label: "last status changed at", sortable: true },
  { name: "countryCode", label: "country code", sortable: false },
  { name: "lastComment", label: "last comment", sortable: false }
]

export default function IndexScene() {
  return (
    <SignedIn>
      <Allowed
        ability={[
          "read_leasing_requests",
          "manage_own_leasing_requests",
          "manage_country_leasing_requests",
          "manage_leasing_requests"
        ]}
      >
        <Breadcrumb />
        <TableList
          title="Leasing requests"
          gqlQuery={SEARCH_LEASING_REQUESTS}
          defaultOrderBy="lastStatusChangedAt"
          defaultSort="desc"
          attributes={attributes}
          Filter={Filter}
          CustomTableRow={IndexElement}
          withRefresh
        />
      </Allowed>
    </SignedIn>
  )
}
