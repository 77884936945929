import PropTypes from "prop-types"
import React from "react"
import CarTag from "./CarTag"

export default function CarTagList(props) {
  const { car, loading } = props

  return (
    <div>
      {car == null && !loading && <CarTag tagName="unavailable" small />}
      {(car?.sold || !car?.activeForSales) && <CarTag tagName="sold" small />}
      {car?.new && <CarTag tagName="new" small />}
      {car?.onlineScoring && <CarTag tagName="onlineScoring" small />}
      {car?.lastItems && <CarTag tagName="lastItems" small />}
      {car?.fastDelivering && <CarTag tagName="fastDelivering" small />}
      {car?.sellingStatus === "pre-owned" && <CarTag tagName="preOwned" small />}
      {car?.sellingStatus === "used" && <CarTag tagName="used" small />}
      {!car?.isActive && car?.activeForSales && <CarTag tagName="webInactive" small />}
    </div>
  )
}

CarTagList.propTypes = {
  car: PropTypes.object,
  loading: PropTypes.bool
}

CarTagList.defaultProps = {
  car: null,
  loading: false
}
