import styled from "styled-components"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableContainer from "@material-ui/core/TableContainer"
import TableCell from "@material-ui/core/TableCell"

export const StyledTableHead = styled(TableHead)`
  background-color: #f5f5f5;
`

export const StyledTotal = styled(TableRow)`
  background-color: #f5f5f5;
`

export const StyledIndexTableRowBody = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`

export const TopStyledIndexTableRowBody = styled(StyledIndexTableRowBody)`
  border-bottom-width: 0;
  .MuiTableCell-root {
    border-bottom-width: 0;
  }
`

export const BottomStyledIndexTableRowBody = styled(TableRow)`
  border-top-width: 0;
  .MuiTableCell-root {
    border-top-width: 0;
  }
`

export const DashboardStyledTableContainer = styled(TableContainer)`
  margin: 30px 0;
  box-shadow: 10px 10px 15px -4px rgba(0, 0, 0, 0.6);
  height: 300px;
`

export const DashboardStyledTableCell = styled(TableCell)`
  background-color: var(--stormy-blue);
  color: white;
  text-transform: uppercase;
`
