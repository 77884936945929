import { useEffect, useState } from "react"
import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"

export default function LogRocketInitializer(props) {
  const { children } = props
  const [logRocketReady, setLogRocketReady] = useState(false)

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      LogRocket.init("jkkdpt/swipcar", {
        release: process.env.REACT_APP_GIT_INFO.substring(0, 8)
      })
      setupLogRocketReact(LogRocket)
    }
    setLogRocketReady(true)
  }, [])

  return logRocketReady ? children : null
}
