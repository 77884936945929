import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import * as PropTypes from "prop-types"
import { ModalPrimaryButton } from "../../styled/buttons"
import { Paragraph } from "../../styled/typography"
import GraphQLErrors from "../../GraphQLErrors"

const DESTROY_LEASING_REQUEST = gql`
  mutation destroyLeasingRequest($leasingRequestId: ID!) {
    destroyLeasingRequest(input: { leasingRequestId: $leasingRequestId }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function DestroyLeasingRequest(props) {
  const { leasingRequestId } = props
  const { t } = useTranslation()
  const history = useHistory()
  const [mutation, { data, loading, error }] = useMutation(DESTROY_LEASING_REQUEST, {
    onCompleted: newData => {
      if (newData.destroyLeasingRequest?.errors == null) {
        history.push("/leasing-requests")
      }
    }
  })

  const handleSubmit = () => {
    mutation({
      variables: { leasingRequestId }
    })
  }
  const errors = data?.destroyLeasingRequest?.errors
  if (error) return <p>Server Error</p>

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <Paragraph>{t("areYouSure")}</Paragraph>
      <ModalPrimaryButton isFetching={loading}>{t("delete")}</ModalPrimaryButton>
    </Form>
  )
}

DestroyLeasingRequest.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
