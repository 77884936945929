import { createMuiTheme } from "@material-ui/core/styles"

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#7CB3F2",
      main: "#547AA6",
      dark: "#3B5573",
      contrastText: "#fff"
    },
    secondary: {
      light: "#865FD9",
      main: "#6E4FB3",
      dark: "#473273",
      contrastText: "#fff"
    },
    error: {
      light: "#FF0000",
      main: "#FF0000",
      dark: "#FF0000",
      contrastText: "#fff"
    },
    background: {
      paper: "#fff",
      default: "#f6f7ff"
    }
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiMenu: {
      paper: {
        padding: "5px 0"
      }
    },
    MuiAppBar: {
      root: {
        padding: "0"
      }
    },
    MuiButton: {
      text: {
        borderRadius: "0",
        "&:hover": {
          backgroundColor: "#fff"
        },
        "&focus": {
          outline: "none"
        }
      },
      root: {
        borderRadius: "0"
      }
    },
    MuiCard: {
      root: {
        borderRadius: "0"
      }
    },
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: "green"
        }
      }
    },
    MuiTableCell: {
      body: {
        paddingTop: "5px",
        paddingBottom: "5px"
      }
    },
    MuiChip: {
      root: {
        margin: "5px"
      }
    },
    MuiDrawer: {
      paper: {
        padding: "0"
      }
    },
    MuiTab: {
      root: {
        "&$selected": {}
      }
    },
    MuiFab: {
      root: {
        "&:hover": {
          backgroundColor: "inherited",
          opacity: "0.7"
        }
      }
    }
  }
})

export default MuiTheme
