import PropTypes from "prop-types"
import { gql, useQuery } from "@apollo/client"
import React from "react"
import { isNil, orderBy } from "lodash"
import { Grid } from "@material-ui/core"
import Loading from "../../../../../components/Loading"
import StatusBlock from "../../../../../components/StatusBlock"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      globalStatuses {
        id
        status
        createdAt
        endedAt
        assignedAtId
      }
    }
  }
`

export default function StatusList(props) {
  const { leasingRequestId } = props
  const { loading, data } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  return (
    <Grid item xs={12}>
      {orderBy(data.leasingRequest.globalStatuses, "createdAt", "desc").map(item => (
        <Grid item xs={12} key={item.id}>
          <StatusBlock item={item} />
        </Grid>
      ))}
    </Grid>
  )
}

StatusList.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
