import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { printDateTime } from "../../../../../../helpers/formatter"
import useAbilities from "../../../../../../hooks/useAbilities"
import LeasingCar from "./LeasingCar"

const Title = styled.h3`
  text-transform: uppercase;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => (props.old ? "var(--warm-red)" : "var(--stormy-blue)")}; // old not used should define old opps
`

const StyledSmall = styled.small`
  display: block;
  margin: 0;
`

const Wrapper = styled.div`
  padding: 15px 15px 15px 0;
  margin: 10px 15px 10px 0;
  box-shadow: 15px 15px 10px -7px rgba(222, 222, 222, 1);
`

export default function Opportunity(props) {
  const { opportunity, onCarLoaded, setOpenModal } = props
  const { t } = useTranslation("lead")
  const { kind, createdAt, basicCar } = opportunity
  const { allowed: adminAllowed } = useAbilities("read_admin_lead_data")

  return (
    <Wrapper>
      <Title>{t(`opportunities.kind.${kind}`)}</Title>
      {adminAllowed && <StyledSmall>{`${printDateTime(createdAt)} ${moment(createdAt).fromNow()} `}</StyledSmall>}
      {kind === "leasing_car" && basicCar && (
        <LeasingCar basicCar={basicCar} onCarLoaded={onCarLoaded} setOpenModal={setOpenModal} />
      )}
    </Wrapper>
  )
}

Opportunity.propTypes = {
  onCarLoaded: PropTypes.func,
  opportunity: PropTypes.object.isRequired,
  setOpenModal: PropTypes.func
}

Opportunity.defaultProps = {
  onCarLoaded: () => {},
  setOpenModal: () => {}
}
