import React from "react"
import TableCell from "@material-ui/core/TableCell"
import { useHistory } from "react-router-dom"
import * as PropTypes from "prop-types"
import IconButton from "@material-ui/core/IconButton"
import TabIcon from "@material-ui/icons/Tab"
import { startCase } from "lodash"
import { useTranslation } from "react-i18next"
import { shallowEqual, useSelector } from "react-redux"
import { StyledIndexTableRowBody } from "../../../../components/styled/tables"
import Staff from "../../../../models/staff"
import { printDateTime } from "../../../../helpers/formatter"
import { openWindowForSubject, redirectToSubject } from "../../../../helpers/utils"

export default function IndexElement(props) {
  const { entity } = props
  const { id, title, assignedAtId, status, attemptsToContact, lastOpportunityCreatedAt, countryCode, distributor } = entity
  const { staff } = useSelector(mapStateToProps, shallowEqual)
  const history = useHistory()
  const { t } = useTranslation("lead")
  const staffName = Staff.getName(staff, assignedAtId)

  return (
    <StyledIndexTableRowBody onClick={() => redirectToSubject(history, "Lead", id)}>
      <TableCell>
        <IconButton
          onClick={event => {
            event.stopPropagation()
            openWindowForSubject("Lead", id)
          }}>
          <TabIcon />
        </IconButton>
      </TableCell>
      <TableCell>{startCase(title)}</TableCell>
      <TableCell>{staffName}</TableCell>
      <TableCell>{t(`status.${status}`)}</TableCell>
      <TableCell>{attemptsToContact}</TableCell>
      <TableCell>{printDateTime(lastOpportunityCreatedAt)}</TableCell>
      <TableCell>{t(`common:country.${countryCode}`)}</TableCell>
      <TableCell>{distributor}</TableCell>
    </StyledIndexTableRowBody>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff } = commonDataReducer

  return { staff: staff.items }
}

IndexElement.propTypes = {
  entity: PropTypes.object.isRequired
}
