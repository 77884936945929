import React, { PureComponent } from "react"
import classNames from "classnames"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import WarningIcon from "@material-ui/icons/Warning"
import isEmpty from "lodash/isEmpty"
import makeStyles from "@material-ui/styles/makeStyles"
import withRedux from "../../hocs/withRedux"
import { removeNotification } from "../../actions"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: "var(--kiwi-green)"
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: "var(--lightblue)"
  },
  warning: {
    backgroundColor: "var(--orange)"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}))

function MySnackbarContent(props) {
  const { className, message, onClose, variant, ...other } = props
  const classes = useStyles()
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

class Notifications extends PureComponent {
  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEmpty(this.props.message)) {
      setTimeout(() => {
        this.props.dispatch(removeNotification())
      }, 5000)
    }
  }

  handleClose(event, reason) {
    this.props.dispatch(removeNotification())
  }

  render() {
    const { message, variant } = this.props

    return (
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={!isEmpty(message)}>
        <MySnackbarContent onClose={this.handleClose} variant={variant || "success"} message={message} />
      </Snackbar>
    )
  }
}

const mapStateToProps = state => {
  const { notificationsReducer } = state
  const { message, variant } = notificationsReducer

  return {
    message,
    variant
  }
}

export default withRedux(Notifications, mapStateToProps)
