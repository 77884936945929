import React from "react"
import { Form } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import BasicInput from "../inputs/BasicInput"
import GuarantorInput from "../inputs/GuarantorInput"
import Loading from "../../Loading"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      downPayment
      distributorId
      guarantor
      guarantorEmail
      guarantorName
      guarantorIdNumber
      guarantorPhone
      guarantorStreet
      guarantorStreetNumber
      guarantorPostalCode
      guarantorTown
      guarantorProvince
      offerStatus
      quotationStatus
    }
  }
`
const QUOTATION_REQUEST = gql`
  mutation quotationRequest($leasingRequestId: ID!, $leasingRequestArguments: QuotationRequestInputObject!) {
    quotationRequest(
      input: { leasingRequestId: $leasingRequestId, quotationRequestArguments: $leasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function QuotationRequest(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { loading: queryLoading, data: queryData } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })
  const [mutation, { data, loading, error }] = useMutation(QUOTATION_REQUEST, {
    onCompleted: newData => {
      if (newData.quotationRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  if (queryLoading) return <Loading />
  if (queryData.leasingRequest === null) return <p>Not found</p>

  const errors = data?.quotationRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, leasingRequestArguments: values }
    })
  }

  const getPropsForField = field => {
    return {
      field,
      label: t(`common:${field}`),
      initialValue: queryData.leasingRequest[field],
      errors
    }
  }

  const { distributorId } = queryData.leasingRequest

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <GuarantorInput getPropsForField={getPropsForField} distributorId={distributorId} />
      <BasicInput {...getPropsForField("comment")} required />
      <ModalPrimaryButton isFetching={loading}>{t("lead:createQuotationRequest")}</ModalPrimaryButton>
    </Form>
  )
}

QuotationRequest.defaultProps = {
  handleSuccess: () => {}
}

QuotationRequest.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func
}
