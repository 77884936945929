import {
  FINISH_CURRENT_TASK,
  RECEIVE_ACCOUNT,
  RECEIVE_CURRENT_TASK,
  SIGN_OUT_ACCOUNT,
  TOGGLE_LEFT_MENU,
  UPDATE_WORKING_STATUS
} from "../actions"
import Account from "../models/account"

const defaultState = {
  item: null,
  visualConfiguration: {
    leftMenuOpen: true
  },
  currentTask: null,
  idle: true
}

function accountReducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_ACCOUNT:
      return {
        ...state,
        item: new Account({ ...state.item, ...action.account })
      }
    case TOGGLE_LEFT_MENU:
      return {
        ...state,
        visualConfiguration: {
          leftMenuOpen: !state.visualConfiguration.leftMenuOpen
        }
      }
    case SIGN_OUT_ACCOUNT:
      return {
        ...state,
        item: null,
        currentTask: undefined
      }
    case RECEIVE_CURRENT_TASK:
      return { ...state, currentTask: action.task }
    case FINISH_CURRENT_TASK:
      return { ...state, currentTask: null }
    case UPDATE_WORKING_STATUS:
      return { ...state, idle: action.idle, item: new Account({ ...state.item, ...action.account }) }
    default:
      return state
  }
}

export default accountReducer
