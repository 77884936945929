import React from "react"
import { asField } from "informed"
import ArrowLeft from "@material-ui/icons/ArrowLeft"
import ArrowRight from "@material-ui/icons/ArrowRight"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import esLocale from "date-fns/locale/es"
import DateFnsUtils from "@date-io/date-fns"
import AccessTime from "@material-ui/icons/AccessTime"
import CalendarToday from "@material-ui/icons/CalendarToday"
import { isEmpty } from "lodash"
import moment from "moment"
import { getFieldError } from "../../../../helpers/errorParser"

const DateInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { field, initialValue, helperText, errors, formApi, onChange, onBlur, innerRef, ...rest } = props
  const { setValue, setTouched, setError } = fieldApi
  const { value } = fieldState
  const { error: fieldError } = fieldState

  const errorsText = getFieldError(errors, field)

  return (
    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...rest}
        ref={innerRef}
        id={field}
        ampm={false}
        fullWidth
        error={!isEmpty(errorsText) || !isEmpty(fieldError)}
        helperText={errorsText || fieldError || helperText}
        timeIcon={<AccessTime />}
        dateRangeIcon={<CalendarToday />}
        onError={error => {
          // Avoid infinite render loops by setting the error if it's different
          const newError = isEmpty(error) ? undefined : error
          if (fieldError !== newError) setError(newError)
        }}
        onChange={newValue => {
          // Use moment to remove the time fragment to avoid time-zone problems (off-by-1 days).
          const newDate = moment(newValue).format("YYYY-MM-DD")
          setValue(newDate)
          if (onChange) {
            onChange(newDate)
          }
        }}
        onBlur={e => {
          setTouched()
          if (onBlur) {
            onBlur(e)
          }
        }}
        leftArrowIcon={<ArrowLeft />}
        rightArrowIcon={<ArrowRight />}
        value={value || null}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  )
})

export default DateInput
