import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

const StyledGrid = styled(Grid)`
  height: 100vh;
`

export default function Loading() {
  return (
    <StyledGrid container direction="row" justify="center" alignItems="center">
      <CircularProgress size={200} color="primary" variant="indeterminate" />
    </StyledGrid>
  )
}
