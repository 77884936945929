import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@material-ui/core"
import { Text } from "informed"
import BasicInput from "../../BasicInput"
import CivilStatusSelector from "../../fca/CivilStatusSelector"
import OccupationSelector from "../../fca/OccupationSelector"
import TextSelectInput from "../../TextSelectInput"
import ProvincesInput from "../../fca/ProvincesInput"
import DateInput from "../../DateInput"
import { documentTypeOptions } from "../../../../../helpers/constants"

export default function FCAFields(props) {
  const { getPropsForField } = props
  const jobType = getPropsForField("jobType").initialValue

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Text type="hidden" field="status" initialValue="scoring" />
          <BasicInput {...getPropsForField("phone")} required />
          <TextSelectInput {...getPropsForField("documentType")} options={documentTypeOptions} required />
          <BasicInput {...getPropsForField("idNumber")} required />
          <ProvincesInput {...getPropsForField("fcaProvince")} secondaryField="province" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput {...getPropsForField("street")} required />
          <BasicInput {...getPropsForField("streetNumber")} required />
          <BasicInput {...getPropsForField("floor")} />
          <BasicInput {...getPropsForField("postalCode")} required />
          <BasicInput {...getPropsForField("town")} required />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DateInput {...getPropsForField("birthday")} required openTo="year" views={["year", "month", "date"]} />
          <ProvincesInput {...getPropsForField("fcaBirthProvince")} secondaryField="birthProvince" />
          <CivilStatusSelector {...getPropsForField("fcaCivilStatus")} />
          <OccupationSelector {...getPropsForField("fcaOccupation")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput {...getPropsForField("employedSince")} required openTo="year" views={["year", "month", "date"]} />
          {jobType === "employee" && <BasicInput {...getPropsForField("actualMonthlyIncome")} required type="number" />}
          {jobType === "freelance" && (
            <>
              <BasicInput {...getPropsForField("invoiced")} required type="number" />
              <BasicInput {...getPropsForField("netReturns")} required type="number" />
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

FCAFields.propTypes = {
  getPropsForField: PropTypes.func.isRequired
}
