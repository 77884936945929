import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import Button from "@material-ui/core/Button"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import IconButton from "@material-ui/core/IconButton"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import Call from "@material-ui/icons/Call"
import React, { useContext, useEffect } from "react"
import DoneOutline from "@material-ui/icons/DoneOutline"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { Paragraph } from "../../../../components/styled/typography"
import { printDateTime } from "../../../../helpers/formatter"
import { DashboardStyledTableCell, DashboardStyledTableContainer } from "../../../../components/styled/tables"
import { handleNotificationErrors, openWindowForSubject, redirectToSubject } from "../../../../helpers/utils"
import { GreenButton } from "../../../../components/styled/buttons"
import { receiveNotification } from "../../../../actions"
import { TwilioContext } from "../../../../contexts"

const PENDING_TASKS = gql`
  query account {
    account {
      pendingTasks {
        id
        identifier
        subjectId
        subjectTitle
        subjectType
        afterDate
      }
    }
  }
`

const FINISH_TASK = gql`
  mutation finishTask($id: ID!) {
    finishTask(input: { taskId: $id }) {
      errors {
        messages
        path
      }
      result {
        id
      }
    }
  }
`

export default function PendingTasks() {
  const { accountId, inCall, everythingReady } = useSelector(mapStateToProps, shallowEqual)
  const { t } = useTranslation("dashboard")
  const dispatch = useDispatch()
  const { data, refetch } = useQuery(PENDING_TASKS)
  const [finishTask, { data: finishTaskData }] = useMutation(FINISH_TASK)
  const { connect } = useContext(TwilioContext)
  const history = useHistory()

  useEffect(() => {
    if (finishTaskData?.finishTask?.errors) {
      const { errors } = finishTaskData.finishTask
      dispatch(receiveNotification(handleNotificationErrors(errors), { variant: "error" }))
    } else if (finishTaskData?.finishTask?.result) {
      refetch()
    }
  }, [finishTaskData, dispatch, refetch])

  const handleOnDone = id => {
    return () => finishTask({ variables: { id } })
  }

  const handleOnCall = task => {
    return () => {
      connect(task.subjectId, task.subjectTitle, task.subjectType, accountId)
      redirectToSubject(history, task.subjectType, task.subjectId)
    }
  }

  return (
    <Grid item xs={12} xl={6}>
      <DashboardStyledTableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <DashboardStyledTableCell colSpan={6}>{t("pendingTasks")}</DashboardStyledTableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell>{t("common:subject")}</TableCell>
              <TableCell>{t("common:afterDate")}</TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.account?.pendingTasks.map(task => (
              <TableRow key={task.id} hover>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={event => {
                      event.stopPropagation()
                      openWindowForSubject(task.subjectType, task.subjectId)
                    }}>
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{t(`common:task.${task.identifier}`)}</TableCell>
                <TableCell>{task.subjectTitle}</TableCell>
                <TableCell>
                  <Paragraph>{printDateTime(task.afterDate)}</Paragraph>
                </TableCell>
                <TableCell size="small">
                  <Button
                    disabled={!everythingReady || inCall}
                    variant="contained"
                    color="primary"
                    endIcon={<Call />}
                    onClick={handleOnCall(task)}>
                    {t("common:call")}
                  </Button>
                </TableCell>
                <TableCell size="small">
                  <GreenButton
                    variant="contained"
                    color="primary"
                    endIcon={<DoneOutline />}
                    onClick={handleOnDone(task.id)}>
                    {t("common:done")}
                  </GreenButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DashboardStyledTableContainer>
    </Grid>
  )
}

const mapStateToProps = state => {
  const { accountReducer, callReducer } = state
  const { item } = accountReducer
  const { everythingReady, inCall } = callReducer

  return { accountId: item.id, everythingReady, inCall }
}
