import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { isNil } from "lodash"
import AllDocumentation from "../../../../../components/AllDocumentation"
import Loading from "../../../../../components/Loading"
import PrimaryButton from "../../../../../components/PrimaryButton"

const LEASING_REQUEST_DOCUMENTATION_DATA = gql`
  query lead($id: ID!) {
    leasingRequest(id: $id) {
      jobType
      countryCode
      personalDocuments {
        id
        tag
        preferredUrl
        documentFileName
        documentContentType
      }
      personalDocumentsZip {
        id
        updatedAt
        preferredUrl
      }
    }
  }
`

const DOCUMENTATION_LIST = gql`
  query documentationList($countryCode: CountryCode!, $jobType: JobType!) {
    documentationList(countryCode: $countryCode, jobType: $jobType) {
      key
      translation
    }
  }
`

const UPDATE_PERSONAL_DOCUMENT = gql`
  mutation updateLeasingRequestPersonalDocument(
    $id: ID!
    $personalDocumentId: ID!
    $personalDocumentArguments: UpdateLeasingRequestPersonalDocumentInputObject!
  ) {
    updateLeasingRequestPersonalDocument(
      input: {
        leasingRequestId: $id
        personalDocumentId: $personalDocumentId
        personalDocumentArguments: $personalDocumentArguments
      }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

const DESTROY_PERSONAL_DOCUMENT = gql`
  mutation destroyLeasingRequestPersonalDocument($id: ID!, $personalDocumentId: ID!) {
    destroyLeasingRequestPersonalDocument(input: { leasingRequestId: $id, personalDocumentId: $personalDocumentId }) {
      errors {
        messages
        path
      }
    }
  }
`

const CREATE_PERSONAL_DOCUMENTS_ZIP = gql`
  mutation createPersonalDocumentsZip($id: ID!) {
    createPersonalDocumentsZip(input: { leasingRequestId: $id }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function Documentation(props) {
  const { leasingRequestId } = props
  const { t } = useTranslation("leasingRequest")
  const { loading, data } = useQuery(LEASING_REQUEST_DOCUMENTATION_DATA, {
    variables: { id: leasingRequestId }
  })
  const [
    getDocumentationList,
    { called: documentationCalled, loading: loadingDocumentation, data: documentationListData }
  ] = useLazyQuery(DOCUMENTATION_LIST, {})

  const [createPersonalDocumentsZip, { loading: zipLoading }] = useMutation(CREATE_PERSONAL_DOCUMENTS_ZIP, {
    variables: {
      id: leasingRequestId
    }
  })

  useEffect(() => {
    if (data?.leasingRequest) {
      const { jobType, countryCode } = data.leasingRequest
      if (jobType && countryCode) {
        getDocumentationList({
          variables: {
            jobType,
            countryCode
          }
        })
      }
    }
  }, [data])

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>
  if (loadingDocumentation || !documentationCalled) return <Loading />

  return (
    <>
      <Grid container alignItems="center" alignContent="center" justify="center">
        <Grid item xs={12} sm={6}>
          <a href={data.leasingRequest.personalDocumentsZip.preferredUrl}>{t("downloadLatestZip")}</a>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrimaryButton onClick={createPersonalDocumentsZip} isFetching={zipLoading}>
            {t("createZip")}
          </PrimaryButton>
        </Grid>
      </Grid>
      <AllDocumentation
        showTag
        resourceId={leasingRequestId}
        personalDocuments={data.leasingRequest.personalDocuments}
        documentationList={documentationListData.documentationList}
        gqlUpdateMutation={UPDATE_PERSONAL_DOCUMENT}
        gqlDestroyMutation={DESTROY_PERSONAL_DOCUMENT}
      />
    </>
  )
}

Documentation.propTypes = {
  leasingRequestId: PropTypes.string.isRequired
}
