import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import { Paragraph } from "../../styled/typography"

const SEND_DOCUMENTATION_REQUEST_EMAIL = gql`
  mutation sendDocumentationRequestEmail($leadId: ID!) {
    sendDocumentationRequestEmail(input: { leadId: $leadId }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function SendDocumentationRequestEmail(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation("lead")
  const [mutation, { data, loading, error }] = useMutation(SEND_DOCUMENTATION_REQUEST_EMAIL, {
    onCompleted: newData => {
      if (newData.sendDocumentationRequestEmail?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.sendDocumentationRequestEmail?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = () => {
    mutation({
      variables: { leadId }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <Paragraph>{t("sendDocumentationRequestEmailExplanation")}</Paragraph>
      <ModalPrimaryButton isFetching={loading}>{t("sendDocumentationRequestEmail")}</ModalPrimaryButton>
    </Form>
  )
}

SendDocumentationRequestEmail.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
