import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const COLORS = {
  unavailable: { color: "#ff0050", label: "unavailable" },
  outdated: { color: "#795548", label: "outdated" },
  new: { color: "#49a33a", label: "new" },
  onlineScoring: { color: "#03a9f4", label: "onlineScoring" },
  sold: { color: "#ff0050", label: "sold" },
  lastItems: { color: "#c162f9", label: "lastItems" },
  fastDelivering: { color: "#08d7a7", label: "fastDelivering" },
  preOwned: { color: "#e544e2", label: "sellingStatus.pre-owned" },
  flexible: { color: "#e5ad44", label: "sellingStatus.flexible" },
  used: { color: "#6700ff", label: "sellingStatus.used" },
  webInactive: { color: "#795548", label: "webInactive" }
}

const StyledTag = styled.p`
  color: #fff;
  background-color: ${props => props.color};
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 1px;
  margin-bottom: 1px;
  padding: 1px 10px;
  display: inline-block;
`

export default function CarTag(props) {
  const { tagName, small } = props
  const { color, label } = COLORS[tagName]
  const { t } = useTranslation()

  return (
    <StyledTag color={color} small={small}>
      {t(label)}
    </StyledTag>
  )
}

CarTag.propTypes = {
  small: PropTypes.bool,
  tagName: PropTypes.string.isRequired
}

CarTag.defaultProps = {
  small: false
}
