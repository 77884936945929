import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledCard = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.color};
  width: 250px;
  padding: 15px 0;
  box-shadow: 10px 10px 15px -4px rgba(0, 0, 0, 0.6);
  margin-right: 15px;
  margin-top: 15px;
`

const TextContainer = styled.div`
  margin: 0 10px;
  text-align: left;
`

const StyledTitle = styled.p`
  color: white;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 0.75rem;
`

const StyledValue = styled.p`
  color: white;
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 2rem;
`

const IconWrapper = styled.div`
  margin-right: 10px;
`

export default function SimpleData(props) {
  const { title, value, IconJSX, color } = props

  return (
    <StyledCard color={color}>
      <TextContainer>
        <StyledValue>{value}</StyledValue>
        <StyledTitle>{title}</StyledTitle>
      </TextContainer>
      <IconWrapper>
        <IconJSX style={{ color: "white" }} fontSize="large" />
      </IconWrapper>
    </StyledCard>
  )
}

SimpleData.propTypes = {
  IconJSX: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}
