import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Grid } from "@material-ui/core"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { isNil } from "lodash"
import InlineEditableField from "../../../../../components/fields/InlineEditableField"
import BasicField from "../../../../../components/fields/BasicField"
import Loading from "../../../../../components/Loading"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      validatedEmail
      idNumber
      birthday
      birthProvince
      street
      streetNumber
      signatoryEmail
      postalCode
      town
      province
      occupation
      civilStatus
      cif
      employedSince
      invoiced
      netReturns
      actualMonthlyIncome
      paymentIban
      deliveryAddress
      deliveryProvince
      driverFullName
      driverIdNumber
      driveLicenseExpirationDate
      driveLicenseExpeditionDate
      fcaOccupation
      fcaCivilStatus
      fcaBirthProvince
      fcaProvince
      fcaAcceptsConditions
      companyName
      promotion
      promotionStartedAt
      promotionOption
      plateNumber
      oldPlateNumber
      externalClientId
    }
  }
`

export default function MoreData(props) {
  const { leasingRequestId, getPropsForField } = props
  const { t } = useTranslation("common", "leasingRequest")
  const { loading, data } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  const { leasingRequest } = data

  return (
    <>
      <Grid item xs={12} md={4}>
        <BasicField title={t("validatedEmail")} type="boolean" value={data.leasingRequest.validatedEmail} />
        <InlineEditableField title={t("idNumber")} {...getPropsForField("idNumber", data)} />
        <InlineEditableField title={t("birthday")} type="date" {...getPropsForField("birthday", data)} />
        <InlineEditableField title={t("birthProvince")} {...getPropsForField("birthProvince", data)} />
        <InlineEditableField title={t("street")} {...getPropsForField("street", data)} />
        <InlineEditableField title={t("streetNumber")} {...getPropsForField("streetNumber", data)} />
        <InlineEditableField title={t("postalCode")} {...getPropsForField("postalCode", data)} />
        <InlineEditableField title={t("town")} {...getPropsForField("town", data)} />
        <InlineEditableField title={t("province")} {...getPropsForField("province", data)} />
        <InlineEditableField title={t("occupation.title")} {...getPropsForField("occupation", data)} />
        <InlineEditableField title={t("civilStatus.title")} {...getPropsForField("civilStatus", data)} />
        <BasicField type="boolean" title={t("promotion")} value={leasingRequest.promotion} />
        {leasingRequest.promotion && (
          <>
            <BasicField
              title={t("promotionOption")}
              value={leasingRequest.promotionOption}
              humanizeFunc={value => t(`common:promotionOptions.${value}`)}
            />
            <BasicField title={t("promotionStartedAt")} value={leasingRequest.promotionStartedAt} />
          </>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <InlineEditableField title={t("companyName")} {...getPropsForField("companyName", data)} />
        <InlineEditableField title={t("companyCif")} {...getPropsForField("cif", data)} />
        <BasicField title={t("employedSince")} type="date" value={leasingRequest.employedSince} />
        <BasicField title={t("invoiced")} value={leasingRequest.invoiced} />
        <BasicField title={t("netReturns")} value={leasingRequest.netReturns} />
        <BasicField title={t("actualMonthlyIncome")} value={leasingRequest.actualMonthlyIncome} />
        <BasicField title={t("leasingRequest:paymentIban")} value={leasingRequest.paymentIban} />
        <InlineEditableField
          title={t("leasingRequest:deliveryAddress")}
          {...getPropsForField("deliveryAddress", data)}
        />
        <InlineEditableField
          title={t("leasingRequest:deliveryProvince")}
          {...getPropsForField("deliveryProvince", data)}
        />
        <BasicField title={t("driveLicenseExpirationDate")} value={leasingRequest.driveLicenseExpirationDate} />
        <BasicField title={t("driveLicenseExpeditionDate")} value={leasingRequest.driveLicenseExpeditionDate} />
        <BasicField title={t("driverFullName")} value={leasingRequest.driverFullName} />
        <BasicField title={t("driverIdNumber")} value={leasingRequest.driverIdNumber} />
        <InlineEditableField title={t("leasingRequest:plateNumber")} {...getPropsForField("plateNumber", data)} />
        <InlineEditableField title={t("leasingRequest:oldPlateNumber")} {...getPropsForField("oldPlateNumber", data)} />
        <InlineEditableField title={t("leasingRequest:signatoryEmail")} {...getPropsForField("signatoryEmail", data)} />
        <InlineEditableField
          title={t("leasingRequest:externalClientId")}
          {...getPropsForField("externalClientId", data)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BasicField title="fcaOccupation" value={leasingRequest.fcaOccupation} />
        <BasicField title="fcaCivilStatus" value={leasingRequest.fcaCivilStatus} />
        <BasicField title="fcaBirthProvince" value={leasingRequest.fcaBirthProvince} />
        <BasicField title="fcaProvince" value={leasingRequest.fcaProvince} />
        <BasicField title="fcaAcceptsConditions" type="boolean" value={leasingRequest.fcaAcceptsConditions} />
      </Grid>
    </>
  )
}

MoreData.propTypes = {
  getPropsForField: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
