import React from "react"
import { Form } from "informed"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import PropTypes from "prop-types"
import { Alert } from "@material-ui/lab"
import GraphQLErrors from "../../GraphQLErrors"
import { ModalPrimaryButton } from "../../styled/buttons"
import Loading from "../../Loading"
import GuarantorInput from "../inputs/GuarantorInput"
import CheckboxInput from "../inputs/CheckboxInput"
import Distributor from "../../../models/distributor"

const SEND_QUOTATION = gql`
  mutation sendQuotation($leasingRequestId: ID!, $sendQuotationArguments: SendQuotationInputObject!) {
    sendQuotation(input: { leasingRequestId: $leasingRequestId, sendQuotationArguments: $sendQuotationArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      guarantor
      guarantorEmail
      guarantorName
      guarantorIdNumber
      guarantorPhone
      guarantorStreet
      guarantorStreetNumber
      guarantorPostalCode
      guarantorTown
      guarantorProvince
      quotationEmailSentAt
      distributor {
        id
        quotationIntegrationEnabled
        type
      }
    }
  }
`

export default function SendQuotation(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")

  const [mutation, { data, loading, error }] = useMutation(SEND_QUOTATION, {
    onCompleted: newData => {
      if (newData.sendQuotation?.errors == null) {
        handleSuccess()
      }
    }
  })
  const { loading: queryLoading, data: queryData } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (queryLoading) return <Loading />
  if (queryData.leasingRequest === null) return <p>Not found</p>

  const errors = data?.sendQuotation?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, sendQuotationArguments: values }
    })
  }

  const getPropsForField = field => {
    return {
      field,
      label: t(`common:${field}`),
      initialValue: queryData.leasingRequest[field],
      errors
    }
  }

  const distributor = new Distributor(queryData.leasingRequest.distributor)
  const quotationEmailAlreadySent = Boolean(queryData.leasingRequest.quotationEmailSentAt)

  return (
    <Form onSubmit={handleSubmit}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} translations="leasingRequest" showAll />
          {formState.values.sendAutomaticQuotation && quotationEmailAlreadySent && (
            <Alert severity="error">{t("quotationEmailAlreadySent")}</Alert>
          )}
          {distributor.quotationIntegrationEnabled && (
            <CheckboxInput field="sendAutomaticQuotation" label={t("sendAutomaticQuotation")} initialValue />
          )}
          <GuarantorInput getPropsForField={getPropsForField} distributorId={distributor.id} />
          <ModalPrimaryButton isFetching={loading}>{t("sendQuotation")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

SendQuotation.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
