import React from "react"
import { useTranslation } from "react-i18next"
import { countryCodeOptions } from "../../../../helpers/constants"
import TextSelectInput from "../TextSelectInput"

export default function CountryCodeSelector(props) {
  const { ...rest } = props
  const { t } = useTranslation()

  return (
    <TextSelectInput
      field="countryCode"
      label={t("common:country.title")}
      options={countryCodeOptions(t)}
      humanizeFunc={value => t(`common:country.${value}`)}
      {...rest}
    />
  )
}
