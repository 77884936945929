import PropTypes from "prop-types"
import React, { useState } from "react"
import { DateRangePicker } from "react-dates"
import { useField } from "informed"
import styled from "styled-components"
import { FormControl, FormLabel } from "@material-ui/core"

const StyledFormControl = styled(FormControl)`
  position: relative;
  width: 100%;
  height: 100%;

  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`

const StyledFormLabel = styled(FormLabel)`
  position: absolute;
  left: 10px;
  top: -10px;
  z-index: 1000;
  padding: 5px;

  font-size: 0.75rem;
  background-color: #f1f1f1;
`

const StyledInputWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .DateRangePickerInput,
  .DateInput,
  .DateInput_input {
    background: none;
    border: none;
    font-size: 1rem;
  }

  .DateInput_input::placeholder {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
  }
`

export default function DateRangeInput(props) {
  const { startDateField, endDateField, startDateLabel, endDateLabel, label, ...rest } = props
  const { fieldApi: startFieldApi, fieldState: startFieldState } = useField({ ...props, field: startDateField })
  const { fieldApi: endFieldApi, fieldState: endFieldState } = useField({ ...props, field: endDateField })

  const [focusedInput, setFocusedInput] = useState(null)

  const { setValue: setStartValue, setTouched: setStartTouched } = startFieldApi
  const { setValue: setEndValue, setTouched: setEndTouched } = endFieldApi

  const { value: startValue } = startFieldState
  const { value: endValue } = endFieldState

  return (
    <StyledFormControl>
      {label && <StyledFormLabel>{label}</StyledFormLabel>}
      <StyledInputWrapper>
        <DateRangePicker
          startDate={startValue}
          startDateId={startDateField}
          startDatePlaceholderText={startDateLabel}
          endDate={endValue}
          endDateId={endDateField}
          endDatePlaceholderText={endDateLabel}
          onDatesChange={({ startDate, endDate }) => {
            setStartValue(startDate)
            setEndValue(endDate)

            if (startDate) setStartTouched()
            if (endDate) setEndTouched()
          }}
          focusedInput={focusedInput}
          onFocusChange={newFocusedInput => {
            setFocusedInput(newFocusedInput)
          }}
          isOutsideRange={() => false}
          {...rest}
        />
      </StyledInputWrapper>
    </StyledFormControl>
  )
}

DateRangeInput.propTypes = {
  startDateField: PropTypes.string,
  endDateField: PropTypes.string,
  label: PropTypes.string,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string
}

DateRangeInput.defaultProps = {
  startDateField: "startDate",
  endDateField: "endDate",
  label: "Date range",
  startDateLabel: "Start Date",
  endDateLabel: "End Date"
}
