import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { createOption } from "../../../../helpers/forms"
import TextSelectInput from "../TextSelectInput"

export default function StaffSelector(props) {
  const { ...rest } = props
  const { staff, accountId } = useSelector(mapStateToProps, shallowEqual)
  const { t } = useTranslation()

  const staffOptions = staff.filter(s => s.id !== accountId).map(s => createOption(s.id, s.name))
  staffOptions.unshift(createOption(accountId, t("me")))
  staffOptions.unshift(createOption("nil", t("none")))

  return <TextSelectInput {...rest} options={staffOptions} />
}

const mapStateToProps = state => {
  const { commonDataReducer, accountReducer } = state
  const { staff } = commonDataReducer
  const { item: account } = accountReducer

  return { staff: staff.actives, accountId: account.id }
}

StaffSelector.defaultProps = {
  field: "assigned_at_id",
  label: "Assignee"
}

StaffSelector.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string
}
