import { RECEIVE_COMMON_DATA } from "./actions"

// eslint-disable-next-line import/prefer-default-export
export const receiveCommonData = (distributors, staff) => {
  return {
    type: RECEIVE_COMMON_DATA,
    distributors,
    staff
  }
}
