import { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import LogRocket from "logrocket"
import { gql, useMutation } from "@apollo/client"
import moment from "moment"
import "moment/locale/es"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

const UPDATE_ACCOUNT = gql`
  mutation updateAccount($manualCanReceiveACall: Boolean) {
    updateAccount(input: { accountArguments: { manualCanReceiveACall: $manualCanReceiveACall } }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function Initializers(props) {
  const { children } = props
  const { accountId, accountName, accountEmail, accountLocale } = useSelector(mapStateToProps, shallowEqual)
  const [updateAccount, { called }] = useMutation(UPDATE_ACCOUNT)

  useEffect(() => {
    if (accountId) {
      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        LogRocket.identify(accountId, {
          name: accountName,
          email: accountEmail
        })
        Sentry.setUser({ email: accountEmail, username: accountName })
      }
      moment.locale(accountLocale)
    }
  }, [accountId])

  useEffect(() => {
    if (accountId && !called) {
      updateAccount({ variables: { manualCanReceiveACall: false } })
    }
  }, [accountId, called, updateAccount])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production") return

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      release: process.env.REACT_APP_GIT_INFO,
      beforeSend(event) {
        const logRocketSession = LogRocket.sessionURL
        if (logRocketSession !== null) {
          event.extra = event.extra || {} // eslint-disable-line no-param-reassign
          event.extra.LogRocket = logRocketSession // eslint-disable-line no-param-reassign
        }

        return event
      }
    })
  }, [])

  return children
}

const mapStateToProps = state => {
  const { accountReducer } = state
  const { item: account } = accountReducer

  return {
    accountId: account?.id,
    accountName: account?.name,
    accountEmail: account?.email,
    accountLocale: account?.locale
  }
}
