import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/link-error"
import LogRocket from "logrocket"
import * as Sentry from "@sentry/react"
import { getAuth, logout } from "./services/accountService"

const fetcher = (...args) => {
  return window.fetch(...args)
}

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}graphql`, fetch: fetcher })

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${getAuth("client")}`
    }
  }))

  return forward(operation)
})

const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.statusCode === 401) {
    window.location.reload() // replace that for closing CRM if inactivity
    return logout()
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      console.error(error)
      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        Sentry.captureException(error)
        LogRocket.captureMessage(error, {
          tags: {
            gql: true
          }
        })
      }
    })
  }
  return null
})

const GraphQLClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorMiddleware, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache"
    },
    query: {
      fetchPolicy: "no-cache"
    },
    mutate: {
      fetchPolicy: "no-cache"
    }
  }
})

export default GraphQLClient
