import PropTypes from "prop-types"
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { createOption } from "../../../../helpers/forms"
import TextSelectInput from "../TextSelectInput"

export default function DistributorSelector(props) {
  const { countryCode, ...rest } = props
  let { distributors } = useSelector(mapStateToProps, shallowEqual)
  if (countryCode) distributors = distributors.filter(d => d.countryCode === countryCode)
  const distributorOptions = distributors.map(d => createOption(d.id, d.name))
  const { t } = useTranslation()

  return <TextSelectInput {...rest} label={t("distributor")} options={distributorOptions} />
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

DistributorSelector.defaultProps = {
  countryCode: null,
  field: "distributorId"
}

DistributorSelector.propTypes = {
  countryCode: PropTypes.string,
  field: PropTypes.string
}
