import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import CheckboxInput from "../inputs/CheckboxInput"
import DistributorEmail from "../inputs/DistributorEmail"

const REQUEST_OFFER = gql`
  mutation requestOffer($leasingRequestId: ID!, $requestOfferArguments: RequestOfferInputObject!) {
    requestOffer(input: { leasingRequestId: $leasingRequestId, requestOfferArguments: $requestOfferArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function RequestOffer(props) {
  const { leasingRequestId, handleSuccess, distributorId, locale } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(REQUEST_OFFER, {
    onCompleted: newData => {
      if (newData.requestOffer?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.requestOffer?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, requestOfferArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ formState, formApi }) => (
        <>
          <GraphQLErrors errors={errors} />
          <CheckboxInput field="sendDistributorEmail" label={t("sendDistributorEmail")} initialValue={false} />
          {formState.values.sendDistributorEmail && (
            <DistributorEmail
              formApi={{ current: formApi }}
              leasingRequestId={leasingRequestId}
              distributorId={distributorId}
              locale={locale}
              errors={errors}
              personalDocumentationChecklist
            />
          )}
          <ModalPrimaryButton isFetching={loading}>{t("requestOffer")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

RequestOffer.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired,
  distributorId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}
