const gqlFields = `
  actionLogs(offset: $offset, limit: $limit) {
    results {
      id
      action
      callMethod
      error
      calledAt
      enqueuedAt
      transactionName
      transactionUser
      subjectId
      performerId
      parameters
    }
  }
`

export default gqlFields
