import axios from "axios"
import { getAuth } from "./accountService"

// eslint-disable-next-line import/prefer-default-export
export function uploadDocument(id, formData) {
  return axios.post(`${process.env.REACT_APP_API_URL}leasing_requests/${id}/upload_document`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `bearer ${getAuth()}`
    }
  })
}
