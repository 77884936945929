import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import StaffSelector from "../inputs/StaffSelector"

const REASSIGN_LEAD = gql`
  mutation reassignLead($id: ID!, $leadArguments: ReassignLeadInputObject!) {
    reassignLead(input: { leadId: $id, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function ReassignLead(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation("lead")
  const [mutation, { data, loading, error }] = useMutation(REASSIGN_LEAD, {
    onCompleted: newData => {
      if (newData.reassignLead?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.reassignLead?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leadId, leadArguments: values }
    })
  }

  const validationSchema = Yup.object().shape({
    assignedAtId: Yup.string().required(t("common:required"))
  })

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <GraphQLErrors errors={errors} />
      <StaffSelector field="assignedAtId" />
      <ModalPrimaryButton isFetching={loading}>{t("reassign")}</ModalPrimaryButton>
    </Form>
  )
}

ReassignLead.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
