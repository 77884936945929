import React from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import styled from "styled-components"

const SERVICES = [
  "hasCarChecking",
  "hasCarRepair",
  "hasRoadAssistance",
  "hasRoadTax",
  "deductible",
  "hasReplacementCar",
  "hasReplacementTyres",
  "preDeliveryCar",
  "refurbishInsurance",
  "homeDelivery"
]

const ServiceTitle = styled.p`
  display: inline-block;
  margin: 0;
  line-height: 20px;
`

const Service = styled.div`
  display: flex;
  align-items: center;
`

export default function Services(props) {
  const { car } = props
  const { t } = useTranslation()

  return (
    <>
      {car.hasAllRiskInsurance && (
        <Service>
          <CheckBoxIcon color="primary" />
          <ServiceTitle>
            {car.allRiskExcess
              ? t("hasAllRiskInsuranceWithExcess", { excess: car.allRiskExcess })
              : t("hasAllRiskInsurance")}
          </ServiceTitle>
        </Service>
      )}
      {SERVICES.map(service => {
        if (car[service]) {
          return (
            <Service key={service}>
              <CheckBoxIcon color="primary" />
              <ServiceTitle>{t(service)}</ServiceTitle>
            </Service>
          )
        }
        return null
      })}
    </>
  )
}

Services.propTypes = {
  car: PropTypes.object.isRequired
}
