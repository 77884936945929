import React from "react"
import { gql } from "@apollo/client"
import SignedIn from "../../../components/layouts/SignedIn"
import IndexElement from "./IndexElement"
import TableList from "../../../components/layouts/TableList"
import Allowed from "../../../components/layouts/Allowed"
import Breadcrumb from "../../../components/layouts/Breadcrumb"
import Filter from "./Filter"
import CreateLead from "../../../components/forms/CreateLead"
import useAbilities from "../../../hooks/useAbilities"

import { exportLeads } from "../../../actions/leads"

const SEARCH_LEADS = gql`
  query searchLeads(
    $q: String
    $status: LeadStatus
    $countryCode: CountryCode
    $assignedAtId: String
    $lastOpportunityCreatedAt: LeadLastOpportunityCreatedAt
    $createdAt: LeadLastOpportunityCreatedAt
    $origin: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $limit: Int
    $offset: Int
    $sort: Sort
    $orderBy: LeadsSearchOrderBy
  ) {
    searchLeads(
      q: $q
      countryCode: $countryCode
      status: $status
      assignedAtId: $assignedAtId
      lastOpportunityCreatedAt: $lastOpportunityCreatedAt
      createdAt: $createdAt
      origin: $origin
      utmSource: $utmSource
      utmMedium: $utmMedium
      utmCampaign: $utmCampaign
      limit: $limit
      offset: $offset
      sort: $sort
      orderBy: $orderBy
    ) {
      searchInfo {
        total,
        filters
      }
      results {
        id
        title
        assignedAtId
        status
        attemptsToContact
        lastOpportunityCreatedAt
        email
        countryCode
        distributor
      }
    }
  }
`

const attributes = [
  { name: "title", label: "full name", sortable: false },
  { name: "assignedAtId", label: "assignee", sortable: false },
  { name: "status", sortable: false },
  { name: "attemptsToContact", label: "attempts", sortable: true },
  { name: "lastOpportunityCreatedAt", label: "last opportunity", sortable: true },
  { name: "countryCode", label: "country code" },
  { name: "distributor", label: "distributor" }
]

export default function IndexScene() {
  const { allowed: createLead } = useAbilities("create_leads")

  return (
    <SignedIn>
      <Allowed ability={["read_leads", "manage_own_leads", "manage_country_leads", "manage_leads"]}>
        <Breadcrumb />
        <TableList
          title="Leads"
          gqlQuery={SEARCH_LEADS}
          defaultOrderBy="lastOpportunityCreatedAt"
          defaultSort="desc"
          attributes={attributes}
          Filter={Filter}
          CustomTableRow={IndexElement}
          CreationForm={createLead ? CreateLead : null}
          withExport={true}
          actionExport={currentFilters =>
            exportLeads(
              currentFilters,
              () => alert("Se enviará un email con la exportación"),
              () => alert("error")
            )
          }
        />
      </Allowed>
    </SignedIn>
  )
}
