import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import BasicInput from "../inputs/BasicInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const START_CONTRACT_EXTENSION = gql`
  mutation startContractExtension(
    $leasingRequestId: ID!
    $startContractExtensionArguments: StartContractExtensionInputObject!
  ) {
    startContractExtension(
      input: { leasingRequestId: $leasingRequestId, startContractExtensionArguments: $startContractExtensionArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function StartContractExtension(props) {
  const { leasingRequestId, handleSuccess, setOpenModal } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(START_CONTRACT_EXTENSION, {
    onCompleted: newData => {
      if (newData.startContractExtension?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.startContractExtension?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, startContractExtensionArguments: values }
    })
    setOpenModal("updatePlan")
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <BasicInput
        disabled
        type="number"
        field="duration"
        label={t("common:duration")}
        initialValue={12}
        errors={errors}
        required
      />
      <BasicInput field="comment" label={t("common:comments")} errors={errors} multiline required />
      <ModalPrimaryButton isFetching={loading}>{t("startContractExtension")}</ModalPrimaryButton>
    </Form>
  )
}

StartContractExtension.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
