import React from "react"
import makeStyles from "@material-ui/styles/makeStyles"
import styled from "styled-components"
import { Dialog, DialogContent, DialogTitle, IconButton, Slide } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import * as PropTypes from "prop-types"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Title = styled.div`
  text-transform: uppercase;
  font-size: 22px;
  margin-bottom: 0;
  color: var(--black);
`

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))

export default function FormDialog(props) {
  const { title, handleClose, open, children, closeButton } = props
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableBackdropClick={closeButton}
      disableEscapeKeyDown={closeButton}>
      <DialogTitle>
        <Title>{title}</Title>
        {closeButton && (
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  closeButton: PropTypes.bool
}

FormDialog.defaultProps = {
  closeButton: false
}
