import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Grid } from "@material-ui/core"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import Comment from "../../../../Comment"

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    "margin-top": theme.spacing(1)
  }
}))

export default function Comments(props) {
  const { leasingRequest } = props
  const { comments } = leasingRequest
  const { t } = useTranslation()
  const classes = useStyles()

  if (isEmpty(comments)) return t("noComments")

  return (
    <Grid container direction="column">
      {comments.map(comment => (
        <Grid key={comment.id} item className={classes.container}>
          <Comment comment={comment} />
        </Grid>
      ))}
    </Grid>
  )
}

Comments.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
