import React, { useState } from "react"
import { Divider, IconButton, Paper } from "@material-ui/core"
import styled from "styled-components"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import * as PropTypes from "prop-types"
import Toolbar from "@material-ui/core/Toolbar"
import { StyledH1 } from "../../styled/typography"

const ActionContainer = styled.div`
  vertical-align: top;
  float: right;
  button,
  p {
    display: inline-block;
  }
`

const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 0 0 15px 0;
  margin-bottom: 50px; /* Extra margin so sales tool button doesn't cover any input */
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  ${({ disabled }) =>
    disabled &&
    `
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  `};
`

const StyledToolbar = styled(Toolbar)`
  padding: 0 15px 0 15px;
`

const StyledTitle = styled(StyledH1)`
  margin-bottom: 0;
  flex: 1 1 100%;
`

const MenuWrapper = styled.div`
  flex: 0 0 auto;
`

const ChildrenWrapper = styled.div`
  padding: 0 15px;
`

const StyledDivider = styled(Divider)`
  margin: 0 15px;
`

export default function EntityLayout(props) {
  const {
    title,
    MenuItemsJSX,
    Modals,
    openModal,
    setOpenModal,
    refetch,
    resource,
    withAction,
    onClickAction,
    actionIcons,
    actionText,
    disabled,
    children
  } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleOpenModal = modalName => {
    return () => {
      handleMenuClose()
      setOpenModal(modalName)
    }
  }

  const closeModal = () => {
    setOpenModal(null)
  }

  if (withAction) console.warn("withAction is deprecated and will be removed")

  return (
    <StyledPaper square disabled={disabled}>
      <StyledToolbar>
        <StyledTitle>{title}</StyledTitle>
        {withAction && (
          <ActionContainer>
            {actionText && <p>{actionText}</p>}
            <IconButton onClick={onClickAction} size="small">
              {actionIcons}
            </IconButton>
          </ActionContainer>
        )}
        {MenuItemsJSX && (
          <MenuWrapper>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} keepMounted>
              <MenuItemsJSX setOpenModal={handleOpenModal} />
            </Menu>
          </MenuWrapper>
        )}
      </StyledToolbar>
      {title && <StyledDivider />}
      <ChildrenWrapper>{children}</ChildrenWrapper>
      {Modals && <Modals openModal={openModal} closeModal={closeModal} refetch={refetch} resource={resource} />}
    </StyledPaper>
  )
}

EntityLayout.defaultProps = {
  title: null,
  MenuItemsJSX: null,
  Modals: null,
  openModal: null,
  setOpenModal: null,
  refetch: null,
  resource: null,
  withAction: null,
  onClickAction: null,
  actionIcons: null,
  actionText: null,
  disabled: false
}

EntityLayout.propTypes = {
  title: PropTypes.string,
  MenuItemsJSX: PropTypes.elementType,
  Modals: PropTypes.elementType,
  openModal: PropTypes.string,
  setOpenModal: PropTypes.func,
  refetch: PropTypes.func,
  resource: PropTypes.object,
  withAction: PropTypes.bool,
  onClickAction: PropTypes.func,
  actionIcons: PropTypes.node,
  actionText: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
}
