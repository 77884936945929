import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { capitalize, isNil } from "lodash"
import { shallowEqual, useSelector } from "react-redux"
import * as Yup from "yup"
import InlineEditableField from "../../../../../components/fields/InlineEditableField"
import BasicField from "../../../../../components/fields/BasicField"
import { documentTypeOptions, jobTypeOptions, winProbabilityOptions } from "../../../../../helpers/constants"
import Loading from "../../../../../components/Loading"
import LinkField from "../../../../../components/fields/LinkField"
import StaffField from "../../../../../components/fields/StaffField"
import CarTag from "../../../../../components/CarTagList/CarTag"
import Distributor from "../../../../../models/distributor"
import { StyledH5 } from "../../../../../components/styled/typography"
import { CarListDialogContext } from "../../../../../contexts"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      title
      status
      lastStatusChangedAt
      firstName
      lastName
      secondLastName
      email
      phone
      jobType
      countryCode
      locale
      createdAt
      updatedAt
      companyName
      guarantor
      guarantorName
      guarantorEmail
      guarantorIdNumber
      guarantorPhone
      guarantorStreet
      guarantorStreetNumber
      guarantorPostalCode
      guarantorTown
      guarantorProvince
      downPayment
      pendingScheduledCallAt
      lastRollbackReason
      validatedEmail
      leadId
      clientId
      webProcessEnabled
      externalReferenceId
      distributorOfferId
      globalStatus
      deposit
      depositPaid
      assignedAtId
      contractExtensionAssignedAtId
      createdById
      scoringExpertAssignedAtId
      paidFcaRedsysMerchantOrder
      preDeliveryCar
      preDeliveryCarManaged
      preDeliveryCarOptions
      online
      idNumber
      documentType
      distributorId
      deliveryAddress
      deliveryProvince
      deliveryTown
      deliveryPostalCode
      estimatedDeliveryAt
      cif
      birthday
      birthProvince
      street
      streetNumber
      floor
      postalCode
      town
      province
      quantity
      conditions
      inReconsideration
      winProbability
      markAsBillableAt
      contractExtensionDuration
      comments(limit: 2) {
        id
        content
        createdAt
        createdById
      }
      basicCar {
        carId
        priceId
        title
        duration
        kmYear
        color
        price
        distributorId
        outdated
        deliveryTime
        carDeliveryTime
        carExternalReferenceId
        distributorContactId
        sold
      }
    }
  }
`

export default function MainData(props) {
  const { leasingRequestId, getPropsForField } = props
  const { t } = useTranslation(["common", "leasingRequest"])
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const { setSelectedCar } = useContext(CarListDialogContext)
  const { loading, data } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  const handleSelectCar = id => {
    if (setSelectedCar && id) {
      return setSelectedCar(id)
    }
    return null
  }

  const { leasingRequest } = data

  const { basicCar } = leasingRequest
  const distributor = Distributor.getDistributor(distributors, leasingRequest.distributorId)
  const distributorContact = distributor.findContact(basicCar.distributorContactId)

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().positive(t("common:positive")).integer(t("common:integer"))
  })

  return (
    <>
      <Grid item xs={12} md={4}>
        <StyledH5>{t("leasingRequest:customerData")}</StyledH5>
        {leasingRequest.clientId && <LinkField title="Client" url={`/clients/${leasingRequest.clientId}`} />}
        {leasingRequest.leadId && <LinkField title="Lead" url={`/leads/${leasingRequest.leadId}`} />}
        <InlineEditableField title={t("firstName")} {...getPropsForField("firstName", data)} />
        <InlineEditableField title={t("lastName")} {...getPropsForField("lastName", data)} />
        <InlineEditableField title={t("secondLastName")} {...getPropsForField("secondLastName", data)} />
        <InlineEditableField title="Email" {...getPropsForField("email", data)} />
        <BasicField title={t("validatedEmail")} type="boolean" value={leasingRequest.validatedEmail} />
        <InlineEditableField title={t("phone")} {...getPropsForField("phone", data)} />
        <InlineEditableField
          title={t("jobType")}
          type="select"
          humanizeFunc={value => {
            if (value) return t(`jobTypes.${value}`)
            return null
          }}
          options={jobTypeOptions}
          {...getPropsForField("jobType", data)}
        />
        <BasicField
          title={t("country.title")}
          humanizeFunc={value => t(`country.${value}`)}
          value={leasingRequest.countryCode}
        />
        <BasicField
          title={t("locale.title")}
          humanizeFunc={value => t(`locale.${value}`)}
          value={leasingRequest.locale}
        />
        <InlineEditableField title={t("companyName")} {...getPropsForField("companyName", data)} />
        <InlineEditableField title={t("companyCif")} {...getPropsForField("cif", data)} />
        <InlineEditableField
          title={t("documentType")}
          type="select"
          {...getPropsForField("documentType", data)}
          options={documentTypeOptions}
          required
        />
        <InlineEditableField title={t("idNumber")} {...getPropsForField("idNumber", data)} />
        <InlineEditableField title={t("birthday")} type="date" {...getPropsForField("birthday", data)} />
        <InlineEditableField title={t("birthProvince")} {...getPropsForField("birthProvince", data)} />
        <InlineEditableField title={t("street")} {...getPropsForField("street", data)} />
        <InlineEditableField title={t("streetNumber")} {...getPropsForField("streetNumber", data)} />
        <InlineEditableField title={t("floor")} {...getPropsForField("floor", data)} />
        <InlineEditableField title={t("postalCode")} {...getPropsForField("postalCode", data)} />
        <InlineEditableField title={t("town")} {...getPropsForField("town", data)} />
        <InlineEditableField title={t("province")} {...getPropsForField("province", data)} />
        <StyledH5>{t("guarantor")}</StyledH5>
        <InlineEditableField type="boolean" title={t("guarantor")} {...getPropsForField("guarantor", data)} />
        <InlineEditableField title={t("guarantorName")} {...getPropsForField("guarantorName", data)} />
        <InlineEditableField title={t("guarantorEmail")} {...getPropsForField("guarantorEmail", data)} />
        <InlineEditableField title={t("guarantorIdNumber")} {...getPropsForField("guarantorIdNumber", data)} />
        <InlineEditableField title={t("guarantorPhone")} {...getPropsForField("guarantorPhone", data)} />
        <InlineEditableField title={t("guarantorStreet")} {...getPropsForField("guarantorStreet", data)} />
        <InlineEditableField title={t("guarantorStreetNumber")} {...getPropsForField("guarantorStreetNumber", data)} />
        <InlineEditableField title={t("guarantorPostalCode")} {...getPropsForField("guarantorPostalCode", data)} />
        <InlineEditableField title={t("guarantorTown")} {...getPropsForField("guarantorTown", data)} />
        <InlineEditableField title={t("guarantorProvince")} {...getPropsForField("guarantorProvince", data)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledH5>{t("leasingRequest:carData")}</StyledH5>
        {leasingRequest.webProcessEnabled && (
          <LinkField
            title="Web process"
            url={`${process.env.REACT_APP_WEB_URL}${leasingRequest.countryCode}/online/${leasingRequest.id}`}
          />
        )}
        <BasicField title={t("car")} value={basicCar.title} handleClick={() => handleSelectCar(basicCar.carId)} />
        <BasicField
          title={t("plan")}
          value={t("concretePlan", {
            duration: basicCar.duration,
            kmYear: basicCar.kmYear,
            price: basicCar.price
          })}
        />
        {basicCar.outdated && <CarTag tagName="outdated" small />}
        {basicCar.sold && <CarTag tagName="sold" small />}
        <InlineEditableField
          title={t("quantity")}
          type="number"
          validationSchema={validationSchema}
          {...getPropsForField("quantity", data)}
        />
        {basicCar.deliveryTime && (
          <BasicField
            title={t("deliveryTimeOnCreate")}
            value={t("concreteDeliveryTime", {
              deliveryTimeFrom: basicCar.deliveryTime - 1,
              deliveryTimeUntil: basicCar.deliveryTime + 1
            })}
          />
        )}
        <BasicField
          title={t("currentCarDeliveryTime")}
          value={t("concreteDeliveryTime", {
            deliveryTimeFrom: basicCar.carDeliveryTime - 1,
            deliveryTimeUntil: basicCar.carDeliveryTime + 1
          })}
        />
        <BasicField title={t("color")} value={capitalize(t(`colors.${basicCar.color}`))} />
        <InlineEditableField
          title={t("leasingRequest:deliveryAddress")}
          {...getPropsForField("deliveryAddress", data)}
        />
        <InlineEditableField
          title={t("leasingRequest:deliveryPostalCode")}
          {...getPropsForField("deliveryPostalCode", data)}
        />
        <InlineEditableField title={t("leasingRequest:deliveryTown")} {...getPropsForField("deliveryTown", data)} />
        <InlineEditableField
          title={t("leasingRequest:deliveryProvince")}
          {...getPropsForField("deliveryProvince", data)}
        />
        <InlineEditableField
          type="date"
          title={t("leasingRequest:estimatedDeliveryAt")}
          {...getPropsForField("estimatedDeliveryAt", data)}
        />
        <BasicField title={t("carExternalReferenceId")} value={basicCar.carExternalReferenceId} />
        <StyledH5>{t("distributor")}</StyledH5>
        <BasicField title={t("distributor")} value={distributor.name} />
        <BasicField
          title={t("leasingRequest:distributorContact")}
          value={distributorContact ? `${distributorContact.fullName} <${distributorContact.email}>` : t("notDefined")}
        />
        <BasicField title={t("onlineScoring")} type="boolean" value={leasingRequest.online} />
        <InlineEditableField title={t("externalReferenceId")} {...getPropsForField("externalReferenceId", data)} />
        <BasicField title={t("distributorOfferId")} value={leasingRequest.distributorOfferId} />
        <BasicField title={t("leasingRequest:preDeliveryCar")} type="boolean" value={leasingRequest.preDeliveryCar} />
        <BasicField
          title={t("preDeliveryCarOptions")}
          value={leasingRequest.preDeliveryCarOptions
            .map(option => t(`preDeliveryCarOptionLabels.${option}`))
            .join(", ")}
        />
        <BasicField
          title={t("leasingRequest:preDeliveryCarManaged")}
          type="boolean"
          value={leasingRequest.preDeliveryCarManaged}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledH5>{t("scoring")}</StyledH5>
        <BasicField title={t("lastStatusChangedAt")} type="datetime" value={leasingRequest.lastStatusChangedAt} />
        <BasicField
          title={t("leasingRequestGlobalStatus.title")}
          value={leasingRequest.globalStatus}
          humanizeFunc={v => t(`leasingRequestGlobalStatus.${v}`)}
        />
        <BasicField
          title={t("contractExtensionDuration")}
          value={leasingRequest.contractExtensionDuration}
          humanizeFunc={v => v && t("month", { count: v })}
        />
        <InlineEditableField title={t("depositTitle")} type="number" {...getPropsForField("deposit", data)} />
        <BasicField title={t("leasingRequest:depositPaid")} type="boolean" value={leasingRequest.depositPaid} />
        <BasicField title={t("leasingRequest:conditions")} type="boolean" value={leasingRequest.conditions} />
        <BasicField
          type="boolean"
          title={t("leasingRequest:inReconsideration")}
          value={leasingRequest.inReconsideration}
        />
        <BasicField
          title={t("leasingRequest:paidFcaRedsysMerchantOrder")}
          value={leasingRequest.paidFcaRedsysMerchantOrder}
        />
        <InlineEditableField type="number" title={t("downPayment")} {...getPropsForField("downPayment", data)} />
        <StyledH5>{t("internal")}</StyledH5>
        <StaffField title={t("assignedAt")} value={leasingRequest.assignedAtId} />
        <StaffField title={t("contractExtensionAssignedAtId")} value={leasingRequest.contractExtensionAssignedAtId} />
        <StaffField title={t("createdBy")} value={leasingRequest.createdById} />
        <StaffField title={t("staffRole.ScoringExpert")} value={leasingRequest.scoringExpertAssignedAtId} />
        <BasicField title={t("lastRollbackReason")} value={leasingRequest.lastRollbackReason} multiline />
        <BasicField title={t("createdAt")} type="datetime" value={leasingRequest.createdAt} />
        <BasicField title={t("updatedAt")} type="datetime" value={leasingRequest.updatedAt} />
        <InlineEditableField
          type="select"
          title={t("leasingRequest:winProbability")}
          options={winProbabilityOptions}
          humanizeFunc={v => (v ? `${v}%` : "-")}
          {...getPropsForField("winProbability", data)}
        />
        <InlineEditableField
          title={t("leasingRequest:markAsBillableAt")}
          type="date"
          {...getPropsForField("markAsBillableAt", data)}
        />
      </Grid>
    </>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

MainData.propTypes = {
  getPropsForField: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired
}
