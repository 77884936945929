import find from "lodash/find"
import { createOption } from "../helpers/forms"

export default class Distributor {
  constructor(distributor) {
    Object.assign(this, distributor)
    if (!this.contacts) {
      this.contacts = []
    }
  }

  static getDistributor(distributors, id) {
    if (!distributors) return null
    return find(distributors, { id })
  }

  static getName(distributors, id) {
    const distributor = find(distributors, { id })

    return distributor?.name
  }

  findContact(contactId) {
    return find(this.contacts, { id: contactId })
  }

  contactOptions() {
    return this.contacts.map(c => createOption(c.id, `${c.fullName} <${c.email}>`))
  }

  isFCAES() {
    return this.type === "Distributor::FcaEs"
  }

  isLeaseplanES() {
    return this.type === "Distributor::LeaseplanEs"
  }

  isLeaseplanFleetsES() {
    return this.type === "Distributor::LeaseplanFleetsEs"
  }
}
