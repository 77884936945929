import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Divider from "@material-ui/core/Divider/Divider"
import styled from "styled-components"
import { gql, useQuery } from "@apollo/client"
import { isNil } from "lodash"
import InlineEditableField from "../../../../../components/fields/InlineEditableField"
import BasicField from "../../../../../components/fields/BasicField"
import { jobTypeOptions } from "../../../../../helpers/constants"
import Opportunity from "../Opportunities/Opportunity"
import { StyledH3 } from "../../../../../components/styled/typography"
import InterestedCar from "../../../../../components/InterestedCar"
import Loading from "../../../../../components/Loading"

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      title
      status
      lastStatusChangedAt
      firstName
      lastName
      secondLastName
      email
      phone
      jobType
      countryCode
      locale
      origin
      utmMultistep
      modelDesired
      defaulter
      monthlyIncome
      monthlyPayment
      carKindDesired
      annualKilometers
      companyName
      guarantorName
      guarantorEmail
      guarantorIdNumber
      guarantorPhone
      guarantorStreet
      guarantorStreetNumber
      guarantorPostalCode
      guarantorTown
      guarantorProvince
      pendingScheduledCallAt
      lastRollbackCreatedById
      lastRollbackReason
      unansweredEmail
      wrongNumber
      currentFleetCar
      currentFleetVans
      currentFleetTrucks
      currentTotalFleet
      currentFleetDescription
      nextFleetRenewal
      wantExtendFleet
      carFleetExpansionQuantity
      vansFleetExpansionQuantity
      trucksFleetExpansionQuantity
      totalFleetExpansionQuantity
      comments(limit: 2) {
        id
        content
        createdAt
        createdById
      }
      opportunities(limit: 3) {
        id
        kind
        createdAt
        basicCar {
          carId
          priceId
          title
          duration
          kmYear
          color
          price
          deliveryTime
          distributorId
          outdated
        }
      }
      interestedCars {
        id
        title
        carId
        version
        color
        distributorId
        priceId
        duration
        kmYear
        price
        outdated
        deliveryTime
        createdAt
      }
    }
  }
`

const TopicWrapper = styled(Grid)`
  margin: 30px 0 0 0;
`

const StyledDivider = styled(Divider)`
  margin: 0 5px 15px 0;
`

export default function PersonalData(props) {
  const { leadId, getPropsForField, setOpenModal } = props
  const { t } = useTranslation("common", "lead")
  const { loading, data, refetch } = useQuery(LEAD, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.lead)) return <p>Not found</p>

  const { lead } = data
  const { opportunities, interestedCars } = lead

  return (
    <>
      <Grid item xs={12} md={3}>
        <InlineEditableField title={t("firstName")} {...getPropsForField("firstName", data)} />
        <InlineEditableField title={t("lastName")} {...getPropsForField("lastName", data)} />
        <InlineEditableField title={t("secondLastName")} {...getPropsForField("secondLastName", data)} />
        <InlineEditableField title="Email" {...getPropsForField("email", data)} />
        <InlineEditableField title={t("phone")} {...getPropsForField("phone", data)} />
        <InlineEditableField
          title={t("jobType")}
          type="select"
          humanizeFunc={value => {
            if (value) return t(`jobTypes.${value}`)
            return null
          }}
          options={jobTypeOptions}
          {...getPropsForField("jobType", data)}
        />
        <BasicField title={t("country.title")} humanizeFunc={value => t(`country.${value}`)} value={lead.countryCode} />
        <BasicField title={t("locale.title")} humanizeFunc={value => t(`locale.${value}`)} value={lead.locale} />
      </Grid>
      <Grid item xs={12} md={3}>
        <BasicField title={t("lead:origin")} value={lead.origin} />
        <BasicField title={t("lead:defaulter")} value={lead.defaulter} type="boolean" />
        <BasicField title={t("lead:monthlyIncome.title")} value={t(`lead:monthlyIncome.${lead.monthlyIncome}`)} />
        <BasicField title={t("lead:monthlyPayment.title")} value={t(`lead:monthlyPayment.${lead.monthlyPayment}`)} />
        <BasicField
          title={t("lead:annualKilometers.title")}
          value={t(`lead:annualKilometers.${lead.annualKilometers}`)}
        />
        <BasicField title={t("lead:utmMultistep")} value={lead.utmMultistep} />
        <BasicField title={t("lead:modelDesired")} value={lead.modelDesired} />
        <BasicField
          title={t("lead:carKindDesired")}
          value={lead.carKindDesired}
          humanizeFunc={value => value?.map(v => t(`kind.${v}`)).join(", ")}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InlineEditableField title={t("companyName")} {...getPropsForField("companyName", data)} />
        <InlineEditableField title={t("guarantorName")} {...getPropsForField("guarantorName", data)} />
        <InlineEditableField title={t("guarantorEmail")} {...getPropsForField("guarantorEmail", data)} />
        <InlineEditableField title={t("guarantorIdNumber")} {...getPropsForField("guarantorIdNumber", data)} />
        <InlineEditableField title={t("guarantorPhone")} {...getPropsForField("guarantorPhone", data)} />
        <InlineEditableField title={t("guarantorStreet")} {...getPropsForField("guarantorStreet", data)} />
        <InlineEditableField title={t("guarantorStreetNumber")} {...getPropsForField("guarantorStreetNumber", data)} />
        <InlineEditableField title={t("guarantorPostalCode")} {...getPropsForField("guarantorPostalCode", data)} />
        <InlineEditableField title={t("guarantorTown")} {...getPropsForField("guarantorTown", data)} />
        <InlineEditableField title={t("guarantorProvince")} {...getPropsForField("guarantorProvince", data)} />
      </Grid>
      <Grid item xs={12} md={3}>
        <InlineEditableField
          type="number"
          title={t("lead:currentFleetCar")}
          {...getPropsForField("currentFleetCar", data)}
        />
        <InlineEditableField
          type="number"
          title={t("lead:currentFleetVans")}
          {...getPropsForField("currentFleetVans", data)}
        />
        <InlineEditableField
          type="number"
          title={t("lead:currentFleetTrucks")}
          {...getPropsForField("currentFleetTrucks", data)}
        />
        <InlineEditableField
          type="number"
          title={t("lead:currentTotalFleet")}
          {...getPropsForField("currentTotalFleet", data)}
        />
        <InlineEditableField
          title={t("lead:currentFleetDescription")}
          {...getPropsForField("currentFleetDescription", data)}
        />
        <InlineEditableField
          type="date"
          title={t("lead:nextFleetRenewal")}
          {...getPropsForField("nextFleetRenewal", data)}
        />
        <InlineEditableField
          type="boolean"
          title={t("lead:wantExtendFleet")}
          {...getPropsForField("wantExtendFleet", data)}
        />
        <InlineEditableField
          type="number"
          title={t("lead:carFleetExpansionQuantity")}
          {...getPropsForField("carFleetExpansionQuantity", data)}
        />
        <InlineEditableField
          type="number"
          title={t("lead:vansFleetExpansionQuantity")}
          {...getPropsForField("vansFleetExpansionQuantity", data)}
        />
        <InlineEditableField
          type="number"
          title={t("lead:trucksFleetExpansionQuantity")}
          {...getPropsForField("trucksFleetExpansionQuantity", data)}
        />
        <InlineEditableField
          type="number"
          title={t("lead:totalFleetExpansionQuantity")}
          {...getPropsForField("totalFleetExpansionQuantity", data)}
        />
      </Grid>
      <TopicWrapper container>
        <Grid item xs={12}>
          <StyledH3>{t("lead:opportunities.last")}</StyledH3>
          <StyledDivider />
        </Grid>
        {opportunities.map(opportunity => (
          <Grid item xs={12} md={6} key={opportunity.id}>
            <Opportunity key={opportunity.id} opportunity={opportunity} setOpenModal={setOpenModal} />
          </Grid>
        ))}
      </TopicWrapper>
      <TopicWrapper container>
        <Grid item xs={12}>
          <StyledH3>{t("lead:interestedIn")}</StyledH3>
          <StyledDivider />
        </Grid>
        {interestedCars.map(interestedCar => (
          <Grid item xs={12} md={6} key={interestedCar.id}>
            <InterestedCar
              key={interestedCar.id}
              subjectId={lead.id}
              subjectType="Lead"
              interestedCar={interestedCar}
              refetch={refetch}
              setOpenModal={setOpenModal}
            />
          </Grid>
        ))}
      </TopicWrapper>
    </>
  )
}

PersonalData.propTypes = {
  getPropsForField: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired
}
