import PropTypes from "prop-types"
import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Staff from "../../../../../models/staff"
import { MultiLineParagraph } from "../../../../../components/styled/typography"

const StyledAlert = styled(Alert)`
  margin-top: 5px;
`

function PendingOnlineLeasingRequestAlert(props) {
  const { pendingOnlineLeasingRequest } = props
  const { t } = useTranslation("lead")

  if (!pendingOnlineLeasingRequest) return null

  return (
    <StyledAlert severity="warning">
      <AlertTitle>{t("pendingOnlineLeasingRequest")}</AlertTitle>
      <MultiLineParagraph>{t("pendingOnlineLeasingRequestExplanation")}</MultiLineParagraph>
    </StyledAlert>
  )
}

function LastRollbackAlert(props) {
  const { status, staffName, lastRollbackReason } = props

  if (status !== "rollback") return null

  return (
    <StyledAlert severity="warning">
      <AlertTitle>Rollback</AlertTitle>
      <MultiLineParagraph>
        {lastRollbackReason} | {staffName}
      </MultiLineParagraph>
    </StyledAlert>
  )
}

function LastScoringRejectedAlert(props) {
  const { staffName, lastScoringRejectedReason, lastScoringRejectedReasonComment } = props
  const { t } = useTranslation()

  if (!lastScoringRejectedReason) return null

  return (
    <StyledAlert severity="warning">
      <AlertTitle>{t(`closeReasonOptions.${lastScoringRejectedReason}`)}</AlertTitle>
      <MultiLineParagraph>
        {lastScoringRejectedReasonComment} | {staffName}
      </MultiLineParagraph>
    </StyledAlert>
  )
}

function AnswerEmail(props) {
  const { unansweredEmail } = props
  const { t } = useTranslation("lead")

  if (!unansweredEmail) return null

  return (
    <StyledAlert severity="warning">
      <AlertTitle>{t("pendingEmail")}</AlertTitle>
      {t("pendingEmailExplanation")}
    </StyledAlert>
  )
}

function WrongNumber(props) {
  const { wrongNumber } = props
  const { t } = useTranslation("lead")

  if (!wrongNumber) return null

  return (
    <StyledAlert severity="error">
      <AlertTitle>{t("wrongNumber")}</AlertTitle>
      {t("wrongNumberAlert")}
    </StyledAlert>
  )
}

export default function Alerts(props) {
  const {
    status,
    lastRollbackReason,
    lastRollbackCreatedById,
    lastScoringRejectedReason,
    lastScoringRejectedReasonComment,
    lastScoringRejectedById,
    unansweredEmail,
    wrongNumber,
    pendingOnlineLeasingRequest
  } = props
  const { staff } = useSelector(mapStateToProps, shallowEqual)
  const rollbackStaffName = Staff.getName(staff, lastRollbackCreatedById)
  const scoringRejectedStaffName = Staff.getName(staff, lastScoringRejectedById)

  return (
    <>
      <LastScoringRejectedAlert
        staffName={scoringRejectedStaffName}
        lastScoringRejectedReason={lastScoringRejectedReason}
        lastScoringRejectedReasonComment={lastScoringRejectedReasonComment}
      />
      <LastRollbackAlert status={status} staffName={rollbackStaffName} lastRollbackReason={lastRollbackReason} />
      <PendingOnlineLeasingRequestAlert pendingOnlineLeasingRequest={pendingOnlineLeasingRequest} />
      <AnswerEmail unansweredEmail={unansweredEmail} />
      <WrongNumber wrongNumber={wrongNumber} />
    </>
  )
}
const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff } = commonDataReducer

  return { staff: staff.items }
}

WrongNumber.propTypes = {
  wrongNumber: PropTypes.bool.isRequired
}

AnswerEmail.propTypes = {
  unansweredEmail: PropTypes.bool.isRequired
}

LastScoringRejectedAlert.propTypes = {
  lastScoringRejectedReason: PropTypes.string,
  lastScoringRejectedReasonComment: PropTypes.string,
  staffName: PropTypes.string
}

LastScoringRejectedAlert.defaultProps = {
  lastScoringRejectedReason: null,
  lastScoringRejectedReasonComment: null,
  staffName: null
}

LastRollbackAlert.propTypes = {
  lastRollbackReason: PropTypes.string,
  staffName: PropTypes.string,
  status: PropTypes.string.isRequired
}

LastRollbackAlert.defaultProps = {
  lastRollbackReason: null,
  staffName: null
}

PendingOnlineLeasingRequestAlert.propTypes = {
  pendingOnlineLeasingRequest: PropTypes.bool.isRequired
}

Alerts.propTypes = {
  lastRollbackCreatedById: PropTypes.string,
  lastRollbackReason: PropTypes.string,
  lastScoringRejectedReason: PropTypes.string,
  lastScoringRejectedReasonComment: PropTypes.string,
  lastScoringRejectedById: PropTypes.string,
  status: PropTypes.string.isRequired,
  unansweredEmail: PropTypes.bool,
  wrongNumber: PropTypes.bool,
  pendingOnlineLeasingRequest: PropTypes.bool.isRequired
}

Alerts.defaultProps = {
  lastRollbackCreatedById: null,
  lastRollbackReason: null,
  lastScoringRejectedReason: null,
  lastScoringRejectedReasonComment: null,
  lastScoringRejectedById: null,
  unansweredEmail: false,
  wrongNumber: false
}
