import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import BasicInput from "../inputs/BasicInput"
import TextSelectInput from "../inputs/TextSelectInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const RESOLVE_CONTRACT_EXTENSION = gql`
  mutation resolveContractExtension(
    $leasingRequestId: ID!
    $resolveContractExtensionArguments: ResolveContractExtensionInputObject!
  ) {
    resolveContractExtension(
      input: {
        leasingRequestId: $leasingRequestId
        resolveContractExtensionArguments: $resolveContractExtensionArguments
      }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

const nextStatusOption = t => [
  { value: "approved", label: t("common:contractExtensionStatus.approved") },
  { value: "rejected", label: t("common:contractExtensionStatus.rejected") }
]

export default function ResolveContractExtension(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(RESOLVE_CONTRACT_EXTENSION, {
    onCompleted: newData => {
      if (newData.resolveContractExtension?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.resolveContractExtension?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, resolveContractExtensionArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <TextSelectInput
        field="status"
        label={t("common:contractExtensionStatus.title")}
        options={nextStatusOption(t)}
        errors={errors}
        required
      />
      <BasicInput field="comment" label={t("common:comments")} errors={errors} multiline required />
      <ModalPrimaryButton isFetching={loading}>{t("resolveContractExtension")}</ModalPrimaryButton>
    </Form>
  )
}

ResolveContractExtension.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
