import React, { lazy, Suspense } from "react"
import { HashRouter as Router, Route, Switch } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

import DashboardScene from "./scenes/DashboardScene"
import HelpScene from "./scenes/HelpScene"

import MainScene from "./scenes/MainScene"

import IndexLeasingRequestScene from "./scenes/LeasingRequestScene/IndexScene"
import ShowLeasingRequestScene from "./scenes/LeasingRequestScene/ShowScene"

import IndexLeadScene from "./scenes/LeadScene/IndexScene"
import ShowLeadScene from "./scenes/LeadScene/ShowScene"

import Initializers from "./Initializers"
import Twilio from "./components/Twilio"
import GraphQLClient from "./GraphQLClient"
import LogRocketInitializer from "./components/LogRocketInitializer"
import ActionCable from "./components/ActionCable"
import ManifestUpdateNotifier from "./components/ManifestUpdateNotifier"

const AnalyticsScene = lazy(() => import("./scenes/AnalyticsScene"))
const IndexAnalyticsScene = lazy(() => import("./scenes/AnalyticsBetaScene/IndexScene"))
const ShowAnalyticsScene = lazy(() => import("./scenes/AnalyticsBetaScene/ShowScene"))

const IndexCarScene = lazy(() => import("./scenes/CarScene/IndexScene"))
const LandingsCarScene = lazy(() => import("./scenes/CarScene/LandingsScene"))
const ShowCarScene = lazy(() => import("./scenes/CarScene/ShowScene"))

const IndexDistributorScene = lazy(() => import("./scenes/DistributorScene/IndexScene"))
const ShowDistributorScene = lazy(() => import("./scenes/DistributorScene/ShowScene"))

const IndexDealerScene = lazy(() => import("./scenes/DealerScene/IndexScene"))
const ShowDealerScene = lazy(() => import("./scenes/DealerScene/ShowScene"))

const IndexBrandScene = lazy(() => import("./scenes/BrandScene/IndexScene"))
const ShowBrandScene = lazy(() => import("./scenes/BrandScene/ShowScene"))

const IndexStaffScene = lazy(() => import("./scenes/StaffScene/IndexScene"))
const AbilitiesStaffScene = lazy(() => import("./scenes/StaffScene/AbilitiesScene"))
const ShowStaffScene = lazy(() => import("./scenes/StaffScene/ShowScene"))
const TaskAssignmentScene = lazy(() => import("./scenes/StaffScene/TaskAssignmentScene"))
const OnlineScene = lazy(() => import("./scenes/StaffScene/OnlineScene"))
const QualityScene = lazy(() => import("./scenes/StaffScene/QualityScene"))

const IndexTransactionalSmsScene = lazy(() => import("./scenes/TransactionalSmsScene/IndexScene"))
const ShowTransactionalSmsScene = lazy(() => import("./scenes/TransactionalSmsScene/ShowScene"))

const IndexTransactionalEmailScene = lazy(() => import("./scenes/TransactionalEmailScene/IndexScene"))
const ShowTransactionalEmailScene = lazy(() => import("./scenes/TransactionalEmailScene/ShowScene"))

const IndexEmailTemplateScene = lazy(() => import("./scenes/EmailTemplateScene/IndexScene"))
const ShowEmailTemplateScene = lazy(() => import("./scenes/EmailTemplateScene/ShowScene"))

const IndexSalesScene = lazy(() => import("./scenes/SalesScene/IndexScene"))

const IndexClientScene = lazy(() => import("./scenes/ClientScene/IndexScene"))
const ShowClientScene = lazy(() => import("./scenes/ClientScene/ShowScene"))

const IndexTaskScene = lazy(() => import("./scenes/TaskScene/IndexScene"))
const ShowTaskScene = lazy(() => import("./scenes/TaskScene/ShowScene"))

const ShowClientRequestScene = lazy(() => import("./scenes/ClientRequestScene/ShowScene"))
const IndexClientRequestScene = lazy(() => import("./scenes/ClientRequestScene/IndexScene"))

export default function App() {
  return (
    <LogRocketInitializer>
      <ApolloProvider client={GraphQLClient}>
        <Initializers>
          <ActionCable>
            <Twilio>
              <ManifestUpdateNotifier>
                <Router>
                  <Suspense fallback={null}>
                    <Switch>
                      <Route exact path="/dashboard" component={DashboardScene} />
                      <Route exact path="/help" component={HelpScene} />
                      <Route exact path="/staff/abilities" component={AbilitiesStaffScene} />
                      <Route exact path="/staff/task-assignment" component={TaskAssignmentScene} />
                      <Route exact path="/staff/online" component={OnlineScene} />
                      <Route exact path="/staff/quality" component={QualityScene} />
                      <Route exact path="/staff/:staffId" component={ShowStaffScene} />
                      <Route exact path="/staff" component={IndexStaffScene} />
                      <Route exact path="/help" component={HelpScene} />
                      <Route exact path="/analytics" component={AnalyticsScene} />
                      <Route exact path="/analytics-beta/:dashboard" component={ShowAnalyticsScene} />
                      <Route exact path="/analytics-beta" component={IndexAnalyticsScene} />
                      <Route exact path="/cars/landings" component={LandingsCarScene} />
                      <Route exact path="/cars/:carId" component={ShowCarScene} />
                      <Route exact path="/cars" component={IndexCarScene} />
                      <Route exact path="/brands/:brandId" component={ShowBrandScene} />
                      <Route exact path="/brands" component={IndexBrandScene} />
                      <Route
                        exact
                        path="/transactional-emails/:transactionalEmailId"
                        component={ShowTransactionalEmailScene}
                      />
                      <Route exact path="/transactional-emails" component={IndexTransactionalEmailScene} />
                      <Route
                        exact
                        path="/transactional-sms/:transactionalSmsId"
                        component={ShowTransactionalSmsScene}
                      />
                      <Route exact path="/transactional-sms" component={IndexTransactionalSmsScene} />
                      <Route exact path="/email-templates/:emailTemplateId" component={ShowEmailTemplateScene} />
                      <Route exact path="/email-templates" component={IndexEmailTemplateScene} />
                      <Route exact path="/distributors/:distributorId" component={ShowDistributorScene} />
                      <Route exact path="/distributors" component={IndexDistributorScene} />
                      <Route exact path="/dealers/:dealerId" component={ShowDealerScene} />
                      <Route exact path="/dealers" component={IndexDealerScene} />
                      <Route exact path="/leads/:leadId" component={ShowLeadScene} />
                      <Route exact path="/leads" component={IndexLeadScene} />
                      <Route exact path="/leasing-requests/:leasingRequestId" component={ShowLeasingRequestScene} />
                      <Route exact path="/leasing-requests" component={IndexLeasingRequestScene} />
                      <Route exact path="/sales" component={IndexSalesScene} />
                      <Route exact path="/clients/:clientId" component={ShowClientScene} />
                      <Route exact path="/clients" component={IndexClientScene} />
                      <Route exact path="/client-requests/:clientRequestId" component={ShowClientRequestScene} />
                      <Route exact path="/client-requests" component={IndexClientRequestScene} />
                      <Route exact path="/tasks/:clientId" component={ShowTaskScene} />
                      <Route exact path="/tasks" component={IndexTaskScene} />
                      <Route exact path="" component={MainScene} />
                    </Switch>
                  </Suspense>
                </Router>
              </ManifestUpdateNotifier>
            </Twilio>
          </ActionCable>
        </Initializers>
      </ApolloProvider>
    </LogRocketInitializer>
  )
}
