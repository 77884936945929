import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Card, CardActions, CardContent, CardHeader, IconButton } from "@material-ui/core"
import StarIcon from "@material-ui/icons/Star"
import styled from "styled-components"
import Fab from "@material-ui/core/Fab"
import CardMedia from "@material-ui/core/CardMedia"
import { shallowEqual, useSelector } from "react-redux"
import SearchIcon from "@material-ui/icons/Search"
import { useTranslation } from "react-i18next"
import { camelCase, upperFirst } from "lodash"
import { CarListDialogContext, CustomerEntityContext } from "../../../../contexts"
import { printCurrency } from "../../../../helpers/formatter"
import { jobTypes } from "../../../../helpers/constants"
import Distributor from "../../../../models/distributor"
import CarTagList from "../../../CarTagList"
import { Paragraph } from "../../../styled/typography"

const StyledCard = styled(Card)`
  margin: 10px 5px;
  background-color: var(--light-grey);
`

const StyledCardDisabled = styled(StyledCard)`
  opacity: 0.6;
`

const StyledCardMedia = styled(CardMedia)`
  height: 200px;
  background-color: #e6e6e6;
`

const ActiveStarIcon = styled(StarIcon)`
  color: var(--stormy-blue);
`

const CarTitle = styled.p`
  color: var(--stormy-blue);
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`

const DeliveryTime = styled(Paragraph)`
  margin-bottom: 5px;
`

const Price = styled.p`
  color: var(--stormy-blue);
  font-weight: bold;
  margin: 0;
`

const StyledCardActions = styled(CardActions)`
  justify-content: space-between;
  align-items: center;
`

const StyledCardContent = styled(CardContent)`
  min-height: 150px;
`

const jobTypeAvailable = (car, jobType) => {
  return car[`availableFor${upperFirst(camelCase(jobType))}`]
}

function JobTypeAvailabilityHint(props) {
  const { car } = props
  const { t } = useTranslation()

  const availableTypes = jobTypes.filter(jobType => jobTypeAvailable(car, jobType))

  switch (availableTypes.length) {
    case 0:
      return <Paragraph>{t("jobTypes.carsAvailability.none")}</Paragraph>
    case 1:
      return <Paragraph>{t("jobTypes.carsAvailability.one", { type: t(`jobTypes.${availableTypes[0]}`) })}</Paragraph>
    case 2:
      return (
        <Paragraph>
          {t("jobTypes.carsAvailability.two", {
            firstType: t(`jobTypes.${availableTypes[0]}`),
            secondType: t(`jobTypes.${availableTypes[1]}`)
          })}
        </Paragraph>
      )
    default:
      return null
  }
}

JobTypeAvailabilityHint.propTypes = {
  car: PropTypes.object.isRequired
}

export default function CarItem(props) {
  const { car, setFavCar, fav, setSelectedCar } = props
  const { t } = useTranslation()
  // TODO: Passing jobType on CarListDialogContext is deprecated. Use CustomerEntityContext instead.
  const { options } = useContext(CarListDialogContext)
  const customerEntityContext = useContext(CustomerEntityContext)
  const jobType = customerEntityContext.jobType || options.jobType
  const { distributors } = useSelector(mapStateToProps, shallowEqual)

  const distributor = Distributor.getDistributor(distributors, car.distributorId)
  const available = jobTypeAvailable(car, jobType)
  const StyledCardType = available ? StyledCard : StyledCardDisabled

  const togglePinned = () => {
    setFavCar(car)
  }

  return (
    <StyledCardType>
      <CardHeader
        action={
          <IconButton disabled={!available} onClick={togglePinned}>
            {fav ? <ActiveStarIcon /> : <StarIcon />}
          </IconButton>
        }
        title={<CarTitle>{`${car.brand} ${car.model}`}</CarTitle>}
        subheader={car.version}
      />
      <StyledCardMedia image={car.defaultImageUrl} />
      <StyledCardContent>
        <Paragraph>{distributor.name}</Paragraph>
        <CarTagList car={car} />
        {!available && <JobTypeAvailabilityHint car={car} />}
      </StyledCardContent>
      <StyledCardActions disableSpacing>
        <div>
          {car.deliveryTime && <DeliveryTime>{t("carDeliveryTime", { count: car.deliveryTime })}</DeliveryTime>}
          <Price>{t("minimumPrice", { minimumPrice: printCurrency(car.minimumPrice) })}</Price>
        </div>
        {setSelectedCar && (
          <Fab size="small" color="primary" disabled={!available} onClick={() => setSelectedCar(car.id)}>
            <SearchIcon />
          </Fab>
        )}
      </StyledCardActions>
    </StyledCardType>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

CarItem.propTypes = {
  car: PropTypes.object.isRequired,
  fav: PropTypes.bool.isRequired,
  setFavCar: PropTypes.func.isRequired,
  setSelectedCar: PropTypes.func.isRequired
}
