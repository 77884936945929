import React, { useContext } from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import { Ballot } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import BasicCarData from "../../../BasicCarData"
import { LeasingRequestCarContext } from "../../../../../../../contexts"

export default function LeasingCar(props) {
  const { basicCar, onCarLoaded, setOpenModal } = props
  const { setLeasingRequestCar } = useContext(LeasingRequestCarContext)
  const { t } = useTranslation("lead")

  const createLeasingRequest = () => {
    setLeasingRequestCar({
      carId: basicCar.carId,
      priceId: basicCar.priceId,
      distributorId: basicCar.distributorId
    })
    setOpenModal("createLeasingRequest")
  }

  return (
    <>
      <Button onClick={createLeasingRequest} color="secondary" startIcon={<Ballot />}>
        {t("createLeasingRequest")}
      </Button>
      <BasicCarData
        title={basicCar.title}
        duration={basicCar.duration}
        kmYear={basicCar.kmYear}
        price={basicCar.price}
        deliveryTime={basicCar.deliveryTime}
        distributorId={basicCar.distributorId}
        carId={basicCar.carId}
        outdated={basicCar.outdated}
        onCarLoaded={onCarLoaded}
      />
    </>
  )
}
LeasingCar.propTypes = {
  basicCar: PropTypes.object.isRequired,
  onCarLoaded: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired
}
