import { useEffect, useRef } from "react"

export default function useUnload(fn) {
  const callback = useRef(fn)

  useEffect(() => {
    const onUnload = callback.current
    window.addEventListener("unload", onUnload)

    return () => {
      window.removeEventListener("unload", onUnload)
    }
  }, [callback])
}
