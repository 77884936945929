import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Alert, AlertTitle } from "@material-ui/lab"
import styled from "styled-components"
import { isEmpty } from "lodash"
import { MultiLineParagraph } from "../../../../../components/styled/typography"

const StyledAlert = styled(Alert)`
  margin-top: 5px;
`

function ReturnToContractReasonAlert(props) {
  const { returnToContractReason, status } = props
  const { t } = useTranslation("leasingRequest")

  if (status === "closed" || returnToContractReason === null) return null

  return (
    <StyledAlert severity="warning">
      <AlertTitle>{t("common:contractStatus.title")}</AlertTitle>
      <MultiLineParagraph>{t(`returnToContractReasons.${returnToContractReason}`)}</MultiLineParagraph>
    </StyledAlert>
  )
}

function ClosedReasonAlert(props) {
  const { closedReason, closedReasonComment, status, won } = props
  const { t } = useTranslation("leasingRequest", "common")

  if (status !== "closed" || won || isEmpty(closedReason)) return null

  return (
    <StyledAlert severity="warning">
      <AlertTitle>{t(`common:closeReasons.${closedReason}`)}</AlertTitle>
      <MultiLineParagraph>{closedReasonComment}</MultiLineParagraph>
    </StyledAlert>
  )
}

export default function Alerts(props) {
  const { status, won, returnToContractReason, closedReason, closedReasonComment } = props

  return (
    <>
      <ReturnToContractReasonAlert status={status} returnToContractReason={returnToContractReason} />
      <ClosedReasonAlert
        status={status}
        won={won}
        closedReason={closedReason}
        closedReasonComment={closedReasonComment}
      />
    </>
  )
}

ReturnToContractReasonAlert.propTypes = {
  returnToContractReason: PropTypes.string,
  status: PropTypes.string.isRequired
}

ReturnToContractReasonAlert.defaultProps = {
  returnToContractReason: null
}

ClosedReasonAlert.propTypes = {
  status: PropTypes.string.isRequired,
  won: PropTypes.bool.isRequired,
  closedReason: PropTypes.string,
  closedReasonComment: PropTypes.string
}

ClosedReasonAlert.defaultProps = {
  closedReason: null,
  closedReasonComment: null
}

Alerts.propTypes = {
  closedReason: PropTypes.string,
  closedReasonComment: PropTypes.string,
  returnToContractReason: PropTypes.string,
  status: PropTypes.string.isRequired,
  won: PropTypes.bool.isRequired
}

Alerts.defaultProps = {
  closedReason: null,
  closedReasonComment: null,
  returnToContractReason: null
}
