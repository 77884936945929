import React, { useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import * as PropTypes from "prop-types"
import MenuItem from "@material-ui/core/MenuItem"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { includes } from "lodash"
import Breadcrumb from "../../../../components/layouts/Breadcrumb"
import Loading from "../../../../components/Loading"
import EntityLayout from "../../../../components/layouts/EntityLayout"
import FormDialog from "../../../../components/FormDialog"
import useAbilities from "../../../../hooks/useAbilities"
import CommentFormWrapper from "../../../../components/CommentFormWrapper"
import AddComment from "../../../../components/forms/AddComment"
import StatusChip from "../../../../components/StatusChip"
import CarListDialog from "../../../../components/CarListDialog"
import PrimaryActions from "./PrimaryActions"
import PendingScheduledCall from "../../../../components/ScheduledCallChip"
import SecondaryActions from "./SecondaryActions"
import Alerts from "./Alerts"
import SendLeadEmail from "../../../../components/forms/SendLeadEmail"
import ScheduleLeadCall from "../../../../components/forms/ScheduleLeadCall"
import UploadDocuments from "../../../../components/forms/UploadDocuments"
import { uploadDocument } from "../../../../services/leadService"
import UpdateLeadStatus from "../../../../components/forms/UpdateLeadStatus"
import UpdateLeadCountry from "../../../../components/forms/UpdateLeadCountry"
import DoNotCallLeadAgain from "../../../../components/forms/DoNotCallLeadAgain"
import ReassignLead from "../../../../components/forms/ReassignLead"
import RemoveAllLeadData from "../../../../components/forms/RemoveAllLeadData"
import SendDocumentationRequestEmail from "../../../../components/forms/SendDocumentationRequestEmail"
import SendWrongNumberEmail from "../../../../components/forms/SendWrongNumberEmail"
import CloseLead from "../../../../components/forms/CloseLead"
import RelistLead from "../../../../components/forms/RelistLead"
import { receiveNotification } from "../../../../actions"
import SendDocumentationMissingEmail from "../../../../components/forms/SendDocumentationMissingEmail"
import CreateLeasingRequest from "../../../../components/forms/CreateLeasingRequest"
import UpdateLeadPromotion from "../../../../components/forms/UpdateLeadPromotion"
import NoEmailScene from "./NoEmailScene"
import CurrentTask from "../../../../components/CurrentTask"
import { CarListDialogContext, LeasingRequestCarContext } from "../../../../contexts"
import SendCarInformationEmail from "../../../../components/forms/SendCarInformationEmail"
import TabsScene from "./TabsScene"

const ADD_INTERESTED_CAR = gql`
  mutation addInterestedCar($leadId: ID!, $interestedCar: AddInterestedCarInputObject!) {
    addInterestedCar(input: { leadId: $leadId, interestedCar: $interestedCar }) {
      errors {
        messages
        path
      }
      result {
        interestedCars {
          id
          priceId
          carId
        }
      }
    }
  }
`

const REMOVE_INTERESTED_CAR = gql`
  mutation removeInterestedCar($leadId: ID!, $interestedCar: RemoveInterestedCarInputObject!) {
    removeInterestedCar(input: { leadId: $leadId, interestedCar: $interestedCar }) {
      errors {
        messages
        path
      }
      result {
        interestedCars {
          id
          priceId
          carId
        }
      }
    }
  }
`

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      email
      title
      status
      jobType
      locale
      countryCode
      lastStatusChangedAt
      pendingScheduledCallAt
      lastRollbackCreatedById
      lastRollbackReason
      lastScoringRejectedReason
      lastScoringRejectedReasonComment
      lastScoringRejectedById
      unansweredEmail
      wrongNumber
      pendingOnlineLeasingRequest
      updatedAt
      interestedCars {
        id
        priceId
        carId
      }
      comments(limit: 2) {
        id
        content
        createdAt
        createdById
        commentableId
        commentableType
        commercialDetails
      }
    }
  }
`

const BASIC_LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      jobType
      countryCode
    }
  }
`

const ADD_LEAD_COMMENT = gql`
  mutation addLeadComment($commentableId: ID!, $commentArguments: AddLeadCommentInputObject!) {
    addLeadComment(input: { leadId: $commentableId, commentArguments: $commentArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const UPDATE_LEAD_COMMENT = gql`
  mutation updateLeadComment($commentableId: ID!, $commentArguments: UpdateLeadCommentInputObject!) {
    updateLeadComment(input: { leadId: $commentableId, commentArguments: $commentArguments }) {
      errors {
        className
        messages
        path
      }
    }
  }
`

// TODO At some point React.forwardRef should become unnecessary
const MenuItems = React.forwardRef((props, ref) => {
  const { setOpenModal } = props
  const { t } = useTranslation("lead")
  const { allowed: updateStatuses } = useAbilities("update_lead_statuses")
  const { allowed: reassignLeads } = useAbilities("reassign_leads")
  const { allowed: relistLeads } = useAbilities("relist_leads")
  const { allowed: removeLeadData } = useAbilities("remove_lead_data")

  return (
    <>
      <MenuItem ref={ref} onClick={setOpenModal("sendEmail")}>
        {t("common:sendEmail")}
      </MenuItem>
      <MenuItem ref={ref} onClick={setOpenModal("sendCarInformationEmail")}>
        {t("sendCarInformationEmail")}
      </MenuItem>
      <MenuItem ref={ref} onClick={setOpenModal("uploadDocument")}>
        {t("common:uploadDocument")}
      </MenuItem>
      {updateStatuses && (
        <MenuItem ref={ref} onClick={setOpenModal("updateStatus")}>
          {t("common:updateStatus")}
        </MenuItem>
      )}
      <MenuItem ref={ref} onClick={setOpenModal("changeCountry")}>
        {t("changeCountry")}
      </MenuItem>
      {relistLeads && (
        <MenuItem ref={ref} onClick={setOpenModal("relist")}>
          {t("relist")}
        </MenuItem>
      )}
      <MenuItem ref={ref} onClick={setOpenModal("updatePromotion")}>
        {t("common:updatePromotion")}
      </MenuItem>
      <MenuItem ref={ref} onClick={setOpenModal("doNotCall")}>
        {t("doNotCall")}
      </MenuItem>
      {reassignLeads && (
        <MenuItem ref={ref} onClick={setOpenModal("reassign")}>
          {t("common:reassign")}
        </MenuItem>
      )}
      {removeLeadData && (
        <MenuItem ref={ref} onClick={setOpenModal("removeAllData")}>
          {t("removeAllData")}
        </MenuItem>
      )}
    </>
  )
})

MenuItems.propTypes = {
  setOpenModal: PropTypes.func.isRequired
}

function Modals(props) {
  const { openModal, closeModal, refetch, resource } = props
  const { t } = useTranslation("lead")

  const handleSuccess = () => {
    refetch()
    closeModal()
  }

  return (
    <>
      <FormDialog title={t("common:sendEmail")} handleClose={closeModal} open={openModal === "sendEmail"}>
        <SendLeadEmail leadId={resource.id} locale={resource.locale} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:scheduleACall")} handleClose={closeModal} open={openModal === "scheduleCall"}>
        <ScheduleLeadCall leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:uploadDocument")} handleClose={closeModal} open={openModal === "uploadDocument"}>
        <UploadDocuments resourceId={resource.id} uploadDocument={uploadDocument} />
      </FormDialog>
      <FormDialog title={t("common:updateStatus")} handleClose={closeModal} open={openModal === "updateStatus"}>
        <UpdateLeadStatus leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("changeCountry")} handleClose={closeModal} open={openModal === "changeCountry"}>
        <UpdateLeadCountry leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("doNotCall")} handleClose={closeModal} open={openModal === "doNotCall"}>
        <DoNotCallLeadAgain leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:reassign")} handleClose={closeModal} open={openModal === "reassign"}>
        <ReassignLead leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("removeAllData")} handleClose={closeModal} open={openModal === "removeAllData"}>
        <RemoveAllLeadData leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("sendDocumentationRequestEmail")}
        handleClose={closeModal}
        open={openModal === "sendDocumentationRequestEmail"}
      >
        <SendDocumentationRequestEmail leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("sendCarInformationEmail")}
        handleClose={closeModal}
        open={openModal === "sendCarInformationEmail"}
      >
        <SendCarInformationEmail leadId={resource.id} locale={resource.locale} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("sendMissingDocumentationEmail")}
        handleClose={closeModal}
        open={openModal === "sendMissingDocumentationEmail"}
      >
        <SendDocumentationMissingEmail leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("wrongNumberAction")} handleClose={closeModal} open={openModal === "wrongNumber"}>
        <SendWrongNumberEmail leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:close")} handleClose={closeModal} open={openModal === "close"}>
        <CloseLead leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("relist")} handleClose={closeModal} open={openModal === "relist"}>
        <RelistLead leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog
        title={t("createLeasingRequest")}
        handleClose={closeModal}
        open={openModal === "createLeasingRequest"}
        closeButton
      >
        <CreateLeasingRequest leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
      <FormDialog title={t("common:updatePromotion")} handleClose={closeModal} open={openModal === "updatePromotion"}>
        <UpdateLeadPromotion leadId={resource.id} handleSuccess={handleSuccess} />
      </FormDialog>
    </>
  )
}

Modals.defaultProps = {
  openModal: null
}

Modals.propTypes = {
  openModal: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired
}

export default function LeadScene(props) {
  const { leadId } = props
  const dispatch = useDispatch()
  const { t } = useTranslation("lead")
  const [openModal, setOpenModal] = useState(null)
  const [selectedCar, setSelectedCar] = useState(null)
  const [leasingRequestCar, setLeasingRequestCar] = useState(null)
  const [skipNoEmail, setSkipNoEmail] = useState(false)
  const [interestedIn, setInterestedIn] = useState([])
  const { allowed: adminAllowed } = useAbilities("read_admin_lead_data")
  const { loading, error, data, refetch } = useQuery(LEAD, {
    variables: {
      id: leadId
    }
  })
  const [addInterestedCar] = useMutation(ADD_INTERESTED_CAR)
  const [removeInterestedCar] = useMutation(REMOVE_INTERESTED_CAR)

  useEffect(() => data && data.lead && setInterestedIn(data.lead.interestedCars || []), [data])

  const onPlanClick = (carId, priceRangeId, selectedId) => {
    if (selectedId) removeInterest(selectedId)
    else addInterest(carId, priceRangeId)
  }

  const addInterest = (carId, priceRangeId) => {
    addInterestedCar({
      variables: {
        leadId,
        interestedCar: { carId, priceRangeId }
      }
    }).then(({ data: newData }) => {
      if (newData.addInterestedCar.errors !== null) {
        const errors = newData.addInterestedCar.errors
          .filter(e => e.path === "base")
          .map(e => e.messages)
          .join(", ")
        dispatch(receiveNotification(errors, { variant: "error" }))
      } else {
        dispatch(receiveNotification("Ok"))
        setInterestedIn(newData.addInterestedCar.result.interestedCars)
      }
    })
  }

  const removeInterest = id => {
    removeInterestedCar({
      variables: {
        leadId,
        interestedCar: { id }
      }
    }).then(({ data: newData }) => {
      if (newData.removeInterestedCar.errors !== null) {
        const errors = newData.removeInterestedCar.errors
          .filter(e => e.path === "base")
          .map(e => e.messages)
          .join(", ")
        dispatch(receiveNotification(errors, { variant: "error" }))
      } else {
        dispatch(receiveNotification("Ok"))
        setInterestedIn(newData.removeInterestedCar.result.interestedCars)
      }
    })
  }

  const customerEntityRetriever = leadData => {
    return {
      jobType: leadData?.lead?.jobType,
      countryCode: leadData?.lead?.countryCode
    }
  }

  if (loading) return <Loading />
  if (error) return <p>Server Error</p>
  if (data.lead === null) return <p>Not found</p>

  const {
    email,
    title,
    status,
    locale,
    lastStatusChangedAt,
    pendingScheduledCallAt,
    comments,
    lastRollbackCreatedById,
    lastRollbackReason,
    lastScoringRejectedReason,
    lastScoringRejectedReasonComment,
    lastScoringRejectedById,
    unansweredEmail,
    updatedAt,
    wrongNumber,
    pendingOnlineLeasingRequest
  } = data.lead

  const breadcrumbItems = [{ label: "Leads" }, { label: `${title}`, active: true }]
  const showLastStatusChangedAt =
    adminAllowed || includes(["documentation_requested", "converted", "rollback", "scoring_rejected"], status)

  return (
    <>
      <CarListDialogContext.Provider
        value={{
          options: { selectedCars: interestedIn },
          onPlanClick,
          selectedCar,
          setSelectedCar
        }}
      >
        <CarListDialog
          customerEntityQuery={BASIC_LEAD}
          customerEntityQueryVariables={{ id: leadId }}
          customerEntityRetriever={customerEntityRetriever}
        />
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <CurrentTask />
        <LeasingRequestCarContext.Provider value={{ leasingRequestCar, setLeasingRequestCar }}>
          <EntityLayout
            title={title}
            MenuItemsJSX={MenuItems}
            Modals={Modals}
            openModal={openModal}
            setOpenModal={setOpenModal}
            refetch={refetch}
            resource={{ id: leadId, locale }}
          >
            <Alerts
              status={status}
              lastRollbackCreatedById={lastRollbackCreatedById}
              lastRollbackReason={lastRollbackReason}
              lastScoringRejectedReason={lastScoringRejectedReason}
              lastScoringRejectedReasonComment={lastScoringRejectedReasonComment}
              lastScoringRejectedById={lastScoringRejectedById}
              unansweredEmail={unansweredEmail}
              wrongNumber={wrongNumber}
              pendingOnlineLeasingRequest={pendingOnlineLeasingRequest}
            />
            <PrimaryActions
              leadId={leadId}
              leadTitle={title}
              openScheduleCallModal={() => setOpenModal("scheduleCall")}
            />
            <PendingScheduledCall pendingScheduledCallAt={pendingScheduledCallAt} />
            <SecondaryActions leadStatus={status} openModal={setOpenModal} />
            {(email || skipNoEmail) && (
              <>
                <CommentFormWrapper lastComments={comments} gqlUpdateMutation={UPDATE_LEAD_COMMENT}>
                  <AddComment gqlMutation={ADD_LEAD_COMMENT} commentableId={leadId} handleSuccess={() => refetch()} />
                </CommentFormWrapper>
                <StatusChip
                  status={t(`${adminAllowed ? "status" : "fakeStatus"}.${status}`)}
                  lastStatusChangedAt={showLastStatusChangedAt ? lastStatusChangedAt : null}
                />
                <TabsScene
                  leadId={leadId}
                  selectedCar={selectedCar}
                  setOpenModal={setOpenModal}
                  lastRefreshAt={updatedAt}
                />
              </>
            )}
            {!email && !skipNoEmail && (
              <NoEmailScene
                leadId={leadId}
                refetch={refetch}
                adminAllowed={adminAllowed}
                setSkipNoEmail={setSkipNoEmail}
              />
            )}
          </EntityLayout>
        </LeasingRequestCarContext.Provider>
      </CarListDialogContext.Provider>
    </>
  )
}

LeadScene.propTypes = {
  leadId: PropTypes.string.isRequired
}
