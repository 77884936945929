import React, { useRef } from "react"
import { Form } from "informed"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import PropTypes from "prop-types"
import styled from "styled-components"
import * as Yup from "yup"
import BasicInput from "../inputs/BasicInput"
import GraphQLErrors from "../../GraphQLErrors"
import { Paragraph } from "../../styled/typography"
import { ModalPrimaryButton } from "../../styled/buttons"
import EmailTemplateSelector from "../inputs/EmailTemplateSelector"
import MDInput from "../inputs/MDInput"
import { EmailContext } from "../../../contexts"

const SEND_CAR_INFORMATION_EMAIL = gql`
  mutation sendCarInformationEmail($leadId: ID!, $emailArguments: SendCarInformationEmailInputObject!) {
    sendCarInformationEmail(input: { leadId: $leadId, emailArguments: $emailArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

const StyledMDInput = styled(MDInput)`
  margin-top: 5px;
`

export default function SendCarInformationEmail(props) {
  const { leadId, locale, handleSuccess } = props
  const { t } = useTranslation("lead")
  const formApi = useRef(null)

  const [mutation, { data, loading, error }] = useMutation(SEND_CAR_INFORMATION_EMAIL, {
    onCompleted: newData => {
      if (newData.sendCarInformationEmail?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.sendCarInformationEmail?.errors
  if (error) return <p>Server Error</p>

  const handleTemplateChange = emailTemplate => {
    formApi.current.setValue("emailSubject", emailTemplate.subject)
    formApi.current.setValue("body", emailTemplate.body)
  }

  const handleSubmit = values => {
    mutation({
      variables: {
        leadId,
        emailArguments: values
      }
    })
    formApi.current.reset()
  }

  const validationSchema = Yup.object().shape({
    body: Yup.string().required(t("common:required"))
  })

  return (
    <Form
      validationSchema={validationSchema}
      getApi={api => {
        formApi.current = api
      }}
      onSubmit={handleSubmit}
    >
      <GraphQLErrors errors={errors} />
      <Paragraph>{t("sendCarInformationEmailExplanation")}</Paragraph>
      <EmailContext.Provider
        value={{
          resourceId: leadId,
          resource: "Lead",
          locale
        }}
      >
        <EmailTemplateSelector onChange={handleTemplateChange} />
      </EmailContext.Provider>
      <BasicInput field="emailSubject" label={t("common:emailSubject")} required />
      <StyledMDInput field="body" errors={errors} />
      <Paragraph>{t("common:doNotAddSignature")}</Paragraph>
      <ModalPrimaryButton isFetching={loading}>{t("sendCarInformationEmail")}</ModalPrimaryButton>
    </Form>
  )
}

SendCarInformationEmail.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}
