import axios from "axios/index"

export function getToday() {
  return axios.get(`${process.env.REACT_APP_API_URL}account/today`, {
    headers: {
      Authorization: `bearer ${getAuth()}`
    }
  })
}

export function next() {
  return axios.get(`${process.env.REACT_APP_API_URL}account/next`, {
    headers: {
      Authorization: `bearer ${getAuth()}`
    }
  })
}

export function login(params) {
  return axios.post(`${process.env.REACT_APP_API_URL}authenticate`, params)
}

export function logout() {
  localStorage.removeItem("authToken")
}

export function getAuth() {
  return localStorage.getItem("authToken")
}

export function storeAuth(authToken) {
  localStorage.setItem("authToken", authToken)
}

export function getChartsAuthToken() {
  return axios.get(`${process.env.REACT_APP_API_URL}analytics/authorization/authenticate`, {
    headers: {
      Authorization: `bearer ${getAuth()}`
    }
  })
}
