import { RECEIVE_NOTIFICATION, REMOVE_NOTIFICATION } from "./actions"

export const receiveNotification = (message, params) => {
  return {
    type: RECEIVE_NOTIFICATION,
    message,
    params: params || {}
  }
}

export const removeNotification = () => {
  return {
    type: REMOVE_NOTIFICATION
  }
}
