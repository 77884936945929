import React from "react"
import PropTypes from "prop-types"
import { gql, useQuery } from "@apollo/client"
import { Alert } from "@material-ui/lab"
import { Grid } from "@material-ui/core"
import { isNil } from "lodash"
import { useTranslation } from "react-i18next"
import { EmailContext } from "../../../contexts"
import Loading from "../../Loading"
import SendEmail from "../SendEmail"
import { createOption } from "../../../helpers/forms"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      title
      email
      locale
      distributor {
        contacts {
          email
          fullName
        }
      }
    }
  }
`

const SEND_EMAIL = gql`
  mutation sendEmailToLeasingRequest($resourceId: ID!, $emailArguments: SendEmailToLeasingRequestInputObject!) {
    sendEmailToLeasingRequest(input: { leasingRequestId: $resourceId, emailArguments: $emailArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function SendLeasingRequestEmail(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { loading, data } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  const { leasingRequest } = data

  const recipients = data.leasingRequest.distributor.contacts.map(c =>
    createOption(c.email, `${c.fullName} <${c.email}>`)
  )
  recipients.push(createOption(leasingRequest.email, `${leasingRequest.title} <${leasingRequest.email}>`))

  return (
    <EmailContext.Provider
      value={{
        gqlMutation: SEND_EMAIL,
        resourceId: leasingRequestId,
        resource: "LeasingRequest",
        locale: data.leasingRequest.locale
      }}>
      <Alert severity="warning">{t("common:favorReplyOverSend")}</Alert>
      <Grid item xs={12}>
        <SendEmail handleSuccess={handleSuccess} genericAvailable recipients={recipients} />
      </Grid>
    </EmailContext.Provider>
  )
}

SendLeasingRequestEmail.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired
}
