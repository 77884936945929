import React from "react"
import PropTypes from "prop-types"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import styled from "styled-components"
import Markdown from "react-markdown"
import { printDate, printDateTime } from "../../../helpers/formatter"
import { StyledLabel } from "../../styled/typography"

export const Text = styled.p`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};
  white-space: ${({ multiline }) => (multiline ? "pre-line" : "inherit")};
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
`

export const Wrapper = styled.div`
  margin-bottom: 5px;
`

const CheckboxIcon = value => {
  if (value) return <CheckBox style={{ color: "rgba(0, 0, 0, 0.54)" }} />

  return <CheckBoxOutlineBlank style={{ color: "rgba(0, 0, 0, 0.54)" }} />
}

const getText = (value, type, humanizeFunc) => {
  const falsyValue = value == null || value === "" || (Array.isArray(value) && value.length === 0)
  const text = falsyValue ? "-" : value

  if (type === "datetime") return printDateTime(value)
  if (type === "date") return printDate(value)
  if (type === "boolean") return CheckboxIcon(value || false)
  if (humanizeFunc && !falsyValue) return humanizeFunc(value)

  return text
}

const getHtmlValue = (text, handleClick, value, type, multiline) => {
  if (value && type === "markdown") return <Markdown source={value} linkTarget="_blank" />

  return (
    <Text onClick={handleClick} multiline={multiline}>
      {text}
    </Text>
  )
}

export default function BasicField(props) {
  const { type, value, title, humanizeFunc, handleClick, multiline } = props
  const text = getText(value, type, humanizeFunc)
  const valueHtml = getHtmlValue(text, handleClick, value, type, multiline)

  return (
    <Wrapper>
      {title && <StyledLabel>{title}</StyledLabel>}
      {valueHtml}
    </Wrapper>
  )
}

BasicField.defaultProps = {
  handleClick: null,
  humanizeFunc: null,
  multiline: false,
  title: null,
  value: null,
  type: null
}

BasicField.propTypes = {
  handleClick: PropTypes.func,
  humanizeFunc: PropTypes.func,
  multiline: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any
}
