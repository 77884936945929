import * as Yup from "yup"
import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { Alert } from "@material-ui/lab"
import { ModalPrimaryButton } from "../../styled/buttons"
import BasicInput from "../inputs/BasicInput"
import DateInput from "../inputs/DateInput"
import GraphQLErrors from "../../GraphQLErrors"

const UPDATE_LEASING_REQUEST_PLAN = gql`
  mutation updateLeasingRequestPlan($id: ID!, $leasingRequestArguments: UpdateLeasingRequestPlanInputObject!) {
    updateLeasingRequestPlan(input: { leasingRequestId: $id, leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function UpdateLeasingRequestPlan(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation()
  const [mutation, { data, loading, error }] = useMutation(UPDATE_LEASING_REQUEST_PLAN, {
    onCompleted: newData => {
      if (newData.updateLeasingRequestPlan?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updateLeasingRequestPlan?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({ variables: { id: leasingRequestId, leasingRequestArguments: values } })
  }

  const validationSchema = Yup.object().shape({
    duration: Yup.number()
      .positive(t("positive"))
      .integer(t("integer"))
      .max(99, t("max", { value: 99 }))
      .min(1, t("min", { value: 1 })),
    kmYear: Yup.number()
      .positive(t("positive"))
      .integer(t("integer"))
      .max(999000, t("max", { value: 999000 }))
      .min(1000, t("min", { value: 1000 }))
      .test("multiple-of-1000", t("multipleOf1000"), value => value === undefined || value % 1000 === 0)
  })

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Alert severity="info">{t("blankFieldsExplanation")}</Alert>
      <GraphQLErrors errors={errors} />
      <BasicInput field="duration" label={t("durationWithUnit")} type="number" errors={errors} />
      <BasicInput field="kmYear" label={t("kmYear")} type="number" errors={errors} inputProps={{ step: 1000 }} />
      <DateInput field="wonAt" label={t("contractSignedAt")} openTo="year" views={["year", "month", "date"]} />
      <DateInput
        field="contractExpiresAt"
        label={t("leasingRequest:contractExpiresAt")}
        openTo="year"
        views={["year", "month", "date"]}
      />
      <ModalPrimaryButton isFetching={loading}>{t("leasingRequest:updatePlan")}</ModalPrimaryButton>
    </Form>
  )
}

UpdateLeasingRequestPlan.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
