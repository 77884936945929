import React, { useState } from "react"
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import styled from "styled-components"

const StyledIcon = styled(ListItemIcon)`
  color: white;
`

const StyledListItemText = styled(ListItemText)`
  span {
    font-size: 14px;
    color: white;
    line-height: 1;
  }
`

const StyledExpandLess = styled(ExpandLess)`
  color: var(--white);
`

const StyledExpandMore = styled(ExpandMore)`
  color: var(--white);
`

export default function CollapseListItem(props) {
  const { access, title, icon, children } = props
  const [open, setOpen] = useState(false)

  if (!access) return null

  const childWithProp = React.Children.map(children, child => {
    return React.cloneElement(child, { isNested: true })
  })

  return [
    <ListItem key={1} button onClick={() => setOpen(!open)}>
      <StyledIcon>{icon}</StyledIcon>
      <StyledListItemText primary={title} />
      {open ? <StyledExpandLess /> : <StyledExpandMore />}
    </ListItem>,
    <Collapse key={2} in={open} timeout="auto" unmountOnExit>
      <List disablePadding>{childWithProp}</List>
    </Collapse>
  ]
}

CollapseListItem.defaultProps = {
  access: true
}
