import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { isEmpty, reject } from "lodash"
import { Alert } from "@material-ui/lab"
import styled from "styled-components"
import BasicInput from "../inputs/BasicInput"
import CheckboxInput from "../inputs/CheckboxInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import Loading from "../../Loading"

const StyledAlert = styled(Alert)`
  margin-top: 8px;
`

const COMPLETE_SCORING = gql`
  mutation completeScoring($leasingRequestId: ID!, $completeScoringArguments: CompleteScoringInputObject!) {
    completeScoring(
      input: { leasingRequestId: $leasingRequestId, completeScoringArguments: $completeScoringArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      id
      online
      otherLeasingRequests {
        id
        status
      }
    }
  }
`

export default function NoStockScoringReject(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { data: queryData, loading: queryLoading } = useQuery(LEASING_REQUEST, {
    variables: { id: leasingRequestId }
  })
  const [mutation, { data, loading, error }] = useMutation(COMPLETE_SCORING, {
    onCompleted: newData => {
      if (newData.completeScoring?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.completeScoring?.errors
  if (error) return <p>Server Error</p>
  if (queryLoading) return <Loading />

  const handleSubmit = values => {
    mutation({
      variables: {
        leasingRequestId,
        completeScoringArguments: { ...values, rejectedReason: "no_stock", scoringStatus: "rejected" }
      }
    })
  }

  const validationSchema = Yup.object().shape({
    scoringComments: Yup.string().trim().required(t("common:required"))
  })

  const { online, otherLeasingRequests } = queryData.leasingRequest
  const ongoingLeasingRequests = reject(reject(otherLeasingRequests, { status: "closed" }), { id: leasingRequestId })

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      {!online && !isEmpty(ongoingLeasingRequests) && (
        <>
          <StyledAlert severity="warning">{t("ongoingLeasingRequestsWarning")}</StyledAlert>
          <CheckboxInput field="ongoingRejected" label={t("ongoingRejected")} initialValue={false} errors={errors} />
        </>
      )}
      <BasicInput field="scoringComments" label={t("common:comments")} errors={errors} multiline required />
      <ModalPrimaryButton isFetching={loading}>{t("noStockScoringRejectSubmit")}</ModalPrimaryButton>
    </Form>
  )
}

NoStockScoringReject.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
