import PropTypes from "prop-types"
import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import { Form } from "informed"
import { useTranslation } from "react-i18next"
import BasicInput from "../../../../components/forms/inputs/BasicInput"
import { activeOptions, leadStatusesOptions } from "../../../../helpers/constants"
import TextSelectInput from "../../../../components/forms/inputs/TextSelectInput"
import StaffSelector from "../../../../components/forms/inputs/StaffSelector"
import CountryCodeSelector from "../../../../components/forms/inputs/CountryCodeSelector"

import DateRangeInput from "../../../../components/forms/inputs/DateRangeInput"

export default function Filter(props) {
  const { handleFilterAttributes } = props
  const { t } = useTranslation("lead")

  const [dateParams, setDateParams] = useState({ startDate: '', endDate: '' })
  const [createdAtParams, setCreatedAtParams] = useState({ startDate: '', endDate: '' })

  const setDateFilter = (attribute, startDate, endDate) => {
    handleFilterAttributes({
      ...props.filterAttributes,
      [attribute]: {
        gte: startDate.toDate(),
        lt: endDate.toDate()
      }
    })
  }

  return (
    <Form onValueChange={handleFilterAttributes}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BasicInput field="q" label={t("common:search")} variant="outlined" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextSelectInput
            field="status"
            label={t("common:status")}
            options={leadStatusesOptions(t)}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StaffSelector
            field="assignedAtId"
            label="Assignee"
            options={activeOptions}
            variant="outlined"
            displayClear
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CountryCodeSelector variant="outlined" displayClear />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateRangeInput
            label="Last Opportunity"
            startDateField="startDate"
            startDateLabel="Start date"
            endDateField="endDate"
            endDateLabel="End date"
            startDate={dateParams.startDate}
            startDateId="startDate"
            endDate={dateParams.endDate}
            endDateId="endDate"
            onDatesChange={({ startDate, endDate }) => {
              const newStartDate = startDate && startDate?.startOf("day")
              const newEndDate = endDate && endDate?.startOf("day")
              setDateParams({ startDate: newStartDate, endDate: newEndDate })

              if (newStartDate && newEndDate) {
                setDateFilter('lastOpportunityCreatedAt', newStartDate, newEndDate)
              }
            }}
            isOutsideRange={() => false}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <DateRangeInput
            label="Created At"
            startDateField="startDate"
            startDateLabel="Start date"
            endDateField="endDate"
            endDateLabel="End date"
            startDate={createdAtParams.startDate}
            startDateId="startDate"
            endDate={createdAtParams.endDate}
            endDateId="endDate"
            onDatesChange={({ startDate, endDate }) => {
              const newStartDate = startDate && startDate?.startOf("day")
              const newEndDate = endDate && endDate?.startOf("day")
              setCreatedAtParams({ startDate: newStartDate, endDate: newEndDate })

              if (newStartDate && newEndDate) {
                setDateFilter('createdAt', newStartDate, newEndDate)
              }
            }}
            isOutsideRange={() => false}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextSelectInput
            field="origin"
            label={'Origin'}
            options={props.filters['origin'] || []}
            variant="outlined"
            displayClear
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextSelectInput
            field="utmSource"
            label={'UTM Source'}
            options={props.filters['utm_source'] || []}
            variant="outlined"
            displayClear
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextSelectInput
            field="utmMedium"
            label={'UTM Medium'}
            options={props.filters['utm_medium'] || []}
            variant="outlined"
            displayClear
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextSelectInput
            field="utmCampaign"
            label={'UTM Campaign'}
            options={props.filters['utm_campaign'] || []}
            variant="outlined"
            displayClear
          />
        </Grid>
      </Grid>
    </Form>
  )
}

Filter.propTypes = {
  handleFilterAttributes: PropTypes.func.isRequired
}
