import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import Loading from "../../Loading"
import { preDeliveryCarOptions } from "../../../helpers/constants"
import GraphQLErrors from "../../GraphQLErrors"
import CheckboxInput from "../inputs/CheckboxInput"
import TextSelectInput from "../inputs/TextSelectInput"

const LEASING_REQUEST = gql`
  query leasing_request($id: ID!) {
    leasingRequest(id: $id) {
      id
      preDeliveryCar
      preDeliveryCarOptions
    }
  }
`

const UPDATE_PRE_DELIVERY_CAR = gql`
  mutation updatePreDeliveryCar($id: ID!, $leasingRequestArguments: UpdatePreDeliveryCarInputObject!) {
    updatePreDeliveryCar(input: { leasingRequestId: $id, leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function UpdatePreDeliveryCar(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { data: queryData, loading: queryLoading } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })
  const [mutation, { data, loading, error }] = useMutation(UPDATE_PRE_DELIVERY_CAR, {
    onCompleted: newData => {
      if (newData.updatePreDeliveryCar?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updatePreDeliveryCar?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leasingRequestId, leasingRequestArguments: values }
    })
  }

  const getPropsForField = field => {
    return {
      field,
      label: t(`common:${field}`),
      initialValue: queryData.leasingRequest[field],
      errors
    }
  }

  if (queryLoading) return <Loading />
  if (queryData.leasingRequest === null) return <p>Not found</p>

  return (
    <Form onSubmit={handleSubmit}>
      {({ formState }) => (
        <>
          <GraphQLErrors errors={errors} />
          <CheckboxInput type="boolean" {...getPropsForField("preDeliveryCar")} />
          {formState.values.preDeliveryCar && (
            <TextSelectInput
              required
              multiple
              options={preDeliveryCarOptions(t)}
              {...getPropsForField("preDeliveryCarOptions")}
            />
          )}
          <ModalPrimaryButton isFetching={loading}>{t("updatePreDeliveryCar")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

UpdatePreDeliveryCar.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
