import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { reject } from "lodash"
import { Paragraph } from "../styled/typography"
import ImageViewer from "./ImageViewer"
import PdfViewer from "./PdfViewer"
import Header from "./Header"

const SLIDE_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true
}

const SlideWrapper = styled.div`
  width: 100%;
  height: 1200px;
  overflow: auto;
`

export default function AllDocumentation(props) {
  const {
    resourceId,
    personalDocuments,
    documentationList,
    showTag,
    onDocumentChanged,
    gqlUpdateMutation,
    gqlDestroyMutation
  } = props
  const { t } = useTranslation()
  const [documents, setDocuments] = useState(personalDocuments)

  useEffect(() => {
    if (onDocumentChanged) onDocumentChanged()
  }, [])

  useEffect(() => {
    setDocuments(personalDocuments)
  }, [personalDocuments])

  const removeDocument = id => {
    setDocuments(reject(documents, { id }))
  }

  return (
    <Grid item xs={12}>
      {documents.length === 0 ? (
        <Paragraph>{t("noDocumentation")}</Paragraph>
      ) : (
        <Slider {...SLIDE_SETTINGS}>
          {documents.map(document => (
            <div key={document.id}>
              <Header
                resourceId={resourceId}
                personalDocumentId={document.id}
                src={document.preferredUrl}
                caption={document.documentFileName}
                tag={document.tag}
                documentationList={documentationList}
                showTag={showTag}
                gqlUpdateMutation={gqlUpdateMutation}
                gqlDestroyMutation={gqlDestroyMutation}
                afterDestroy={() => removeDocument(document.id)}
              />
              <SlideWrapper>
                {document.documentContentType === "application/pdf" ? (
                  <PdfViewer {...document} />
                ) : (
                  <ImageViewer {...document} />
                )}
              </SlideWrapper>
            </div>
          ))}
        </Slider>
      )}
    </Grid>
  )
}

AllDocumentation.propTypes = {
  documentationList: PropTypes.array.isRequired,
  personalDocuments: PropTypes.array.isRequired,
  gqlUpdateMutation: PropTypes.object,
  gqlDestroyMutation: PropTypes.object.isRequired,
  onDocumentChanged: PropTypes.func,
  resourceId: PropTypes.string.isRequired,
  showTag: PropTypes.bool
}

AllDocumentation.defaultProps = {
  onDocumentChanged: null,
  gqlUpdateMutation: null,
  showTag: false
}
