import React from "react"
import * as PropTypes from "prop-types"
import SignedIn from "../../../components/layouts/SignedIn"
import Allowed from "../../../components/layouts/Allowed"
import LeadScene from "./LeadScene"

export default function ShowScene(props) {
  const { match } = props
  const { leadId } = match.params

  return (
    <SignedIn>
      <Allowed ability={["read_leads", "manage_own_leads", "manage_country_leads", "manage_leads"]}>
        <LeadScene leadId={leadId} />
      </Allowed>
    </SignedIn>
  )
}

ShowScene.propTypes = {
  match: PropTypes.object.isRequired
}
