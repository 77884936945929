import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const UPDATE_CLIENT_REQUEST = gql`
  mutation updateClientRequest($clientRequestId: ID!, $clientRequestArguments: UpdateClientRequestInputObject!) {
    updateClientRequest(input: { clientRequestId: $clientRequestId, clientRequestArguments: $clientRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function AssignLeasingRequestToClientRequest(props) {
  const { clientRequestId, leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("clientRequest")
  const [mutation, { data, loading, error }] = useMutation(UPDATE_CLIENT_REQUEST, {
    onCompleted: newData => {
      if (newData.updateClientRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updateClientRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = () => {
    mutation({
      variables: { clientRequestId, clientRequestArguments: { leasingRequestId } }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <ModalPrimaryButton isFetching={loading}>{t("assignLeasingRequestToClientRequest")}</ModalPrimaryButton>
    </Form>
  )
}

AssignLeasingRequestToClientRequest.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  clientRequestId: PropTypes.string.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
