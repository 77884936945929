import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Paragraph } from "../../../styled/typography"

const StyledDropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:focus {
    border-color: var(--stormy-blue);
  }
`

export default function DropzoneInput(props) {
  const { t } = useTranslation()
  const { handleUpload } = props
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => handleUpload(file))
  }, [])
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/png, image/jpg, image/jpeg, image/gif, application/pdf",
    multiple: true,
    maxSize: 35000000
  })

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const rejectedFiles = fileRejections.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <>
      <StyledDropzone {...getRootProps()}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        <Paragraph>{t("dropText")}</Paragraph>
      </StyledDropzone>
      <aside>
        <Paragraph>{t("acceptedDocuments")}</Paragraph>
        <ul>{files}</ul>
        <Paragraph>{t("rejectedDocuments")}</Paragraph>
        <ul>{rejectedFiles}</ul>
      </aside>
    </>
  )
}

DropzoneInput.propTypes = {
  handleUpload: PropTypes.func.isRequired
}
