import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"

const BILL_LEASING_REQUEST = gql`
  mutation markAsBillableLeasingRequest($leasingRequestId: ID!) {
    markAsBillableLeasingRequest(input: { leasingRequestId: $leasingRequestId }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function BillLeasingRequest(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(BILL_LEASING_REQUEST, {
    onCompleted: newData => {
      if (newData.markAsBillableLeasingRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.markAsBillableLeasingRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = () => {
    mutation({
      variables: { leasingRequestId }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <ModalPrimaryButton isFetching={loading}>{t("markAsBillable")}</ModalPrimaryButton>
    </Form>
  )
}

BillLeasingRequest.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
