import styled from "styled-components"

// eslint-disable-next-line import/prefer-default-export
export const StyledGreyPaper = styled.div`
  margin-bottom: 5px;
  padding: 15px 15px 15px 15px;
  background-color: var(--light-grey);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`

export const SimpleWrapper = styled.div`
  background-color: var(--white);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`
