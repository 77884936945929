import React, { useEffect } from "react"
import { gql, useQuery } from "@apollo/client"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { Redirect } from "react-router"
import { useTranslation } from "react-i18next"
import { receiveAccount, receiveCommonData } from "../../../../actions"
import { getAuth } from "../../../../services/accountService"

const ACCOUNT_QUERY = gql`
  query account {
    account {
      me {
        id
        name
        email
        countryCode
        locale
        abilities
        idle
        callPool
        lookingForCall
        role
      }
      allStaff
      allDistributors
    }
  }
`

export default function Account(props) {
  const { children } = props
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { account } = useSelector(mapStateToProps, shallowEqual)

  const { data } = useQuery(ACCOUNT_QUERY)

  useEffect(() => {
    if (data) {
      const receivedAccount = data.account.me
      dispatch(receiveAccount(receivedAccount))
      dispatch(receiveCommonData(data.account.allDistributors, data.account.allStaff))
      i18n.changeLanguage(receivedAccount.locale)
    }
  }, [data])

  if (isEmpty(getAuth())) return <Redirect push to="/" />

  return account && children
}

const mapStateToProps = state => {
  const { accountReducer } = state
  const { item: account } = accountReducer

  return {
    account: account != null
  }
}
