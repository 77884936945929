import React from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Announcement, Check, Email, Warning } from "@material-ui/icons"
import styled from "styled-components"
import Step from "../Step"
import StepIcon from "../StepIcon"
import { Paragraph } from "../../../../../styled/typography"
import { lastStatusDate } from "../../../../../../helpers/utils"

const StatusTitle = styled(Paragraph)`
  font-size: 110%;
`

const calculateVariant = contractStatus => {
  if (contractStatus === "not_started") return "default"
  if (contractStatus === "signed") return "completed"

  return "current"
}

export default function Contract(props) {
  const { leasingRequest } = props
  const { contractStatus, contractStatuses, statuses } = leasingRequest
  const { t } = useTranslation("leasingRequest")

  const variant = calculateVariant(contractStatus)
  const startedAt = lastStatusDate(statuses, ["contract"])
  const endedAt = lastStatusDate(contractStatuses, ["signed"])

  return (
    <Step
      title={t("flowSteps.contract")}
      variant={variant}
      startedAt={startedAt}
      endedAt={endedAt}
      leftArrow
      rightArrow>
      <StatusTitle>{t(`common:contractStatus.${contractStatus}`)}</StatusTitle>
      {contractStatus === "not_started" && <StepIcon icon={Warning} variant={variant} />}
      {contractStatus === "requested" && <StepIcon icon={Announcement} variant={variant} />}
      {contractStatus === "sent" && <StepIcon icon={Email} variant={variant} />}
      {contractStatus === "signed" && <StepIcon icon={Check} variant={variant} />}
    </Step>
  )
}

Contract.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
