import React from "react"
import * as PropTypes from "prop-types"
import { Grid } from "@material-ui/core"
import DocumentationReview from "./DocumentationReview"
import ScoringDocumentation from "./ScoringDocumentation"
import Scoring from "./Scoring"
import ScoringResult from "./ScoringResult"
import Contract from "./Contract"
import Result from "./Result"
import { GridSizesContext } from "../../../../../contexts"

export default function Steps(props) {
  const { leasingRequest } = props
  const { online } = leasingRequest

  const gridSizes = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: online ? 6 : 4,
    xl: online ? 3 : 2
  }

  return (
    <GridSizesContext.Provider value={gridSizes}>
      <Grid container spacing={2}>
        {!online && <DocumentationReview leasingRequest={leasingRequest} />}
        {!online && <ScoringDocumentation leasingRequest={leasingRequest} />}
        <Scoring leasingRequest={leasingRequest} />
        <ScoringResult leasingRequest={leasingRequest} />
        <Contract leasingRequest={leasingRequest} />
        <Result leasingRequest={leasingRequest} />
      </Grid>
    </GridSizesContext.Provider>
  )
}

Steps.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
