import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import CountryCodeSelector from "../inputs/CountryCodeSelector"

const UPDATE_COUNTRY_CODE = gql`
  mutation updateCountryCode($id: ID!, $leadArguments: UpdateCountryCodeInputObject!) {
    updateCountryCode(input: { leadId: $id, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function UpdateLeadCountry(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation("lead")
  const [mutation, { data, loading, error }] = useMutation(UPDATE_COUNTRY_CODE, {
    onCompleted: newData => {
      if (newData.updateCountryCode?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updateCountryCode?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leadId, leadArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <CountryCodeSelector required />
      <ModalPrimaryButton isFetching={loading}>{t("changeCountry")}</ModalPrimaryButton>
    </Form>
  )
}

UpdateLeadCountry.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
