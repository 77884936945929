import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { shallowEqual, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import { TwilioContext } from "../../contexts"
import { redirectToSubject } from "../../helpers/utils"
import Popup from "./Popup"

export default function ActiveCall(props) {
  const { popupProps } = props
  const { connection } = useContext(TwilioContext)
  const [muted, setMuted] = useState(localStorage.getItem("muted") === "true" || false)
  const { subjectId, subjectType, subjectName, inCall, allPartiesInCall, warnings } = useSelector(
    mapStateToProps,
    shallowEqual
  )
  const history = useHistory()

  useEffect(() => {
    if (inCall && connection && allPartiesInCall) connection.mute(muted)
  }, [inCall, connection, allPartiesInCall, muted])

  if (!inCall) return null

  const hangUp = () => {
    if (connection) connection.disconnect()
  }

  const micToggle = () => {
    setMuted(!muted)
    localStorage.setItem("muted", !muted)
  }

  const goToSubject = () => {
    return redirectToSubject(history, subjectType, subjectId)
  }

  return (
    <Popup
      allPartiesInCall={allPartiesInCall}
      goToSubject={goToSubject}
      subjectName={subjectName}
      subjectType={subjectType}
      micToggle={micToggle}
      muted={muted}
      hangUp={hangUp}
      signalWarning={!isEmpty(warnings)}
      {...popupProps}
    />
  )
}

ActiveCall.propTypes = {
  popupProps: PropTypes.object
}

ActiveCall.defaultProps = {
  popupProps: {}
}

const mapStateToProps = state => {
  const { callReducer } = state
  const { subjectId, subjectType, subjectName, inCall, allPartiesInCall, warnings } = callReducer

  return { subjectId, subjectType, subjectName, inCall, allPartiesInCall, warnings }
}
