import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import TextField from "@material-ui/core/TextField/TextField"
import { Grid, MenuItem } from "@material-ui/core"
import { filter, isEmpty, some } from "lodash"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { EmailContext } from "../../../../contexts"
import { emailTemplateCategoryOptions } from "../../../../helpers/constants"

const SEARCH_EMAIL_TEMPLATES = gql`
  query searchEmailTemplates($resource: Resource, $locale: Locale) {
    searchEmailTemplates(resource: $resource, locale: $locale, limit: 100) {
      results {
        id
        identifier
        category
        subject
        body
      }
    }
  }
`

export default function EmailTemplateSelector(props) {
  const { onChange } = props
  const [value, setValue] = useState("")
  const [category, setCategory] = useState("")
  const [options, setOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const { t } = useTranslation()
  const { resource, locale } = useContext(EmailContext)
  const { data } = useQuery(SEARCH_EMAIL_TEMPLATES, {
    variables: {
      resource,
      locale
    }
  })

  useEffect(() => {
    if (data) {
      const categoryList = filter(emailTemplateCategoryOptions(t), option =>
        some(data.searchEmailTemplates.results, { category: option.value })
      )

      setOptions(data.searchEmailTemplates.results)
      setCategoryOptions(categoryList)

      if (isEmpty(categoryList)) setCategory("")
      else setCategory(categoryList[0].value)
    }
  }, [data, t])

  const handleEmailTemplate = event => {
    setValue(event.target.value)
    const emailTemplate = options.find(o => o.id === event.target.value)
    onChange(emailTemplate)
  }

  const handleCategoryChange = event => {
    setCategory(event.target.value)
    setValue("")
  }

  const showCategories = !isEmpty(categoryOptions) && categoryOptions.length > 1
  const filteredOptions = showCategories && !isEmpty(category) ? filter(options, { category }) : options

  return (
    <Grid container spacing={2}>
      {showCategories && (
        <Grid item xs={6}>
          <TextField
            label={t("emailTemplateCategory")}
            select
            fullWidth
            onChange={handleCategoryChange}
            value={category}>
            {categoryOptions.map(categoryOption => (
              <MenuItem key={categoryOption.value} value={categoryOption.value}>
                {categoryOption.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={showCategories ? 6 : 12}>
        <TextField label={t("selectEmailTemplate")} select fullWidth onChange={handleEmailTemplate} value={value}>
          {filteredOptions.map(emailTemplate => {
            return (
              <MenuItem key={emailTemplate.id} value={emailTemplate.id}>
                {emailTemplate.identifier}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
    </Grid>
  )
}

EmailTemplateSelector.propTypes = {
  onChange: PropTypes.func.isRequired
}
