import React, { useContext, useEffect, useState } from "react"
import { Form, Text, useFormState } from "informed"
import { gql, useMutation, useQuery } from "@apollo/client"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import FormControl from "@material-ui/core/FormControl"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import { MultiLineParagraph, StyledLabel } from "../../styled/typography"
import { LeasingRequestCarContext } from "../../../contexts"
import TextSelectInput from "../inputs/TextSelectInput"
import { createOption } from "../../../helpers/forms"
import { createLeasingRequestValidationSchema } from "../../../helpers/validators"
import { leasingRequestCloseReasonsOptions } from "../../../helpers/constants"
import CheckboxInput from "../inputs/CheckboxInput"
import CreateLeasingRequestFields from "../inputs/CreateLeasingRequestFields"
import CarInformationFields from "../CarInformationFields"

const UPDATE_LEASING_REQUEST = gql`
  mutation updateLeasingRequest($leasingRequestId: ID!, $leasingRequestArguments: UpdateLeasingRequestInputObject!) {
    updateLeasingRequest(
      input: { leasingRequestId: $leasingRequestId, leasingRequestArguments: $leasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

const DUPLICATE_LEASING_REQUEST = gql`
  mutation duplicateLeasingRequest(
    $leasingRequestId: ID!
    $duplicateLeasingRequestArguments: DuplicateLeasingRequestInputObject!
  ) {
    duplicateLeasingRequest(
      input: {
        leasingRequestId: $leasingRequestId
        duplicateLeasingRequestArguments: $duplicateLeasingRequestArguments
      }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      firstName
      lastName
      secondLastName
      companyName
      email
      phone
      street
      streetNumber
      floor
      postalCode
      town
      province
      birthday
      documentType
      idNumber
      jobType
      driveLicenseExpeditionDate
      countryCode
      fcaProvince
      fcaBirthProvince
      fcaOccupation
      fcaCivilStatus
      employedSince
      actualMonthlyIncome
      invoiced
      netReturns
      quantity
      downPayment
      guarantor
      guarantorName
      guarantorEmail
      guarantorIdNumber
      guarantorPhone
      guarantorStreet
      guarantorStreetNumber
      guarantorPostalCode
      guarantorTown
      guarantorProvince
    }
  }
`

const CAR = gql`
  query car($id: ID!, $planId: ID!) {
    car(id: $id) {
      id
      color
      title
      distributorId
      plan(planId: $planId) {
        kmYear
        duration
        price
      }
    }
  }
`

const DuplicateLeasingRequest = props => {
  const { getPropsForField, errors, leadId } = props
  const { t } = useTranslation("leasingRequest")
  const formState = useFormState()

  return (
    <>
      <CheckboxInput field="closeCurrent" label={t("leasingRequest:closeCurrent")} />
      {formState.values.closeCurrent && (
        <TextSelectInput
          field="closedReason"
          label={t("common:closeReason")}
          options={leasingRequestCloseReasonsOptions(t)}
          errors={errors}
        />
      )}
      <CreateLeasingRequestFields leadId={leadId} getPropsForField={getPropsForField} errors={errors} />
    </>
  )
}

DuplicateLeasingRequest.propTypes = {
  getPropsForField: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired,
  errors: PropTypes.array
}

DuplicateLeasingRequest.defaultProps = {
  errors: null
}

export default function ChangeLeasingRequestPlan(props) {
  const { leasingRequestId, distributorId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mode, setMode] = useState(null)
  const { leasingRequestCar } = useContext(LeasingRequestCarContext)
  const { carId, priceId } = leasingRequestCar
  const { data: leasingRequestData, loading: leasingRequestLoading } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })
  const { data: carData, loading: carLoading } = useQuery(CAR, {
    variables: {
      id: carId,
      planId: priceId
    }
  })

  const gqlMutation = mode === "changeLeasingRequestPlan" ? UPDATE_LEASING_REQUEST : DUPLICATE_LEASING_REQUEST
  const [mutation, { data, loading, error }] = useMutation(gqlMutation, {
    onCompleted: newData => {
      if (newData[operationName]?.errors == null) {
        handleSuccess()
      }
    }
  })

  useEffect(() => {
    if (carData?.car?.distributorId !== distributorId) {
      setMode("duplicate")
    } else {
      setMode("changeLeasingRequestPlan")
    }
  }, [carData?.car?.distributorId])

  if (carLoading || leasingRequestLoading) return null

  if (leasingRequestData.leasingRequest === null) return <p>Not found</p>
  if (error) return <p>Server Error</p>

  const operationName = gqlMutation.definitions[0].name.value
  const errors = data?.[operationName]?.errors

  const handleSubmit = values => {
    const gqlArguments =
      mode === "changeLeasingRequestPlan"
        ? { leasingRequestArguments: values }
        : { duplicateLeasingRequestArguments: { ...values, priceId, carId, mode: values.mode || "offline" } }
    mutation({
      variables: { leasingRequestId, ...gqlArguments }
    })
  }

  const onModeChange = event => {
    setMode(event.target.value)
  }

  const getPropsForField = (field, defaultValue) => {
    const initialValue =
      leasingRequestData.leasingRequest[field] === undefined ? defaultValue : leasingRequestData.leasingRequest[field]

    return {
      errors,
      field,
      initialValue,
      label: t(`common:${field}`)
    }
  }

  return (
    <Form onSubmit={handleSubmit} validationSchema={createLeasingRequestValidationSchema(t)}>
      <GraphQLErrors errors={errors} />
      <MultiLineParagraph>{t("changeLeasingRequestPlanExplanation")}</MultiLineParagraph>
      <FormControl component="fieldset">
        <StyledLabel>{t("leasingRequest:changeLeasingRequestPlanMode")}</StyledLabel>
        <RadioGroup row value={mode} onChange={onModeChange}>
          {["changeLeasingRequestPlan", "duplicate"].map(m => (
            <FormControlLabel key={m} control={<Radio color="primary" />} label={t(`leasingRequest:${m}`)} value={m} />
          ))}
        </RadioGroup>
      </FormControl>
      {mode === "changeLeasingRequestPlan" && (
        <>
          <CarInformationFields car={carData?.car} />
          <Text type="hidden" field="carId" initialValue={carId} />
          <Text type="hidden" field="priceId" initialValue={priceId} />
          <TextSelectInput
            required
            field="color"
            label={t("common:color")}
            options={carData.car?.color.map(c => createOption(c, c)) || []}
            humanizeFunc={value => t(`common:colors.${value}`)}
            errors={errors}
          />
        </>
      )}
      {mode === "duplicate" && (
        <DuplicateLeasingRequest
          leadId={leasingRequestData.leasingRequest.leadId}
          getPropsForField={getPropsForField}
          errors={errors}
        />
      )}
      <ModalPrimaryButton isFetching={loading}>
        {mode === "changeLeasingRequestPlan" ? t("changeLeasingRequestPlan") : t("duplicate")}
      </ModalPrimaryButton>
    </Form>
  )
}

ChangeLeasingRequestPlan.defaultProps = {
  handleSuccess: () => {}
}

ChangeLeasingRequestPlan.propTypes = {
  distributorId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func,
  leasingRequestId: PropTypes.string.isRequired
}
