import PropTypes from "prop-types"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Grid } from "@material-ui/core"
import { isNil } from "lodash"
import BasicField from "../../../../../components/fields/BasicField"
import Loading from "../../../../../components/Loading"
import InlineEditableField from "../../../../../components/fields/InlineEditableField"
import StaffField from "../../../../../components/fields/StaffField"

const LEAD_ADMIN_DATA = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      assignedAtId
      createdAt
      lastRollbackCreatedById
      attemptsToContact
      lastAttemptToContactAt
      lastAssigneeAt
      lastValidContactAt
      documentationRequestCallDone
      hoursUntilDocumentationRequestCall
      documentationRequestedAt
      lastAbandonedCallAt
      wrongNumber
      unansweredEmail
      removeRequested
      fcaOccupation
      fcaCivilStatus
      fcaBirthProvince
      fcaProvince
      fcaAcceptsConditions
      doNotCall
    }
  }
`

export default function Admin(props) {
  const { leadId, getPropsForField } = props

  const { data, loading } = useQuery(LEAD_ADMIN_DATA, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.lead)) return <p>Not found</p>

  const { lead } = data

  return (
    <>
      <Grid item xs={12} md={6}>
        <StaffField title="Assignee" value={lead.assignedAtId} />
        <BasicField title="Created at" type="datetime" value={lead.createdAt} />
        <StaffField title="Last Rollback Created by" value={lead.lastRollbackCreatedById} />
        <BasicField title="Attempts to Contact" value={lead.attemptsToContact} />
        <BasicField title="Documentation Requested At" type="datetime" value={lead.documentationRequestedAt} />
        <InlineEditableField title="Wrong Number" type="boolean" {...getPropsForField("wrongNumber", data)} />
        <InlineEditableField title="Unanswered Email" type="boolean" {...getPropsForField("unansweredEmail", data)} />
        <BasicField title="Remove Requested" type="boolean" value={lead.removeRequested} />
        <InlineEditableField title="Do not call" type="boolean" {...getPropsForField("doNotCall", data)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicField title="Last Attempted to Contact at" type="datetime" value={lead.lastAttemptToContactAt} />
        <BasicField title="Last Assigned at" type="datetime" value={lead.lastAssigneeAt} />
        <BasicField title="Last Valid Contact at" type="datetime" value={lead.lastValidContactAt} />
        <BasicField title="Documentation Request Call Done" type="boolean" value={lead.documentationRequestCallDone} />
        <BasicField title="Hours Until Documentation Request Call" value={lead.hoursUntilDocumentationRequestCall} />
        <BasicField title="Last Abandoned Call at" type="datetime" value={lead.lastAbandonedCallAt} />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicField title="fcaOccupation" value={lead.fcaOccupation} />
        <BasicField title="fcaCivilStatus" value={lead.fcaCivilStatus} />
        <BasicField title="fcaBirthProvince" value={lead.fcaBirthProvince} />
        <BasicField title="fcaProvince" value={lead.fcaProvince} />
        <BasicField title="fcaAcceptsConditions" type="boolean" value={lead.fcaAcceptsConditions} />
      </Grid>
    </>
  )
}

Admin.propTypes = {
  getPropsForField: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
