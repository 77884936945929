import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Divider from "@material-ui/core/Divider/Divider"
import styled from "styled-components"
import { gql, useQuery } from "@apollo/client"
import { isNil } from "lodash"
import Opportunity from "../Opportunities/Opportunity"
import { StyledH3 } from "../../../../../components/styled/typography"
import InterestedCar from "../../../../../components/InterestedCar"
import Loading from "../../../../../components/Loading"

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      opportunities(limit: 1) {
        id
        kind
        createdAt
        basicCar {
          carId
          priceId
          title
          duration
          kmYear
          color
          price
          deliveryTime
          distributorId
          outdated
        }
      }
      interestedCars {
        id
        title
        carId
        version
        color
        distributorId
        priceId
        duration
        kmYear
        price
        outdated
        deliveryTime
        createdAt
      }
    }
  }
`

const TopicWrapper = styled(Grid)`
  margin: 5px 0 0 0;
`

const StyledDivider = styled(Divider)`
  margin: 0 5px 5px 0;
`

const StyledHeader = styled(StyledH3)`
  margin: 0;
`

export default function Summary(props) {
  const { leadId, setOpenModal } = props
  const { t } = useTranslation("lead")
  const { loading, data, refetch } = useQuery(LEAD, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.lead)) return <p>Not found</p>

  const { lead } = data
  const { opportunities, interestedCars } = lead

  return (
    <>
      <TopicWrapper container>
        <Grid item xs={12}>
          <StyledHeader>{t("lead:opportunities.last")}</StyledHeader>
          <StyledDivider />
        </Grid>
        {opportunities.map(opportunity => (
          <Grid item xs={12} key={opportunity.id}>
            <Opportunity key={opportunity.id} opportunity={opportunity} setOpenModal={setOpenModal} />
          </Grid>
        ))}
      </TopicWrapper>
      <TopicWrapper container>
        <Grid item xs={12}>
          <StyledHeader>{t("lead:interestedIn")}</StyledHeader>
          <StyledDivider />
        </Grid>
        {interestedCars.map(interestedCar => (
          <Grid item xs={12} md={6} key={interestedCar.id}>
            <InterestedCar
              key={interestedCar.id}
              subjectId={lead.id}
              subjectType="Lead"
              interestedCar={interestedCar}
              refetch={refetch}
              setOpenModal={setOpenModal}
            />
          </Grid>
        ))}
      </TopicWrapper>
    </>
  )
}

Summary.propTypes = {
  leadId: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired
}
