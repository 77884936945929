import React from "react"
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import makeStyles from "@material-ui/styles/makeStyles"
import classNames from "classnames"
import Toolbar from "@material-ui/core/Toolbar"
import BugReportIcon from "@material-ui/icons/BugReport"
import HelpIcon from "@material-ui/icons/Help"
import styled from "styled-components"
import LogRocket from "logrocket"
import { useTranslation } from "react-i18next"
import * as PropTypes from "prop-types"
import { Link } from "react-router-dom"
import ActiveTwilio from "../../ActiveTwilio"
import withRedux from "../../../hocs/withRedux"

const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px) !important`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    height: 60,
    width: 60,
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: "none !important"
  },
  grow: {
    flexGrow: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  }
}))

const Logo = styled.img`
  height: 40px;
  width: auto;
  margin: 10px 1rem;
`

function BugReport() {
  const { t } = useTranslation()

  const handleBugReport = () => {
    // eslint-disable-next-line no-alert
    const explanation = window.prompt(t("bugTracking.explanation"))
    if (LogRocket && explanation != null) {
      LogRocket.captureMessage(`SE ${explanation}`, {
        tags: {
          staff: true,
          completeMessage: explanation,
          url: window.location.href
        }
      })
    }
  }

  return (
    <IconButton style={{ color: "white" }} onClick={handleBugReport}>
      <BugReportIcon style={{ color: "white" }} />
    </IconButton>
  )
}

function Help() {
  return (
    <Link to="/help">
      <IconButton>
        <HelpIcon style={{ color: "white" }} />
      </IconButton>
    </Link>
  )
}

function NavBar(props) {
  const { leftMenuOpen, toggleLeftMenu, leftMenu } = props
  const classes = useStyles()

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: leftMenu && leftMenuOpen
      })}>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={toggleLeftMenu}
          className={classNames(classes.menuButton, {
            [classes.hide]: leftMenuOpen
          })}>
          <MenuIcon />
        </IconButton>
        <Logo src={`${process.env.REACT_APP_CLOUDINARY_AUTO}/v1543925691/static/logos/swipcar_white.png`} />
        <div className={classes.grow} />
        <div className={classes.search}>
          <ActiveTwilio />
          <BugReport />
          <Help />
        </div>
      </Toolbar>
    </AppBar>
  )
}

NavBar.propTypes = {
  leftMenuOpen: PropTypes.bool.isRequired,
  toggleLeftMenu: PropTypes.func.isRequired,
  leftMenu: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  const { accountReducer } = state
  const {
    visualConfiguration: { leftMenuOpen }
  } = accountReducer

  return { leftMenuOpen }
}

export default withRedux(NavBar, mapStateToProps)
