import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { find } from "lodash"
import { Text, useFormApi } from "informed"
import TextSelectInput from "../../TextSelectInput"

export const fcaCivilStatus = t => [
  { value: "C", label: t("common:civilStatus.married") },
  { value: "D", label: t("common:civilStatus.divorced") },
  { value: "S", label: t("common:civilStatus.single") }
]

export default function CivilStatusSelector(props) {
  const { t } = useTranslation()
  const { initialValue, errors } = props
  const formApi = useFormApi()

  const handleChange = e => {
    const { value } = e.target
    const current = find(fcaCivilStatus(t), { value }) || {}
    formApi.setValue("civilStatus", current.label)
  }

  const selected = find(fcaCivilStatus(t), { value: initialValue }) || {}

  return (
    <>
      <TextSelectInput
        field="fcaCivilStatus"
        label={t("common:civilStatus.title")}
        initialValue={selected.value}
        errors={errors}
        options={fcaCivilStatus(t)}
        onChange={handleChange}
        required
      />
      <Text field="civilStatus" initialValue={selected.label} hidden />
    </>
  )
}

CivilStatusSelector.propTypes = {
  errors: PropTypes.array,
  initialValue: PropTypes.string
}

CivilStatusSelector.defaultProps = {
  errors: null,
  initialValue: null
}
