import React from "react"
import * as PropTypes from "prop-types"
import classNames from "classnames"
import { makeStyles } from "@material-ui/styles"
import { green, red } from "@material-ui/core/colors"

const useStyles = makeStyles(theme => ({
  icon: {
    width: "100%",
    "min-height": "150px",
    color: theme.palette.grey[300]
  },
  current: {
    color: "var(--stormy-blue)"
  },
  completed: {
    color: green[400]
  },
  cancelled: {
    color: red[400]
  }
}))

export default function StepIcon(props) {
  const { icon, variant } = props
  const classes = useStyles()
  const Icon = icon

  return <Icon className={classNames(classes.icon, classes[variant])} />
}

StepIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
  variant: PropTypes.string
}

StepIcon.defaultProps = {
  variant: null
}
