import React, { useContext, useEffect } from "react"
import PlayIcon from "@material-ui/icons/PlayArrow"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { gql, useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"
import CircularProgress from "@material-ui/core/CircularProgress"
import { DefaultButton } from "../../../../components/styled/buttons"
import { receiveCurrentTask, receiveNotification } from "../../../../actions"
import { TwilioContext } from "../../../../contexts"
import { redirectToSubject } from "../../../../helpers/utils"

const Wrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: inline-flex;
`

const NEXT_TASK = gql`
  mutation nextTask($id: ID!) {
    nextTask(input: { staffId: $id }) {
      errors {
        messages
        path
      }
      result {
        id
        subjectId
        subjectTitle
        subjectType
        mandatoryCall
        identifier
        comment
        commentType
      }
    }
  }
`

export default function NextTask() {
  const { accountId, inCall, everythingReady, callPool } = useSelector(mapStateToProps, shallowEqual)
  const history = useHistory()
  const dispatch = useDispatch()
  const { connect } = useContext(TwilioContext)
  const [nextTask, { data, loading }] = useMutation(NEXT_TASK)

  const getNextTask = () => {
    nextTask({ variables: { id: accountId } })
  }

  useEffect(() => {
    if (data) {
      if (data.nextTask.result == null) {
        dispatch(receiveNotification("There are no more tasks"))
      } else {
        const { subjectId, subjectType, subjectTitle, mandatoryCall } = data.nextTask.result
        dispatch(receiveCurrentTask(data.nextTask.result))
        if (mandatoryCall && subjectType === "Lead") {
          connect(subjectId, subjectTitle, subjectType, accountId)
        }
        redirectToSubject(history, subjectType, subjectId)
      }
    }
  }, [accountId, connect, data, dispatch, history])

  return (
    !callPool && (
      <Wrapper>
        <DefaultButton
          onClick={getNextTask}
          disabled={!everythingReady || inCall || loading}
          variant="contained"
          size="large"
          startIcon={<PlayIcon />}
          color="primary">
          Next Task
          {loading && <CircularProgress size={22} />}
        </DefaultButton>
      </Wrapper>
    )
  )
}

const mapStateToProps = state => {
  const { accountReducer, callReducer } = state
  const { item } = accountReducer
  const { inCall, everythingReady } = callReducer

  return { accountId: item.id, inCall, everythingReady, callPool: item.callPool }
}
