import React, { useRef } from "react"
import { Form } from "informed"
import { useMutation } from "@apollo/client"
import * as PropTypes from "prop-types"
import InputAdornment from "@material-ui/core/InputAdornment"
import Button from "@material-ui/core/Button"
import SendIcon from "@material-ui/icons/Send"
import { useTranslation } from "react-i18next"
import BasicInput from "../inputs/BasicInput"

export default function AddComment(props) {
  const { commentableId, gqlMutation, handleSuccess } = props
  const form = useRef(null)
  const { t } = useTranslation()
  const [mutation, { data, loading }] = useMutation(gqlMutation, {
    onCompleted: newData => {
      if (newData[mutationName]?.errors == null) {
        form.current.reset()
        handleSuccess()
      }
    }
  })
  const mutationName = gqlMutation?.definitions?.[0]?.name?.value
  const errors = data?.[mutationName]?.errors

  const handleSubmit = values => {
    mutation({
      variables: { commentableId, commentArguments: values }
    })
  }

  return (
    <Form
      onSubmit={handleSubmit}
      getApi={api => {
        form.current = api
      }}>
      <BasicInput
        field="content"
        label={t("addNewComment")}
        errors={errors}
        variant="outlined"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button disabled={loading} type="submit">
                <SendIcon />
              </Button>
            </InputAdornment>
          )
        }}
      />
    </Form>
  )
}

AddComment.defaultProps = {
  handleSuccess: () => {}
}

AddComment.propTypes = {
  commentableId: PropTypes.string.isRequired,
  gqlMutation: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func
}
