import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import GraphQLErrors from "../../GraphQLErrors"
import { ModalPrimaryButton } from "../../styled/buttons"
import CheckboxInput from "../inputs/CheckboxInput"
import DistributorEmail from "../inputs/DistributorEmail"

const REQUEST_CONTRACT = gql`
  mutation requestContractToDistributor(
    $leasingRequestId: ID!
    $requestContractToDistributorArguments: RequestContractToDistributorInputObject!
  ) {
    requestContractToDistributor(
      input: {
        leasingRequestId: $leasingRequestId
        requestContractToDistributorArguments: $requestContractToDistributorArguments
      }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function RequestContractToDistributor(props) {
  const { leasingRequestId, distributorId, locale, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(REQUEST_CONTRACT, {
    onCompleted: newData => {
      if (newData.requestContractToDistributor?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.requestContractToDistributor?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({ variables: { leasingRequestId, requestContractToDistributorArguments: values } })
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ formState, formApi }) => (
        <>
          <GraphQLErrors errors={errors} />
          <CheckboxInput field="sendDistributorEmail" label={t("sendDistributorEmail")} initialValue={false} />
          {formState.values.sendDistributorEmail && (
            <DistributorEmail
              formApi={{ current: formApi }}
              leasingRequestId={leasingRequestId}
              distributorId={distributorId}
              locale={locale}
              errors={errors}
              personalDocumentationChecklist
            />
          )}
          <ModalPrimaryButton isFetching={loading}>{t("requestContractToDistributor")}</ModalPrimaryButton>
        </>
      )}
    </Form>
  )
}

RequestContractToDistributor.propTypes = {
  distributorId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}
