import PropTypes from "prop-types"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { isNil } from "lodash"
import BasicField from "../../../../../components/fields/BasicField"
import Loading from "../../../../../components/Loading"
import InlineEditableField from "../../../../../components/fields/InlineEditableField"
import { printSnakeCase } from "../../../../../helpers/formatter"
import {
  leasingRequestCreatedReasonOptions,
  leasingRequestNegotiationLostReasonOptions,
  leasingRequestNegotiationStatusOptions
} from "../../../../../helpers/constants"

const LEASING_REQUEST = gql`
  query leasingRequest($id: ID!) {
    leasingRequest(id: $id) {
      incidence
      incidenceReason
      undecidedClient
      undecidedClientReason
      scoringComments
      documentationReady
      approved
      won
      duplicated
      validatedForCommissionAt
      quotationEmailSentAt
      pendingQuotationEmailSentAt
      scoringStartedAt
      scoringCompletedAt
      dataSentToDistributorAt
      wonAt
      closedAt
      billableAt
      lastFcaRedsysMerchantOrder
      paidFcaRedsysMerchantOrder
      instantorTransactionId
      status
      globalStatus
      offerStatus
      quotationStatus
      scoringStatus
      contractStatus
      closedStatus
      contractExtensionAttempts
      contractExtensionFirst
      contractExtensionSecond
      contractExtensionThird
      negotiationStatus
      negotiationLostReason
      negotiationChangedAt
      webProcessEnabled
      integrationEnabled
      earlyCancelled
      renovated
      createdReason
    }
  }
`

export default function Admin(props) {
  const { leasingRequestId, getPropsForField } = props
  const { t } = useTranslation(["leasingRequest", "common"])

  const { data, loading } = useQuery(LEASING_REQUEST, {
    variables: {
      id: leasingRequestId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.leasingRequest)) return <p>Not found</p>

  const { leasingRequest } = data

  console.log(leasingRequest)

  return (
    <>
      <Grid item xs={12} md={3}>
        <InlineEditableField
          title="Motivo creación"
          type="select"
          humanizeFunc={printSnakeCase}
          options={leasingRequestCreatedReasonOptions(t)}
          {...getPropsForField("createdReason", data)}
        />
        <BasicField title="Global status" value={leasingRequest.globalStatus} />
        <BasicField title="Status" value={leasingRequest.status} />
        <BasicField title={t("offerStatus")} value={t(`offerStatuses.${leasingRequest.offerStatus}`)} />
        <BasicField title={t("quotationStatus")} value={t(`quotationStatuses.${leasingRequest.quotationStatus}`)} />
        <BasicField title="Scoring status" value={leasingRequest.scoringStatus} />
        <BasicField title="Contract status" value={leasingRequest.contractStatus} />
        <BasicField title="Closed status" value={t(`common:closedStatus.${leasingRequest.closedStatus}`)} />
        <BasicField title="Contract extension status" value={leasingRequest.contractExtensionStatus} />
        <BasicField title="Incidence" type="boolean" value={leasingRequest.incidence} />
        <BasicField title="Incidence reason" value={leasingRequest.incidenceReason} />
        <BasicField title="Undecided client" type="boolean" value={leasingRequest.undecidedClient} />
        <BasicField title="Undecided client reason" value={leasingRequest.undecidedClientReason} />
        <BasicField title="Scoring comments" value={leasingRequest.scoringComments} />
        <InlineEditableField
          title="Documentation ready"
          type="boolean"
          {...getPropsForField("documentationReady", data)}
        />
        <BasicField title="Approved" type="boolean" value={leasingRequest.approved} />
        <BasicField title="Won" type="boolean" value={leasingRequest.won} />
        <BasicField title="Duplicated" type="boolean" value={leasingRequest.duplicated} />
        <BasicField title="Web process enabled" type="boolean" {...getPropsForField("webProcessEnabled", data)} />
        <InlineEditableField
          title="Integration enabled"
          type="boolean"
          {...getPropsForField("integrationEnabled", data)}
        />
        <BasicField title="Early cancelled" type="boolean" value={leasingRequest.earlyCancelled} />
        <BasicField title="Renovated" type="boolean" value={leasingRequest.renovated} />
      </Grid>
      <Grid item xs={12} md={3}>
        <BasicField
          title="Validated for commission at"
          type="datetime"
          value={leasingRequest.validatedForCommissionAt}
        />
        <BasicField title={t("quotationEmailSentAt")} type="datetime" value={leasingRequest.quotationEmailSentAt} />
        <BasicField
          title={t("pendingQuotationEmailSentAt")}
          type="datetime"
          value={leasingRequest.pendingQuotationEmailSentAt}
        />
        <InlineEditableField
          title="Scoring started at"
          type="datetime"
          {...getPropsForField("scoringStartedAt", data)}
        />
        <InlineEditableField
          title="Scoring completed at"
          type="datetime"
          {...getPropsForField("scoringCompletedAt", data)}
        />
        <BasicField
          title="All data sent to distributor at"
          type="datetime"
          value={leasingRequest.dataSentToDistributorAt}
        />
        <InlineEditableField title="Won at" type="datetime" {...getPropsForField("wonAt", data)} />
        <InlineEditableField title="Closed at" type="datetime" {...getPropsForField("closedAt", data)} />
        <InlineEditableField title="Billable at" type="datetime" {...getPropsForField("billableAt", data)} />
      </Grid>
      <Grid item xs={12} md={3}>
        <BasicField title="Last FCA Redsys merchant order" value={leasingRequest.lastFcaRedsysMerchantOrder} />
        <BasicField title="Paid FCA Redsys merchant order" value={leasingRequest.paidFcaRedsysMerchantOrder} />
        <BasicField title="Instantor transaction id" value={leasingRequest.instantorTransactionId} />
      </Grid>
      <Grid item xs={12} md={3}>
        <h3>Renovaciones</h3>
        <BasicField title="Intentos de extensión" value={leasingRequest.contractExtensionAttempts} />
        <InlineEditableField
          title="Primera extensión"
          type="date"
          {...getPropsForField("contractExtensionFirst", data)}
        />
        <InlineEditableField
          title="Segunda extensión"
          type="date"
          {...getPropsForField("contractExtensionSecond", data)}
        />
        <InlineEditableField
          title="Tercera extensión"
          type="date"
          {...getPropsForField("contractExtensionThird", data)}
        />
        <h3>Negociación</h3>
        <InlineEditableField
          title="Estado"
          type="select"
          humanizeFunc={printSnakeCase}
          options={leasingRequestNegotiationStatusOptions(t)}
          {...getPropsForField("negotiationStatus", data)}
        />
        {leasingRequest.negotiationStatus === "lost" && (
          <InlineEditableField
            title="Razon perdido"
            type="select"
            humanizeFunc={printSnakeCase}
            options={leasingRequestNegotiationLostReasonOptions(t)}
            {...getPropsForField("negotiationLostReason", data)}
          />
        )}
        <BasicField title="Ultimo cambio de estado" type="datetime" value={leasingRequest.negotiationChangedAt} />
      </Grid>
    </>
  )
}

Admin.propTypes = {
  leasingRequestId: PropTypes.string.isRequired,
  getPropsForField: PropTypes.func.isRequired
}
