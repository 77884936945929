import React from "react"
import MDEditor from "@uiw/react-md-editor"
import { asField } from "informed"
import { isEmpty } from "lodash"
import { getFieldError } from "../../../../helpers/errorParser"
import { StyledError } from "../../../styled/typography"

const MDInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { field, initialValue, errors, onChange, onBlur, forwardedRef, errorsPlace } = props

  const { setValue, setTouched } = fieldApi

  let { value } = fieldState
  const { error: fieldError } = fieldState
  if (value === undefined) {
    value = initialValue || ""
  }

  const errorsText = getFieldError(errors, field, errorsPlace)

  return (
    <>
      <MDEditor
        height={400}
        field={field}
        value={value}
        onChange={newValue => {
          setValue(newValue)
          if (onChange) {
            onChange(newValue)
          }
        }}
        onBlur={e => {
          setTouched()
          if (onBlur) {
            onBlur(e)
          }
        }}
        ref={forwardedRef}
        autoFocus={false}
        textareaProps={{
          spellCheck: true
        }}
        preview="edit"
      />
      {!isEmpty(errorsText) && <StyledError>{errorsText}</StyledError>}
      {!isEmpty(fieldError) && <StyledError>{fieldError}</StyledError>}
    </>
  )
})

export default MDInput
