import PropTypes from "prop-types"
import React from "react"
import styled, { keyframes } from "styled-components"
import { Grid } from "@material-ui/core"
import { fadeInDown } from "react-animations"
import Email from "../Email"
import { StyledGreyPaper } from "../../styled/papers"
import ReplyEmail from "../../forms/ReplyEmail"

const fadeInDownAnimation = keyframes`${fadeInDown}`

const FullEmailWrapper = styled(Grid)`
  width: 100%;
  animation: 0.5s ${fadeInDownAnimation};
`

const Content = styled.pre`
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ContentWrapper = styled(Grid)`
  background-color: var(--light-grey);
  padding: 15px;
`

export default function FullEmail(props) {
  const { email, handleClick } = props
  const { to, subject, content, sent } = email

  return (
    <FullEmailWrapper item>
      <Email email={email} handleClick={handleClick} />
      <ContentWrapper item xs={12}>
        <Content>{content}</Content>
      </ContentWrapper>
      {!sent && (
        <Grid item xs={12}>
          <StyledGreyPaper>
            <ReplyEmail emailSubject={`Re: ${subject}`} to={to} />
          </StyledGreyPaper>
        </Grid>
      )}
    </FullEmailWrapper>
  )
}

FullEmail.propTypes = {
  email: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}
