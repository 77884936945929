import React, { useContext, useEffect } from "react"
import PlayIcon from "@material-ui/icons/PlayArrow"
import PauseIcon from "@material-ui/icons/Pause"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { gql, useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"
import CircularProgress from "@material-ui/core/CircularProgress"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DefaultButton } from "../../../../components/styled/buttons"
import { receiveAccount, receiveCurrentTask, receiveNotification, updateWorkingStatus } from "../../../../actions"
import { ActionCableContext } from "../../../../contexts"
import { Paragraph } from "../../../../components/styled/typography"
import { redirectToSubject } from "../../../../helpers/utils"
import { updateCurrentCall } from "../../../../actions/call"

const Wrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: inline-flex;
`

const START_WORKING = gql`
  mutation startWorking {
    startWorking(input: {}) {
      result {
        idle
        callPool
        lookingForCall
        taskInProgress
      }
      errors {
        messages
        path
      }
    }
  }
`

const STOP_WORKING = gql`
  mutation stopWorking {
    stopWorking(input: {}) {
      result {
        idle
        callPool
        lookingForCall
        taskInProgress
      }
      errors {
        messages
        path
      }
    }
  }
`

const showError = (errors, dispatch) => {
  const errorMessages = errors.map(e => e.messages).join(", ")
  dispatch(receiveNotification(errorMessages, { variant: "error" }))
}

export default function StartStopWorking() {
  const { windowIdle, accountIdle, everythingReady, callPool, lookingForCall } = useSelector(
    mapStateToProps,
    shallowEqual
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const { subscribeWorkingChannel, unsubscribeWorkingChannel } = useContext(ActionCableContext)
  const [startWorking, { data: startData, loading }] = useMutation(START_WORKING)
  const [stopWorking, { data: stopData }] = useMutation(STOP_WORKING)
  const { t } = useTranslation("dashboard")

  useEffect(() => {
    if (startData) {
      const { result, errors } = startData.startWorking
      if (result) {
        subscribeWorkingChannel(
          () => {
            dispatch(updateWorkingStatus(result, false))
          },
          data => {
            if (data.action === "task") {
              if (data.task == null) {
                dispatch(receiveNotification("There are no more tasks"))
              } else {
                const { subjectId, subjectType, subjectTitle } = data.task
                dispatch(receiveCurrentTask(data.task))
                dispatch(updateCurrentCall(subjectId, subjectType, subjectTitle))
                redirectToSubject(history, subjectType, subjectId)
              }
            }
            if (data.action === "updateStaff") {
              dispatch(receiveAccount(data.item))
            }
          }
        )
      }
      if (errors) showError(errors, dispatch)
    }
  }, [dispatch, history, startData, subscribeWorkingChannel])

  useEffect(() => {
    if (stopData) {
      const { result, errors } = stopData.stopWorking
      if (result) {
        dispatch(updateWorkingStatus(result, true))
      }
      if (errors) showError(errors, dispatch)
    }
  }, [dispatch, stopData])

  const handleStopWorking = () => {
    unsubscribeWorkingChannel()
    stopWorking()
  }

  return (
    callPool && (
      <>
        {/* <Prompt when message={t("doNotRefresh")} /> */}
        <Wrapper>
          {windowIdle && (
            <DefaultButton
              onClick={startWorking}
              disabled={!everythingReady || loading}
              variant="contained"
              size="large"
              startIcon={<PlayIcon />}
              color="primary">
              {t("startWorking")}
              {loading && <CircularProgress size={22} />}
            </DefaultButton>
          )}
          {!accountIdle && !windowIdle && (
            <>
              <DefaultButton
                onClick={handleStopWorking}
                disabled={lookingForCall}
                variant="contained"
                size="large"
                startIcon={<PauseIcon />}
                color="secondary">
                {t("stopWorking")}
                {loading && <CircularProgress size={22} />}
              </DefaultButton>
              {lookingForCall && (
                <Grid>
                  <Paragraph>{t("callPool.wait-1")}</Paragraph>
                  <LinearProgress widht={100} />
                  <Paragraph>{t("callPool.wait-2")}</Paragraph>
                </Grid>
              )}
            </>
          )}
        </Wrapper>
      </>
    )
  )
}

const mapStateToProps = state => {
  const { accountReducer, callReducer } = state
  const { item, idle } = accountReducer
  const { inCall, everythingReady } = callReducer

  return {
    accountId: item.id,
    inCall,
    everythingReady,
    accountIdle: item.idle,
    windowIdle: idle,
    callPool: item.callPool,
    lookingForCall: item.lookingForCall
  }
}
