import PropTypes from "prop-types"
import React from "react"
import {
  AttachMoney,
  Backspace,
  Clear,
  Create,
  Description,
  Done,
  Flag,
  Gavel,
  Help,
  LocalOffer,
  Mail,
  MoneyOff,
  RemoveShoppingCart,
  Replay,
  Report,
  Restore,
  Subject,
  ThumbsUpDown
} from "@material-ui/icons"
import { every, includes, isArray } from "lodash"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DefaultButton } from "../../../../../components/styled/buttons"
import { leasingRequestStatusFields } from "../../../../../helpers/constants"

const items = {
  acceptDocumentation: {
    allowedStatus: ["documentation_review"],
    icon: Done
  },
  rollback: {
    allowedStatus: ["documentation_review"],
    icon: Clear
  },
  requestOffer: {
    icon: LocalOffer,
    allowedStatus: {
      offerStatus: ["not_started"]
    }
  },
  receiveOffer: {
    icon: LocalOffer,
    allowedStatus: {
      offerStatus: ["requested"]
    }
  },
  restartOffer: {
    icon: Replay,
    allowedStatus: {
      globalStatus: ["scoring_documentation_pending"],
      offerStatus: ["requested", "received"]
    }
  },
  sendQuotation: {
    allowedStatus: {
      quotationStatus: ["not_started", "requested", "sent", "signed"]
    },
    icon: Gavel
  },
  quotationSigned: {
    icon: Gavel,
    allowedStatus: {
      quotationStatus: ["not_started", "requested", "sent"]
    }
  },
  restartQuotation: {
    icon: Replay,
    allowedStatus: {
      quotationStatus: ["requested", "sent", "signed"],
      scoringStatus: ["undecided"],
      status: ["scoring"]
    }
  },
  startScoring: {
    allowedStatus: ["sales", "scoring_documentation_pending", "scoring_under_review", "scoring_undecided"],
    icon: Mail
  },
  reconsiderScoring: {
    allowedStatus: [
      "scoring_under_review",
      "scoring_undecided",
      "approved",
      "contract_distributor",
      "contract_undecided",
      "contract_sent"
    ],
    icon: Help
  },
  completeScoring: {
    allowedStatus: ["scoring_under_review", "scoring_undecided"],
    icon: Done
  },
  scoringUndecided: {
    allowedStatus: {
      quotationStatus: ["signed"],
      offerStatus: ["received"],
      scoringStatus: ["not_started"]
    },
    icon: Done
  },
  noStockScoringReject: {
    allowedStatus: {
      quotationStatus: ["signed"],
      offerStatus: ["received"],
      scoringStatus: ["not_started"]
    },
    icon: RemoveShoppingCart
  },
  undecidedClient: {
    allowedStatus: ["approved", "contract_distributor", "contract_sent"],
    icon: ThumbsUpDown
  },
  requestContractToDistributor: {
    allowedStatus: ["approved"],
    icon: Subject
  },
  sendContract: {
    allowedStatus: ["approved", "contract_distributor", "contract_undecided", "contract_sent"],
    icon: Mail
  },
  contractSigned: {
    allowedStatus: ["contract_undecided", "contract_sent"],
    icon: Done
  },
  sendAllDataToDistributor: {
    allowedStatus: ["contract_signed"],
    icon: Mail
  },
  win: {
    allowedStatus: ["contract_signed", "cancelled"],
    icon: Flag
  },
  winContractExtension: {
    allowedStatus: {
      contractExtensionStatus: ["signed"]
    },
    icon: Flag
  },
  lose: {
    allowedStatus: {
      closedStatus: ["cancelled"]
    },
    icon: MoneyOff
  },
  markAsBillable: {
    allowedStatus: {
      closedStatus: ["won"]
    },
    icon: AttachMoney
  },
  cancel: {
    allowedStatus: {
      closedStatus: ["won", "billable"]
    },
    icon: Backspace
  },
  returnToContract: {
    allowedStatus: {
      closedStatus: ["won"]
    },
    icon: Create
  },
  returnToContractDistributor: {
    allowedStatus: ["contract_sent"],
    icon: Create
  },
  reopen: {
    allowedStatus: ["closed", "not_signed"],
    icon: Restore
  },
  close: {
    allowedStatus: [
      "sales",
      "scoring_documentation_pending",
      "scoring_under_review",
      "scoring_undecided",
      "approved",
      "contract_distributor",
      "contract_sent",
      "contract_undecided",
      "contract_signed"
    ],
    icon: Clear
  },
  signContractExtension: {
    allowedStatus: {
      contractExtensionStatus: ["approved"]
    },
    icon: Description
  },
  resolveContractExtension: {
    allowedStatus: {
      contractExtensionStatus: ["pending"]
    },
    icon: Done
  },
  createClientRequest: {
    allowedStatus: {
      closedStatus: ["billable"]
    },
    icon: Report
  },
  closeNotSigned: {
    allowedStatus: [
      "sales",
      "scoring_documentation_pending",
      "scoring_under_review",
      "scoring_undecided",
      "approved",
      "contract_distributor",
      "contract_sent",
      "contract_undecided",
      "contract_signed"
    ],
    icon: Clear
  }
}

const Wrapper = styled(Grid)`
  margin: 15px 0;
`

const getIcon = Icon => {
  return <Icon />
}

export default function SecondaryActions(props) {
  const { statuses, openModal } = props
  const { t } = useTranslation("leasingRequest")

  const handleClick = key => {
    openModal(key)
  }

  const allowedAction = action => {
    if (isArray(action.allowedStatus)) return includes(action.allowedStatus, statuses.globalStatus)

    return every(
      leasingRequestStatusFields,
      key => action.allowedStatus[key] === undefined || includes(action.allowedStatus[key], statuses[key])
    )
  }

  return (
    <Wrapper item xs={12}>
      {Object.keys(items).map(
        k =>
          allowedAction(items[k]) && (
            <DefaultButton
              key={k}
              onClick={() => handleClick(k)}
              variant="contained"
              size="large"
              color="secondary"
              startIcon={getIcon(items[k].icon)}
            >
              {t(k)}
            </DefaultButton>
          )
      )}
    </Wrapper>
  )
}

SecondaryActions.propTypes = {
  statuses: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired
}
