import i18n from "i18next"
import XHR from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { AVAILABLE_LOCALES } from "./helpers/constants"

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      queryStringParams: { v: process.env.REACT_APP_GIT_INFO },
      crossDomain: true,
      loadPath: process.env.REACT_APP_LOCALE_PATH
    },
    fallbackLng: "en",

    ns: ["common", "dashboard", "lead", "leasingRequest", "client", "clientRequest"],
    defaultNS: ["common"],
    lowerCaseLng: true,
    whitelist: ["en"].concat(AVAILABLE_LOCALES),

    debug: process.env.REACT_APP_ENVIRONMENT !== "production",
    load: "languageOnly",
    /* cache: {
      enabled: true,
      expirationTime: 7 * 24 * 60 * 60 * 100,
      versions: {en: 'v1.0.3'}
    }, */
    react: {
      useSuspense: false
    }
  })

export default i18n
