const gqlFields = `
  id
  clientId
  createdAt
  updatedAt
  globalStatus
  status
  quotationStatus
  offerStatus
  scoringStatus
  contractStatus
  closedStatus
  contractExtensionStatus
  contractExtensionAttempts
  contractExtensionFirst
  contractExtensionSecond
  contractExtensionThird
  won
  wonAt
  documentationReady
  externalReferenceId
  online
  guarantor
  guarantorName
  guarantorEmail
  guarantorIdNumber
  conditions
  deposit
  downPayment
  scoringStartedAt
  scoringCompletedAt
  scoringComments
  quantity
  prioritization
  lastStatusChangedAt
  closedReason
  closedReasonComment
  webProcessEnabled
  contractExpiresAt
  estimatedDeliveryAt
  earlyCancelled
  comments {
    id
    content
    createdById
    createdAt
  }
  statuses {
    status
    createdAt
    endedAt
  }
  globalStatuses {
    status
    createdAt
    endedAt
  }
  scoringStatuses {
    status
    createdAt
    endedAt
  }
  contractStatuses {
    status
    createdAt
    endedAt
  }
  contractExtensionStatuses {
    status
    createdAt
    endedAt
  }
  assignedAt {
    id
    name
  }
  distributor {
    id
    name
    onlineUrlEnabled
  }
  personalDocuments {
    id
    tag
    preferredUrl
    documentFileName
  }
  basicCar {
    carId
    carDeliveryTime
    deliveryTime
    outdated
    duration
    kmYear
    price
    sold
    color
    car {
      id
      brand
      model
      version
      defaultImageUrl
    }
  }
`

export default gqlFields
