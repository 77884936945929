export const RECEIVE_ACCOUNT = "RECEIVE_ACCOUNT"
export const SIGN_OUT_ACCOUNT = "SIGN_OUT_ACCOUNT"
export const TOGGLE_LEFT_MENU = "TOGGLE_LEFT_MENU"
export const RECEIVE_CURRENT_TASK = "RECEIVE_CURRENT_TASK"
export const FINISH_CURRENT_TASK = "FINISH_CURRENT_TASK"
export const UPDATE_WORKING_STATUS = "UPDATE_WORKING_STATUS"

export const toggleLeftMenu = () => {
  return {
    type: TOGGLE_LEFT_MENU
  }
}

export const signOutAccount = () => {
  return {
    type: SIGN_OUT_ACCOUNT
  }
}

export const receiveAccount = account => {
  return {
    type: RECEIVE_ACCOUNT,
    account
  }
}

export const receiveCurrentTask = task => {
  return {
    type: RECEIVE_CURRENT_TASK,
    task
  }
}

export const finishCurrentTask = () => {
  return {
    type: FINISH_CURRENT_TASK
  }
}

export const updateWorkingStatus = (account, idle) => {
  return {
    type: UPDATE_WORKING_STATUS,
    account,
    idle
  }
}
