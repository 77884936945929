import React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import { asField } from "informed"
import { isEmpty, isEqual } from "lodash"
import { TextField } from "@material-ui/core"
import Clear from "@material-ui/icons/Clear"
import styled from "styled-components"
import { getFieldError } from "../../../../helpers/errorParser"

const ClearIcon = styled(Clear)`
  cursor: pointer;
  right: 25px;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
`

const TextSelectInput = asField(({ fieldState, fieldApi, ...props }) => {
  const {
    field,
    errorsPlace,
    errors,
    onChange,
    onBlur,
    forwardedRef,
    options,
    noneOption,
    multiple,
    displayClear,
    helperText,
    humanizeFunc,
    ...rest
  } = props

  const { setValue } = fieldApi

  const { value } = fieldState
  const { error: fieldError } = fieldState

  const emptyValue = multiple ? [] : ""

  const errorsText = getFieldError(errors, field, errorsPlace)

  const clear = () => {
    setValue(emptyValue)
    if (onChange) {
      onChange({ target: { value: emptyValue } })
    }
  }

  return (
    <>
      <TextField
        id={field}
        field={field}
        name={field}
        select
        fullWidth
        multiple={multiple}
        ref={forwardedRef}
        onChange={e => {
          const { value: newValue } = e.target
          setValue(newValue)
          if (onChange) {
            onChange(e)
          }
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e)
          }
        }}
        error={!isEmpty(errorsText) || !isEmpty(fieldError)}
        helperText={fieldError || errorsText || helperText}
        value={value === undefined ? emptyValue : value}
        SelectProps={{ multiple }}
        InputProps={{
          endAdornment: value !== undefined && !isEqual(value, emptyValue) && displayClear && (
            <ClearIcon label="Clear" onClick={clear} />
          )
        }}
        {...rest}>
        {noneOption && (
          <MenuItem key={0} value="">
            <em />
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
            {humanizeFunc ? humanizeFunc(option.value) : option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  )
})

export default TextSelectInput
