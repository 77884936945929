import React from "react"
import TableCell from "@material-ui/core/TableCell"
import { useHistory } from "react-router-dom"
import * as PropTypes from "prop-types"
import IconButton from "@material-ui/core/IconButton"
import TabIcon from "@material-ui/icons/Tab"
import { startCase } from "lodash"
import { useTranslation } from "react-i18next"
import { shallowEqual, useSelector } from "react-redux"
import { StyledIndexTableRowBody } from "../../../../components/styled/tables"
import Staff from "../../../../models/staff"
import { printDateTime } from "../../../../helpers/formatter"
import BasicField from "../../../../components/fields/BasicField"
import Distributor from "../../../../models/distributor"
import { openWindowForSubject, redirectToSubject } from "../../../../helpers/utils"

export default function IndexElement(props) {
  const { entity } = props
  const {
    id,
    title,
    assignedAtId,
    createdReason,
    contractExtensionAssignedAtId,
    globalStatus,
    quotationStatus,
    offerStatus,
    basicCar,
    online,
    distributorId,
    lastStatusChangedAt,
    lastComment,
    countryCode
  } = entity
  const { staff, distributors } = useSelector(mapStateToProps, shallowEqual)
  const history = useHistory()
  const { t } = useTranslation("leasingRequest")
  const staffName = Staff.getName(staff, contractExtensionAssignedAtId) || Staff.getName(staff, assignedAtId)
  const DistributorName = Distributor.getName(distributors, distributorId)

  return (
    <StyledIndexTableRowBody onClick={() => redirectToSubject(history, "LeasingRequest", id)}>
      <TableCell>
        <IconButton
          onClick={event => {
            event.stopPropagation()
            openWindowForSubject("LeasingRequest", id)
          }}
        >
          <TabIcon />
        </IconButton>
      </TableCell>
      <TableCell>{startCase(title)}</TableCell>
      <TableCell>{staffName}</TableCell>
      <TableCell>{t(`common:createdReasonOptions.${createdReason}`)}</TableCell>
      <TableCell>{t(`common:leasingRequestGlobalStatus.${globalStatus}`)}</TableCell>
      <TableCell>{t(`leasingRequest:quotationStatuses.${quotationStatus}`)}</TableCell>
      <TableCell>{t(`leasingRequest:offerStatuses.${offerStatus}`)}</TableCell>
      <TableCell>{`${basicCar?.brand} ${basicCar?.model}`}</TableCell>
      <TableCell>
        <BasicField value={online} type="boolean" />
      </TableCell>
      <TableCell>{DistributorName}</TableCell>
      <TableCell>{printDateTime(lastStatusChangedAt)}</TableCell>
      <TableCell>{t(`common:country.${countryCode}`)}</TableCell>
      <TableCell>{lastComment?.content}</TableCell>
    </StyledIndexTableRowBody>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { staff, distributors } = commonDataReducer

  return { staff: staff.items, distributors: distributors.items }
}

IndexElement.propTypes = {
  entity: PropTypes.object.isRequired
}
