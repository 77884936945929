import { Description, Grade } from "@material-ui/icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import SimpleData from "../../../../components/SimpleData"

const ACCOUNT = gql`
  query account($monthAgo: Int) {
    account {
      monthlyWon(monthAgo: $monthAgo)
      monthlyContractExtensionWon(monthAgo: $monthAgo)
    }
  }
`

export default function SalesCloserSummarize() {
  const { t } = useTranslation("dashboard")
  const { data: lastMonth } = useQuery(ACCOUNT, { variables: 1 })
  const { data: currentMonth } = useQuery(ACCOUNT)

  return (
    <>
      <SimpleData
        IconJSX={Grade}
        title={t("lastMonthBillable")}
        value={`${lastMonth?.account?.monthlyWon}`}
        color="var(--colorful-1)"
      />
      <SimpleData
        IconJSX={Grade}
        title={t("currentMonthBillable")}
        value={`${currentMonth?.account?.monthlyWon}`}
        color="var(--colorful-2)"
      />
      <SimpleData
        IconJSX={Description}
        title={t("currentMonthContractExtensionWon")}
        value={`${currentMonth?.account?.monthlyContractExtensionWon}`}
        color="var(--colorful-3)"
      />
    </>
  )
}
