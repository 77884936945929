import PropTypes from "prop-types"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SwipeableViews from "react-swipeable-views"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { gql } from "@apollo/client"
import Summary from "../Summary"
import PersonalData from "../PersonalData"
import LeasingRequests from "../../../../../components/LeasingRequests"
import ActionLogs from "../../../../../components/ActionLogs"
import actionLogQqlFields from "../../../../../components/ActionLogs/gqlFields"
import Emails from "../Emails"
import Documentation from "../Documentation"
import Feed from "../Feed"
import MoreData from "../MoreData"
import Opportunities from "../Opportunities"
import CallsAndTasks from "../CallsAndTasks"
import StatusList from "../StatusList"
import Admin from "../Admin"
import Marketing from "../Marketing"
import useAbilities from "../../../../../hooks/useAbilities"

const UPDATE_LEAD = gql`
  mutation updateLead($id: ID!, $leadArguments: UpdateLeadInputObject!) {
    updateLead(input: { leadId: $id, leadArguments: $leadArguments }) {
      errors {
        messages
        path
      }
      result {
        id
        title
        status
        lastStatusChangedAt
        firstName
        lastName
        secondLastName
        email
        phone
        jobType
        countryCode
        locale
        createdAt
        origin
        defaulter
        monthlyIncome
        monthlyPayment
        carKindDesired
        annualKilometers
        companyName
        guarantorName
        guarantorEmail
        guarantorIdNumber
        pendingScheduledCallAt
        lastRollbackCreatedById
        lastRollbackReason
        unansweredEmail
        wrongNumber
      }
    }
  }
`

const ACTION_LOGS_QUERY = gql`
  query lead($id: ID!, $offset: Int, $limit: Int) {
    lead(id: $id) {
      id
      ${actionLogQqlFields}
    }
  }
`

const GridTab = styled(Grid)`
  margin-bottom: 15px;
  padding: 5px 10px;
`

const StyledTabs = styled(Tabs)`
  .Mui-selected {
    background-color: var(--light-grey);
  }
`

export default function TabsScene(props) {
  const { leadId, selectedCar, setOpenModal, lastRefreshAt } = props
  const { t } = useTranslation("lead")
  const [selectedTab, setSelectedTab] = useState(0)
  const { allowed: adminAllowed } = useAbilities("read_admin_lead_data")
  const { allowed: marketingAllowed } = useAbilities("read_marketing_lead_data")
  const { allowed: manageLeads } = useAbilities("manage_leads")
  const { allowed: manageCountryLeads } = useAbilities("manage_country_leads")

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab)
  }

  const getPropsForField = (field, newData) => {
    return {
      field,
      id: leadId,
      value: newData.lead[field],
      mutationArguments: "leadArguments",
      gqlMutation: UPDATE_LEAD,
      isEditable: true
    }
  }

  return (
    <>
      <StyledTabs variant="scrollable" value={selectedTab} onChange={handleTabChange}>
        <Tab label={t("common:summary")} />
        <Tab label={t("common:mainData")} />
        <Tab label={t("common:leasingRequest_plural")} />
        <Tab label={t("common:emails")} />
        <Tab label={t("common:documentation.title")} />
        <Tab label={t("common:comments")} />
        <Tab label={t("common:moreData")} />
        {(manageLeads || manageCountryLeads) && <Tab label={t("opportunities.title")} value={7} />}
        {adminAllowed && <Tab label="Calls & Tasks" value={8} />}
        {adminAllowed && <Tab label="Status" value={9} />}
        {(adminAllowed || manageLeads) && <Tab label="Admin Data" value={10} />}
        {marketingAllowed && <Tab label="Marketing" value={11} />}
        {adminAllowed && <Tab label="Action logs" value={12} />}
      </StyledTabs>
      <SwipeableViews
        key={lastRefreshAt}
        slideStyle={{ overflow: "auto auto" }}
        index={selectedTab}
        onChangeIndex={index => handleTabChange(null, index)}
      >
        <GridTab container value={selectedTab} index={0}>
          {/* Negated selected car is for forcing reconstruction when car is added to interested cars */}
          {!selectedCar && selectedTab === 0 && (
            <Summary leadId={leadId} getPropsForField={getPropsForField} setOpenModal={setOpenModal} />
          )}
        </GridTab>
        <GridTab container value={selectedTab} index={1}>
          {/* Negated selected car is for forcing reconstruction when car is added to interested cars */}
          {!selectedCar && selectedTab === 1 && (
            <PersonalData leadId={leadId} getPropsForField={getPropsForField} setOpenModal={setOpenModal} />
          )}
        </GridTab>
        <GridTab container value={selectedTab} index={2}>
          {selectedTab === 2 && <LeasingRequests leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={3}>
          {selectedTab === 3 && <Emails leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={4}>
          {selectedTab === 4 && <Documentation leadId={leadId} getPropsForField={getPropsForField} />}
        </GridTab>
        <GridTab container value={selectedTab} index={5}>
          {selectedTab === 5 && <Feed leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={6}>
          {selectedTab === 6 && <MoreData leadId={leadId} getPropsForField={getPropsForField} />}
        </GridTab>
        <GridTab container value={selectedTab} index={7}>
          {selectedTab === 7 && <Opportunities leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={8}>
          {selectedTab === 8 && <CallsAndTasks leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={9}>
          {selectedTab === 9 && <StatusList leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={10}>
          {selectedTab === 10 && <Admin leadId={leadId} getPropsForField={getPropsForField} />}
        </GridTab>
        <GridTab container value={selectedTab} index={11}>
          {selectedTab === 11 && <Marketing leadId={leadId} />}
        </GridTab>
        <GridTab container value={selectedTab} index={12}>
          {selectedTab === 12 && <ActionLogs gqlQueryName="lead" gqlQuery={ACTION_LOGS_QUERY} resourceId={leadId} />}
        </GridTab>
      </SwipeableViews>
    </>
  )
}

TabsScene.propTypes = {
  leadId: PropTypes.string.isRequired,
  selectedCar: PropTypes.string,
  setOpenModal: PropTypes.func.isRequired,
  lastRefreshAt: PropTypes.string
}

TabsScene.defaultProps = {
  selectedCar: null,
  lastRefreshAt: null
}
