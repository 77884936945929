export const spainProvinces = [
  "Álava",
  "Albacete",
  "Alicante",
  "Almería",
  "Ávila",
  "Badajoz",
  "Baleares",
  "Barcelona",
  "Burgos",
  "Cáceres",
  "Cádiz",
  "Castellón",
  "Ciudad Real",
  "Córdoba",
  "La Coruña",
  "Cuenca",
  "Girona",
  "Granada",
  "Guadalajara",
  "Guipuzcoa",
  "Huelva",
  "Huesca",
  "Jaén",
  "León",
  "Lleida",
  "La Rioja",
  "Lugo",
  "Madrid",
  "Málaga",
  "Murcia",
  "Navarra",
  "Orense",
  "Asturias",
  "Palencia",
  "Las Palmas de G.C.",
  "Pontevedra",
  "Salamanca",
  "Tenerife",
  "Cantabria",
  "Segovia",
  "Sevilla",
  "Soria",
  "Tarragona",
  "Teruel",
  "Toledo",
  "Valencia",
  "Valladolid",
  "Vizcaya",
  "Zamora",
  "Zaragoza",
  "Ceuta",
  "Melilla"
]

export const spainProvinceOptions = () =>
  spainProvinces.map((p, index) => {
    return { value: index + 1, label: p }
  })

export const allDeliveryProvinceOptions = () => ({
  es: spainProvinceOptions(),
  it: [],
  pt: [],
  fr: []
})
