import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import { Paragraph } from "../../styled/typography"

const DO_NOT_CALL_AGAIN_LEAD = gql`
  mutation doNotCallAgainLead($id: ID!) {
    doNotCallAgainLead(input: { leadId: $id }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function DoNotCallLeadAgain(props) {
  const { leadId, handleSuccess } = props
  const { t } = useTranslation("lead")
  const [mutation, { data, loading, error }] = useMutation(DO_NOT_CALL_AGAIN_LEAD, {
    onCompleted: newData => {
      if (newData.doNotCallAgainLead?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.doNotCallAgainLead?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = () => {
    mutation({
      variables: { id: leadId }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <Paragraph>{t("doNotCallExplanation")}</Paragraph>
      <ModalPrimaryButton isFetching={loading}>{t("doNotCall")}</ModalPrimaryButton>
    </Form>
  )
}

DoNotCallLeadAgain.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
