import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import * as PropTypes from "prop-types"
import BasicInput from "../inputs/BasicInput"
import { ModalPrimaryButton } from "../../styled/buttons"
import { jobTypeOptions, localeOptions, originOptions } from "../../../helpers/constants"
import TextSelectInput from "../inputs/TextSelectInput"
import CountryCodeSelector from "../inputs/CountryCodeSelector"

const CREATE_LEAD = gql`
  mutation createLead($leadArguments: CreateLeadInputObject!) {
    createLead(input: { leadArguments: $leadArguments }) {
      result {
        id
      }
      errors {
        messages
        path
      }
    }
  }
`

export default function CreateLead(props) {
  const { handleSuccess } = props
  const [mutation, { data, loading, error }] = useMutation(CREATE_LEAD, {
    onCompleted: newData => {
      if (newData.createLead?.errors == null) {
        handleSuccess(newData.createLead.result)
      }
    }
  })
  const errors = data?.createLead?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leadArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <BasicInput field="firstName" label="First Name" errors={errors} required />
      <BasicInput field="lastName" label="Last Name" errors={errors} required />
      <BasicInput field="phone" label="Phone" errors={errors} required />
      <BasicInput field="email" label="Email" errors={errors} required />
      <TextSelectInput field="jobType" errors={errors} label="Job type" options={jobTypeOptions} required />
      <TextSelectInput field="origin" name="origin" label="Origin" errors={errors} options={originOptions} required />
      <CountryCodeSelector required />
      <TextSelectInput field="locale" label="Locale" options={localeOptions} required />
      <ModalPrimaryButton isFetching={loading}>Create Lead</ModalPrimaryButton>
    </Form>
  )
}

CreateLead.defaultProps = {
  handleSuccess: () => {}
}

CreateLead.propTypes = {
  handleSuccess: PropTypes.func
}
