import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { gql, useQuery } from "@apollo/client"
import { isNil } from "lodash"
import Opportunity from "./Opportunity"
import Loading from "../../../../../components/Loading"

const LEAD_OPPORTUNITIES = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      opportunities {
        id
        kind
        createdAt
        basicCar {
          carId
          title
          duration
          kmYear
          color
          price
          distributorId
          outdated
        }
      }
    }
  }
`

const StyledGrid = styled(Grid)`
  padding: 1px;
`

export default function Opportunities(props) {
  const { leadId } = props
  const { loading, data } = useQuery(LEAD_OPPORTUNITIES, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.lead)) return <p>Not found</p>

  return (
    <StyledGrid container>
      {data.lead.opportunities.map(opp => (
        <Grid key={opp.id} item xs={12} md={4} lg={4}>
          <Opportunity opportunity={opp} />
        </Grid>
      ))}
    </StyledGrid>
  )
}

Opportunities.propTypes = {
  leadId: PropTypes.string.isRequired
}
