import { RECEIVE_NOTIFICATION, REMOVE_NOTIFICATION } from "../actions/actions"

const defaultState = {
  message: "",
  variant: "success"
}

function notificationsReducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATION: {
      return { ...state, message: action.message, variant: action.params.variant }
    }
    case REMOVE_NOTIFICATION: {
      return { ...state, message: "" }
    }
    default: {
      return state
    }
  }
}

export default notificationsReducer
