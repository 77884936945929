import PropTypes from "prop-types"
import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import StaffSelector from "../inputs/StaffSelector"

const REASSIGN_LEASING_REQUEST = gql`
  mutation reassignLeasingRequest($id: ID!, $leasingRequestArguments: ReassignLeasingRequestInputObject!) {
    reassignLeasingRequest(input: { leasingRequestId: $id, leasingRequestArguments: $leasingRequestArguments }) {
      errors {
        messages
        path
      }
    }
  }
`

export default function ReassignLeasingRequest(props) {
  const { leasingRequestId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const [mutation, { data, loading, error }] = useMutation(REASSIGN_LEASING_REQUEST, {
    onCompleted: newData => {
      if (newData.reassignLeasingRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.reassignLeasingRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { id: leasingRequestId, leasingRequestArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <StaffSelector field="assignedAtId" />
      <StaffSelector label={t("common:contractExtensionAssignedAtId")} field="contractExtensionAssignedAtId" />
      <ModalPrimaryButton isFetching={loading}>{t("reassignSalesCloser")}</ModalPrimaryButton>
    </Form>
  )
}

ReassignLeasingRequest.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  leasingRequestId: PropTypes.string.isRequired
}
