import React from "react"
import PropTypes from "prop-types"
import {
  CallEnd as CallEndIcon,
  Mic as MicIcon,
  MicOff as MicOffIcon,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellular4Bar
} from "@material-ui/icons"
import Draggable from "react-draggable"
import styled from "styled-components"
import { CircularProgress, Collapse, Fab, Paper } from "@material-ui/core"
import { useTranslation } from "react-i18next"

const StyledPaper = styled(Paper)`
  position: fixed;
  bottom: 10vh;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  z-index: 1400;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
`

const StyledAvatar = styled(Fab)`
  background: ${props => (props.disabled ? "var(--warm-grey)" : "var(--kiwi-green)")};
  cursor: pointer;
`

const SubjectData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`

const CircularProgressStyled = styled(CircularProgress)`
  color: var(--kiwi-green);
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
`

const HangUpButton = styled(Fab)`
  margin: 0;
  background-color: var(--warm-red);
  color: var(--white);
`

const MicButton = styled(Fab)`
  background-color: var(--white);
  margin: 0 15px 0 0;
`

const SignalWarning = styled.div`
  text-transform: none;
  text-align: center;
  margin-top: 10px;
`

const SubjectName = styled.p`
  max-width: 150px;
  cursor: pointer;
  margin: 0;
`

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: inline-flex;
  margin: 0 15px 0 0;
`

const CallActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export default function Popup(props) {
  const { t } = useTranslation()
  const {
    allPartiesInCall,
    goToSubject,
    subjectName,
    subjectType,
    micToggle,
    muted,
    hangUp,
    signalWarning,
    showHangUpButton,
    actions,
    footer
  } = props

  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <StyledPaper>
        <SubjectData>
          <Wrapper>
            <StyledAvatar disabled={!allPartiesInCall} onClick={goToSubject}>
              {signalWarning && <SignalCellularConnectedNoInternet2Bar />}
              {!signalWarning && <SignalCellular4Bar />}
            </StyledAvatar>
            {!allPartiesInCall && <CircularProgressStyled size={68} />}
          </Wrapper>
          <SubjectName onClick={goToSubject}>{subjectName}</SubjectName>
        </SubjectData>
        <CallActions>
          <MicButton onClick={micToggle} disabled={!allPartiesInCall} size="large">
            {muted ? <MicOffIcon fontSize="large" /> : <MicIcon fontSize="large" />}
          </MicButton>
          {showHangUpButton && (
            <HangUpButton onClick={hangUp} disabled={!allPartiesInCall || subjectType === "Conference"} size="large">
              <CallEndIcon fontSize="large" />
            </HangUpButton>
          )}
          {actions}
        </CallActions>
        <Collapse in={signalWarning}>
          <SignalWarning>{t("twilioSignalWarning")}</SignalWarning>
        </Collapse>
        {footer}
      </StyledPaper>
    </Draggable>
  )
}

Popup.propTypes = {
  allPartiesInCall: PropTypes.bool.isRequired,
  goToSubject: PropTypes.func.isRequired,
  hangUp: PropTypes.func.isRequired,
  micToggle: PropTypes.func.isRequired,
  muted: PropTypes.bool.isRequired,
  subjectName: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
  signalWarning: PropTypes.bool.isRequired,
  showHangUpButton: PropTypes.bool,
  actions: PropTypes.node,
  footer: PropTypes.node
}

Popup.defaultProps = {
  showHangUpButton: true,
  actions: null,
  footer: null
}
