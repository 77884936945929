import React from "react"
import * as PropTypes from "prop-types"
import SignedIn from "../../../components/layouts/SignedIn"
import Allowed from "../../../components/layouts/Allowed"
import LeasingRequestScene from "./LeasingRequestScene"

export default function ShowScene(props) {
  const { match } = props
  const { leasingRequestId } = match.params

  return (
    <SignedIn>
      <Allowed
        ability={[
          "read_leasing_requests",
          "manage_own_leasing_requests",
          "manage_country_leasing_requests",
          "manage_leasing_requests"
        ]}>
        <LeasingRequestScene leasingRequestId={leasingRequestId} />
      </Allowed>
    </SignedIn>
  )
}

ShowScene.propTypes = {
  match: PropTypes.object.isRequired
}
