import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { first, orderBy } from "lodash"
import Email from "./Email"
import FullEmail from "./FullEmail"
import { EmailContext } from "../../contexts"

export default function EmailList(props) {
  const { emails, gqlMutation, resourceId, resource, locale, onEmailLoaded, disabledDocuments } = props
  const sortedEmails = orderBy(emails, "createdAt", "desc")
  const [clickedEmail, setClickedEmail] = useState(first(sortedEmails))

  useEffect(() => {
    onEmailLoaded()
  }, [clickedEmail])

  const viewEmail = email => {
    return () => {
      setClickedEmail(email)
    }
  }

  return (
    <>
      <EmailContext.Provider
        value={{
          gqlMutation,
          resourceId,
          resource,
          locale,
          disabledDocuments
        }}>
        {sortedEmails.map(email => {
          return clickedEmail === email ? (
            <FullEmail key={email.id} email={email} handleClick={() => () => setClickedEmail(null)} />
          ) : (
            <Email key={email.id} email={email} handleClick={viewEmail} />
          )
        })}
      </EmailContext.Provider>
    </>
  )
}

EmailList.propTypes = {
  emails: PropTypes.array.isRequired,
  gqlMutation: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  onEmailLoaded: PropTypes.func,
  resource: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  disabledDocuments: PropTypes.bool
}

EmailList.defaultProps = {
  onEmailLoaded: () => {},
  disabledDocuments: false
}
