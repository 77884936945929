import React from "react"
import SalesCloserSummarize from "./SalesCloserSummarize"
import PendingTasks from "./PendingTasks"

export default function SalesCloserDashboard() {
  return (
    <>
      <SalesCloserSummarize />
      <PendingTasks />
    </>
  )
}
