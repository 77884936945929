import React from "react"
import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import SignIn from "../../components/forms/SignIn"
import SignedOut from "../../components/layouts/SignedOut"

const StyledPaper = styled(Paper)`
  overflow: hidden;
  padding: 20px;
  position: relative;
`

export default function MainScene() {
  return (
    <SignedOut>
      <Grid container justify="center">
        <Grid item xs={10} md={4} lg={3} xl={2}>
          <StyledPaper>
            <SignIn />
          </StyledPaper>
        </Grid>
      </Grid>
    </SignedOut>
  )
}
