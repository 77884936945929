import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Grid } from "@material-ui/core"
import React from "react"
import { gql, useQuery } from "@apollo/client"
import { isNil } from "lodash"
import InlineEditableField from "../../../../../components/fields/InlineEditableField"
import BasicField from "../../../../../components/fields/BasicField"
import Loading from "../../../../../components/Loading"

const LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      validatedEmail
      idNumber
      birthday
      birthProvince
      street
      streetNumber
      postalCode
      town
      province
      cif
      employedSince
      invoiced
      netReturns
      actualMonthlyIncome
      promotion
      promotionOption
      promotionStartedAt
    }
  }
`

export default function MoreData(props) {
  const { leadId, getPropsForField } = props
  const { t } = useTranslation("common", "lead")
  const { loading, data } = useQuery(LEAD, {
    variables: {
      id: leadId
    }
  })

  if (loading) return <Loading />
  if (isNil(data?.lead)) return <p>Not found</p>

  return (
    <>
      <Grid item xs={12} md={6}>
        <BasicField title={t("validatedEmail")} type="boolean" value={data.lead.validatedEmail} />
        <InlineEditableField title={t("idNumber")} {...getPropsForField("idNumber", data)} />
        <InlineEditableField title={t("birthday")} type="date" {...getPropsForField("birthday", data)} />
        <InlineEditableField title={t("birthProvince")} {...getPropsForField("birthProvince", data)} />
        <InlineEditableField title={t("street")} {...getPropsForField("street", data)} />
        <InlineEditableField title={t("streetNumber")} {...getPropsForField("streetNumber", data)} />
        <InlineEditableField title={t("postalCode")} {...getPropsForField("postalCode", data)} />
        <InlineEditableField title={t("town")} {...getPropsForField("town", data)} />
        <InlineEditableField title={t("province")} {...getPropsForField("province", data)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InlineEditableField title={t("companyName")} {...getPropsForField("companyName", data)} />
        <InlineEditableField title={t("companyCif")} {...getPropsForField("cif", data)} />
        <BasicField title={t("employedSince")} type="date" value={data.lead.employedSince} />
        <BasicField title={t("invoiced")} value={data.lead.invoiced} />
        <BasicField title={t("netReturns")} value={data.lead.netReturns} />
        <BasicField title={t("actualMonthlyIncome")} value={data.lead.actualMonthlyIncome} />
        <BasicField type="boolean" title={t("promotion")} value={data.lead.promotion} />
        {data.lead.promotion && (
          <>
            <BasicField
              title={t("promotionOption")}
              value={data.lead.promotionOption}
              humanizeFunc={value => t(`common:promotionOptions.${value}`)}
            />
            <BasicField title={t("promotionStartedAt")} value={data.lead.promotionStartedAt} />
          </>
        )}
      </Grid>
    </>
  )
}

MoreData.propTypes = {
  getPropsForField: PropTypes.func.isRequired,
  leadId: PropTypes.string.isRequired
}
