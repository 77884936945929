import { shallowEqual, useSelector } from "react-redux"
import React from "react"
import PropTypes from "prop-types"
import { useFormState } from "informed"
import Distributor from "../../../../models/distributor"
import BasicInput from "../BasicInput"
import CheckboxInput from "../CheckboxInput"

export default function GuarantorInput(props) {
  const { distributorId, getPropsForField, initialGuarantorValue } = props
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const formState = useFormState()
  const distributor = Distributor.getDistributor(distributors, distributorId)

  return (
    <>
      <CheckboxInput {...getPropsForField("guarantor", initialGuarantorValue)} />
      {formState.values.guarantor && (
        <>
          <BasicInput {...getPropsForField("guarantorName")} required />
          <BasicInput {...getPropsForField("guarantorEmail")} required />
          <BasicInput {...getPropsForField("guarantorIdNumber")} required />
          {(distributor.isLeaseplanFleetsES() || distributor.isLeaseplanES()) && (
            <>
              <BasicInput {...getPropsForField("guarantorPhone")} required />
              <BasicInput {...getPropsForField("guarantorStreet")} required />
              <BasicInput {...getPropsForField("guarantorStreetNumber")} required />
              <BasicInput {...getPropsForField("guarantorPostalCode")} required />
              <BasicInput {...getPropsForField("guarantorTown")} required />
              <BasicInput {...getPropsForField("guarantorProvince")} required />
            </>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

GuarantorInput.propTypes = {
  distributorId: PropTypes.string.isRequired,
  getPropsForField: PropTypes.func.isRequired,
  initialGuarantorValue: PropTypes.bool
}

GuarantorInput.defaultProps = {
  initialGuarantorValue: null
}
