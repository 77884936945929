import { Grid } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CompletedTasks from "./CompletedTasks"
import SalesSummarize from "./SalesSummarize"
import CurrentLeasingRequests from "./CurrentLeasingRequests"
// import NextTask from "./NextTask"
import StartStopWorking from "./StartStopWorking"
import { getToday } from "../../../services/accountService"

export default function SalesDashboard() {
  const [dashboardData, setDashboardData] = useState()

  useEffect(() => {
    let mounted = true
    getToday().then(response => {
      if (mounted) setDashboardData(response.data)
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      <Grid container>
        {/* <NextTask /> */}
        <StartStopWorking />
      </Grid>
      <CompletedTasks />
      <SalesSummarize dashboardData={dashboardData} />
      <CurrentLeasingRequests />
    </>
  )
}
