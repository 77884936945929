import React from "react"
import * as PropTypes from "prop-types"
import { StyledH1 } from "../../styled/typography"
import useAbilities from "../../../hooks/useAbilities"

export default function Allowed(props) {
  const { children, ability } = props
  const { allowed } = useAbilities(ability)

  if (!allowed) return <StyledH1>Not allowed!</StyledH1>

  return children
}

Allowed.defaultProp = {
  children: null
}

Allowed.propTypes = {
  children: PropTypes.node,
  ability: PropTypes.any.isRequired
}
