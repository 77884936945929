import React from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Grid } from "@material-ui/core"
import { Check, Clear } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import styled from "styled-components"
import Step from "../Step"
import StepIcon from "../StepIcon"
import { Paragraph } from "../../../../../styled/typography"
import { lastStatusDate } from "../../../../../../helpers/utils"

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main
  },
  failure: {
    color: "var(--warm-red)"
  }
}))

const StyledTitle = styled(Paragraph)`
  font-size: 120%;
  font-weight: 600;
`

const Status = props => {
  const { completed, title, subtitle } = props
  const classes = useStyles()

  return (
    <Grid container spacing={0} justify="center" className={completed ? classes.success : classes.failure}>
      <Grid item>
        {completed && <Check />}
        {!completed && <Clear />}
      </Grid>
      <Grid item>
        <StyledTitle title={subtitle}>{title}</StyledTitle>
      </Grid>
    </Grid>
  )
}

Status.propTypes = {
  completed: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
}

Status.defaultProps = {
  subtitle: null
}

const calculateVariant = (online, status, documentationReady) => {
  if (online) return "completed"
  if (status === "new" && documentationReady) return "current"
  if (!documentationReady) return "default"

  return "completed"
}

export default function ScoringDocumentation(props) {
  const { leasingRequest } = props
  const { documentationReady, status, globalStatuses, offerStatus, quotationStatus, online } = leasingRequest
  const { t } = useTranslation("leasingRequest")

  const variant = calculateVariant(online, status, documentationReady)
  const startedAt = lastStatusDate(globalStatuses, "scoring_documentation_pending", "createdAt")
  const endedAt = lastStatusDate(globalStatuses, "scoring_documentation_pending", "endedAt")

  return (
    <Step
      title={t("flowSteps.scoringDocumentation")}
      variant={variant}
      startedAt={startedAt}
      endedAt={endedAt}
      leftArrow
      rightArrow>
      {online && <StepIcon icon={Check} variant={variant} />}
      {!online && (
        <>
          <Status
            title={t("common:quotation")}
            subtitle={t(`quotationStatuses.${quotationStatus}`)}
            completed={quotationStatus === "signed"}
          />
          <Status
            title={t("common:offer")}
            subtitle={t(`offerStatuses.${offerStatus}`)}
            completed={offerStatus === "received"}
          />
        </>
      )}
    </Step>
  )
}

ScoringDocumentation.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
