import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import PropTypes from "prop-types"
import React from "react"

export default function EnhancedTableHead(props) {
  const { sort, orderBy, attributes, onRequestSort } = props

  const createSortHandler = attribute => {
    return event => {
      onRequestSort(event, attribute)
    }
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {attributes.map(attribute => {
          return attribute.sortable ? (
            <TableCell key={attribute.name} sortDirection={orderBy === attribute.name ? sort : false}>
              <TableSortLabel
                active={orderBy === attribute.name}
                direction={sort}
                onClick={createSortHandler(attribute.name)}>
                {attribute.label || attribute.name}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={attribute.name}>{attribute.label || attribute.name}</TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired
}
