import PropTypes from "prop-types"
import { Form } from "informed"
import Grid from "@material-ui/core/Grid"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { filter, includes, isNil } from "lodash"
import BasicInput from "../../../forms/inputs/BasicInput"
import TextSelectInput from "../../../forms/inputs/TextSelectInput"
import {
  jobTypeOptions,
  colorCarOptions,
  cvOptions,
  deliveryTimeOptions,
  fuelCarOptions,
  kindCarOptions,
  leasingDurationsOptions,
  leasingKmsOptions,
  monthlyPriceOptions,
  sellingStatusCarOptions,
  transmissionCarOptions
} from "../../../../helpers/constants"
import DistributorSelector from "../../../forms/inputs/DistributorSelector"
import { allDeliveryProvinceOptions } from "../../../../helpers/provinces"

const CAR_DELIVERY_PROVINCES = gql`
  query account {
    account {
      carDeliveryProvinces
    }
  }
`

const StyledForm = styled(Form)`
  background-color: var(--light-grey);
  margin: 0 -24px 15px -24px;
  padding: 0 24px;
`

export default function Filter(props) {
  const { filters, setFilters, countryCode } = props
  const { t } = useTranslation()
  const [deliveryProvinceOptions, setDeliveryProvinceOptions] = useState([])
  const { data } = useQuery(CAR_DELIVERY_PROVINCES)

  const countryDeliveryProvinceOptions = allDeliveryProvinceOptions()[countryCode]

  useEffect(() => {
    if (isNil(data?.account?.carDeliveryProvinces)) return

    const filtered = filter(countryDeliveryProvinceOptions, option =>
      includes(data.account.carDeliveryProvinces, option.value)
    )
    setDeliveryProvinceOptions(filtered)
  }, [data])

  const handleFilterValueChange = values => {
    setFilters(JSON.parse(JSON.stringify(values)))
  }

  return (
    <StyledForm onValueChange={handleFilterValueChange}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={8}>
          <BasicInput field="q" label={t("common:search")} variant="outlined" initialValue={filters.q} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="availableFor"
            label={t("common:jobType")}
            options={jobTypeOptions}
            variant="outlined"
            displayClear
            humanizeFunc={value => (value ? t(`jobTypes.${value}`) : null)}
            initialValue={filters.availableFor}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="kind"
            label={t("common:kind.title")}
            options={kindCarOptions(t)}
            variant="outlined"
            displayClear
            initialValue={filters.kind}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="minPrice"
            label={t("common:minPrice")}
            options={monthlyPriceOptions}
            variant="outlined"
            displayClear
            initialValue={filters.minPrice}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="maxPrice"
            label={t("common:maxPrice")}
            options={monthlyPriceOptions}
            variant="outlined"
            displayClear
            initialValue={filters.maxPrice}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="duration"
            label={t("common:duration")}
            options={leasingDurationsOptions}
            variant="outlined"
            displayClear
            initialValue={filters.duration}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="kmYear"
            label={t("common:kmYear")}
            options={leasingKmsOptions}
            variant="outlined"
            displayClear
            initialValue={filters.kmYear}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="fuel"
            label={t("common:fuel.title")}
            options={fuelCarOptions(t)}
            variant="outlined"
            displayClear
            initialValue={filters.fuel}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="transmission"
            label={t("common:transmission.title")}
            options={transmissionCarOptions}
            variant="outlined"
            displayClear
            initialValue={filters.transmission}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="color"
            label={t("common:color")}
            options={colorCarOptions}
            variant="outlined"
            displayClear
            initialValue={filters.color}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="deliveryTime"
            label={t("common:deliveryTime")}
            options={deliveryTimeOptions}
            variant="outlined"
            displayClear
            initialValue={filters.deliveryTime}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DistributorSelector
            variant="outlined"
            countryCode={countryCode}
            displayClear
            initialValue={filters.distributorId}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="sellingStatus"
            label={t("common:sellingStatus.title")}
            options={sellingStatusCarOptions}
            variant="outlined"
            displayClear
            initialValue={filters.sellingStatus}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="minCV"
            label={t("common:minCV")}
            options={cvOptions}
            variant="outlined"
            displayClear
            initialValue={filters.minCV}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextSelectInput
            field="deliveryProvinces"
            label={t("common:deliveryProvinces")}
            options={deliveryProvinceOptions}
            variant="outlined"
            displayClear
            initialValue={filters.deliveryProvinces}
          />
        </Grid>
      </Grid>
    </StyledForm>
  )
}

Filter.propTypes = {
  countryCode: PropTypes.string,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired
}

Filter.defaultProps = {
  countryCode: null
}
