import PropTypes from "prop-types"
import React, { useContext } from "react"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton"
import { CarListDialogContext } from "../../../../contexts"
import PlansTable from "../../../PlansTable"

function ActionJSX(props) {
  const { id, priceRange, car } = props
  const { onPlanClick, options } = useContext(CarListDialogContext)
  const { selectedCars } = options

  const selected = selectedCars.find(c => c.priceId === priceRange.id && c.carId === car.id)

  return (
    <IconButton
      size="small"
      color="primary"
      onClick={() => onPlanClick(id, priceRange.id, selected?.id, car.distributorId)}
    >
      {selected ? <CheckIcon style={{ color: "green" }} /> : <AddIcon />}
    </IconButton>
  )
}

ActionJSX.propTypes = {
  id: PropTypes.string.isRequired,
  car: PropTypes.object.isRequired,
  priceRange: PropTypes.object.isRequired
}

export default function Plans(props) {
  const { car } = props

  return (
    <PlansTable
      id={car.id}
      durationsIncluded={car.durationsIncluded}
      kmsYearIncluded={car.kmsYearIncluded}
      car={car}
      minimumPrice={car.minimumPrice}
      ActionJSX={ActionJSX}
    />
  )
}

Plans.propTypes = {
  car: PropTypes.object.isRequired
}
