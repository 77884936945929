import React from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Check } from "@material-ui/icons"
import Step from "../Step"
import StepIcon from "../StepIcon"
import { lastStatusDate } from "../../../../../../helpers/utils"

const calculateVariant = (online, documentationReady) => {
  if (online || documentationReady) return "completed"

  return "current"
}

export default function DocumentationReview(props) {
  const { leasingRequest } = props
  const { createdAt, documentationReady, globalStatuses, online } = leasingRequest
  const { t } = useTranslation("leasingRequest")

  const variant = calculateVariant(online, documentationReady)
  const endedAt = lastStatusDate(globalStatuses, "documentation_review", "endedAt")

  return (
    <Step
      title={t("flowSteps.documentationReview")}
      variant={variant}
      startedAt={createdAt}
      endedAt={endedAt}
      rightArrow>
      {online && <StepIcon icon={Check} variant={variant} />}
    </Step>
  )
}

DocumentationReview.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
