import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { IconButton } from "@material-ui/core"
import { Call, DeleteForever } from "@material-ui/icons"
import { printDateTime } from "../../helpers/formatter"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`
const StyledCallIcon = styled(Call)`
  margin-right: 5px;
  color: var(--stormy-blue);
`

const StyledParagraph = styled.small`
  font-weight: bold;
  vertical-align: top;
  line-height: 24px;
  font-size: 16px;
`

export default function PendingScheduledCall(props) {
  const { pendingScheduledCallAt, showRemoveModal } = props
  const { t } = useTranslation()

  if (isEmpty(pendingScheduledCallAt))
    return (
      <Wrapper>
        <StyledCallIcon />
        <StyledParagraph>{t("scheduledCallMissing")}</StyledParagraph>
      </Wrapper>
    )

  const label = t("pendingScheduledCallAt", { time: printDateTime(pendingScheduledCallAt, "HH:mm DD/MM/YYYY", true) })

  return (
    <Wrapper>
      <StyledCallIcon />
      <StyledParagraph>{label}</StyledParagraph>
      {showRemoveModal && (
        <IconButton color="secondary" onClick={showRemoveModal}>
          <DeleteForever />
        </IconButton>
      )}
    </Wrapper>
  )
}

PendingScheduledCall.propTypes = {
  pendingScheduledCallAt: PropTypes.string,
  showRemoveModal: PropTypes.func
}

PendingScheduledCall.defaultProps = {
  pendingScheduledCallAt: null,
  showRemoveModal: null
}
