import React from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Check, Clear } from "@material-ui/icons"
import { Divider } from "@material-ui/core"
import styled from "styled-components"
import { includes } from "lodash"
import Step from "../Step"
import StepIcon from "../StepIcon"
import { Paragraph } from "../../../../../styled/typography"
import { leasingRequestOngoingContractExtensionStatuses } from "../../../../../../helpers/constants"
import { lastStatusDate } from "../../../../../../helpers/utils"

const StatusTitle = styled(Paragraph)`
  font-size: 110%;
  font-weight: bold;
`

const StatusSubtitle = styled(Paragraph)`
  font-size: 110%;
`

const StyledDivider = styled(Divider)`
  margin: 15px auto;
`

const calculateVariant = ({ won, contractExtensionStatus, earlyCancelled }) => {
  if (earlyCancelled) return "cancelled"
  if (contractExtensionStatus === "won") return "completed"
  if (contractExtensionStatus === "rejected") return "cancelled"
  if (includes(leasingRequestOngoingContractExtensionStatuses, contractExtensionStatus)) return "current"
  if (won) return "completed"

  return "default"
}

const calculateStartedAt = ({ contractExtensionStatus, contractExtensionStatuses, wonAt }) => {
  if (contractExtensionStatus === "won") return lastStatusDate(contractExtensionStatuses, ["won"])
  if (contractExtensionStatus === "rejected") return lastStatusDate(contractExtensionStatuses, ["rejected"])
  if (includes(leasingRequestOngoingContractExtensionStatuses, contractExtensionStatus))
    return lastStatusDate(contractExtensionStatuses, ["pending"])

  return wonAt
}

export default function Result(props) {
  const { leasingRequest } = props
  const {
    closedStatus,
    contractExtensionStatus,
    contractExtensionStatuses,
    contractExtensionAttempts,
    won,
    wonAt,
    earlyCancelled
  } = leasingRequest
  const { t } = useTranslation("leasingRequest")

  const variant = calculateVariant({ won, contractExtensionStatus, earlyCancelled })
  const ongoingContractExtension = includes(leasingRequestOngoingContractExtensionStatuses, contractExtensionStatus)
  const startedAt = calculateStartedAt({ contractExtensionStatus, contractExtensionStatuses, wonAt })

  return (
    <Step title={t("flowSteps.result")} variant={variant} startedAt={startedAt} leftArrow>
      <StatusTitle>{t(`common:closedStatus.${closedStatus}`)}</StatusTitle>
      {contractExtensionStatus === "won" && (
        <>
          <StyledDivider />
          <StatusTitle>
            {t("common:contractExtension")} {contractExtensionAttempts}
          </StatusTitle>
          <StatusSubtitle>{t(`common:contractExtensionStatus.${contractExtensionStatus}`)}</StatusSubtitle>
          <StepIcon icon={Check} variant={variant} />
        </>
      )}
      {contractExtensionStatus === "rejected" && (
        <>
          <StyledDivider />
          <StatusTitle>{t("common:contractExtension")}</StatusTitle>
          <StatusSubtitle>{t(`common:contractExtensionStatus.${contractExtensionStatus}`)}</StatusSubtitle>
          <StepIcon icon={Clear} variant={variant} />
        </>
      )}
      {ongoingContractExtension && (
        <>
          <StyledDivider />
          <StatusTitle>{t("common:contractExtension")}</StatusTitle>
          <StatusSubtitle>{t(`common:contractExtensionStatus.${contractExtensionStatus}`)}</StatusSubtitle>
        </>
      )}
      {earlyCancelled && (
        <>
          <StyledDivider />
          <StatusTitle>{t("leasingRequest:earlyCancelled")}</StatusTitle>
        </>
      )}
      {!ongoingContractExtension && contractExtensionStatus !== "won" && contractExtensionStatus !== "rejected" && (
        <>
          <StepIcon icon={Check} variant={variant} />
        </>
      )}
    </Step>
  )
}

Result.propTypes = {
  leasingRequest: PropTypes.object.isRequired
}
