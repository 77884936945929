import React from "react"
import { Form } from "informed"
import { gql, useMutation } from "@apollo/client"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { shallowEqual, useSelector } from "react-redux"
import { ModalPrimaryButton } from "../../styled/buttons"
import GraphQLErrors from "../../GraphQLErrors"
import TextSelectInput from "../inputs/TextSelectInput"
import Distributor from "../../../models/distributor"

const UPDATE_LEASING_REQUEST = gql`
  mutation updateLeasingRequest($leasingRequestId: ID!, $leasingRequestArguments: UpdateLeasingRequestInputObject!) {
    updateLeasingRequest(
      input: { leasingRequestId: $leasingRequestId, leasingRequestArguments: $leasingRequestArguments }
    ) {
      errors {
        messages
        path
      }
    }
  }
`

export default function UpdateLeasingRequestDistributorContact(props) {
  const { leasingRequestId, distributorId, handleSuccess } = props
  const { t } = useTranslation("leasingRequest")
  const { distributors } = useSelector(mapStateToProps, shallowEqual)
  const distributorContacts = Distributor.getDistributor(distributors, distributorId).contactOptions()

  const [mutation, { data, loading, error }] = useMutation(UPDATE_LEASING_REQUEST, {
    onCompleted: newData => {
      if (newData.updateLeasingRequest?.errors == null) {
        handleSuccess()
      }
    }
  })
  const errors = data?.updateLeasingRequest?.errors
  if (error) return <p>Server Error</p>

  const handleSubmit = values => {
    mutation({
      variables: { leasingRequestId, leasingRequestArguments: values }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <GraphQLErrors errors={errors} />
      <TextSelectInput
        field="distributorContactId"
        label={t("distributorContact")}
        errors={errors}
        options={distributorContacts}
        required
      />
      <ModalPrimaryButton isFetching={loading}>{t("updateDistributorContact")}</ModalPrimaryButton>
    </Form>
  )
}

const mapStateToProps = state => {
  const { commonDataReducer } = state
  const { distributors } = commonDataReducer

  return { distributors: distributors.items }
}

UpdateLeasingRequestDistributorContact.defaultProps = {
  handleSuccess: () => {}
}

UpdateLeasingRequestDistributorContact.propTypes = {
  distributorId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func,
  leasingRequestId: PropTypes.string.isRequired
}
